import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
/*-----------------------------------------系统管理----------------------------------*/
// 角色管理
export const sysRolelList = (data?: any) => request({
    url: `${BASEURL}/common/queryRoles`,
    method: 'GET',
    data,
})
//
export const sysRoleAdd = (data?: any) => request({
    url: `${BASEURL}/role/addRole`,
    data,
})

export const sysRoleDel = (data?: any) => request({
    url: `${BASEURL}/role/delRole`,
    data,
})

export const sysRoleUpdate = (data?: any) => request({
    url: `${BASEURL}/role/editRole`,
    data,
})

export const sysRoleAuthUpdate = (data?: any) => request({
    url: `${BASEURL}/role/editRoleOperationAuth`,
    data,
})

export const sysRoleAuthList = (data?: any) => request({
    url: `${BASEURL}/role/queryOperationAuth`,
    method: 'GET',
    data,
})

export const sysRoleAuthListByRole = (data?: any) => request({
    url: `${BASEURL}/role/queryOperationAuthIdsByRole`,
    method: 'GET',
    data,
})

// 获取数据权限
export const sysRoleGetDataAuth = (data?: any) => request({
    url: `${BASEURL}/role/getDataPermission`,
    method: 'GET',
    data,
})

export const sysRoleDataAuthSave = (data?: any) => request({
    url: `${BASEURL}/role/saveDataPermission`,
    data,
})

// 标签
export const sysLabelList = (data?: any) => request({
    url: `${BASEURL}/label/pageSearch`,
    method: 'GET',
    data,
})

export const sysLabelDel = (data?: any) => request({
    url: `${BASEURL}/label/deleteLabel`,
    data,
})

export const sysLabelAddUpdate = (data?: any) => request({
    url: `${BASEURL}/label/addOrUpdateLabel`,
    data,
})

// 线索池管理
export const sysPoolList = (data?: any) => request({
    url: `${BASEURL}/common/select`,
    data,
})

// 线索池管理  编辑回显数据
export const getDetailst = (data?: any) => request({
    url: `${BASEURL}/cluecool/details`,
    data,
})

export const sysPoolAdd = (data?: any) => request({
    url: `${BASEURL}/cluecool/new`,
    data,
})

export const sysPoolDel = (data?: any) => request({
    url: `${BASEURL}/cluecool/delete`,
    method: 'GET',
    data,
})

export const sysPoolUpdate = (data?: any) => request({
    url: `${BASEURL}/cluecool/edit`,
    data,
})

// 规则管理
export const sysRuleBackSave = (data?: any) => request({
    url: `${BASEURL}/rule/saveComeBack`,
    data,
})

// 启用or禁用
export const sysRuleBackIsUse = (data?: any) => request({
    url: `${BASEURL}/rule/startComBackRule`,
    data,
})

export const sysRuleBackList = (data?: any) => request({
    url: `${BASEURL}/rule/lookCustComeBack`,
    data,
})

export const sysRuleAutoList = (data?: any) => request({
    url: `${BASEURL}/rule/pageAuto`,
    data,
})

export const sysRuleAutoDel = (data?: any) => request({
    url: `${BASEURL}/rule/del`,
    data,
})

// 新增 自动划入规则
export const sysRuleAutoSaveAdd = (data?: any) => request({
    url: `${BASEURL}/rule/saveAuto`,
    data,
})

// 编辑 自动划入规则
export const sysRuleAutoSaveUpdata = (data?: any) => request({
    url: `${BASEURL}/rule/editAuto`,
    data,
})

// 启用划入规则
export const sysRuleAutoIsUse = (data?: any) => request({
    url: `${BASEURL}/rule/start`,
    data,
})

// 禁用划入规则
export const sysRuleAutoIsUseClose = (data?: any) => request({
    url: `${BASEURL}/rule/close`,
    data,
})

export const sysRuleCustList = (data?: any) => request({
    url: `${BASEURL}/rule/pageCust`,
    data,
})

export const sysRuleCustDel = (data?: any) => request({
    url: `${BASEURL}/rule/delcust`,
    data,
})

// 启用用户数量限制
export const sysRuleCustIsUse = (data?: any) => request({
    url: `${BASEURL}/rule/startcust`,
    data,
})

// 禁用用户数量限制
export const sysRuleCustIsUseClosecust = (data?: any) => request({
    url: `${BASEURL}/rule/closecust`,
    data,
})

//  新建客户数量限制
export const sysRuleCustSaveAdd = (data?: any) => request({
    url: `${BASEURL}/rule/newcust`,
    data,
})

//  修改客户数量限制
export const sysRuleCustSaveUpdata = (data?: any) => request({
    url: `${BASEURL}/rule/editcust`,
    data,
})


// 工作报告规则-- 审批报告查询
export const sysRuleGetAuditReport = (data?: any) => request({
    url: `${BASEURL}/rule/getWorkDailyReviewRule`,
    data,
})

// 工作报告规则-- 审批报告新增编辑
export const sysRuleAuditReportAddUpdate = (data?: any) => request({
    url: `${BASEURL}/rule/saveOrUpdateDailyReviewRule`,
    data,
})

// 工作报告规则-- 审批报告删除
export const sysRuleAuditReportDel = (data?: any) => request({
    url: `${BASEURL}/rule/delDailyReviewRule`,
    data,
})

// 工作报告规则-- 统计报告新增编辑
export const sysRuleGetSumReport = (data?: any) => request({
    url: `${BASEURL}/rule/getWorkDailyAnalysisRule`,
    data,
})

// 工作报告规则-- 统计报告新增编辑
export const sysRuleSumReportAddUpdate = (data?: any) => request({
    url: `${BASEURL}/rule/saveOrUpdateDailyAnalysisRule`,
    data,
})

// 工作报告规则-- 审批报告删除
export const sysRuleSumReportDel = (data?: any) => request({
    url: `${BASEURL}/rule/delDailyAnalysisRule`,
    data,
})

// 数据字典
export const sysDictList = (data?: any) => request({
    url: `${BASEURL}/common/searchDict`,
    data,
})

export const sysDictListRight = (data?: any) => request({
    url: `${BASEURL}/common/searchDictRight`,
    data,
})

export const sysDictAdd = (data?: any) => request({
    url: `${BASEURL}/dict/newdict`,
    data,
})

export const sysDictUpdate = (data?: any) => request({
    url: `${BASEURL}/dict/editdict`,
    data,
})

export const sysDictDelete = (data?: any) => request({
    url: `${BASEURL}/dict/del`,
    data,
})

export const sysDictOptionAdd = (data?: any) => request({
    url: `${BASEURL}/dict/newoption`,
    data,
})

export const sysDictOptionUpdate = (data?: any) => request({
    url: `${BASEURL}/dict/editoption`,
    data,
})

// 获取省市区数据
export const sysDictCitys = (data?: any) => request({
    url: `${BASEURL}/common/city`,
    data,
})

// 获取产品分类子维度
export const getProductCategory = (data?: any) => request({
    url: `${BASEURL}/common/getProductCategory`,
    method: 'GET',
    data,
})


// =======账务审批设置======
// 获取账务审批设置-合同审批层级设置
export const sysGetAuditRule = (data?: any) => request({
    url: `${BASEURL}/auditRule/getRule`,
    data,
})

// 获取账务审批设置-合同审批层级设置-保存
export const sysAuditRuleSave = (data?: any) => request({
    url: `${BASEURL}/auditRule/editRule`,
    data,
})
