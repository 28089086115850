import React, { useState } from "react";
import { Input, Select, DatePicker, TreeSelect, Tooltip, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Double, Ellipsis, Icon } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { customerSeePhoneNumber } from '@api/index'

// ts--types
export type TableListItem = {
    key: number;
    isSyn: number;
    id: number;
    mobilePhoneNumber: string;
    businessOpportunityTitle: string;
    customerName: string;
    estimatedSalesAmount: string;
    followBusinessLine: string;
    estimatedSigningDate: string;
    salesStage: string;
    creator: string;
    synergy: string;
    businessOppId: string;
    customerId: string;
};

const RenderPhoneNumber = ({record}: any) => {
    const {id, mobilePhoneNumber, repeatPhoneNumber, type, whetherContactOwner} = record || {}
    const [text, setText] = useState(mobilePhoneNumber)
    const onOwnClick = async () => {
        if (whetherContactOwner) {
            const {data } = await customerSeePhoneNumber({id, type}) as any
            setText(data)
        } else {
            message.error('您无权查看该信息')
        }
    }
    return (<div>
        <Tooltip placement="top" title={`该号码在系统中已重复${repeatPhoneNumber}个`}>{text}</Tooltip> 
        {/* { (text||'').length ? <Icon type="icon-icon_yulan" style={{cursor: 'pointer'}} onClick={onOwnClick} /> : null} */}
    </div>)
}

export type OperateActionType = '新建商机' | '添加协同人' | '转移线索' | '批量导出' | '批量删除' | '添加跟进'

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({dictId: 13 }, false)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '姓名',
        dataIndex: 'contactsName',
        key: 'contactsName',
        width: 100,
        order: 7,
        fixed: 'left',
    },
    {
        title: '职位',
        dataIndex: 'jobPosition',
        key: 'jobPosition',
        // valueType: 'select',
        order: 5,
        // params: { dictId: 22 },
        // request,
        render: (_, record: any) => `${record.jobPositionStr||'-'}` 
    },
    // {
    //     title: '主要联系人',
    //     dataIndex: 'primaryContact',
    //     key: 'primaryContact',
    //     fixed: 'left',
    //     width: 120, 
    //     hideInSearch: true,
    //     render: (text: any) => `${['1'].includes(text) ? '是' : '否'}`
    // },
    {
        title: '关联客户',
        dataIndex: 'customer',
        key: 'customer',
        width: 120, 
        order: 3,
    },
    {
        title: '手机号',
        dataIndex: 'mobilePhoneNumber',
        key: 'mobilePhoneNumber',
        width: 180,
        order: 6,
        render: (text: any, record: any) => <RenderPhoneNumber record={record} />
    },
    {
        title: '固话',
        dataIndex: 'fixedTelephoneNumber',
        key: 'fixedTelephoneNumber',
        hideInSearch: true,
    },
    {
        title: '电子邮箱',
        dataIndex: 'email',
        key: 'email',
        hideInSearch: true,
    },
    {
        title: '微信号码',
        dataIndex: 'wechatAccount',
        key: 'wechatAccount',
        hideInSearch: true,
    },
    // {
    //     title: 'QQ号码',
    //     dataIndex: 'qq',
    //     key: 'qq',
    //     hideInSearch: true,
    // },
    // {
    //     title: '钉钉号',
    //     dataIndex: 'dingNumber',
    //     key: 'dingNumber',
    //     hideInSearch: true,
    // },
    // {
    //     title: '联系结果反馈',
    //     dataIndex: 'contactFeedbackStr',
    //     key: 'contactFeedbackStr',
    //     hideInSearch: true,
    // },
    {
        title: '来源',
        dataIndex: 'source',
        key: 'source',
        order: 4,
        params: { dictId:27 },
        request,
        render: (text: any, record: any) => `${record.sourceStr||'-'}`
    },
    {
        title: '备注',
        dataIndex: 'remarks',
        key: 'remarks',
        hideInSearch: true,
    },
    // {
    //     title: '创建人',
    //     dataIndex: 'creator',
    //     key: 'creator',
    //     hideInSearch: true,
    // },
    {
        title: '客户归属人',
        dataIndex: 'ownerStr',
        key: 'ownerStr',
        order: 100,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
                const owner = (ids||[]).map((v: string) => v.slice(1))
                return { owner }
            },
        },
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        order: 2, 
        fieldProps: { allowEmpty: [true, true]},
        valueType: 'dateRange',
        render: (text: any, record: any) => `${record.createTime|| '-'}`
    },
    {
        title: '更新时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        order: 1,
        fixed: 'right',
        fieldProps: { allowEmpty: [true, true]},
        valueType: 'dateRange',
        render: (text: any, record: any) => `${record.updateTime||'-'}`
    },
    // {
    //     title: '操作',
    //     dataIndex: 'operate',
    //     key: 'operate',
    //     readOnly: true,
    //     render: (text: string, record: object) => [
    //      <a key="update" onClick={() => operate && operate('编辑联系人', record)}>编辑</a>
    //     ]
    // }
  ]
}
