import React, { useEffect, useRef, useState } from "react"
import { ProForm, ProFormDigit, ProFormRadio, ProFormCascader, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import ReactWEditor from 'wangeditor-for-react';
import { ImportButton, ProFormTreeSelect } from "@components/index";
import { useCommonApiHooks, useDeptDataHooks, useDocDataHooks, useHasAuthCode } from "@hooks/index";
import { BASEURL } from "@api/index";
import { Button, Form, message, Modal, TreeSelect } from "antd";
import WithSearchTree, { WithSearchTreeWarp } from "@components/Common/WithSearchTree";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { columnsFn0, columnsFn1, columnsFn2, columnsFn3, OperateActionType, TableListItem, tabPanes } from "./data";
import { MyTabs } from "@components/EnterpriseDetail";
import { ModalForm, ProFormText } from '@ant-design/pro-form' 
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { handleUploadFiles, productApi, treeDataDisabledRules } from "@utils/index";
import { knowledgeDocCategoryAddUpdate, knowledgeDocCategoryDel, knowledDocDel, knowledDocUpDown, knowledgeDocList, knowledgeDocCreate, knowledgeDragSort, knowledgeDocMove } from "@api/knowledge";
import { useHistory } from "react-router-dom";
import { stringify } from "qs";
import { UpLoadDocument } from "../cpns/MyDoc/Create";
import { calcCreatHref } from "../Home/data";

const getApi = productApi(knowledgeDocList)
const docDelApi = productApi(knowledDocDel)
const docUpDownApi = productApi(knowledDocUpDown)
const docCategoryAddUpdateApi = productApi(knowledgeDocCategoryAddUpdate)
const docCategoryDelApi = productApi(knowledgeDocCategoryDel)
const docCreateApi = productApi(knowledgeDocCreate) // 创建编辑文档
const dragSortApi = productApi(knowledgeDragSort)
const moveApi = productApi(knowledgeDocMove)

// 新增分类
interface AddCategoryProps extends ModalFormProps{
    onSuccess?: () => void;
    params?: Record<string, any>;
    values?: Record<string, any>;
}
export const AddCategory = ({params, values, onSuccess, ...rest}: AddCategoryProps) => {
    const formRef = useRef<ProFormInstance>()
    const onFinish = async (values: any) => {
        const { success } = await docCategoryAddUpdateApi({...values, ...params}, false, onSuccess)
        return success
    }
    const onVisibleChange = (visible: boolean) => {
        if(visible) {
            formRef.current?.setFieldsValue(values||{})
        } else {
            formRef.current?.resetFields()
        }
    }
    return (<ModalForm<AddCategoryProps> 
            params={params} 
            layout="horizontal" 
            width={600} 
            labelCol={{span: 5}} 
            onFinish={onFinish}  
            formRef={formRef} 
            onVisibleChange={onVisibleChange}
            {...rest}>
            <ProFormText label="分类名称" name="name" rules={[{required: true, message: '请填写分类名称'}]} />
    </ModalForm>)
}

// 上传文档
interface UpLoadDocProps extends ModalFormProps{
    onSuccess?: () => void;
    params?: Record<string, any>;
    hideFields?: string[];
    values?: Record<string, any>;
}
export const UpLoadDoc = ({params, values, hideFields, onFinish: pOnFinish, onSuccess, ...rest}: UpLoadDocProps) => {
    const formRef = useRef<ProFormInstance>()
    const uploadFormRef = useRef<ProFormInstance>()
    const onFinish = async () => {
        const values = await uploadFormRef.current?.validateFields().catch(err => false)
        console.log(values)
        if (values) {
            if (pOnFinish) {
                pOnFinish(values)
            } else {
                const {content: files, ...rest} = values
                const contents = (handleUploadFiles(files)||[]).map(({name: title, url: content}) => ({title, content}))
                await docCreateApi({...rest, files: contents, ...(params||{})}, true, onSuccess)
            }
        }
    }
    const onVisibleChange = (visible: boolean) => {
        if(visible) {
            uploadFormRef.current?.setFieldsValue(values||{})
        } else {
            uploadFormRef.current?.resetFields()
        }
    }
    return (<ModalForm<AddCategoryProps> 
            params={params} 
            layout="horizontal" 
            width={600} 
            onFinish={onFinish}  
            formRef={formRef} 
            onVisibleChange={onVisibleChange}
            {...rest}>
            <UpLoadDocument submitter={false} params={params} labelCol={{span: 6}} formRef={uploadFormRef} hideFields={hideFields||[]} />
    </ModalForm>)
}

export default () => {
    const history = useHistory()
    const [activeKey, setActiveKey] = useState('0')
    const [categoryId, setCategoryId] = useState<any>('')
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const { cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const tagsTreeData = treeDataDisabledRules(labelTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    // 获取客户的下拉框
    // const [options] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`}, {}, true)
    const [addCategoryProps, setAddCategoryProps] = useState<AddCategoryProps>({visible: false, title: '新增分类', values: {}, params: {}})
    const [upLoadDocProps, setUpLoadDocProps] = useState<UpLoadDocProps>({visible: false, title: '上传文档', values: {}, params: {}})
    const [moveDocProps, setMoveDocProps] = useState<UpLoadDocProps>({visible: false, title: '移动文档', values: {}, params: {}})

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [treeData, setTreeData, getTreeData] = useDocDataHooks({}, false)
    const treeSearch = ({filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }
    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
    }

    const onTreeDrop = async (info: any) => {
        const { dragNode, node, dropPosition } = info || {}
        const { id: fromId, pos: fromPos, parentId: fromParentId } = dragNode
        const { id, pos, parentId } = node 
        if (fromParentId !== parentId) {
            return message.warning('只支持同层级排序')
        }
        let fromIdx = fromPos.split('-').pop()
        let toIdx = pos.split('-').pop()
        if (fromIdx > toIdx) { // up
            console.log('up')
            fromIdx = Number(fromIdx) + 1
            toIdx = dropPosition > 0 ? Number(toIdx) + 2 : Number(toIdx) + 1
        } else { // down
            fromIdx = Number(fromIdx) + 1
            toIdx = Number(toIdx) + 1
        }
        const from = {id: fromId, parentId, pos: fromPos, index: fromIdx}
        const to = {id, parentId, pos, index: toIdx}
        await dragSortApi({from, to}, true, () => {
            getTreeData({type: Number(activeKey) + 1} as any)
        })
    }

    const titleOperate = (record: any, type: string) => {
        const { id, parentId, name } = record || {}
        if (type === 'add') {
            setAddCategoryProps({
                ...addCategoryProps,
                title: '新增分类',
                visible: true,
                values: {},
                params: {parentId: id, type: Number(activeKey) + 1},
                onSuccess: () => {
                    setAddCategoryProps({...addCategoryProps, visible: false})
                    getTreeData({type: Number(activeKey) + 1} as any)
                }
            })
        } else if (type === 'update') {
            setAddCategoryProps({
                ...addCategoryProps,
                title: '编辑分类',
                visible: true,
                values: {name},
                params: {id, type: Number(activeKey) + 1},
                onSuccess: () => {
                    setAddCategoryProps({...addCategoryProps, visible: false})
                    getTreeData({type: Number(activeKey) + 1} as any)
                }
            })
        } else if (type === 'del') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async () => {
                    docCategoryDelApi({id, type: Number(activeKey) + 1}, true, () => getTreeData({type: Number(activeKey) + 1} as any))
                },
            });
        }
    }

    const operate = (type: OperateActionType, record: any) => {
        const { id } = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelApi({ids: [id]}, true, reFush)
                },
            });
        } else if(type === '上架') {
            Modal.confirm({
                title: '确认要上架吗?',
                content: '文档上架后，在系统中可以被用户浏览',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 1}, true, reFush)
                },
            });
        } else if(type === '下架') {
            Modal.confirm({
                title: '确认要下架吗?',
                content: '文档下架后，将不被系统用户搜索到',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 2}, true, reFush)
                },
            });
        } else if(['创建文档', '编辑'].includes(type)) {
            const link = calcCreatHref({...record, type: Number(activeKey) + 1, id, fromPath: '/knowledge/docList', ownership: 2, categoryId: type === '创建文档' ? categoryId : undefined})
            history.push(link)
        } else if (type === '上传文档') {
            setUpLoadDocProps({
                ...upLoadDocProps,
                visible: true,
                params: {
                    type: Number(activeKey) + 1,
                    ownership: 2,
                    format: 2,
                },
                values:{ categoryId },
                onSuccess: () => {
                    setUpLoadDocProps({...upLoadDocProps, visible: false})
                }
            })
        } else if (type === '移动分类') {
            setMoveDocProps({
                ...moveDocProps,
                visible: true,
                hideFields: ['labelIds', 'customerIds', 'file'],
                params: {
                    type: Number(activeKey) + 1,
                    ownership: 2,
                    format: 2,
                },
                values:{ categoryId },
                onFinish: async(values: any) => {
                    await moveApi({ids: selectedRowKeys, ...values}, true, () => {
                        setMoveDocProps({
                            ...moveDocProps,
                            visible: false
                        })
                        reFush()
                    })
                }
            })
        }
    }

    const calcColumns = () => {
        return [columnsFn0, columnsFn1, columnsFn2, columnsFn3][activeKey as any](operate)
    }

    const reFush = () => {
        // @ts-ignore
        // ref.current?.clearSelected()
        ref.current?.reload()
    }

    useEffect(() => {
        setCategoryId('')
        // @ts-ignore
        ref.current?.clearSelected()
        getTreeData({type: Number(activeKey) + 1} as any)
    }, [activeKey])

    return(<div>
        <MyTabs tabPanes={tabPanes} activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{marginLeft: 10}} style={{background: '#fff'}} />
        <div style={{display: 'flex', width: '100%', background: '#fff'}}>
            <div style={{width: '265px', marginRight: '16px', position: 'sticky', top: '130px', borderRight: '1px solid #F3F3F3'}}>
                <WithSearchTreeWarp title={'文档分类'} onOperate={() => titleOperate(null, 'add')}>
                    <WithSearchTree 
                        style={{marginTop: '12px',}} 
                        treeData={treeData} 
                        blockNode 
                        draggable
                        showIcons={['add', 'update', 'del']}
                        expandedKeys={expandedKeys} 
                        autoExpandParent={true} 
                        onExpand={onExpand} 
                        onSearch={treeSearch}
                        onDrop={onTreeDrop}
                        onSelect={([categoryId]) => setCategoryId(categoryId)}
                        titleOperate={titleOperate}
                        placeholder="请输入分类名称"
                    />
                </WithSearchTreeWarp>
            </div>
            <ProTable<TableListItem>
                style={{flex: 1, overflow: 'scroll'}}
                className="rightDiv no-padding-pro-card-body"
                scroll={{x: 1300}}
                columns={calcColumns()}
                params={{type: Number(activeKey) + 1, categoryId}}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        <Button key="btn0" type='primary' disabled={!selectedRowKeys.length} onClick={() => operate('移动分类', null)}>移动分类</Button>,
                        <Button key="btn1" type='primary' onClick={() => operate('创建文档', null)}>{['2'].includes(activeKey) ? '创建企业模版' : '创建企业文档' }</Button>,
                        ['2'].includes(activeKey) ? null : <Button key="btn2" type='primary' onClick={() => operate('上传文档', null)}>上传企业文档</Button>,
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    className: 'search-with-background',
                    optionRender: (searchConfig, formProps, dom) => [ ...dom.reverse() ]
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)},
                }}
            />
        </div>
        <AddCategory {...addCategoryProps} modalProps={{onCancel: () => setAddCategoryProps({...addCategoryProps, visible: false}), maskClosable: false}} />
        <UpLoadDoc {...upLoadDocProps} modalProps={{onCancel: () => setUpLoadDocProps({...upLoadDocProps, visible: false}), maskClosable: false}} />
        <UpLoadDoc {...moveDocProps} modalProps={{onCancel: () => setMoveDocProps({...moveDocProps, visible: false}), maskClosable: false}} />
    </div>)
}