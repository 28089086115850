import React, { useEffect, useRef, useState } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { customerContactAllList } from '@api/index' 
import { productApi } from "@utils/util";
import { ProTableWithFormSetting } from "@components/index";
import { useHasAuthCode } from "@hooks/index";

const getApi = productApi(customerContactAllList)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
     
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 1800}}
                scroll={{x: 'max-content'}}
                columns={columnsFn(operate)}
                request={getListApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={false}
                columnsState={{ persistenceKey: 'customer/contact', persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: 'customer/contact:searchState', persistenceType: 'localStorage' }}
            />
        </div>
    </div>
}
