import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
/*-----------------------------------------财务管理----------------------------------*/
// 发票列表
export const invoicePageList = (data?: any) => request({
    url: `${BASEURL}/invoice/invoiceManageQuery`,
    // method: 'GET',
    data,
})

// 新增编辑发票
export const invoiceAddUpdate = (data?: any) => request({
    url: `${BASEURL}/invoice/saveOrUpdateInvoice`,
    data,
})

// 一键开票
export const invoiceAddQuickly = (data?: any) => request({
    url: `${BASEURL}/invoice/saveOrUpdateInvoice`,
    data,
})

// 发票撤销申请
export const invoiceCancel = (data?: any) => request({
    url: `${BASEURL}/invoice/cancelApply`,
    method: 'GET',
    data,
})

// 更新发票号码
export const invoiceCodeUpdate = (data?: any) => request({
    url: `${BASEURL}/invoice/updateInvoiceNumber`,
    data,
})

// 新增/编辑邮寄信息
export const invoicePostInfoAddUpdate = (data?: any) => request({
    url: `${BASEURL}/invoice/saveOrUpdateMailInfo`,
    data,
})

// 发票详情
export const invoiceInfo = (data?: any) => request({
    url: `${BASEURL}/invoice/getInvoiceDetails`,
    method: 'GET',
    data,
})

// 发票邮寄信息
export const invoicePostInfo = (data?: any) => request({
    url: `${BASEURL}/invoice/getMailInfoById`,
    method: 'GET',
    data,
})

// 发票审批列表
export const invoiceAuditPageList = (data?: any) => request({
    url: `${BASEURL}/invoice/invoiceRecheckQuery`,
    // method: 'GET',
    data,
})

// 发票审批
export const invoiceAuditOperate = (data?: any) => request({
    url: `${BASEURL}/invoice/checkInvoice`,
    data,
})

// 合同编号
export const invoiceUpdateContractNumber = (data?: any) => request({
    url: `${BASEURL}/invoice/updateContractNumber`,
    data,
})

// 模糊查询企业名称列表
export const invoiceSearchCompanyName = (data?: any) => request({
    url: `${BASEURL}/invoice/searchCompanyName`,
    method: 'GET',
    data,
})

// 根据企业名全称获取最新一次开票详情
export const invoiceGetLatestInfo = (data?: any) => request({
    url: `${BASEURL}/invoice/getLatestInvoiceInfo`,
    method: 'GET',
    data,
})


//=========================合同管理==================
// ============公共==================
// 查询当前用户销售合同
export const getContractByUserId = (data?: any) => request({
    url: `${BASEURL}/contract/getXsContractTree`,
    data,
})

// 根据用户联动查询销售合同
export const getContractByCustomerId = (data?: any) => request({
    url: `${BASEURL}/contract/getXsContractTreeByCustomerId`,
    data,
})

// 审批日志查询
export const getContractAuditLog = (data?: any) => request({
    url: `${BASEURL}/auditLog/getAuditLog`,
    data,
})

// 获取编号
export const contractGetNumber = (data?: any) => request({
    url: `${BASEURL}/contract/getNumber`,
    // method: 'GET',
    data,
})

// 新增合同
export const contractAdd = (data?: any) => request({
    url: `${BASEURL}/contract/newContract`,
    // method: 'GET',
    data,
})

// 删除合同
export const contractDel = (data?: any) => request({
    url: `${BASEURL}/contract/delContract`,
    // method: 'GET',
    data,
})

// 合同撤销
export const contractUndo = (data?: any) => request({
    url: `${BASEURL}/contract/revokeContract`,
    // method: 'GET',
    data,
})

// 合同管理查询
export const contractSearch = (data?: any) => request({
    url: `${BASEURL}/contract/contractSearch`,
    // method: 'GET',
    data,
})

// 合同详情查询
export const contractInfoById = (data?: any) => request({
    url: `${BASEURL}/contract/contractDetail`,
    // method: 'GET',
    data,
})

// 合同详情-回款记录查询
export const contractRebackRecordInfo = (data?: any) => request({
    url: `${BASEURL}/contract/contractBackLog`,
    // method: 'GET',
    data,
})


//=========================合同审批==================
// 获取审批状态
export const contractAuditStatus = (data?: any) => request({
    url: `${BASEURL}/contract/getAuditStatus`,
    // method: 'GET',
    data,
})

// 合同审批查询
export const contractAuditSearch = (data?: any) => request({
    url: `${BASEURL}/contract/contractAuditSearch`,
    // method: 'GET',
    data,
})
// 销售合同审批【通过，拒绝，作废】
export const contractAudit = (data?: any) => request({
    url: `${BASEURL}/contract/contractAuditSuccessOrFail`,
    data,
})

// 合同作废
export const contractCancel = (data?: any) => request({
    url: `${BASEURL}/contract/cancelContract`,
    data,
})

// 采购合同审批【通过，拒绝，作废】
export const purchaseContractAudit = (data?: any) => request({
    url: `${BASEURL}/contract/contractAuditSuccessOrFail`,
    data,
})

//=========================回款管理==================
// 回款管理-查询
export const rebackSearch = (data?: any) => request({
    url: `${BASEURL}/contract/backSearch`,
    data,
})

//=========================回款审批==================
// 回款审批-查询
export const rebackAuditSearch = (data?: any) => request({
    url: `${BASEURL}/contract/backAuditSearch`,
    data,
})

// 回款审批
export const rebackAuditOperate = (data?: any) => request({
    url: `${BASEURL}/contract/backAuditSuccessOrFail`,
    data,
})

// 新建回款记录
export const rebackListAdd = (data?: any) => request({
    url: `${BASEURL}/contract/newBack`,
    data,
})

// 删除回款记录
export const rebackListDel = (data?: any) => request({
    url: `${BASEURL}/contract/delBack`,
    data,
})

// 回款记录-撤销
export const rebackListUndo = (data?: any) => request({
    url: `${BASEURL}/contract/cancelBack`,
    data,
})

// 根据id 获取回款详情
export const rebackInfoById = (data?: any) => request({
    url: `${BASEURL}/contract/backDetail`,
    data,
})


// 回款待认领-查询
export const rebackClaimSearch = (data?: any) => request({
    url: `${BASEURL}/contract/searchBackClaimAudit`,
    data,
})

// 待认领回款-撤销
export const rebackAuditUndo= (data?: any) => request({
    url: `${BASEURL}/contract/cancelBackClaim`,
    data,
})

// 待认领回款-删除
export const rebackAuditDel= (data?: any) => request({
    url: `${BASEURL}/contract/delBackClaim`,
    data,
})

// 待认领回款-认领
export const rebackAuditClaim= (data?: any) => request({
    url: `${BASEURL}/contract/backClaim`,
    data,
})


