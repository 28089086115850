import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn0, columnsFn1, columnsFn2 } from './data'
import type { OperateActionType, TableListItem } from './data'
import { BASEURL, rebateGetAccountTreeData, rebatePayeeDel, rebateRateDel } from '@api/index'
import { useLocation } from "react-router"
import { ModalForm, ProFormDigit, ProFormRadio, ProFormSelect, ProFormDependency, ProFormCascader} from '@ant-design/pro-form';
import { Button, DatePicker, Tabs, Radio, Table, Modal, FormItemProps, Cascader } from "antd";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { treeDataDisabledRules, productApi, parseSearch, handleUploadFiles, validatorAccountAndType, CLOUD_VENDOR } from "@utils/index";
import { customerGetconsumptionDetails, customerAddRebatesPoint, customerUpdateRebatesPoint, customerGetPayeeInfo, customerAddPayInfo, customerUpdatePayInfo, customerGetRebatesPoint } from '@api/customer'
import { dimensionList, useCommonApiHooks, useDictEnumHooks } from "@hooks/index";
import { ProFormCompactSelect, ProTableWithFormSetting } from "@components/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { EnterpriseDetailProps } from "../../type"
import './index.less'
import { AddGetMoney, AddGetMoneyProps } from '@components/CustomerRebate/RebateConfirm/cpnts/Ali';
import ProTable from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi_consumption = productApi(customerGetconsumptionDetails)
const getApi_Rebate = productApi(customerGetRebatesPoint)
const getApi_payee = productApi(customerGetPayeeInfo)

const addUpdateApi = productApi(customerAddRebatesPoint)
const updateRebatesPointApi = productApi(customerUpdateRebatesPoint)

const addUpdateApiAddPayInfo = productApi(customerAddPayInfo)
const updatePayInfoApi = productApi(customerUpdatePayInfo)

const delRateApi = productApi(rebateRateDel)
const delPayeeApi = productApi(rebatePayeeDel)

export const  accountFieldNames= { label: 'accountName', value: 'id', children: 'children' }

export const accountRequestApi = async (params: any) => {
    const { data } = await rebateGetAccountTreeData(params) as any
    return (data||[]).map((v: any) => ({...v, id: `${v.id}-type`, disabled: !(v.children||[]).length}))
}

interface AddLqRuleProps extends ModalFormProps {
    id?: string;
    record?: any;
    disabledFields?: string[];
    onSuccess?: () => void;
}

const ExpandedRowRender = (record: any) => {
    const { id: orderId } = record
    const [options] = useCommonApiHooks({url: `${BASEURL}/consumeCategoryList`, method: 'GET'}, {orderId}, true)
    const columns = [
        { title: '产品名称', dataIndex: 'productName', key: 'productName' },
        { title: '产品分类', dataIndex: 'productCategoryName', key: 'productCategoryName' },
        { title: '消费金额', dataIndex: 'amountPaid', key: 'amountPaid', sorter: (a: any, b:any) => a.amountPaid - b.amountPaid },
    ]
    return (
        <div>
            <Table
                columns={columns}
                dataSource={options}
                pagination={false}
            />
        </div>
    );
};


// 新增返点比例
const AddRebate = ({ id, record, disabledFields, onSuccess, ...rest }: AddLqRuleProps) => {
    const { search } = useLocation()
    const customerId = parseSearch(search).id
    const [data, ,] = dimensionList()
    const formRef = useRef<ProFormInstance>()
  
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            if (id){
                const { rebatePoint, customerAccount, accountType, mainDimension, sonDimension,hasRebate, cooperationMode} = record || {}
                 formRef.current?.setFieldsValue({ mainSon: [mainDimension, sonDimension], hasRebate, rebatePoint,  cooperationMode, customerAccount: [ `${accountType}-type`, customerAccount ]})
            }
        }
    }
    const onFinish = async (values: any) => {
        const { mainSon, customerAccount, ...rest } = values || {}
        const [ accountType, value2] = customerAccount
        const [mainDimension, sonDimension] = mainSon || []
        const params = {
            ...values,
            id,
            customerId,
            mainDimension,
            sonDimension,
            // mainSon: undefined,
            oldRebatePoint: id ? record.rebatePoint : undefined,
            accountType: parseInt(accountType),
            customerAccount: value2
        }
        const { success } = await (id ? updateRebatesPointApi : addUpdateApi)(params, true, onSuccess)
        return success
    }
    return (<ModalForm<AddLqRuleProps> layout="horizontal" width={500} labelCol={{ span: 5 }} formRef={formRef} onVisibleChange={onVisibleChange} onFinish={onFinish} {...rest}>
        {/* <ProFormSelect name="customerAccount" label="客户账号" options={options} rules={[{required: true, message: '请选择客户账号'}]} /> */}
        {/* <ProFormCustomerAccount name="customerAccount" label="客户账号" rules={[{required: true, validator: validatorAccountAndType}]} customerId={customerId} /> */}
        <ProFormCascader name="customerAccount" label="客户账号" params={{customerId}} request={accountRequestApi} fieldProps={{showSearch: true, fieldNames: accountFieldNames}} rules={[{ required: true, message: '请选择客户账号',}]} placeholder="请选择客户账号" />
        <ProFormDependency name={['customerAccount']}>
            {({customerAccount}) => {
                const [type ] = customerAccount || []
                const accountType = parseInt(type||CLOUD_VENDOR.ALI)
            return (<>
                {accountType === CLOUD_VENDOR.ALI ? <ProFormCascader name="mainSon" label="维度" fieldProps={{options: data, showSearch: true}} rules={[{ required: true, message: '请选择维度',}]} placeholder="请选择" /> : null }
            </>)
            }}
        </ProFormDependency>
        {/* <ProFormCascader name="mainSon" label="维度" fieldProps={{options: data, showSearch: true}} rules={[{ required: false, message: '请选择维度',}]} placeholder="请选择" /> */}
        <ProFormRadio.Group name="hasRebate" label="有无返点" initialValue={1} options={[{ label: '有', value: 1 }, { label: '无', value: 0 }]} rules={[{ required: true, message: '请选择有无返点' }]} />
        <ProFormDigit name="rebatePoint" fieldProps={{ maxLength: 30 }} label="返点比例" rules={[{ required: true, message: '请输入返点比例' }]} />
    </ModalForm>)
}

const Summary = ({summaryData}: any) => {
    const { amountPaid, predictRebates, practicalRebates } = summaryData || {}
    return (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
            <Table.Summary.Cell index={1}></Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5}>{amountPaid}</Table.Summary.Cell>
            <Table.Summary.Cell index={6}>{predictRebates}</Table.Summary.Cell>
            <Table.Summary.Cell index={7}>{practicalRebates}</Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
    );
}

const ConsumeTable = ({customerId}: any) => {
    const [summaryData, setSummaryData] = useState({})
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi_consumption({...params, customerId, sorter}, false)
        const { values, total } = data || {}
        !Array.isArray(values) ? setSummaryData(values?.summary||{}) : null
        return {
            data: Array.isArray(values) ? values : ((values||{}).items || []),
            success: true,
            total
        }
    }
    return (<ProTable<TableListItem>
        // scroll={{x: 1800}}
        request={getListApi}
        columns={columnsFn0()}
        options={{reload: false, density: false, setting: false, fullScreen: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        expandable={{ 
            expandedRowRender: (props) => <ExpandedRowRender {...props} />
        }}
        summary={(pageData) => <Summary summaryData={summaryData} /> }
    />)
}

const RebateTable = ({customerId, authCode}: any) => {
    const ref = useRef<ActionType>(null);
    // 新建客户返点配置项
    const [addRebate, setAddRebate] = useState<AddLqRuleProps>({ title: '新建客户返点', visible: false, id: undefined, onSuccess: undefined, record: {} })
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi_Rebate({...params, customerId, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id } = record || {}
        if (['新建返点比例'].includes(type)) {
            setAddRebate({
                ...addRebate,
                title: type,
                visible: true,
                id: undefined,
                record: {},
                disabledFields: [],
                onSuccess: () => {
                    ref.current?.reload()
                    setAddRebate({ ...addRebate, visible: false })
                }
            })
        } else if (type === '编辑返点比例') {
            console.log(record)
            setAddRebate({
                ...addRebate,
                title: type,
                visible: true,
                record,
                id,
                disabledFields: [],
                onSuccess: () => {
                    ref.current?.reload()
                    setAddRebate({ ...addRebate, visible: false })
                }
            })
        } else if (type === '删除返点比例') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delRateApi({ids: [id]}, true, () => {
                        ref.current?.reload()
                    })
                },
            });
        }
    }

    return (<>
        <ProTable<TableListItem>
            // scroll={{x: 1800}}
            request={getListApi}
            columns={columnsFn1(operate, authCode)}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    authCode.includes('add') ? <Button key="primary" type="primary" onClick={() => operate && operate('新建返点比例')} style={{ marginRight: 15 }}>新建返点比例</Button>: null,
                ],
            }}
            rowKey="id"
            actionRef={ref as any}
        />
        <AddRebate {...addRebate} modalProps={{ onCancel: () => setAddRebate({ ...addRebate, visible: false }), maskClosable: false }} />
    </>)
}

const GetMoneyTable = ({customerId, authCode}: any) => {
    const ref = useRef<ActionType>(null);
    const formRefCollection = useRef<ProFormInstance>()
    // 新建客户返点配置项
    const [getMoneyProps, setGetMoneyProps] = useState<AddGetMoneyProps>({visible: false})

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi_payee({...params, customerId, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id } = record || {}
        if (type === '新建收款人') {
            setGetMoneyProps({
                ...getMoneyProps,
                visible: true,
                hiddenFileds: ['modifyCase'],
                onFinish: async (values) => {
                    const { files: filesArr, customerAccount, aliPayAccount: alipayAccount} = values
                    const files = handleUploadFiles(filesArr||[])
                    const [accountType, value2 ] = customerAccount
                    const params = {
                        ...values, 
                        id,
                        files, 
                        customerId, 
                        alipayAccount,
                        accountType: parseInt(accountType),
                        customerAccount: value2
                    }
                    const { success } = await addUpdateApiAddPayInfo(params, true)
                    setGetMoneyProps({ ...getMoneyProps, visible: false })
                    ref.current?.reload()
                },
                onVisibleChange: async (visible: boolean) => {
                    if (visible) {
                        formRefCollection.current?.resetFields()
                    }
                }
            })
        } else if (type === '编辑收款人') {
            setGetMoneyProps({
                ...getMoneyProps,
                title: type,
                visible: true,
                id,
                hiddenFileds: ['addReason'],
                onFinish: async (values) => {
                    const { files: filesArr, customerAccount, aliPayAccount: alipayAccount} = values
                    const files = handleUploadFiles(filesArr||[])
                    const [accountType, value2 ] = customerAccount
                    const params = {
                        ...values, 
                        id,
                        files, 
                        customerId, 
                        alipayAccount,
                        accountType: parseInt(accountType),
                        customerAccount: value2
                    }
                    const { success } = await updatePayInfoApi(params, true)
                    setGetMoneyProps({ ...getMoneyProps, visible: false })
                    ref.current?.reload()
                },
                onVisibleChange: async (visible: boolean) => {
                    if (visible) {
                        formRefCollection.current?.resetFields()
                        const { customerAccount, accountType, status, getMoneyType: getMoneyerType, getMoneyer, bank, bankAccount, alipayAccount, files, remark} = record || {}
                        formRefCollection.current?.setFieldsValue({ customerAccount: [`${accountType}-type`, customerAccount ], status, getMoneyerType, getMoneyer, bank, bankAccount, aliPayAccount:alipayAccount, files, remark})
                    }
                }
           })
        } else if (type === '删除收款人') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delPayeeApi({ids: [id]}, true, () => {
                        ref.current?.reload()
                    })
                },
            });
        }
    }

    return (<>
        <ProTable<TableListItem>
            // scroll={{x: 1800}}
            request={getListApi}
            columns={columnsFn2(operate, authCode)}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    authCode?.includes('add') ? <Button key="primary2" type="primary" onClick={() => operate && operate('新建收款人')} style={{ marginRight: 15 }}>新建收款人</Button> : null,
                ],
            }}
            rowKey="id"
            actionRef={ref as any}
        />
        <AddGetMoney title="新增收款信息" formRef={formRefCollection} modalProps={{onCancel: () => setGetMoneyProps({...getMoneyProps, visible: false}), maskClosable: false}} {...getMoneyProps} >
           {/* <ProFormSelect name="customerAccount" label="客户账号" options={handleOptions(options)} rules={[{required: true, message: '请选择客户账号'}]} /> */}
           {/* <ProFormCustomerAccount name="customerAccount" label="客户账号" rules={[{required: true, validator: validatorAccountAndType}]} customerId={customerId} /> */}
           <ProFormCascader name="customerAccount" label="客户账号" params={{customerId}} request={accountRequestApi} fieldProps={{showSearch: true, fieldNames: accountFieldNames}} rules={[{ required: true, message: '请选择客户账号',}]} placeholder="请选择客户账号" />
           <ProFormRadio.Group name="status" label="状态" initialValue={1} options={[{ label: '启用', value: 1 }, { label: '禁用', value: 0 }]} rules={[{ required: true, message: '请选择状态' }]} />
        </AddGetMoney>
    </>)
}

const ConsumeInfo = ({ id: customerId, name, authCode, ...rest }: EnterpriseDetailProps) => {
    const items = [
        { Tab: '消费明细', key: '0', Content: <ConsumeTable customerId={customerId} /> },
        // { Tab: '返点比例', key: '1', Content: <RebateTable customerId={customerId} Collaborator={Collaborator} /> },
        { Tab: '收款人信息', key: '2', Content: <GetMoneyTable customerId={customerId} authCode={authCode} /> },
    ]
    return (
        <div className="rebate">
            <div style={{ boxSizing: 'border-box', background: '#fff', paddingTop: 25 }}>
                <MyTabs tabPosition="left" tabPanes={items} />
            </div>
        </div>
    )
}

export default ConsumeInfo
