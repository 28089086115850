import {SET_USER_INFO, SET_USER_ROLE_DATA} from '../contants'
// 存储用户信息
export const userInfo = (state = {}, action: { type: any; userInfo: any }) => {
    const { userInfo } = action
    switch(action.type) {
        case SET_USER_INFO: 
            return userInfo
        default: 
            return state
    }
}

export const userRoleData = (state = [], action: { type?: any; userRoleData?: any }) => {
    const { userRoleData } = action
    switch(action.type) {
        case SET_USER_ROLE_DATA:
            return userRoleData
        default: 
            return state
    }
}
