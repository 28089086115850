import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import ProForm, { ModalForm, ProFormDigit, ProFormText, ProFormRadio } from '@ant-design/pro-form';
import { Button, Form, Input, Modal, Select, TreeSelect } from "antd";
import { ProFormTreeSelect } from '@components/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { treeDataDisabledRules, productApi } from "@utils/index";
import { lqRuleQuery, getDetailst, lqRuleDel, lqRuleAddUpdate, lqRuleInfoById } from '@api/index'
import { useCityTreeDataHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(lqRuleQuery)
const addUpdateApi = productApi(lqRuleAddUpdate)
const delApi = productApi(lqRuleDel)
const getInfoApi = productApi(lqRuleInfoById)

interface AddLqRuleProps extends ModalFormProps{
    id?: string ;
    disabledFields?: string[];
    onSuccess?: () => void;
}
const AddLqRule = ({id, disabledFields, onSuccess, ...rest}: AddLqRuleProps) => {
    const [disabledArea, setDisabledArea] = useState<boolean>(false)
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const [cityTreeData] = useCityTreeDataHooks({type: 2}, true)
    const formRef = useRef<ProFormInstance>()
    const userTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            id && request({id})
        }
    }
    const onValuesChange = (values: any, allValues: any) => {
        if(allValues['isContain'] === 2){
            setDisabledArea(true)
            formRef.current?.setFieldsValue({area: []})
        } else {
            setDisabledArea(false)
        }
    }
    const request = async (param: any) => {
        const { data } = await getInfoApi(param, false)
        const { name, members: memberIds, isContain, area, limit, status } = data || {}
        const members = (memberIds||[]).map((v: string) => `s${v}`)
        formRef.current?.setFieldsValue({
            name,
            members,
            isContain,
            area,
            limit,
            status
        })
        onValuesChange({}, {isContain})
    }
    const onFinish = async (values: any) => {
        const { members: nIds } = values
        const members = nIds.map((v: string) => v.slice(1))
        const { success } = await addUpdateApi({...values, members, id}, true, onSuccess)
        return success
    }

    return (<ModalForm<AddLqRuleProps> layout="horizontal" width={500} labelCol={{span: 5}} formRef={formRef} onValuesChange={onValuesChange} onVisibleChange={onVisibleChange} onFinish={onFinish} {...rest}>
                <ProFormText name="name" disabled={disabledFields?.includes('name')} fieldProps={{ maxLength: 30 }} label="规则名称" rules={[{required: true, message: '请输入规则名称'}]} />
                <ProFormTreeSelect name="members" label="指定成员" fieldProps={{treeData:userTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title'}} rules={[{required: true, message: '请选择成员'}]} />
                <ProForm.Item label="区域" rules={[{required: true, message: '请选择区域'}]}>
                    <Input.Group compact>
                        <Form.Item name="isContain" noStyle initialValue={1}>
                            <Select style={{width: '30%'}} options={[{label: '不包含', value: 1}]} />
                            {/* <Select style={{width: '30%'}} options={[{label: '包含', value: 0}, {label: '不包含', value: 1}, {label: '不限制', value: 2}]} /> */}
                        </Form.Item>
                        <Form.Item name="area" noStyle rules={[{required: !disabledArea, message: '请选择区域'}]}>
                            <TreeSelect style={{width: '70%'}} disabled={disabledArea} treeData={cityTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' />
                        </Form.Item>
                    </Input.Group>
                </ProForm.Item>
                <ProFormDigit name="limit" label="日限制领取" initialValue={1000} max={99999999} rules={[{required: true, message: '请填写'}, {max: 999999999, type: 'number', message: '最大不超过999999999'}]} />
                <ProFormRadio.Group name="status" label="状态" initialValue={'1'} options={[{label: '启用', value: '1'}, {label: '禁用', value: '0'}]} rules={[{required: true, message: '请选择状态'}]} />
            </ModalForm>)
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const [addPoolProps, setAddPoolProps] = useState<AddLqRuleProps>({title: '新建规则', visible: false, id: undefined, onSuccess: undefined })
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id, isDefault } = record || {}
        if(['新建规则'].includes(type)) {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id: undefined,
                disabledFields: [],
                onSuccess: () => {
                    reFresh()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
        } else if (type === '编辑') {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id,
                disabledFields: [],
                onSuccess: () => {
                    reFresh()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
          
        } else if (type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids: selectedRowKeys}, true, () => {
                        setSelectedRowKeys([])
                        reFresh()
                    })
                },
            });
        }
    }
    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }
    const columns = columnsFn(operate)

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                scroll={{x: 1300}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2246) ? <Button key="primary" type="primary" onClick={() => operate('新建规则')}>新建规则</Button> : null,
                        hasAuthCode(2247) ? <Button key="del" disabled={!selectedRowKeys.length} onClick={() => operate('删除')}>删除</Button> : null,
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                }}
            />
        </div>
        <AddLqRule {...addPoolProps} modalProps={{onCancel: () => setAddPoolProps({...addPoolProps, visible:false}), maskClosable: false }} />
    </div>
}
