import { Button, Form, Modal,  } from 'antd'
import React, { useEffect, useRef, useState  } from 'react'
import {MultiIcon, MultiLabel} from '../cpnts'
import '../index.less'
import type { ProFormInstance } from '@ant-design/pro-form';
import { QuestionFrom } from './QuestionFrom'
import { knowledDocDel, knowledgeTemplateDetail } from "@api/index"
import { parseSearch, productApi } from '@utils/util';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'qs';
import { calcTitle } from '../MyDoc/Create';

const getData = productApi(knowledgeTemplateDetail)
const delApi = productApi(knowledDocDel)
export default ({id}:any) => {
    const formRef = useRef<ProFormInstance>();
    const history = useHistory()
    const [templateData, setTemplateData ] = useState<any>([])
    const [listData, setListData ] = useState<any>([])
    // const { search } = useLocation()
    // const { id = 1, type } = parseSearch(search)

    const multiConfig = [
        {
            type: 'Collection',
            title: `收藏${templateData.collect}`,
            activation: templateData.collectStatus,
            success: async ()=> {
                // await getApiDoc(id, false)
                // getTemplateData()
                setTemplateData({
                    ...templateData,
                    collect: templateData.collect + (templateData.collectStatus ? -1 : 1),
                    collectStatus: !templateData.collectStatus
                })
            }
        },
        {
            type: 'good', 
            title: `有用 ${templateData.praise}`,
            activation: templateData.praiseStatus,
            success: async ()=> {
                // getTemplateData()
                setTemplateData({
                    ...templateData,
                    praise: templateData.praise + (templateData.praiseStatus ? -1 : 1),
                    praiseStatus: !templateData.praiseStatus,
                    trample: templateData.trample + (templateData.trample ? -1 : 0),
                    trampleStatus: false
                })
            }
            
        },
        {
            type: 'notGood',
            title: `无用${templateData.trample}`,
            activation: templateData.trampleStatus,
            success: async ()=> {
                // await getApiDoc(id, false)
                // getTemplateData()
                setTemplateData({
                    ...templateData,
                    trample: templateData.trample + (templateData.trampleStatus ? -1 : 1),
                    trampleStatus: !templateData.trampleStatus,
                    praise: templateData.praise + (templateData.praise ? -1 : 0),
                    praiseStatus: false
                })
            }
            
        }
    ]
    const labelList = [
        {
            title: '相关标签',
            children: templateData.labelNames
        },
        {
            title: '关联客户',
            children: templateData.customerNames

        },
        {
            title: '文档分类',
            children: templateData.categoryNames
        }
    ]
    const operate = async (type:string)=> {
        if ( type === 'delete') {
            Modal.confirm({
                title: '确认要删除问题吗?',
                content: '确认删除后不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    const {success} = await delApi({ids: [id]}, true)
                    if (success){
                        getTemplateData()
                        history.push('/knowledge/mine?activeKey=2')
                    } 
                },
            });
           
        }
    }
    const questionsList = templateData.questions?.length > 0 ? templateData.questions.map((element: any, index: number) => {
            const { questionId, question } = element
            return {
                type: 'TextArea',
                label: question,
                index: ++index,
                valueName: index,
                required: true,
            }
         }) : []
    const getTemplateData = async () => {
        const { data, success } = await getData({docId: id}, false)
        success && setTemplateData(data)
    }
    useEffect(() => {
        getTemplateData()
    }, [])
    return <>
        <div className='questionBody'>
            <div className='topDiv'>
                <h2>{ templateData.title }</h2>
                {/* <div className="titleChildrenDiv">
                <span>{issueData.author}</span>
                <span> {issueData.createTime}</span>
                <span> 浏览 {issueData.browse}</span>
                {issueData.solve ? <span style={{color: 'green'}}>已解决</span> : <span style={{color: 'red'}}>未解决</span>}
            </div> */}
                <div className="titleChildrenDiv">
                    <span>作者：{ templateData.author }</span>
                    <span> { templateData.createTime }</span>
                    <span> 浏览 { templateData.browse }</span>
                </div>
                <QuestionFrom questionsList={questionsList} disabledAll={true} formRef={formRef}/>
                <div className='operationDiv questionDiv'>
                    <div>
                        {templateData.status === 1 ? <Button type="primary" onClick={() =>history.push(`/knowledge/create?${stringify({docId: id,type: 3.1,routeName: calcTitle('3.1', '')})}`)}>填写问卷</Button>: null}
                        {templateData.isCanOperate ?  <Button type="primary" onClick={() =>history.push(`/knowledge/create?${stringify({id,type: 3, ownership: templateData.ownership})}`)}>编辑模板</Button> : null }
                        {templateData.isCanOperate ?  <Button type="primary" onClick={() => operate('delete')}>删除模板</Button> : null}
                    </div>
                    {(templateData.state === 1 && templateData.status === 1) ? <MultiIcon multiConfig={multiConfig} id={id} type={1}/>: <div></div> }
                </div>
            </div>
            
            <div className="lineDiv"></div>
            {/* 小标签组件 */}
            <MultiLabel dataList={labelList} />
        </div>
    </>
}
