import React, { useEffect, useRef } from 'react'
import { GraphX6 } from '@components/index'
import { Button, Row } from 'antd'
import { customerSaveDeptGraph } from '@api/customer'
import { productApi } from '@utils/index'
const updateDeptGraphApi = productApi(customerSaveDeptGraph)

type OrganizationChartProps = {
    value?: any;
    id?: any;
    name?: any;
}

export default ({value, id: customerId}: OrganizationChartProps) => {
    const graphRef = useRef<any>(null)
    const onSave = async () => {
        const customerNameJson = JSON.stringify( graphRef.current.getValue() )
        await updateDeptGraphApi({customerId, customerNameJson}, true)
    }
    const onClear = async () => {
        graphRef.current.setValue([])
    }
    useEffect(() => {
        value && graphRef.current?.setValue(JSON.parse(value))
    }, [value])
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20, paddingBottom: 30}}>
        <GraphX6 ref={graphRef} />
        <Row 
            style={{
                position: 'fixed', 
                bottom: 0, 
                left: 0, 
                right: 0, 
                padding: 6, 
                boxShadow: '0px -1px 6px 0px rgba(212,212,212,0.5)', 
                background: '#fff', 
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Button style={{marginRight: 20}} onClick={onClear}>清除</Button>
            <Button type="primary" onClick={onSave}>保存</Button>
        </Row>
    </div>)
}