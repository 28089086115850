import { ResponseData, RequestOptions } from './requestInterface';
import { codeError } from './errorHandler';
import { message, notification } from 'antd';

export default async function (source: ResponseData, requestOptions: RequestOptions) {
  const { errorHandler, successTipMsg, successHandler, disableCommonSuccessHandler } = requestOptions;
  const { code: businessCode, msg } = source
  if (businessCode !== 200) { // 业务code 不为200
    if ([6004, 6005].includes(businessCode as number)) { // token 过期跳转登陆页
      message.warning({
        key: 'tokenTimeOut',
        content: 'token 已失效,或账号已过期',
      });
      setTimeout(() => {
         window.location.href = '/login'
      }, 1000)
      return
    }
    if (errorHandler) {
      return errorHandler(source, requestOptions);
    }
    return codeError(source, requestOptions);
  }

  if (requestOptions.method !== 'GET' && !disableCommonSuccessHandler) {
    // Toast.success(successTipMsg || '操作成功');
  }
  if (successHandler) {
    return successHandler(source, requestOptions);
  }

  return source;
}
