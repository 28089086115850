import React from "react";
import { Input, Select, DatePicker, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { ModalForm, ProFormText, ProFormMoney } from '@ant-design/pro-form';
import { Double, Ellipsis } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { queryDeptTreeData } from "@api/index";
import { isNumber } from "lodash";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

const { RangePicker } = DatePicker
// ts--types
export type TableListItem = {
    id?: number;
    publishStatus?: number
};

export type OperateActionType = '删除' | '编辑'

export const columnsFn: (operate: Function, messageType?: number) => ProColumns<TableListItem>[] = (operate, messageType) => {
  const history = useHistory()
  const [, hasAuthCode] = useHasAuthCode()

  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  if (messageType === 1) {
    return [
      {
          title: '消息标题',
          dataIndex: 'msgTitle',
          render: (text, {id, msgId, msgType}: any) => {
            const onClick = () => {
              hasAuthCode(2385) ? history.push(`/oa/messageDetail?${stringify({id, msgId, msgType})}`) : null
            }
            return <a onClick={onClick}><Ellipsis text={text as any||'-'} /></a>
          }
      },
      {
          title: '发布人',
          dataIndex: 'publisher',
          width:120,
          hideInSearch: true,
          render: (text) => <Ellipsis text={text as any||'-'} />
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          valueType: 'dateRange',
          width:180,
          fieldProps: { 
            format:"YYYY-MM-DD",
          },
          search: {
            transform: (value) => {
              return {
                publishTime: [value[0] + ' 00:00:00',value[1]+ ' 23:59:59']
              }
            },
          },
          render: (text, record: any) => `${(record||{}).publishTime || ''}`
      },
      {
        title: '接收人',
        dataIndex: 'receiverStr',
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        render: (text) => <Ellipsis text={text as any||'-'} />,
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const receiver = (ids||[]).filter((v: any) => v[0] === 's').map((v: string) => v.slice(1))
            return { receiver }
          },
        },
      }
    ]
  } else {
    return [
      {
          title: '消息标题',
          dataIndex: 'msgTitle',
          order: 9,
          render: (text, {id, msgId, msgType, customerName}: any) => {
            const onClick = () => {
              hasAuthCode(2382) ? history.push(`/oa/messageDetail?${stringify({id, msgId, msgType, customerName})}`) : null
            }
            return <a onClick={onClick}><Ellipsis text={text as any||'-'} /></a>
          }
      },
      {
          title: '发布人',
          dataIndex: 'publisher',
          width:120,
          hideInSearch: true,
          render: (text) => <Ellipsis text={text as any||'-'} />
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          order: 8,
          width:180,
          valueType: 'dateRange',
          fieldProps: { 
            format:"YYYY-MM-DD",
          },
          search: {
            transform: (value) => {
              return {
                publishTime: [value[0] + ' 00:00:00',value[1]+ ' 23:59:59']
              }
            },
          },
          render: (text, record: any) => `${(record||{}).publishTime || ''}`
      },
      {
        title: '接收人',
        dataIndex: 'receiverStr',
        order: 6,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text) => <Ellipsis text={text as any||'-'} />,
        search: {
          transform: (ids) => {
            const receiver = (ids||[]).filter((v: any) => v[0] === 's').map((v: string) => v.slice(1))
            return { receiver }
          },
        },
      },
      {
        title: '发布状态',
        dataIndex: 'publishStatus',
        width: 120,
        order: 7,
        valueEnum: {
          2: { text: '未发布', status: 'Default' },
          1: { text: '已发布', status: 'Processing' },
        },
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 120,
          render: (text, record) => {
            return [
              (hasAuthCode(2381) && record.publishStatus === 2) ? <a key="add" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
              hasAuthCode(2383) ? <a key="add2" onClick={() => operate && operate('删除', record)}>删除</a> : null,
           
            ]
          },
      },
    ]
  }
}
