import React, { useCallback, useContext, useEffect, useMemo, useReducer } from "react"
import useKeeper from './cacheReducer'
import CacheContext from "./cacheContext"
import { CLEAR, CREATE, CREATED, DESTROY, RESET } from './contact'
import { isFunction } from "@utils/util"

type KeepAliveProviderProps = {
    children?: any
}
type PromiseCb = (value: void) => void | PromiseLike<void>
let cacheDispatchCurrent: null = null, timer: string | number | NodeJS.Timeout | null | undefined = null
const nextTick = (cb: PromiseCb) => Promise.resolve().then(cb)
const { isValidElement, createElement } = React

const resolveCacheDispatch = (dispatch: any) => () => (action: any) => {
    const { type } = action
    const cacheDispatch = dispatch || cacheDispatchCurrent
    if (!cacheDispatch) return
    if (type === CLEAR) {
       if (timer) clearTimeout(timer)
       timer = setTimeout(() => {
          cacheDispatch(action)
          nextTick(() => {
            cacheDispatch({type: RESET})
          })
       }, 50)
    } else {
        cacheDispatch(action)
    }
}

const handerReactComponent = (children: any, props: any) => isValidElement(children) ? createElement(children as any, {...props}) : isFunction(children) ? children(props) : null

/* 对于层层嵌套的组件结构 ，我们需要一个容器来提供 cacheContext */
export const GetCacheContext = ({children, cacheDispatch}: any) => {
    const cacheContext = useContext<any>(CacheContext) || {}
    useEffect(() => {
        cacheDispatch && isFunction(cacheDispatch) && cacheDispatch(cacheContext.dispatch)
    }, [])
    return <CacheContext.Consumer>
        {context => { return handerReactComponent(children, context) }}
    </CacheContext.Consumer>
}

export const useCacheDispatch = resolveCacheDispatch(cacheDispatchCurrent)

export default ({children, ...prop}: KeepAliveProviderProps) => {
    // cacheStates 存放所有的缓存信息 dispatch 激发动作方法， 可以通过激发动作修改缓存信息
    const [cacheStates, dispatch] = useKeeper()
    const mount = useCallback(({cacheId, reactElement}) => {
        if(cacheStates[cacheId]) {
            const cacheState = cacheStates[cacheId]
            const { status, doms } = cacheState
            if (status === DESTROY) {
                doms.forEach((dom: any) => dom.parentNode.removeChild(dom))
                dispatch({type: CREATE, payload: {cacheId, reactElement}})
            }
        } else {
            dispatch({type: CREATE, payload: {cacheId, reactElement}})
        }
    }, [cacheStates])

    const handleScroll = useCallback((cacheId, {target}) => {
        if (cacheStates[cacheId]) {
           const scrolls = cacheStates[cacheId].scrolls
           scrolls[target] = target.scrollTop
        }
    }, [cacheStates])

    const domRef = (div: any, cacheId: string) => {
        const cacheState = cacheStates[cacheId]
        const {doms, status} = cacheState
        if (div && (!doms || status === DESTROY)) {
           const doms = Array.from(div.childNodes)
           dispatch({type: CREATED, payload: { cacheId, doms }})
        }
    }
    return (<CacheContext.Provider value={{cacheStates, dispatch, mount, handleScroll}}>
        {children}
        {Object.values(cacheStates).filter((cacheState: any) => cacheState.status !== DESTROY).map(({cacheId, reactElement}: any) => (<div id={`cache-${cacheId}`} key={cacheId} ref={(div) => domRef(div, cacheId)}>{reactElement}</div>))}
        {!cacheDispatchCurrent && <GetCacheContext cacheDispatch={(c: any) => (cacheDispatchCurrent = c)}></GetCacheContext> }
    </CacheContext.Provider>)
}

