import { Button, ButtonProps, Dropdown, Menu } from "antd"
import React, { useState } from "react"
import { productApi, request } from '@utils/index'
import { isFunction } from "lodash"

interface ExportButtonProps extends ButtonProps{
    action?: string;
    param?: Record<string, any> | ((param?: any) => Object);
    batchParams?: Record<string, any> | ((param?: any) => Object);
    btnText?: string;
    onSuccess?: () => void;
}

const useExportHooks = ({action, onSuccess}: ExportButtonProps) => {
    const [loding, setLoading] = useState<any>(false)
    const api = productApi((data?: object) => request({ url: action, data }))
    const exportApi = async (param: any) => {
        setLoading(true)
        await api(isFunction(param) ? param() : param, true, (data: any) => {
            const { file: filename } = data || {}
            filename && window.open(filename)
            onSuccess && onSuccess()
        })
        setLoading(false)
    }
    return [loding, exportApi]
}

export const ExportButtonWithBatch = ({action, btnText, param, batchParams, onSuccess, ...rest}: ExportButtonProps) => {
    const [allLoding, exportAllApi] = useExportHooks({action, onSuccess})
    const [loding, exportApi] = useExportHooks({action, onSuccess})

    return (<Dropdown 
        key="more" 
        placement="bottomRight" 
        arrow={false}
        overlay={<Menu>
            <Menu.Item key="btn1"><Button type="link" size="small" loading={allLoding} onClick={() => exportAllApi(param||{})} {...rest} disabled={false}>全部导出</Button></Menu.Item>
            <Menu.Item key="btn2"><Button type="link" size="small" loading={loding} onClick={() => exportApi(batchParams||{})} {...rest}>批量导出</Button></Menu.Item>
        </Menu>}
        >
        <Button>导出</Button>
    </Dropdown>)
}

export default ({action, btnText, param, onSuccess, ...rest}: ExportButtonProps) => {
    const [loding, exportApi] = useExportHooks({action, onSuccess})
    return <Button loading={loding} onClick={() => exportApi(param||{})} {...rest}>{btnText || '批量导出'}</Button>
}
