import { ProColumns } from "@ant-design/pro-table";

export type TableListItem = {
    key: number;
    id: string;
    attention: number | string;
    isDefault: number;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '新建线索池' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  return [
    {
        title: '操作时间',
        dataIndex: 'operateTime',
        order: 1,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.operateTime}`
    },
    {
        title: '操作人',
        dataIndex: 'operator',
    },
    {
        title: '功能模块',
        dataIndex: 'module',
    },
    {
        title: '操作内容',
        hideInSearch: true,
        dataIndex: 'operateDetail',
    }
  ]
}
