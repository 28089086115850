import { request } from '@utils/index'
import { BASEURL } from './baseUrl'
// 产品分类
export const productCategoryTree = (data?: any) => request({
    url: `${BASEURL}/product/getProductCategoryTree`,
    method: 'GET',
    data,
})

export const productCategorySave = (data?: any) => request({
    url: `${BASEURL}/product/saveOrUpdateProductCategory`,
    data,
})

export const productCategoryDel = (data?: any) => request({
    url: `${BASEURL}/product/deleteCategoryById`,
    data,
})

export const productList = (data?: any) => request({
    url: `${BASEURL}/product/searchProductByConditions`,
    data,
})

export const productSave = (data?: any) => request({
    url: `${BASEURL}/product/saveOrUpdateProduct`,
    data,
})

export const productDel = (data?: any) => request({
    url: `${BASEURL}/product/batchDeleteProducts`,
    data,
})

export const productTree = (data?: any) => request({
    url: `${BASEURL}/product/getGeneralProductTree`,
    method: 'GET',
    data,
})
