import { ProColumns } from "@ant-design/pro-table"
import { Double } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { Progress, TreeSelect } from "antd"
import moment from "moment"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { taskStatusOptions } from "../TaskCenter/data"

export const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '未开始',
        Content: null
    },
    {
        Tab: '进行中',
        Content: null
    },
    {
        Tab: '已完成',
        key: 4,
        Content: null
    },
    {
        Tab: '已暂停',
        key: 3,
        Content: null
    }
]

export const customerViewOptions = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '我的',
    value: '1'
  },
  {
    label: '下属的',
    value: '2'
  }
]

export type TableListItem = {
    
}

export type OperateActionType = '新建任务'
const format = "YYYY-MM-DD HH:mm"
export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '任务标题',
        dataIndex: 'taskName',
        fixed: 'left',
        align: 'center',
        render: (text: any, record: any) => <Link to={`/oa/taskDetail?${stringify({id: record.id, fromPath: '/oa/myTask'})}`}>{text}</Link>
      },
      {
        title: '发起人',
        dataIndex: 'taskLaunchers',
        order: 7,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const taskLaunchers = (ids||[]).map((v: string) => v.slice(1))
            return { taskLaunchers }
          },
        },
        render: (text: any, record: any) => `${(record.taskLauncher||[]).toString()}`
      },

      {
        title: '开始时间',
        dataIndex: 'taskStartTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${moment(record.taskStartTime||'').format(format)}`,
        search: {
          transform: (value) => {
            return {
              taskStartTime: [value[0] + ' 00:00:00',value[1]+ ' 23:59:59']
            }
          },
        },
      },
      {
        title: '结束时间',
        dataIndex: 'taskEndTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${moment(record.taskEndTime||'').format(format)}`,
        search: {
          transform: (value) => {
            return {
              taskEndTime: [value[0] + ' 00:00:00',value[1]+ ' 23:59:59']
            }
          },
        },
      },

      {
        title: '任务状态',
        dataIndex: 'taskStatus',
        align: 'center',
        valueType: 'select',
        hideInSearch: true,
        fieldProps: {options: taskStatusOptions.filter(v => !v.hiddenInSelect)},
      },

      {
        title: '任务进度',
        dataIndex: 'taskProgress',
        search: {
          transform: (taskProgress) => {
            return { taskProgress };
          },
        },
        renderFormItem: (props) => <Double props1={{addonAfter: '%'}} props2={{addonAfter: '%'}} />,
        render: (text: any, record: any) => <Progress percent={text} size="small" />
     }
    ]
}