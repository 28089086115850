import { useTableHooks } from "@hooks/index"
import { Button, Col, Modal, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { businessRecordColumnsFn } from '../../data'
import { customerBusinessDel, customerBusinessList } from '@api/index'
import { amountFormat, productApi } from "@utils/util"
import { EnterpriseDetailProps } from "../../type"
import { stringify } from "qs"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { AddFollowRecord, AddFollowRecordProps } from "@components/Customer/List"
import { BussinessSonTable } from "@components/Customer/Business"
import { ProTableWithFormSetting } from "@components/index"
import { mapValues } from "lodash"

const getApi =  productApi(customerBusinessList)
const delBusnessApi = productApi(customerBusinessDel)

export default ({id: customerId, name, readOnly, authCode, ...rest}: EnterpriseDetailProps) => {
    // const {tableProps, fetchApi } = useTableHooks(customerBusinessList, {customerId, ...rest})
    const ref = useRef<ActionType>(null);
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId, customerName: name, businessOppId: undefined})
    
    const operate = (type: string, record: any) => {
        const { businessOppId } = record || {}
        const ids = businessOppId ? [businessOppId] : []
        switch (type) {
            case '批量删除':
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await delBusnessApi({ids}, true, () => {
                            reFresh()
                        })
                    },
                })
                break;
            case '新建跟进':
                setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName: name,
                    businessOppId,
                    request: undefined,
                    onSuccess: () => {
                        reFresh()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
                break;
        }
    }

    const columns = businessRecordColumnsFn(operate, authCode)

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await customerBusinessList({...params, sorter}) as any
        const { values, total, ...rest } = data || {}
        return {
            data: values || [],
            success: true,
            summaryData: mapValues(rest, (o: any) => typeof o === 'number' ? o < 0 ? 0 : amountFormat(o) : o),
            total
        }
    }

    const reFresh = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
        <ProTableWithFormSetting<any>
            request={getListApi}
            scroll={{x: 2500}}
            params={{customerId, ...rest}}
            columns={columns}
            search={false}
            actionRef={ref as any}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    authCode?.includes('add') ? <Button type="primary"><Link to={`/customer/businessAdd?${stringify({customerName: name, customerId, fromPath: '/customer/list'})}`}>新建商机</Link></Button> : null
                ],
            }}
            rowKey="businessOppId"
            expandable={{
                expandedRowRender: ({substatements}: any) => <BussinessSonTable dataSource={substatements||[]} />
            }}
        />
        <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false, destroyOnClose: true}} />
    </div>)
}
