import { useRef, useState } from "react"
import { OperateActionType } from "../../data"
import ProForm, { ProFormInstance } from '@ant-design/pro-form'
import { Modal } from "antd"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { ContractAuditModalFormProps } from "."
import { productApi } from "@utils/index"
import { contractAudit, contractCancel } from "@api/index"

const contractAuditApi = productApi(contractAudit)
const contractCancelApi = productApi(contractCancel)

export const useContractAuditHooks = () => {
    const history = useHistory()
    const formRef = useRef<ProFormInstance>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [commonModalFormProps, setCommonModalFormProps] = useState<ContractAuditModalFormProps>({title: '审批通过', visible: false, params: {}})

    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { contractId } = record || {}

        const contractIds = contractId ? [contractId] : selectedRowKeys
        // 审批通过
        const passAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '审批通过',
            visible: true,
            params: {},
            onFinish: async (values: any) => {
                await contractAuditApi({...values, contractIds, status: -1}, false, () => {
                    setCommonModalFormProps({visible: false})
                    onSuccess?.()
                })
            },
        })

         // 审批拒绝
         const unPassAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '审批拒绝',
            visible: true,
            params: {},
            onFinish: async (values: any) => {
                await contractAuditApi({...values, contractIds, status: -2}, false, () => {
                    setCommonModalFormProps({visible: false})
                    onSuccess?.()
                })
            },
        })

        // 作废合同
        const invalidAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '作废合同',
            visible: true,
            params: {},
            onFinish: async (values: any) => {
                await contractCancelApi({...values, contractIds}, false, () => {
                    setCommonModalFormProps({visible: false})
                    onSuccess?.()
                })
            },
        })


        return {
            passAction,
            unPassAction,
            invalidAction,
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            passAction,
            unPassAction,
            invalidAction,
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            formRef.current?.resetFields()
            switch (type) {
                case '销售合同-审批通过':
                    return passAction()
                case '销售合同-审批拒绝':
                    return unPassAction()
                case '销售合同-作废合同':
                    return invalidAction()
            }
        }
    }

    return {
        formRef,
        commonModalFormProps,
        setCommonModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    }
    
}
