import { Button, Card, Col, Modal, Row } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { NodeType, createGraph, useCreateNode, useGraphBindKey, useGraphEvent, useGraphPlugins } from "./config/graph"
import { createStencil } from "./config/stencil"
import { getStorage, setStorage } from "@utils/index"
import ProForm, { ModalForm,  } from '@ant-design/pro-form';
import { config as demoConfig } from './config/demo'
import { forwardRef, useImperativeHandle } from 'react';

const MaterialLib = ({onClick}: any) => {
    return (<div>
        {
            demoConfig.map(v => (<Card 
                hoverable
                key={v.id}
                title={v.title}
                style={{ width: 240 }}
                cover={<img alt="example" src={v.preImg} />}
                onClick={() => onClick(v)}
            />))
        }
    </div>)
}

type GraphX6Props = {
    value?: any
}

const GraphX6WithMaterial = ({ value }: GraphX6Props, ref: any) => {
    const graphContainerRef = useRef(null)
    const stencilRef = useRef<any>(null)
    const graphRef = useRef<any>(null)
    const [visible, setVisible] = useState(false)

    const setup = () => {
        const graph: any = createGraph({container: graphContainerRef.current })
        useGraphPlugins(graph)
        useGraphBindKey(graph)
        useGraphEvent(graph, graphContainerRef.current)
        const createNode = useCreateNode(graph)
        const stencil = createStencil({container: stencilRef.current, target: graph})
        const nodeNames: NodeType[] = ["胶囊" , "矩形" , "圆角矩形" , "菱形" , "平行四边形" , "圆形", '椭圆']
        const nodes = nodeNames.map(createNode)
        stencil.load(nodes, 'group1')
        return graph
    }

    const onChooseMaterial = (item: any) => {
        setVisible(false)
        setValue(item.json)
    }

    const setValue = (value: any) => {
        graphRef.current.fromJSON(value)
    } 

    const getValue = () => {
        return graphRef.current.toJSON()
    }
    
    useEffect(() => {
        graphRef.current = setup()
    }, [])

    useImperativeHandle(ref, () => {
        return {
            setValue,
            getValue
        }
    }, [])

    return (<>
        <Row>
            <Col style={{width: 180, display: 'flex', flexDirection: 'column'}}>
                <div style={{background: '#e9e9e9', padding: 10, cursor: 'pointer', display: 'flex', alignItems: 'center'}}>
                    <ModalForm 
                        title="选择素材" 
                        visible={visible} 
                        submitter={false}
                        modalProps={{onCancel: () => setVisible(false)}}
                        trigger={
                            <span onClick={() => setVisible(true)}>
                                    <span 
                                        style={{
                                            display: 'inline-block',
                                            width: 25,
                                            height: 22,
                                            backgroundImage: 'url(https://gw.alipayobjects.com/zos/bmw-prod/4bdfc4e6-e50c-493b-a809-33016c1f95e2.svg)'
                                        }}
                                    ></span>素材
                            </span>
                        }>
                        <MaterialLib onClick={onChooseMaterial} />
                    </ModalForm>
                </div>
                <div ref={stencilRef} style={{height: '100%', position: 'relative'}}></div>
            </Col>
            <Col flex={1} ref={graphContainerRef} style={{minHeight: 560}}></Col>
        </Row>
    </>)
}

export const GraphX6 = forwardRef(GraphX6WithMaterial)