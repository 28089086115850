import React, { useEffect, useMemo, useRef, useState } from "react"
import { ProForm, ModalForm, ProFormText, ProFormDatePicker, ProFormUploadButton, ProFormDependency, ProFormMoney, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { AutoComplete, Button, Form, message, Tooltip, Image } from "antd";
import { acceptImg, emailReg, getToken, handleUploadFiles, isImageFile, parseSearch, phoneReg, productApi, validatorTel } from "@utils/util";
import { BASEURL, contractAdd, contractGetNumber, contractInfoById, customerListByName } from "@api/index";
// import { paidOptions, signOptions, textOptions } from "../../data";
import { debounce } from "lodash";
import { FileItemRender } from "@components/Customer/Business/cpnts/AddBusiness";
import { PreviewProps } from "@components/Common/Preview";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { signOptions, textOptions } from "@components/Finance/Contract/data";
import { AddRebackModalForm } from "@components/Finance/RebackMoney/cpnts/RebackList/cpnts";
import { useAddRebackHooks } from "@components/Finance/RebackMoney/cpnts/RebackList/hooks";
import { useDictEnumHooks } from "@hooks/index";
import moment from "moment";

const customerListByNameApi = productApi(customerListByName)
const contractAddApi = productApi(contractAdd)

type AddSaleContractFormContentProps = {
    customerName?: string;
}
export const AddSaleContractFormContent = ({customerName}: AddSaleContractFormContentProps) => {
    const [customerOptions, setCustomerOptions] = useState([])
    const [, , request] = useDictEnumHooks({}, false)
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    
    useEffect(() => {
        customerName && onSearchCustomer(customerName)
    }, [customerName])

    return (<>
        <ProFormSelect 
            width="md" 
            name="customer" 
            label="客户名称" 
            options={customerOptions} 
            fieldProps={{
                showSearch: true, 
                filterOption: false, 
                onSearch: debounce(onSearchCustomer, 600),
                labelInValue: true
            }} 
            rules={[
                { required: true, message: '请选择关联客户',}
            ]}
            placeholder="请输入客户名称查询" 
        />
        <ProFormText 
            width="md" 
            name="xsContractName" 
            fieldProps={{maxLength: 50}} 
            label="销售合同名称" 
            rules={[
                { required: true, message: '请填写销售合同名称',}
            ]} 
            placeholder="请填写" 
        />
        <ProFormText 
            width="md" 
            name="xsContractNumber" 
            fieldProps={{maxLength: 50}} 
            label="销售合同编号" 
            rules={[
                { required: true, message: '请填写销售合同编号',}
            ]} 
            placeholder="请填写" 
            disabled
        />
        <ProFormSelect 
            width="md" 
            name="businessType" 
            label="业务类型" 
            params={{dictId: 269}}
            request={request} 
            // mode="multiple" 
            rules={[
                { required: true, message: '请选择关注业务线',}
            ]} 
            placeholder="请选择"
        />
        <ProFormRadio.Group 
            width="md" 
            name="haveText" 
            label="有无文本合同" 
            options={textOptions} 
            initialValue={1}
            rules={[
                { required: true, message: '请选择有无文本合同'}
            ]} 
        />
        <ProFormMoney 
            width="md" 
            name="contractAmount" 
            fieldProps={{ 
                formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                defaultValue: '',
                addonAfter: '元'
            }} 
            max={999999999} 
            min={0} 
            label="合同金额" 
            rules={[
                { required: true, message: '请输入合同金额',}
            ]} 
            placeholder="请输入" 
        />

        <ProFormDigit 
            width="md" 
            name="backRate" 
            label="合同回款比例" 
            fieldProps={{ addonAfter: '%' }} 
            rules={[
                { required: true, message: '请填写合同回款比例',}
            ]} 
            placeholder="请填写" 
        />
        <ProFormDigit 
            width="md" 
            name="discountRate" 
            label="优惠折扣率" 
            fieldProps={{ addonAfter: '%' }} 
            rules={[
                { required: true, message: '请填写优惠折扣率',}
            ]} 
            placeholder="请填写" 
        />
        <ProFormDatePicker 
            width="md" 
            name="signDate" 
            label="签约日期" 
            initialValue={moment()}
            rules={[
                { required: true, message: '请选择签约日期',}
            ]} 
            placeholder="请选择" 
        />
        <ProFormDependency name={['haveText']}>
            {({haveText}) => {
                return (<ProFormUploadButton 
                    width="md" 
                    name="files" 
                    label="合同附件" 
                    fieldProps={{ 
                        name: 'file', 
                        multiple: true,
                        listType: 'text', 
                        itemRender: (...props) => FileItemRender(...props, onPreview), 
                        headers: {Authorization: getToken()}
                    }} 
                    rules={[
                        { required: haveText === 1, message: '请选择上传合同附件'}
                    ]}
                    action={`${BASEURL}/upload`} 
                    extra=""
                />)
            }}
        </ProFormDependency>
        <ProFormRadio.Group 
            width="md" 
            name="whetherStamp" 
            label="合同是否盖章" 
            options={signOptions} 
            initialValue={1}
            rules={[
                { required: true, message: '请选择合同是否盖章'}
            ]} 
        />
        <ProFormTextArea 
            width="md" 
            name="remark" 
            label="备注" 
            fieldProps={{maxLength: 300, showCount: true}} 
            rules={[
                {required: false, message: '请填写备注'}, 
                {max: 300, type:'string', message: '300字以内'}
            ]} 
        />
        <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </>)
}

export type AddSaleContractModalFormProps = {
    params?: { 
        contractId?: any; // 编辑时候的合同 id
        originId?: any;  // 一键申请的 id 
    };
    onSuccess?: () => void;
} & ModalFormProps

type OperateActionType = '销售合同-提交并创建回款记录'

const AddSaleContractModalForm = ({params, onSuccess, formRef: pFormRef, ...rest}: AddSaleContractModalFormProps) => {
    const formRef = pFormRef || useRef<ProFormInstance>()
    const contractId = params?.contractId || params?.originId
    const [customerName, setCustomerName] = useState('')

    const { 
        addRebackModalFormProps,
        setAddRebackModalFormProps,
        createOperate
    } = useAddRebackHooks()

    const operate = async (type: OperateActionType, record: any) => {
        switch (type) {
            case '销售合同-提交并创建回款记录':
                const ok = await formRef.current?.validateFields().catch(err => false)
                if (ok) {
                    const { file: filesArr, customer, ...rest } = formRef.current?.getFieldsFormatValue?.() || {}
                    const files = handleUploadFiles(filesArr||[])
                    const { label: customerName, value: customerId } = customer || {}
                    const { success, data } = await contractAddApi({...rest, files, contractId, customerName, customerId, contractType: 1}, true, onSuccess)
                    if (success) {
                        const { customerId, xsContractId, xsContractNumber } = data || {}
                        setAddRebackModalFormProps({
                            ...addRebackModalFormProps,
                            title: '新建回款记录',
                            visible: true,
                            params: { customerName },
                            request: async () => {
                                return({
                                    customerId,
                                    xsContract: xsContractId ? {
                                        label: xsContractNumber,
                                        value: xsContractId
                                    } : undefined
                                })
                            },
                            onSuccess: () => {
                                setAddRebackModalFormProps({visible: false})
                                onSuccess?.()
                            }
                        })
                    }
                }
            break;
        }
    }

    const request = async (params: AddSaleContractModalFormProps['params']) => {

        if (contractId) {
            const { data } = await contractInfoById({contractId}) as any
            let xsContractNumber = data.xsContractNumber
            const {
                customerId,
                customerName,
                xsContractName,
                // xsContractNumber,
                businessType,
                haveText,
                contractAmount,
                backRate,
                discountRate,
                signDate,
                files,
                whetherStamp,
                remark
            } = data || {}

            if (params?.originId) { // 合同编号不能重复
                const res = await contractGetNumber({type: 3}) as any
                xsContractNumber = res?.data
            }

            setCustomerName(customerName)

            return {
                customer: customerId ? {
                    label: customerName,
                    value: customerId,
                } : undefined,
                xsContractName,
                xsContractNumber,
                businessType,
                haveText,
                contractAmount,
                backRate,
                discountRate,
                signDate,
                files,
                whetherStamp,
                remark
            }
        } else {
            const { data: xsContractNumber } = await contractGetNumber({type: 3}) as any
            return {
                xsContractNumber,
            }
        }
        
    }

    const onFinish = async ({files: filesArr, customer, ...rest}: any) => {
        const files = handleUploadFiles(filesArr||[])
        const contractId = params?.contractId
        const { label: customerName, value: customerId } = customer || {}
        const { success } = await contractAddApi({...rest, customerName, customerId, files, contractId, contractType: 1}, true, onSuccess)
        return success
    }
    
    return (<>
        <ModalForm<any> 
            modalProps={{maskClosable: false}} 
            layout="horizontal" 
            title={'新建销售合同'}
            width={700} 
            labelCol={{span: 5}} 
            params={{...params}}
            onFinish={onFinish}
            request={request}
            submitter={{
                render: (props, defaultDoms) => {
                    return [
                    <Button key="addContact" style={{marginLeft: 10}} onClick={() => operate('销售合同-提交并创建回款记录', null)}>提交并创建回款记录</Button>,
                    ...defaultDoms,
                    ];
                },
            }}
            formRef={formRef}
            {...rest}
            >
                <AddSaleContractFormContent customerName={customerName} />
        </ModalForm>
        <AddRebackModalForm
            modalProps={{
                onCancel: () => setAddRebackModalFormProps({...addRebackModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...addRebackModalFormProps} 
        />
    </>
    )
}

export default AddSaleContractModalForm