import { DataNode } from 'antd/lib/tree';
import { ModalForm } from '@ant-design/pro-form';
import ProForm, { ProFormText, ProFormRadio, ProFormTextArea, ProFormSelect, ProFormMoney, ProFormUploadButton, ProFormDateTimePicker } from '@ant-design/pro-form';
import React, { useRef } from 'react'

import { Button } from 'antd';
import '../index.less'
import { useCommonApiHooks } from '@hooks/index';
import { BASEURL } from '@api/baseUrl';
import { debounce } from 'lodash';
interface TreeDataItem extends DataNode {
    type: string;
    options?: any;
    label: string;
    valueText?: string;
    valueName? : string; //提交时保存的值名称
    customerId? : string;
    customerName?: string;
    index?: number;
    required: boolean
}

export const QuestionFrom = ({ questionsList, disabledAll = false, ...rest }: any) => {
    const [userOptions, , getOptionsApi] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`, method: 'GET'}, {}, true)
    const onSearchCustomer = (customerName: string) => {
        getOptionsApi({customerName})
    }
    return <div className='questionFromDiv'>
        <ProForm submitter={false} modalProps={{ maskClosable: false }} layout="horizontal" width={500} labelCol={{ span: 5 }} {...rest}>
            {
                questionsList.map((item: TreeDataItem) => {
                    const { type, options = [], label, index, disabled = false, valueName,  valueText = '', required, customerId, customerName } = item
                    switch (type) {
                        case 'input':
                            return <div>
                                <div className={`questionItemTitle zen-ban ${required ? 'requiredTrue' : ''}`}>{index} . &nbsp;{label}</div>
                                <ProFormText disabled={disabledAll} name={valueName} fieldProps={{ maxLength: 30 }} rules={[{ required: required, message: '请填写', }]} placeholder="请填写" />
                            </div>
                        case 'radio':
                            return <div>
                                <div className='questionItemTitle zen-ban'>{index} . &nbsp;{label}</div>
                                <ProFormRadio.Group disabled={disabledAll} name={valueName} initialValue={1} options={options} rules={[{ required: required, message: '请选择' }]} />

                            </div>
                        case 'TextArea':
                            return <div>
                                <div className={`questionItemTitle zen-ban ${required ? 'requiredTrue' : '' }`} >{index} . &nbsp;{label}</div>
                                <ProFormTextArea disabled={disabledAll} name={valueName} fieldProps={{ maxLength: 300, showCount: true }} rules={[{ required: required, message: '请填写' }, { max: 300, type: 'string', message: '300字以内' }]} />

                            </div>
                         case 'text':
                            return <div>
                            <div className={`questionItemTitle zen-ban ${required ? 'requiredTrue' : '' }`}>{index} . &nbsp;{label}</div>
                                <div className='textValue'>{valueText}</div>
                            </div>
                        case 'relationText':
                            return <div>
                            {/* <div className={`questionItemTitle zen-ban ${required ? 'requiredTrue' : '' }`}>&nbsp;{label}</div> */}
                                <div className='textValue requiredTrue' style={{paddingLeft: '10px'}}>关联客户：{valueText}</div>
                            </div>
                         case 'selectCustomer':
                            return <div>
                                {/* <div className={`questionItemTitle zen-ban ${required ? 'requiredTrue' : ''}`}>{index} . &nbsp;{label}</div> */}
                                <ProFormSelect 
                                    label={label} 
                                    style={{width: '400px'}} 
                                    labelCol={{span: 3}} 
                                    labelAlign={'left'} 
                                    disabled={!!customerId} 
                                    name={valueName} 
                                    options={!!customerId ? [{label: customerName , value: Number(customerId)}] : (userOptions||[]).map(({customerOrClueName: label, customerId: value}: any) => ({label, value}))} 
                                    fieldProps={{showSearch: true, filterOption: false, onSearch: debounce(onSearchCustomer, 600)}}
                                    showSearch={true} 
                                    rules={[{ required: required, message: '请选择关联客户',}]} placeholder="请选择" 
                                />
                            </div>
                    }
                })
            }
        </ProForm >
    </div>
}
