import React, { useEffect, useState } from 'react'
import { InputNumber } from 'antd'

export default ({props1, props2, value: pValue, onChange, ...rest}: any) => {
    const [value, setValue] = useState<number[]>(pValue||[])
    const ownOnChange = (idx: number, val: any) => {
        const [val0, val1] = value
        const newVal = idx === 0 ? [val, val1] : [val0, val]
        setValue(newVal)
        onChange && onChange((newVal[0]||newVal[1]) ? newVal : undefined)
    }

    return (<div style={{display: 'flex', alignItems: 'center'}} {...rest}>
        <InputNumber style={{width: 'calc(50% - 10px)'}} {...props1} value={value[0]} onChange={(val) => ownOnChange(0, val)} />
        <span style={{display: 'inline-block', margin: '0 6px'}}>-</span>
        <InputNumber style={{width: 'calc(50% - 10px)'}} {...props2} value={value[1]} onChange={(val) => ownOnChange(1, val)} />
     </div>)
}

