import React, { useEffect, useRef, useState } from "react"
import { ProForm, ModalForm, ProFormText, ProFormDatePicker, ProFormUploadButton, ProFormDependency, ProFormMoney, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { AutoComplete, Button, Form, message, Tooltip, Image } from "antd";
import { acceptImg, emailReg, getToken, handleUploadFiles, isImageFile, parseSearch, phoneReg, productApi, validatorTel } from "@utils/util";
import { BASEURL, customerListByName, getContractByCustomerId, rebackInfoById, rebackListAdd } from "@api/index";
import { debounce } from "lodash";
import { FileItemRender } from "@components/Customer/Business/cpnts/AddBusiness";
import { PreviewProps } from "@components/Common/Preview";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { useDictEnumHooks } from "@hooks/index";
import moment from "moment";

const customerListByNameApi = productApi(customerListByName)

const rebackListAddApi = productApi(rebackListAdd)

type AddRebackFormContentProps = {
    customerName?: string;
}
export const AddRebackFormContent = ({customerName}: AddRebackFormContentProps) => {
    const [customerOptions, setCustomerOptions] = useState([])
    const [, , request] = useDictEnumHooks({}, false)
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    useEffect(() => {
        customerName && onSearchCustomer(customerName)
    }, [customerName])
    return (<>
        <ProFormSelect 
            width="md" 
            name="customerId" 
            label="客户名称" 
            options={customerOptions} 
            fieldProps={{
                showSearch: true, 
                filterOption: false, 
                onSearch: debounce(onSearchCustomer, 600)
            }} 
            rules={[
                { required: true, message: '请选择关联客户',}
            ]}
            placeholder="请输入客户名称查询" 
        />
        <ProFormDependency name={['customerId']}>
            {({customerId}) => {
                return (<ProFormSelect 
                    width="md" 
                    name="xsContract" 
                    label="销售合同编号" 
                    rules={[
                        { required: true, message: '请选择销售合同编号',}
                    ]} 
                    params={{customerId}}
                    fieldProps={{labelInValue: true}}
                    request={async () => {
                        const { data } = await getContractByCustomerId({customerId}) as any
                        return (data||[]).map((v: any) => ({label: v.xsContractNumber, value: v.xsContractId}))
                    }}
                    placeholder="请选择"
                />)
            }}
        </ProFormDependency>

        <ProFormMoney 
            width="md" 
            name="backAmount" 
            fieldProps={{ 
                formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                defaultValue: '',
                addonAfter: '元'
            }} 
            max={999999999} 
            min={0} 
            label="回款金额" 
            rules={[
                { required: true, message: '请输入回款金额',}
            ]} 
            placeholder="请输入" 
        />
        <ProFormDatePicker 
            width="md" 
            name="backTime" 
            label="回款日期" 
            initialValue={moment()}
            rules={[
                { required: true, message: '请选择回款日期',}
            ]} 
            placeholder="请选择" 
        />
        <ProFormSelect 
            width="md" 
            name="payAccount" 
            fieldProps={{maxLength: 50}} 
            label="收款账号"
            params={{dictId: 270}} 
            request={request}
            rules={[
                { required: true, message: '请选择收款账号',}
            ]} 
            placeholder="请选择收款账号" 
        />
        <ProFormDependency name={[]}>
            {() => {
                return (<ProFormUploadButton 
                    width="md" 
                    name="files" 
                    label="回款凭证" 
                    fieldProps={{ 
                        name: 'file', 
                        listType: 'text', 
                        multiple: true,
                        itemRender: (...props) => FileItemRender(...props, onPreview), 
                        headers: {Authorization: getToken()}
                    }} 
                    rules={[
                        { required: true, message: '请上传凭证',}
                    ]} 
                    action={`${BASEURL}/upload`} 
                    extra=""
                />)
            }}
        </ProFormDependency>
        <ProFormTextArea 
            width="md" 
            name="remark" 
            label="备注" 
            fieldProps={{maxLength: 500, showCount: true}} 
            rules={[
                {required: false, message: '请填写备注'}, 
                {max: 500, type:'string', message: '500字以内'}
            ]} 
        />
    </>)
}

export type AddRebackModalFormProps = {
    params?: { backId?: any; originId?: any; customerName?: string};
    onSuccess?: () => void;
} & ModalFormProps


const AddRebackModalForm = ({params ,onSuccess, ...rest}: AddRebackModalFormProps) => {
    const [customerName, setCustomerName] = useState(params?.customerName)
    const request = async (params: AddRebackModalFormProps['params']) => {
        const backId = params?.backId || params?.originId
        if (backId) {
            const { data } = await rebackInfoById({backId}) as any
            const {
                customerId,
                customerName,
                xsContractNumber,
                xsContractId,
                backAmount,
                backTime,
                payAccount,
                remark,
                files
            } = data || {}
            setCustomerName(customerName)
            return({
                customerId,
                backAmount,
                backTime,
                payAccount,
                remark,
                files,
                xsContract: xsContractId ? {
                    label: xsContractNumber,
                    value: xsContractId
                } : undefined
            })
        } else {
            return {
                
            }
        }
    }

    useEffect(() => {
        params?.customerName && setCustomerName(params?.customerName)
    }, [params?.customerName])

    const onFinish = async ({files: filesArr, xsContract, ...rest}: any) => {
        const files = handleUploadFiles(filesArr||[])
        const backId = params?.backId
        const { label: xsContractNumber, value: xsContractId } = xsContract
        const { success } = await rebackListAddApi({files, ...rest, xsContractId, xsContractNumber, backId}, false, onSuccess)
        return success
    }

    return (<ModalForm<any> 
        modalProps={{maskClosable: false}} 
        layout="horizontal" 
        title={'新建回款记录'}
        width={700} 
        labelCol={{span: 5}} 
        onFinish={onFinish}
        params={{...params}}
        request={request}
        {...rest}
        >
            <AddRebackFormContent customerName={customerName} />
        </ModalForm>)
}

export default AddRebackModalForm
