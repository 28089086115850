import { Stencil } from "@antv/x6-plugin-stencil"

const stencilConfig = {
    title: '流程图',
    // stencilGraphWidth: 200,
    stencilGraphHeight: 240,
    collapsable: true,
    groups: [
        {
            title: '基础流程图',
            name: 'group1',
        },
        // {
        //     title: '素材',
        //     name: 'group2',
        //     graphHeight: 250,
        //     layoutOptions: {
        //         rowHeight: 70,
        //     },
        // },
    ],
    layoutOptions: {
        columns: 2,
        columnWidth: 80,
        rowHeight: 55,
    },
}

interface CreateStencilConfig extends Stencil.Options {
    container: any
}
export function createStencil(config: any = {}) {
    const mergeConfig: CreateStencilConfig = {...stencilConfig, ...config}
    const container = mergeConfig.container
    const stencil = new Stencil(mergeConfig)
    container.appendChild(stencil.container)

    return stencil
}