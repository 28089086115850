import React from 'react';
import Loadable from 'react-loadable'

export default (component?: string | React.FC) => Loadable({
    // loader: async () => typeof component === 'string' ? import(`${component}`) : component,
    loader: async () => {
        // const url = typeof component === 'string' ? import(`${component}`) : null
        return typeof component === 'string' ? import(`${component}`) : component
    },
    loading: () => <div>loading...</div>,
});
