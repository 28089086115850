import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume } from "@api/index"
import { useHistory } from "react-router-dom"
import { tabPanes } from "./data"

export default () => {
    const [activeKey, setActiveKey] = useState(' ')

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{paddingLeft: 20, marginBottom: 0, background: '#fff'}} 
          style={{marginTop: 0}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
        />
        
    </div>)
}
