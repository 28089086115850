import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Progress, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

export const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '开票中',
        key: 2,
        Content: null
    },
    {
        Tab: '已开票',
        key: 4,
        Content: null
    },
    {
        Tab: '已驳回',
        key: 3,
        Content: null
    },
    {
        Tab: '已暂停',
        key: 6,
        Content: null
    },
]

export const statusOptions = [
  {
    label: '待审批',
    value: 1
  },
  {
    label: '开票中',
    value: 2
  },
  {
    label: '已驳回',
    value: 3
  },
  {
    label: '已开票',
    value: 4
  },
  {
    label: '已撤销',
    value: 5
  },
  {
    label: '已暂停',
    value: 6
  },
  {
    label: '已作废',
    value: 7
  },
]

export const statusValueEnum = {
  1: { text: '待审批', status: 'Processing' },
  2: { text: '开票中', status: 'Processing' },
  3: { text: '已驳回', status: 'Error' },
  4: { text: '已开票', status: 'Success' },
  5: { text: '已撤销', status: 'Default' },
  6: { text: '已暂停', status: 'Warning' },
  7: { text: '已作废', status: 'Default' },
}

export const viewOptions = [
   {
        label: '我的',
        value: '1'
   },
   {
        label: '下属的',
        value: '2'
   },
   {
        label: '全部',
        value: ''
   }
]


export const paidOptions = [
  {
      label: '已付',
      value: 1
  },
  {
      label: '未付',
      value: 0
  }
]

export const typeOptions = [
  {
      label: '普通发票',
      value: 1
  },
  {
      label: '专用发票',
      value: 2
  }
]

export const mailedOptions = [
  {
      label: '邮寄',
      value: 1
  },
  {
      label: '未邮寄',
      value: 0
  }
]

export const mailStatus = [
  {
      label: '已邮寄',
      value: 1
  },
  {
      label: '未邮寄',
      value: 0
  }
]

export const mailOptions = [{label: '邮寄',value: 1},{label: '不邮寄',value: 0}]


export type TableListItem = {
    
}

export type OperateActionType = '申请开票' | '编辑' | '撤销申请' | '详情' | '暂停原因' | '驳回原因' | '一键开票'

export const columnsFn: (operate: Function, activeKey: any) => ProColumns<TableListItem>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '合同编号',
        dataIndex: 'contractNumber',
        fixed: 'left',
        order: 100,
        align: 'center',
      },
      {
        title: '合同名称',
        dataIndex: 'contractName',
        fixed: 'left',
        order: 99,
        align: 'center',
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 98,
        align: 'center',
      },
      {
        title: '企业名称',
        dataIndex: 'companyName',
        order: 10,
        align: 'center',
      },
      {
        title: '发票号码',
        dataIndex: 'invoiceNumber',
        order: 8,
        align: 'center',
      },
      {
        title: '开票金额',
        dataIndex: 'amount',
        order: 1,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.amount)
      },
      {
        title: '申请状态',
        dataIndex: 'status',
        valueType: 'select',
        hideInSearchImportant: !([' '].includes(activeKey)),
        // fieldProps: { options: statusOptions },
        valueEnum: statusValueEnum,
        order: 9,
        align: 'center',
      },
      {
        title: '业务类型',
        dataIndex: 'businessTypeStr',
        order: 6,
        valueType: 'select',
        align: 'center',
        params: { dictId: 263 },
        request,
        search: {
          transform: (businessType) => {
            return { businessType }
          },
        },
        render: (text: any, record: any) => record.businessTypeStr
      },
      {
        title: '开票类型',
        dataIndex: 'type',
        order: 7,
        valueType: 'select',
        fieldProps: { options: typeOptions },
        align: 'center',
      },
      {
        title: '开票内容',
        dataIndex: 'invoiceContent',
        hideInSearch: true,
        align: 'center',
      },
      {
        title: '是否付款',
        dataIndex: 'paid',
        order: 5,
        fieldProps: { options: paidOptions },
        valueType: 'select',
        align: 'center',
      },
      {
        title: '回款时间',
        dataIndex: 'returnTime',
        hideInSearch: true,
        align: 'center',
      },
      // {
      //   title: '邮寄状态',
      //   dataIndex: 'mailStatus',
      //   order: 2,
      //   valueType: 'select',
      //   fieldProps: { options: mailStatus },
      //   align: 'center',
      // },
      {
        title: '提交人',
        dataIndex: 'user',
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userId = (ids||[]).map((v: string) => v.slice(1))
            return { userId }
          },
        },
        render: (text: any, record: any) => `${(record.user||[]).toString()}`
      },

      {
        title: '审批人',
        dataIndex: 'reviewer',
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const reviewerId = (ids||[]).map((v: string) => v.slice(1))
            return { reviewerId }
          },
        },
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.reviewer||[]).toString()}`}</Ellipsis.Nowrap>
      },

      {
        title: '开票人',
        dataIndex: 'confirmMan',
        hideInSearch: true,
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const confirmManId = (ids||[]).map((v: string) => v.slice(1))
            return { confirmManId }
          },
        },
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.confirmMan||[]).toString()}`}</Ellipsis.Nowrap>
      },

      {
        title: '提交时间',
        dataIndex: 'updateTime',
        valueType: 'dateRange',
        hideInSearch: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.updateTime||''}`
      },
      {
        title: '开票时间',
        dataIndex: 'confirmTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {confirmTimeRange: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.confirmTime||''}`
      },
      {
        title: '审批时间',
        dataIndex: 'reviewTime',
        valueType: 'dateRange',
        hideInSearch: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.reviewTime||''}`
      },
      {
        title: '开票比例',
        dataIndex: 'percent',
        hideInSearch: true,
        render: (text: any, record: any) => `${record.percent}%`
      },
      {
        title: '企业税号',
        dataIndex: 'companyTaxNumber',
        hideInSearch: true,
      },
      {
        title: '开户银行',
        dataIndex: 'bank',
        hideInSearch: true,
      },
      {
        title: '银行账号',
        dataIndex: 'bankAccount',
        hideInSearch: true,
      },
      {
        title: '税票电话',
        dataIndex: 'taxPhone',
        hideInSearch: true,
      },
      {
        title: '税票地址',
        dataIndex: 'taxAddress',
        hideInSearch: true,
      },
      {
        title: '电子邮箱',
        dataIndex: 'email',
        hideInSearch: true,
      },
      // {
      //   title: '是否邮寄',
      //   dataIndex: 'mailed',
      //   hideInSearch: true,
      //   valueType: 'select',
      //   fieldProps: { options: mailOptions }
      // },
      // {
      //   title: '收货信息备注',
      //   dataIndex: 'mailRemark',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件人姓名',
      //   dataIndex: 'receiverName',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件人手机号码',
      //   dataIndex: 'mailPhone',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件地址',
      //   dataIndex: 'receiverAddress',
      //   hideInSearch: true,
      // },
      {
        title: '发票信息备注',
        dataIndex: 'remark',
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.remark||'')}`}</Ellipsis.Nowrap>
      },
     {
        title: '操作',
        key: 'option',
        dataIndex: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text: any, record: any) => [
          (hasAuthCode(2403) && [1].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="撤销申请" onClick={() => operate && operate('撤销申请', record)}>撤销申请</a> : null,
          (hasAuthCode(2397) && [1].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="编辑" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
          (hasAuthCode(2402) ) ? <a style={{'whiteSpace': 'nowrap'}} key="详情" onClick={() => operate && operate('详情', record)}>详情</a> : null,
          (hasAuthCode(2396) && [3].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="申请开票" onClick={() => operate && operate('申请开票', record)}>申请开票</a> : null,
          (hasAuthCode(-1) && [3].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="驳回原因" onClick={() => operate && operate('驳回原因', record)}>驳回原因</a> : null,
          (hasAuthCode(-1) && [6].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="暂停原因" onClick={() => operate && operate('暂停原因', record)}>暂停原因</a> : null,
          (hasAuthCode(2437)) ? <a style={{'whiteSpace': 'nowrap'}} key="一键开票" onClick={() => operate && operate('一键开票', record)}>一键开票</a> : null,
        ],
      },
    ]
}