import { Button, Col, Row, Table, Tooltip } from "antd"
import React from "react"
import { AddGetMoney, AliRebateConfirmPropsType, DelGetMoney, GetMoneyTable, RebateInfoTable, RebateInfoTableProps, RefundOrders, RuleInfo, handleSubmitParams, sum } from "../Ali"
import { DescriptionsList } from "@components/EnterpriseDetail"
import { DataSourceRebateInfoType, baseInfoDesList, getMoneyColumnsFn } from "../Ali/data"
import ProForm, { ProFormText, ProFormTextArea, ProFormDependency, ProFormSelect, ProFormUploadButton, ProFormRadio, ModalForm, ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { OperateActionType, useOperateHooks } from "../hooks"
import { useHistory } from "react-router-dom"
import { Icon } from "@components/index"
import { EditableProTable } from "@ant-design/pro-table"
import { rebateInfoColumnsFn } from './data'
import { rebateConfirmInfoSubmit } from "@api/index"
import { productApi } from "@utils/index"
const sureApi = productApi(rebateConfirmInfoSubmit)


export const HuaWeiRuleInfo = (props: any) => {

  return(<ModalForm title="规则说明" {...props}>
      <h3 style={{margin: '5px 0'}}>【返点金额的计算】</h3>
      <p style={{textIndent: 20}}>1.不需要抵扣退款订单，返点金额计算公式如下：</p>
      <p style={{textIndent: 25}}>（1）若有票据，返点金额=消费金额*返点比例</p>
      <p style={{textIndent: 25}}>（2）若无票据，返点金额=消费金额*返点比例*90%</p>
      <p style={{textIndent: 20}}>2.需要抵扣退款订单，返点金额计算公式如下：</p>
      <p style={{textIndent: 25}}>（1）若有票据，返点金额=（消费金额-抵扣退款金额）*返点比例</p>
      <p style={{textIndent: 25}}>（2）若无票据，返点金额=（消费金额-抵扣退款金额）*返点比例*90%</p>
  </ModalForm>)
}

export const HuaWeiRebateInfoSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
          {/* <Table.Summary.Cell index={1}></Table.Summary.Cell> */}
          <Table.Summary.Cell index={2}>{sum(data, 'consumeAmount')}</Table.Summary.Cell>
          <Table.Summary.Cell index={3}>{sum(data, 'freeConsumeAmount')}</Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
          <Table.Summary.Cell index={5}></Table.Summary.Cell>
          <Table.Summary.Cell index={6}>{sum(data, 'expectRebateAmount')}</Table.Summary.Cell>
          {/* <Table.Summary.Cell index={7}>{sum(data, 'realRebateAmount')}</Table.Summary.Cell> */}
          {/* <Table.Summary.Cell index={8}></Table.Summary.Cell> */}
        </Table.Summary.Row>
    </Table.Summary>)
  }

export default ({id, routeName}: AliRebateConfirmPropsType) => {
    const history = useHistory()

    const { 
        rebateInfoFormRef, 
        getMoneyFormRef,
        requestWithCache,
        onValuesChange,
        rebateInfoTableOnValuesChange,
        getMoneyTableOnValuesChange,
        baseData,
        refundOrderDataSource,
        rebateInfoDataSource,
        getMoneyDataSource,
        delGetMoneyDataSource,
        hasPaperRef,
        rebateInfoProps,
        setRebateInfoProps,
        getMoneyProps,
        setGetMoneyProps,
        delMoneyProps,
        setDelMoneyProps,
        createOperate,
        removeCache
    } = useOperateHooks(id)

    const operate = (type: OperateActionType, record: DataSourceRebateInfoType | null) => {
        const action = createOperate(record)
        action(type)
    }

    const onFinish = async (values: any) => {
      const ok1 = await rebateInfoFormRef.validateFields().catch(err => false)
      const ok2 = await getMoneyFormRef.validateFields().catch(err => false)
      if (ok1 && ok2) {
        const params = handleSubmitParams({refundOrderDataSource, rebateInfoDataSource, getMoneyDataSource, delGetMoneyDataSource})
        await sureApi({...params, ...values, id, type: routeName === '追加返点' ? 1 : 0}, true, () => {
          removeCache()
          history.push('/rebate/list')
        })
      }
    }

    return (
        <ProForm layout="horizontal" params={{id}} request={requestWithCache as any} onValuesChange={onValuesChange} onFinish={onFinish} style={{boxSizing: 'border-box', padding: '15px'}}>
          <div className="zen-ban">基本信息</div>
          <DescriptionsList descList={baseInfoDesList} data={baseData} />
          <div className="zen-ban">退款订单 <Tooltip title={`当前客户账号存在退款订单，相对应的消费订单的返点金额是否已追回，若已追回，请对该退款订单执行追回操作，若未追回，将在当前返点进行抵扣。`}><Icon type="icon-ziyuan" /></Tooltip></div>
          <RefundOrders dataSource={refundOrderDataSource} />
          <div className="zen-ban"><span style={{color: 'red', marginRight: 4}}>*</span>返点信息 <HuaWeiRuleInfo trigger={<Icon type="icon-ziyuan" />} /></div>
          <Row justify="space-between" style={{boxSizing: 'border-box', padding: '0 28px'}}>
            <Col span={8}>
              <ProFormRadio.Group name="hasPaper" label="有无票据" initialValue={1} options={[{label: '有票据', value: 1}, {label: '无票据', value: 0}]} rules={[{ required: true }]} />
            </Col>
            {/* <Button type="primary" size="small" onClick={() => operate && operate('返点信息-新增', null)}>新增</Button> */}
          </Row>
          <RebateInfoTable 
            form={rebateInfoFormRef}
            columns={rebateInfoColumnsFn(operate)} 
            value={rebateInfoDataSource} 
            onValuesChange={rebateInfoTableOnValuesChange} 
            operate={operate} 
            summary={(dataSource) => <HuaWeiRebateInfoSummary data={dataSource} />}
          />
          {/* <AddRebateInfo title="新增返点信息" modalProps={{onCancel: () => setRebateInfoProps({...rebateInfoProps, visible: false}), maskClosable: false}} {...rebateInfoProps} /> */}
          <Row justify="space-between" style={{position: 'relative', marginBottom: 15}}>
            <div className="zen-ban"><span style={{color: 'red', marginRight: 4}}>*</span>收款信息</div>
            <Button size="small" style={{position: 'absolute', right: 26}} type="primary" onClick={() => operate && operate('收款信息-新增', null)}>新增</Button>
          </Row>
          <GetMoneyTable 
            form={getMoneyFormRef} 
            columns={getMoneyColumnsFn(operate)} 
            value={getMoneyDataSource} 
            onChange={getMoneyTableOnValuesChange} 
          />
          <AddGetMoney title="新增收款信息" modalProps={{onCancel: () => setGetMoneyProps({...getMoneyProps, visible: false}), maskClosable: false}} {...getMoneyProps} />
          <DelGetMoney title="删除收款信息" modalProps={{onCancel: () => setDelMoneyProps({...delMoneyProps, visible: false}), maskClosable: false}} {...delMoneyProps} />
          <div className="zen-ban" style={{marginBottom: 15}}>备注</div>
          <ProFormTextArea name="remark" label="" fieldProps={{maxLength: 300, showCount: true}} rules={[{max: 300, type:'string', message: '300字以内'}]} />
        </ProForm>
    )
}