import React from "react";
import { DatePicker } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { useHasAuthCode } from "@hooks/index";
export type TableListItem = {
    id: string | number;
    createTime: string;
    state: string | number;
    productName: string;
    productDesc: string;
};

export type OperateActionType = '新建产品' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '产品名称',
        dataIndex: 'productName',
        fixed: 'left',
        order: 2,
        // order: 100,
        // fieldProps: {placeholder: '请输入字典名称'}
    },
    {
        title: '产品描述',
        dataIndex: 'productDesc',
        hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'state',
      order: 1,
      valueEnum: {
        0: { text: '禁用', status: 0 },
        1: { text: '启用', status: 1 }
      },
      search: {
        transform: (value) => {
          return {
            state: value,
          };
        },
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      hideInSearch: true,
      fieldProps: { allowEmpty: [true, true]},
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 100,
        fixed: 'right',
        render: (text, record) => [
          hasAuthCode(2213) ? <a key="edit" onClick={() => operate && operate('编辑', record)}>编辑</a> : null
        ],
    },
]
}

