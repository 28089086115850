import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { viewOptions } from "./data"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import SaleContract from "./cpnts/SaleContract"
import PurchaseContract from "./cpnts/PurchaseContract"


export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [activeKey, setActiveKey] = useState(' ')
    const [dataScope, setDataScope] = useState('1')
    const [, hasAuthCode] = useHasAuthCode()

    const tabPanes = [
        {
            Tab: '销售合同',
            key: ' ',
            Content: <SaleContract dataScope={dataScope} />
        },
        {
            Tab: '采购合同',
            key: 2,
            Content: <PurchaseContract dataScope={dataScope} />
        }
    ]

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 0, marginBottom: 0, background: '#fff'}} 
          style={{marginTop: 0}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
          tabBarExtraContent={{
            left: <Select options={viewOptions} bordered={false} value={dataScope} onChange={setDataScope} style={{minWidth: 80, marginRight: 30}} />
          }}
        />
        
    </div>)
}
