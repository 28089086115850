import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Router} from 'react-router-dom';
import RouterFn from './router'
import store from './store'

const App = () => {
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter>
                    { RouterFn }
                </BrowserRouter>
            </Provider>
        </div>
    )
}

export default App
