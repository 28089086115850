import React, { Key, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ProForm, ModalForm, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';

// import { IDomEditor, IEditorConfig } from '@wangeditor/editor'
import { BackBan, ProFormTreeSelect, ProFormWithCache } from "@components/index"
import { useCommonApiHooks, useDeptDataHooks, useDocDataHooks, useFullPath, useHasAuthCode } from "@hooks/index";
import { BASEURL, customerListByName } from "@api/index";
import { Button, Form, message, Modal, Tabs } from "antd";
import type { ProFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { getToken, handleUploadFiles, parseSearch, productApi, treeDataDisabledRules } from "@utils/index";
import { EditableProTable, } from "@ant-design/pro-table";
import { questionItem, surveryColumns, surveryItem } from "./data";
import { knowledgeDocCreate, knowledgeDocTemplateCreate, knowledgeDocIssueCreate, knowledgeDocSurveryCreate, knowledDocumentDetails, knowledgeIssueDetail,knowledGetQuestionaireDetail, knowledgeTemplateDetail, knowledCheckDocTitle } from "@api/knowledge";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { useHistory, useLocation } from "react-router-dom";
import { QuestionFrom } from "../Questionnaire/QuestionFrom";
import MyEditor from "@components/Common/MyEditor";
import { stringify } from "qs";
import { debounce } from "lodash";
import _ from "lodash";
const docCheckTitleApi = productApi(knowledCheckDocTitle) // 校验文档名称
const docCreateApi = productApi(knowledgeDocCreate) // 创建编辑文档
const docTemplateCreateApi = productApi(knowledgeDocTemplateCreate) // 创建编辑文档模版
const docIssueCreateApi = productApi(knowledgeDocIssueCreate) // 创建编辑问题
const docSurveryCreateApi = productApi(knowledgeDocSurveryCreate) // 创建编辑问卷
const docInfoApi = productApi(knowledDocumentDetails) // 文档详情
const docIssueInfoApi = productApi(knowledgeIssueDetail) // 问题详情
const docTemplateInfoApi = productApi(knowledgeTemplateDetail) // 模版详情
const docAnsweredTemplateInfoApi = productApi(knowledGetQuestionaireDetail) // 已答模版详情
const customerListByNameApi = productApi(customerListByName)

const {TabPane} = Tabs

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};

const checkDocTitle = async (rules: any, val: string, callback: Function, params?: Record<string, any>) => {
    if (!val) {
        return Promise.reject('请填写标题')
    } else if (val.length > 100) {
        return Promise.reject('100字以内')
    } else {
        const { id, type, format } = params || {}
        const { success, msg } = await docCheckTitleApi({title: val, id, type, format})
        if (!success) {
            return Promise.reject(msg)
        }
        return Promise.resolve()
    }
}

const checkDocContent = async (rules: any, val: string, callback: Function, params?: Record<string, any>) => {
    if (!val || val === '<p><br></p>') {
        return Promise.reject('请填写正文')
    } else {
        return Promise.resolve()
    }
}

const checkDocTitleDebounce = debounce(checkDocTitle, 1000)

const SurveryEditable = ({form, value, onChange, ...rest}: any) => {  
    const columns = surveryColumns(form)
  
    return (<EditableProTable<any>
            columns={columns}
            rowKey="id"
            value={value} 
            recordCreatorProps={{
              newRecordType: 'dataSource',
              record: questionItem()[0],
            }}
            editable={{
              form,
              type: 'multiple',
              deleteText: null,
              editableKeys: (value||[]).map((item: { id: any; }) => item.id),
              actionRender: (row, config, defaultDoms) => {
                return [defaultDoms.delete];
              },
              onValuesChange: (_, editableRows) =>  {
                onChange && onChange(editableRows)
              },
            }}
            onChange={onChange}
            {...rest}
        />
    )
}

const CommonSubmitterRender = (props: any, doms: any) => [
    <Button key="submit" type="primary" style={{ marginLeft: 124 }} onClick={() => props.form?.submit?.()}>提交审核</Button>,
]

// 创建编辑文档
interface CreateDocumentProps extends ProFormProps{
    disabledFields?: string[];
    onSuccess?: () => void;
}
export const CreateDocument = ({onSuccess, disabledFields, params, ...rest}: CreateDocumentProps) => {
    const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: (params||{}).categoryType || (params||{}).type}, true, (v: any) => v.whetherCatalogue)
    // 获取客户的下拉框
    // const [options] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`}, {}, true)
    const { customerId: value, customerName: label } = params || {}
    const [options, setCustomerOptions] = useState(value ? [{label, value: Number(value)}] : [])
    const {labelTreeData: tagsTreeData } = useSelector((state: StateType) => state)
    const labelTreeData = treeDataDisabledRules(tagsTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    const [visible, setVisible] = useState(false)
    const [html, setHtml] = useState('')
    const onFinish = async (value: any) => {
        const { success } = await docCreateApi({...value, ...(params||{})}, true, onSuccess)
        return success
    }
    const onPreView = async (form: any) => {
        const html = form.getFieldValue('content')
        setHtml(html)
        setVisible(true)
    }
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = false) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
        //    const willSetOptions = _.unionBy([...options, ...newOptions], 'value')
           setCustomerOptions(newOptions as any)
        })
    }

    const request = async (params: any) => {
        const { id } = params || {}
        const { data } = await docInfoApi(id)
        const { categoryId, labelIds, customerIds, customerNameStr, title, content } = data || {}
        const customerOptions = customerIds.map((v: number, i: number) => ({value: v, label: customerNameStr[i]}))
        setCustomerOptions(customerOptions)
        return {categoryId, labelIds, customerIds, title, content }
    }
    
    return (<><ProFormWithCache<CreateDocumentProps> 
              layout="horizontal" 
              {...layout} 
              params={params}
              request={(params||{}).id ? request : undefined as any}
              submitter={{render: (props: any, doms: any) => [
                    <Button key="submit" type="primary" style={{ marginLeft: 124 }} onClick={() => props.form?.submit?.()}>{params?.ownership === '2' ? '发布' : '提交审核'}</Button>,
                    <Button key="review" type="primary" style={{ marginLeft: 20 }} onClick={() => onPreView(props.form)}>预览</Button>,
              ]}} 
              onFinish={onFinish} 
            //   onValuesChange={onValuesChange}
              {...rest}>
                <ProFormTreeSelect width="md" name="categoryId" label="文档分类" fieldProps={{treeData: docTreeData, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择文档分类',}]} placeholder="请选择" />
                <ProFormTreeSelect width="md" name="labelIds" label="相关标签" fieldProps={{treeData: labelTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择相关标签',}]} placeholder="请选择" />
                <ProFormSelect name="customerIds" label="关联客户" disabled={disabledFields?.includes('customerIds')} showSearch fieldProps={{mode: 'multiple',  placeholder: '请输入客户名称查询', showSearch: true, filterOption: false, onSearch: debounce(onSearchCustomer, 600)}} options={options||[]} rules={[{required: false, message: '请选择客户'}]} />
                <ProFormTextArea name="title" label="标题" fieldProps={{showCount: true,  maxLength: 100}} rules={[{ required: true, validator: (...args) => checkDocTitle(...args, params||{}) }]} />
                <Form.Item label="正文" name="content" wrapperCol={{span: 24}} rules={[{required: true, message: '请填写正文', validator: checkDocContent}]}>
                    <MyEditor />
                </Form.Item>
            </ProFormWithCache>
            <ModalForm 
               modalProps={{maskClosable: false, onCancel: () => setVisible(false), onOk: () => setVisible(false)}} 
               title="预览"
               visible={visible}
               onFinish={async () => setVisible(false)}
            >
                <div className="editor-content-view" dangerouslySetInnerHTML={{__html: html}}></div>
            </ModalForm>
        </>)
}

// 创建编辑问题
export const CreateIssue = ({onSuccess, params, ...rest}: CreateDocumentProps) => {
    // 获取客户的下拉框
    // const [options] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`}, {}, true)
    const [options, setCustomerOptions] = useState([])
    const {labelTreeData: tagsTreeData } = useSelector((state: StateType) => state)
    const labelTreeData = treeDataDisabledRules(tagsTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    const onFinish = async (value: any) => {
        await docIssueCreateApi({...value, ...(params||{})}, true, onSuccess)
    }

    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = false) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
        //    const willSetOptions = _.unionBy([...options, ...newOptions], 'value')
           setCustomerOptions(newOptions as any)
        })
    }

    const request = async (params: any) => {
        const { id } = params || {}
        const { data } = await docIssueInfoApi({id})
        const { categoryId, labelIds, customerIds, customerNameStr, title, content } = data || {}
        const customerOptions = customerIds.map((v: number, i: number) => ({value: v, label: customerNameStr[i]}))
        setCustomerOptions(customerOptions)
        return {categoryId, labelIds, customerIds, title, content }
    }
    
    return (<ProForm<CreateDocumentProps> 
                layout="horizontal" 
                {...layout} 
                params={params} 
                request={(params||{}).id ? request : undefined as any}
                submitter={{render: (props: any, doms: any) => [
                    <Button key="submit" type="primary" style={{ marginLeft: 124 }} onClick={() => props.form?.submit?.()}>发布</Button>,
                ]}}
                onFinish={onFinish} 
                {...rest}
                >
                <ProFormTreeSelect width="md" name="labelIds" label="相关标签" fieldProps={{treeData: labelTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: false, message: '请选择相关标签',}]} placeholder="请选择" />
                <ProFormSelect name="customerIds" label="关联客户" showSearch fieldProps={{mode: 'multiple', placeholder: '请输入客户名称查询', showSearch: true, filterOption: false, onSearch: debounce(onSearchCustomer, 600)}} options={options||[]} rules={[{required: false, message: '请选择客户'}]} />
                <ProFormTextArea name="title" label="标题" fieldProps={{showCount: true,  maxLength: 100}} rules={[{ required: true, validator: (...args) => checkDocTitle(...args, params||{}) }]} />
                <Form.Item label="正文" name="content" wrapperCol={{span: 24}} rules={[{required: true, message: '请填写正文', validator: checkDocContent}]}>
                    <MyEditor />
                </Form.Item>
            </ProForm>)
}

// 上传文档
interface UpLoadDocumentProps extends ProFormProps{
    onSuccess?: () => void;
    hideFields?: string[]
}
// 上传文档
export const UpLoadDocument = ({onSuccess, params, hideFields, ...rest}: UpLoadDocumentProps) => {
    const fullPath = useFullPath()
    const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: (params||{}).categoryType || (params||{}).type}, false, (v: any) => v.whetherCatalogue)
    // const [options] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`}, {}, true)
    const [options, setCustomerOptions] = useState([])
    const {labelTreeData: tagsTreeData } = useSelector((state: StateType) => state)
    const labelTreeData = treeDataDisabledRules(tagsTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    const onFinish = async ({content: files, ...rest}: any) => {
        const contents = (handleUploadFiles(files)||[]).map(({name: title, url: content}) => ({title, content}))
        const { success } = await docCreateApi({...rest, files: contents, ...(params||{})}, true, onSuccess)
        return success
    }

    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = false) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
        //    const willSetOptions = _.unionBy([...options, ...newOptions], 'value')
           setCustomerOptions(newOptions as any)
        })
    }

    useEffect(() => {
        getTreeData({type: (params||{}).categoryType || (params||{}).type})
    }, [params?.categoryType, params?.type])

    return (<ProFormWithCache<UpLoadDocumentProps> layout="horizontal" cacheKey={fullPath+':2'} {...layout} params={params} submitter={{render: CommonSubmitterRender}} onFinish={onFinish} {...rest}>
                {hideFields?.includes('categoryId') ? null : <ProFormTreeSelect width="md" name="categoryId" label="文档分类" fieldProps={{treeData: docTreeData, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择文档分类',}]} placeholder="请选择" />}
                {hideFields?.includes('labelIds') ? null : <ProFormTreeSelect width="md" name="labelIds" label="相关标签" fieldProps={{treeData: labelTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择相关标签',}]} placeholder="请选择" />} 
                {hideFields?.includes('customerIds') ? null : <ProFormSelect name="customerIds" label="关联客户" showSearch fieldProps={{mode: 'multiple', placeholder: '请输入客户名称查询', showSearch: true, filterOption: false, onSearch: debounce(onSearchCustomer, 600)}} options={options||[]} rules={[{required: false, message: '请选择客户'}]} />}
                {hideFields?.includes('file') ? null : <ProFormUploadDragger width="md" max={10} label="文件导入" name="content" fieldProps={{style: {width: '100%'}, name: 'file', action: `${BASEURL}/upload`, headers: { Authorization: getToken() } }} rules={[{ required: true, message: '文件导入' }]} />}
            </ProFormWithCache>)
}

// 创建问卷模版
const CreateSurveryTemplate = ({onSuccess, params, ...rest}: CreateDocumentProps) => {
    const fullPath = useFullPath()
    const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: (params||{}).type}, true)
    const [visible, setVisible] = useState<boolean>(false)
    const [surveryEditableFormRef] = Form.useForm()
    const [questions, setQuestions] = useState(questionItem(0))
    // const [options] = useCommonApiHooks({url: `${BASEURL}/queryCurrentUserCustomerAndClue`}, {}, true)
    const {labelTreeData: tagsTreeData } = useSelector((state: StateType) => state)
    const labelTreeData = treeDataDisabledRules(tagsTreeData as any, (v: any) => ['0'].includes(v.industryLabelStatus))
    const onFinish = async (value: any) => {
        const ok1 = await surveryEditableFormRef.validateFields().catch(err => false)
        if (ok1) {
            const content = Object.keys(ok1).map(v => ok1[v])
            const { success } = await docTemplateCreateApi({...value, content: JSON.stringify(content||[]), ...(params||{})}, true, onSuccess)
            return success
        }
    }
    const onPreView = () => {
        setVisible(true)
    }
    const request = async (params: any) => {
        const { id } = params || {}
        const { data } = await docTemplateInfoApi({docId: id})
        const { categoryId, labelIds, customerIds, title, questions } = data || {}
        const content = questions.map((v: any, i: number) => ({...v, id: v.id ? v.id : `zqs-${i}`}))
        setQuestions(content||[])
        return {categoryId, labelIds, customerIds, title }
    }

    return (<><ProFormWithCache<CreateDocumentProps> 
                layout="horizontal" 
                {...layout} 
                cacheKey={fullPath+':3'}
                params={params} 
                request={(params||{}).id ? request : undefined as any}
                submitter={{render: (props: any, doms: any) => [
                    <Button key="submit" type="primary" style={{ marginLeft: 124 }} onClick={() => props.form?.submit?.()}>{params?.ownership === '2' ? '发布' : '提交审核'}</Button>,
                    <Button key="review" type="primary" style={{ marginLeft: 20 }} onClick={() => onPreView()}>预览</Button>,
                ]}} 
                onFinish={onFinish} 
                {...rest}
                >
                <ProFormTreeSelect width="md" name="categoryId" label="文档分类" fieldProps={{treeData: docTreeData, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择文档分类',}]} placeholder="请选择" />
                <ProFormTreeSelect width="md" name="labelIds" label="相关标签" fieldProps={{treeData: labelTreeData, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title',}} rules={[{ required: true, message: '请选择相关标签',}]} placeholder="请选择" />
                <ProFormTextArea name="title" label="问卷标题" fieldProps={{showCount: true,  maxLength: 100}} rules={[{ required: true, validator: (...args) => checkDocTitle(...args, params||{}) }]} />
                <Form.Item label="正文" name="list" wrapperCol={{span: 24}} rules={[{required: true, message: '请填写正文', validator: checkDocContent}]}>
                    <SurveryEditable form={surveryEditableFormRef} value={questions} onChange={setQuestions} />
                </Form.Item>
            </ProFormWithCache>
            <ModalForm 
               modalProps={{maskClosable: false, onCancel: () => setVisible(false), onOk: () => setVisible(false)}} 
               title="预览"
               onFinish={async () => setVisible(false)}
               visible={visible}
            >
                <CreateSurvery questionsList={questions.map(v => ({...v, disabled: true}))} submitter={false} style={{boxSizing: 'border-box', padding: 20}} />
            </ModalForm>
        </>)
}

type SurveryItem = {
    type?: ReactNode | React.Component | any;
    question?: ReactNode | React.Component | any;
    answer?: string;
    id?: Key;
}
interface CreateSurveryProps extends ProFormProps{
    questionsList?: SurveryItem[];
}
// 问卷
const CreateSurvery = ({ questionsList, ...rest }: any) => {
    return(<ProForm<CreateSurveryProps> layout="horizontal" width={500} labelCol={{ span: 5 }} {...rest}>
    {
        questionsList.map(({type, question, answer, id, ...rest}: SurveryItem, idx: number) => {
            const ItemComponent = type || ProFormTextArea
            return(<>
                <div className={`zen-ban`} style={{padding: '5px 15px', margin: '10px 0', background: '#f7f7f7'}}>{++idx},&nbsp;{question}</div>
                <ItemComponent name={id || question} rules={[{required: true, message: '请填写答案'}]} {...rest} />
            </>)
        })
    }
</ProForm >)
}

// 创建/填写问卷
const FillInSurvery = ({params, onSuccess}: CreateDocumentProps) => {
    const formRef = useRef<ProFormInstance>();
    const [questionsList, setQuestionsList ] = useState<any>([])
    const [title, setTitle ] = useState<string>('')
    const { docId, customerId = undefined, customerName, userId } = params || {}
    const onSubmit = async () => {
        const ok = await formRef.current?.validateFields().catch(err => false)
        if (ok) {
            const submitData = questionsList.map((element: any, index: number) => {
                const { questionId } = element
                return {
                    ...element,
                    answer: ok[questionId]
                }
             })
            await docSurveryCreateApi({answers: submitData, customerId: ok.customerId}, true, onSuccess)
        }
    }
    const questionsListConfig =  questionsList.map((element: any, index: number) => {
        const { questionId, question } = element
        return {
            ...element,
            type: 'TextArea',
            label: question,
            index: ++index,
            valueName: questionId,
            required: true,
        }
     })
     questionsListConfig.unshift({
        type: 'selectCustomer',
        label: '关联客户',
        customerName,
        customerId,
        valueName: 'customerId',
        required: true,
    })
    const getTemplateData = async () => {
        const { data } = await (customerId ? docAnsweredTemplateInfoApi : docTemplateInfoApi )({customerId,userId, docId }, false)
        const { questionAnswers = null, questions = null, title } = data
        setTitle(title)
        setQuestionsList(questionAnswers || questions)
        if (customerId) {
            const answeData:any = {
                customerId: data.customerId
            }
            const data2 = (questionAnswers || questions)
            data2.forEach((element:any,index:number) => {
                const { questionId } = element
                answeData[questionId] = element.answer
            });
            formRef.current?.setFieldsValue(answeData)
        }  
    }
    useEffect(() => {
        
        getTemplateData()
    }, [])
    return <>
        <div className='questionBody'>
            <div className='topDiv'>
                <h2>{title}</h2>
                
                <QuestionFrom questionsList={questionsListConfig} formRef={formRef}/>
                <Button type="primary" onClick={onSubmit}>保存</Button>
            </div>
        </div>
    </>
}

const CreateDocWithTabs = () => {
    const { search } = useLocation()
    const { id, type, ownership } = parseSearch(search)
    const history = useHistory()
    const onSuccess = () => history.goBack()

    return(<Tabs tabBarStyle={{ marginLeft: 10 }} style={{paddingBottom: 20}}>
        <TabPane tab="创建文档" key="1">
            <CreateDocument params={{id, type: type || 1, categoryType: 124, format: 1, ownership: ownership || 1}} onSuccess={onSuccess} />
        </TabPane>
        <TabPane tab="上传文档" key="2">
            <UpLoadDocument params={{id, type: type || 1, categoryType: 124, format: 2, ownership: ownership || 1}} onSuccess={onSuccess} />
        </TabPane>
        <TabPane tab="创建问卷模板" key="3">
            <CreateSurveryTemplate params={{id, type: 3, format: 1, ownership: ownership || 1}} onSuccess={onSuccess} />
        </TabPane>
    </Tabs>)
}


// type: 1 解决方案 2 客户案例 3 问卷模版 3.1 填写问卷 4 帮助文档 5 问题 
// ownership: 1 个人 2 企业
// format: 1 在线 2 上传
export const calcTitle = (atype: string, id: string, customerId?:any) => {
    const type = atype + ''
    if ((type === '3.1')) {
        return !customerId ? `填写问卷` : `修改问卷`
    } else if(id) {
        return `编辑${type === '5' ? '问题' : Number(type) < 3 ? '文档' : '模版'}`
    } else {
        return `创建${type === '5' ? '问题' : Number(type) < 3 ? '文档' : '模版'}`
    }
}

export default () => {
    const { search } = useLocation()
    const history = useHistory()
    const onSuccess = () => history.goBack()
    const { id, type, ownership, userId, docId, categoryId, customerId, customerName } = parseSearch(search)
    const onMySuccess = ()=> {
        history.goBack()
        history.goBack()
    }
    const WhichComponent = () => {
        if (!type) {
            return <CreateDocWithTabs />
        }
        if (type+'' === '3') {
            return <CreateSurveryTemplate initialValues={{categoryId}} params={{id, type: type || 1, format: 1, ownership: ownership || 1}} onSuccess={onSuccess} />
        } else if (type+'' === '5') {
            return <CreateIssue params={{id, type: type || 1, ownership: ownership || 1}} onSuccess={onSuccess} />
        } else if(type+'' === '3.1') {
            return <FillInSurvery params={{customerId, userId, customerName, docId, type: type || 1, ownership: ownership || 1}} onSuccess={onMySuccess} />
        } else {
            return <CreateDocument 
                    // initialValues={{categoryId, customerIds: customerId ? [customerId*1] : []}} 
                    disabledFields={customerId ? ['customerIds'] : []}
                    params={{id, type: type || 1, format: 1, ownership: ownership || 1, customerId, customerName}} 
                    onSuccess={onSuccess}
                    request={async () => ({categoryId, customerIds: customerId ? [customerId*1] : []})}
            />
        }
    }
    return <div style={{background: '#fff'}}>
        {type ? <BackBan title={calcTitle(type, id, customerId)} style={{marginBottom: 20}} /> : null}
        <WhichComponent />
    </div>
}

