import React from "react";
import { TreeSelect, Button, Input, Row, Col, Cascader } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useHasAuthCode, dimensionList, useDocDataHooks } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { Double, Ellipsis } from "@components/Common";
import { docStateOptions } from "../DocumentList/data";
import { calcHref, SurveyTitleRender } from "../Home/data";
import { Link } from "react-router-dom";
export type TableListItem = {
    key: number;
    id: string;
    mainName: string;
    sonName: string;
};
export type OperateActionType = '下架' | '上架' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: 3}, true)
  const [, hasAuthCode] = useHasAuthCode()

  return [
    {
        title: '问卷标题',
        dataIndex: 'title',
        fixed: 'left',
        order: 100,
        render: (rext: any, record: any) => <Link to={calcHref({...record, type: 3.1})}>{record.title}</Link>
    },
    {
        title: '相关标签',
        dataIndex: 'labelNameStr',
        order: 4,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: labelTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
    },
    {
        title: '文档分类',
        dataIndex: 'categoryIds',
        order: 6,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          placeholder: '请选择',
          treeNodeFilterProp: 'title',
          treeData: docTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text: any, record: any) => record.categoryName
    },

    {
        title: '关联客户',
        dataIndex: 'relatedCustomer',
        order: 3,
        render: (text: any, record: any) => record.relatedCustomer
    },
    {
        title: '文档类型',
        dataIndex: 'ownership',
        hideInSearch: true,
        render: (text: any, record: any) => ['', '个人经验', '企业文档'][record.ownership]
    },
    {
        title: '发布人',
        dataIndex: 'publisher',
        order: 2,
        width: 100,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: deptWithUserTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
                const ownerNames = (ids||[]).map((v: string) => v.slice(1))
                return { ownerNames }
            },
        },
        render: (text: any, record: any) => record.publisher
    },
    {
        title: '发布时间',
        dataIndex: 'publishTime',
        valueType: 'dateRange',
        order: 1,
        width: 120,
        search: {
            transform: (publishTimes) => {
                return { publishTimes }
            },
        },
        render: (text: any, record: any) => record.publishTime
    }
  ]
}
