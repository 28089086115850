import { ProColumns } from "@ant-design/pro-table"
import { Double } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { Progress, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

export const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '未开始',
        key: 1,
        Content: null
    },
    {
        Tab: '进行中',
        key: 2,
        Content: null
    },
    {
        Tab: '已暂停',
        key: 3,
        Content: null
    },
    {
        Tab: '已完成',
        key: 4,
        Content: null
    },
    // {
    //     Tab: '已取消',
    //     key: 5,
    //     Content: null
    // },
    // {
    //     Tab: '已过期',
    //     key: 6,
    //     Content: null
    // }
]

export const taskStatusOptions = [
  {
    label: '未开始',
    hiddenInSelect: true,
    value: 1
  },
  {
    label: '进行中',
    value: 2
  },
  {
    label: '已暂停',
    value: 3
  },
  {
    label: '已完成',
    value: 4
  },
  {
    label: '已取消',
    hiddenInSelect: true,
    value: 5
  },
  {
    label: '已过期',
    value: 6
  },
]

export type TableListItem = {
    
}

export type OperateActionType = '新建任务' | '编辑' | '取消' | '暂停' | '恢复' | '删除'

export const confirmContent: Record<string, any> = {
  '取消': `任务取消后将不再执行该任务`,
  '暂停': `任务暂停后系统将自动锁定任务进度`,
  '恢复': `任务恢复后将正常进行`,
  '删除': `删除后当前内容将永久删除，不可恢复。`
}
export const columnsFn: (operate: Function, activeKey: any) => ProColumns<TableListItem>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '任务标题',
        dataIndex: 'taskName',
        fixed: 'left',
        align: 'center',
        render: (text: any, record: any) => <Link to={`/oa/taskDetail?${stringify({id: record.id, fromPath: '/oa/task'})}`}>{text}</Link>
      },
      {
        title: '发起人',
        dataIndex: 'taskLaunchers',
        order: 7,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const taskLaunchers = (ids||[]).map((v: string) => v.slice(1))
            return { taskLaunchers }
          },
        },
        render: (text: any, record: any) => `${(record.taskLaunchers||[]).toString()}`
      },

      {
        title: '负责人',
        dataIndex: 'taskChargers',
        order: 7,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const taskChargers = (ids||[]).map((v: string) => v.slice(1))
            return { taskChargers }
          },
        },
        render: (text: any, record: any) => `${(record.taskChargers||[]).toString()}`
      },

      {
        title: '开始时间',
        dataIndex: 'taskStartTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.taskStartTime||''}`
      },
      {
        title: '结束时间',
        dataIndex: 'taskEndTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.taskEndTime||''}`
      },
      {
        title: '创建时间',
        dataIndex: 'taskCreateTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.taskCreateTime||''}`
      },

      {
        title: '任务状态',
        dataIndex: 'taskStatus',
        align: 'center',
        valueType: 'select',
        hideInSearch: !(' '.includes(activeKey)),
        search: {
          transform: (taskStatus1) => {
            return { taskStatus1 };
          },
        },
        fieldProps: {options: taskStatusOptions},
      },

      {
        title: '任务进度',
        dataIndex: 'taskProgress',
        search: {
          transform: (taskProgress) => {
            return { taskProgress };
          },
        },
        renderFormItem: (props) => <Double props1={{addonAfter: '%'}} props2={{addonAfter: '%'}} />,
        render: (text: any, record: any) => <Progress percent={text} size="small" />
     },
     {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text: any, record: any) => [
          (hasAuthCode(2371) && [1].includes(record.taskStatus)) ? <a key="编辑" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
          (hasAuthCode(2386) && [1].includes(record.taskStatus)) ? <a key="取消" onClick={() => operate && operate('取消', record)}>取消</a> : null,
          (hasAuthCode(2387) && [2].includes(record.taskStatus)) ? <a key="暂停" onClick={() => operate && operate('暂停', record)}>暂停</a> : null,
          (hasAuthCode(2388) && [3].includes(record.taskStatus)) ? <a key="恢复" onClick={() => operate && operate('恢复', record)}>恢复</a> : null,
          (hasAuthCode(2389) && [5].includes(record.taskStatus)) ? <a key="删除" onClick={() => operate && operate('删除', record)}>删除</a> : null,
        ],
      },
    ]
}