import { Button, Col, Form, FormProps, Input, Row, RowProps, Select } from "antd"
import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useMemo, useState } from "react"
import type { FormItemProps, ColProps } from 'antd'
import { isBoolean } from "lodash"
import { isUndefined } from "lodash"
import { isObject } from "lodash"

interface ConfigItem extends FormItemProps {
    colProps?: ColProps;
    Item: ReactNode | React.Component | any
}

type SearchBoxConfig = ConfigItem[]

export interface SearchBoxProps extends FormProps {
    config?: SearchBoxConfig;
    onReset?: () => void;
    search?: false | { Render: ReactNode }
}

const config: SearchBoxConfig = [
    {
        label: 'label1',
        name: 'param1',
        rules: [],
        colProps: {span: 6},
        Item: Input
    },
    {
        label: 'label2',
        name: 'param2',
        rules: [],
        shouldUpdate: true,
        colProps: {span: 6},
        Item: (props: any) => {
            const [options, setOptions] = useState([{value: '1', label: 'ssss'}])
            const api = async () => {
                setTimeout(() => {
                    setOptions([{value: 'v-1', label: 'opyions1'}])
                }, 3000)
            }
            api()
            return <Select options={options} {...props} />
        }
    }
]

interface FieldsProps extends RowProps {
    config?: SearchBoxConfig;
    // isUseMemo?: boolean
}
const Fields = ({config, children, ...rest}: FieldsProps) => {
    return (<Row gutter={24} style={{padding: '16px 0', boxSizing: 'border-box'}} {...rest}>
       {
           (config||[]).map(({Item, colProps, ...rest}, i) => {
               return(<Col {...colProps} key={i}>
                <Form.Item {...rest}>
                    {/* {useMemo(() => <Item />, []) } */}
                    <Item />
                </Form.Item>
            </Col>)
           })
       }
       {children}
    </Row>
    )
}

export const SearchBox = ({config, search, onReset, ...rest}: SearchBoxProps) => {
    const [form] = Form.useForm();
    const ownOnReset = () => {
        form.resetFields()
        onReset && onReset()
    }
    const SearchRender = () => {
        if ((isBoolean(search) && search) || isUndefined(search)) {
            return <Col>
                <Button type="primary" htmlType="submit">查询</Button>
                <Button style={{ margin: '0 8px' }} onClick={ownOnReset}>重置</Button>
            </Col>
        }
        if (search && search?.Render) {
            return search.Render
        }
    }

    return (<Form form={form} layout="inline" {...rest}>
            <Fields config={config || []}>
              {SearchRender()}
            </Fields> 
    </Form>)
}

SearchBox.Label = (label: ReactNode) => <span style={{display:'inline-block'}}>{label}</span>
SearchBox.Fields = Fields

export default SearchBox

const Label = (label: ReactNode) => <span style={{display: 'inline-block', width: 80}}>{label}</span>
const colProps = { span: 8 }

export const searchConfigFn = () => [
    {
        label: Label('客户名称'),
        name: 'param1',
        rules: [],
        colProps,
        Item: (props: any) => <Input placeholder="请输入客户名称" {...props} />
    },
    {
        label: Label('归属人'),
        name: 'param2',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择归属人" {...props} />
    },
    {
        label: Label('协同人'),
        name: 'param3',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择协同人" {...props} />
    },
    {
        label: Label('客户状态'),
        name: 'param4',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择客户状态" {...props} />
    },
    {
        label: Label('客户等级'),
        name: 'param5',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择客户等级" {...props} />
    },
    {
        label: Label('客户来源'),
        name: 'param6',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择客户来源" {...props} />
    },
    {
        label: Label('所属地区'),
        name: 'param7',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择地区" {...props} />
    },
    {
        label: Label('客户标签'),
        name: 'param8',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择客户标签" {...props} />
    },
    {
        label: Label('创建时间'),
        name: 'param9',
        rules: [],
        colProps,
        Item: (props: any) => <Select placeholder="请选择创建时间" {...props} />
    },
]