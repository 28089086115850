// 创建问卷

import React, { useState, useRef } from 'react'
import { Breadcrumb, Button, Tabs, Tooltip } from "antd"
import ProForm, { ProFormText, ProFormDependency, ProFormSelect, ProFormUploadDragger, ProFormCascader } from '@ant-design/pro-form';
import { MyTabs } from "@components/EnterpriseDetail";
import '../index.less'
import { getToken } from '@utils/util';
import { BASEURL } from '@api/index';


import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { EditableProTable } from '@ant-design/pro-table';
import type { ProFormInstance } from '@ant-design/pro-form';
import { ProFormDigit } from '@ant-design/pro-form';
import ProCard from '@ant-design/pro-card';
import set from 'rc-util/lib/utils/set';


const { TabPane } = Tabs


type FormDataType = {
    businessOpportunityTitle: string;
}
type DataSourceType = {
    id: React.Key;
    associate?: string;
    questionsNum?: number;
    type?: string;
    fraction?: number;
    scoringMethod?: string;
};

const defaultData: DataSourceType[] = [
    {
        id: 624748504,
        associate: '题库名称一',
        questionsNum: 10,
        type: 'multiple',
        scoringMethod: 'continuous',
        fraction: 20,
    }
];
// 可编辑表格 组件
const EditTable = ({ formRef, label, name, required }: any) => {
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>(() => []);
    const actionRef = useRef<ActionType>();
    const columns: ProColumns<DataSourceType>[] = [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '录入问题',
            key: 'type',
            dataIndex: 'type',
            valueType: 'text',
        },
        {
            title: '操作',
            valueType: 'option',
            render: (_, row) => [
                <a
                    key="delete"
                    onClick={() => {
                        // const tableDataSource = formRef.current?.getFieldValue('table') as DataSourceType[];
                        // formRef.current?.setFieldsValue({
                        //     table: tableDataSource.filter((item) => item.id !== row?.id),
                        // });
                    }}
                >
                    移除
                </a>,
                <a
                    key="edit"
                    onClick={() => {
                        actionRef.current?.startEditable(row.id);
                    }}
                >
                    编辑
                </a>,
            ],
        },
    ];
    return (<EditableProTable<DataSourceType>
        rowKey="id"
        actionRef={actionRef}
        scroll={{
            x: true,
        }}
        controlled
        formItemProps={{
            label,
            required,
            rules: [
                {
                    validator: async (_, value) => {
                        if (value.length < 1) {
                            throw new Error('请至少添加一个题库');
                        }

                        if (value.length > 5) {
                            throw new Error('最多可以设置五个题库');
                        }
                    },
                },
            ],
        }}
        maxLength={10}
        name={name}
        columns={columns}
        recordCreatorProps={{
            record: (index) => {
                return { id: index + 1 };
            },
        }}
        editable={{
            type: 'multiple',
            editableKeys,
            onChange: setEditableRowKeys,
        }}
    />
    );
}
export default () => {
    const [fileList, setFileLst] = useState<any[]>([])
    const [proText, setProText] = useState<string>('')
    const customRequest = ({ file }: any) => setFileLst([file])
    const formRef = useRef<ProFormInstance<any>>();
    const onRemove = () => setFileLst([])
    const onSubmit = async (value: object) => {
        console.log(value, proText)
    }
    return <>
        <ProForm<{
            table: DataSourceType[];
        }>
            layout="horizontal"
            formRef={formRef}
            initialValues={{
                table: defaultData,
            }}
            labelCol={{ span: 3 }}
            onFinish={onSubmit}
            // params={{businessOppId: id, customerId}}
            // request={id && request as any}
            // onValuesChange={onValuesChange}
            // formRef={formRef}
            submitter={{
                render: (props, doms) => [
                    <Button key="submit" type="primary" style={{ marginLeft: 160 }} onClick={() => props.form?.submit?.()}>提交</Button>,
                ]
            }}
        >
            <ProFormCascader width="md" name="synergys" label="文档分类" fieldProps={{ options: [], multiple: true, showSearch: true, style: { width: 328 } }} rules={[{ required: false, message: '请选择协同人', }]} placeholder="请选择" />
            <ProFormSelect width="md" name="contactsId" label="相关标签" options={[]} rules={[{ required: false, message: '请选择主要联系人', }]} placeholder="请选择" />
            <ProFormText width="md" name="businessOpportunityTitle" label="问卷标题" fieldProps={{ maxLength: 30 }} rules={[{ required: true, message: '请填写商机标题', }]} placeholder="请选择" />
            <EditTable formRef={formRef} label='问卷内容' required="true" name='table' />
        </ProForm>
    </>
}
