import React from "react";
import { TreeSelect, Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useCityTreeDataHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { Double } from "@components/Common";

export type TableListItem = {
    key: number;
    id: string;
    attention: number | string;
    isDefault: number;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '新建规则' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData } = useSelector((state: StateType) => state)
  const [cityTreeData] = useCityTreeDataHooks({type: 2}, true)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '规则名称',
        dataIndex: 'name',
        fixed: 'left',
        order: 100,
    },
    {
        title: '指定成员',
        dataIndex: 'member',
        order: 1,
        width:200,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
              const members = (ids||[]).map((v: string) => v.slice(1))
              return { members }
            },
        },
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 80,
      valueEnum: {
        0: { text: '禁用', status: 'Default' },
        1: { text: '启用', status: 'Processing' },
      },
    },
    {
        title: '区域',
        dataIndex: 'area',
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: cityTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text: any, record: any) => `${record.rule}`
    },
    {
        title: '日领取限制',
        dataIndex: 'limit',
        width:120,
        search: {
          transform: (limit) => {
            return { limit };
          },
        },
        renderFormItem: (props) => <Double />
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 100,
        fixed: 'right',
        render: (text, record: any) => {
          return [
            hasAuthCode(2248) ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑', record)}>编辑</Button> : null,
          ]
        },
    },
  ]
}
