import ProTable, { ActionType } from "@ant-design/pro-table"
import { knowledgeAnswered } from "@api/knowledge"
import { useHasAuthCode } from "@hooks/index"
import { productApi } from "@utils/index"
import { Modal } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, OperateActionType, TableListItem } from "./data"

const getApi = productApi(knowledgeAnswered)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record: any) => {}
    const reFush = () => {
        setTimeout(() => {
            ref.current?.reload()
        }, 1000)
    }
    const columns = columnsFn(operate)
    
    return (<ProTable<TableListItem>
        scroll={{x: 1200}}
        columns={columns}
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        pagination={{defaultPageSize: 10}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => 
            [ ...dom.reverse() ]
        }}
    />)
}