import { userAuth } from '@api/index'
import { getAuthCodeArr } from '@utils/index'
import {SET_USER_INFO, SET_USER_ROLE_DATA} from '../contants'
export const setUserInfo = (userInfo: { permissions: any; roles: any; user: any }) => {
    return {
        type: SET_USER_INFO,
        userInfo
    }
}


export const setUserRoleData = (userRoleData: any) => {
    return {
        type: SET_USER_ROLE_DATA,
        userRoleData
    }
}

// 异步获取当前账号用户信息
export const asyncSetUserInfo = () => {
    return async (dispatch: any) => {
        dispatch(setUserInfo({permissions: [], roles: [], user: {}}))
        const res = await userAuth({})
        const { data } = res as any || {}
        const {authorities, roleIds: roles, ...user } = data || {}
        const permissions = getAuthCodeArr(authorities)
        dispatch(setUserInfo({permissions, roles, user}))
        return {permissions, roles, user}
    }
}