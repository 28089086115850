import ProTable, { ActionType } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Dropdown, Menu, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, confirmContent, OperateActionType, operateMap, TableListItem, tabPanes } from "./data"
import { BASEURL, invoiceAuditOperate, invoiceCodeUpdate, invoicePostInfo, invoicePostInfoAddUpdate, invoiceAuditPageList, invoiceUpdateContractNumber } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ImportButton, ProTableWithFormSetting } from "@components/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { ModalForm, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { getCheckedColumnsStateByColumns } from "../BillManage"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"

const getPageListApi = productApi(invoiceAuditPageList)
const operateApi = productApi(invoiceAuditOperate)
const updateCodeApi = productApi(invoiceCodeUpdate)
const updatePostInfoApi = productApi(invoicePostInfoAddUpdate)
const updateContractCodeApi = productApi(invoiceUpdateContractNumber)
const persistenceKey = `finance/billAudit`

interface ReasonProps extends ModalFormProps{
    params?: any;
    disabled?: boolean;
    onSuccess?: () => void;
}
export const Reason = ({ children, params, initialValues, onSuccess, disabled, ...rest}: ReasonProps) => {
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.setFieldsValue(initialValues||{})
        }
    }
    return (<><ModalForm<ReasonProps> 
            layout="horizontal" 
            width={600} 
            labelCol={{span: 5}} 
            onVisibleChange={onVisibleChange} 
            formRef={formRef} 
            {...rest}>
            {children ? children : <ProFormTextArea label="备注" name="reason" disabled={disabled} fieldProps={{ showCount: true, maxLength: 30 }} rules={[{ required: true, message: '请填写备注',}]} />}
    </ModalForm>
    </>)
}


export default () => {
    const history = useHistory()
    const { downLoadUrlMap } = useSelector((state: StateType) => state)
    const ref = useRef<ActionType>(null);
    const formRef = useRef<ProFormInstance>()
    const [activeKey, setActiveKey] = useState(' ')
    const [, hasAuthCode] = useHasAuthCode()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [reasonProps, setReasonProps] = useState<ReasonProps>({visible: false, disabled: false })
    const [billCodeProps, setBillCodeProps] = useState<ReasonProps>({visible: false, disabled: false })
    const [contractNumberProps, setContractNumberProps] = useState<ReasonProps>({visible: false, disabled: false })
    const [postInfoProps, setPostInfoProps] = useState<ReasonProps>({visible: false, disabled: false })
    const [summary, setSummary] = useState<any>({})

    const operate = async (action: OperateActionType, record: any) => {
        const { id, invoiceId, reason, invoiceNumber, contractNumber, status } = record || {}
        if (['详情'].includes(action)) {
            history.push(`/finance/billDetail?${stringify({id})}`)
        } else if (['审批通过', '确认开票', '撤销开票', '撤销', '作废发票'].includes(action)) {
            Modal.confirm({
                title: `${action}`,
                content: confirmContent[action],
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    const ids = id ? [id] : selectedRowKeys
                    await operateApi({ids, operate: operateMap[action]}, true, reFush)
                },
            });
        } else if (['驳回申请', '驳回原因', '暂停开票', '暂停原因'].includes(action)) {
            setReasonProps({
                title: action,
                visible: true,
                initialValues: { reason },
                disabled: action.includes('原因'),
                async onFinish (values: any) {
                    if (action.includes('原因')) {
                        setReasonProps({visible: false})
                    } else {
                        await operateApi({...values, ids: [id], operate: operateMap[action]}, true, () => {
                            setReasonProps({visible: false})
                            reFush()
                        }) 
                    }
                },
            })
        } else if (['发票号码'].includes(action)) {
            setBillCodeProps({
                title: action,
                visible: true,
                initialValues: {invoiceNumber},
                onFinish: async ({invoiceNumber: number}: any) => {
                    await updateCodeApi({number, id}, true, () => {
                        setBillCodeProps({visible: false})
                        reFush()
                    })
                },
            })
        } else if (['邮寄信息'].includes(action)) {
            const { data } = await invoicePostInfo({id}) as any
            const { id: pid, expressCompany, orderId, senderName, senderPhone, senderAddress, receiverName, receiverPhone, receiverAddress } = data || {}
            setPostInfoProps({
                title: action,
                visible: true,
                initialValues: { 
                    expressCompany, 
                    orderId, 
                    senderName, 
                    senderPhone, 
                    senderAddress, 
                    receiverName, 
                    receiverPhone, 
                    receiverAddress 
                },
                onFinish: async(values: any) => {
                    await updatePostInfoApi({...values, invoiceId: id, id: pid}, true, () => {
                        setPostInfoProps({visible: false})
                        reFush()
                    })
                }
            })
        } else if (['合同编号'].includes(action)) {
            setContractNumberProps({
                title: action,
                visible: true,
                initialValues: {contractNumber},
                onFinish: async ({contractNumber}: any) => {
                    await updateContractCodeApi({contractNumber, id}, true, () => {
                        setContractNumberProps({visible: false})
                        reFush()
                    })
                },
            })
        } else if (['更改开票信息'].includes(action)) {
            history.push(`/finance/billAdd?${stringify({id, fromPath: '/finance/billAudit'})}`)
        }
    }

    const getApi = async (params: any) => {
        const { taskStatus1, taskStatus, ...rest } = params
        const { data } = await getPageListApi({...rest, taskStatus: taskStatus === ' ' ? taskStatus1 : taskStatus }, false)
        const { values, total, ...summary } = data || {}
        setSummary(summary)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const params = activeKey === ' ' ? {} : {status: activeKey}

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params,
            searchType: 1
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params,
            searchType: 1
        })
    }

    const calcDisabled = () => !selectedRowKeys.length

    const columns = columnsFn(operate, activeKey)

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
          style={{background: '#fff'}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
        />
        <ProTableWithFormSetting<TableListItem>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false}}
                headerTitle={
                    <h5 style={{fontSize: '14px', margin: 0}}>
                        开票金额：<span style={{color: 'red'}}>{summary?.amount||0}</span> 
                        （汇总列表查询结果）
                    </h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2404) ? <Button key="btn5" type="primary" disabled={calcDisabled()} onClick={() => operate('审批通过', null)}>审批通过</Button> : null,
                        hasAuthCode(2406) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('确认开票', null)}>确认开票</Button> : null,
                        <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                            {/* { hasAuthCode(2416) ? <Menu.Item key="btn1"><ImportButton action={`${BASEURL}/invoice/importMailed`} templateUrl={downLoadUrlMap["发票审批-导入邮寄信息"]} title="导入邮寄信息" trigger={<a>邮寄信息</a>}></ImportButton></Menu.Item> : null } */}
                            { hasAuthCode(2417) ? <Menu.Item key="btn2"><ImportButton action={`${BASEURL}/invoice/importInvoice`} templateUrl={downLoadUrlMap["发票审批-导入发票号码"]} title="导入发票号码" trigger={<a>发票号码</a>}></ImportButton></Menu.Item> : null }
                        </Menu>}>
                            <Button>批量导入</Button>
                        </Dropdown>,
                        // hasAuthCode(2399) ? <ExportButton key="btn5" disabled={calcDisabled()} param={getQueryParams} action={`${BASEURL}/invoice/exportInvoices`}></ExportButton> : null,
                        hasAuthCode(2399) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/invoice/exportInvoices`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="id"
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <Reason modalProps={{onCancel: () => setReasonProps({...reasonProps, visible: false}), maskClosable: false}} {...reasonProps} />
        <Reason modalProps={{onCancel: () => setBillCodeProps({...billCodeProps, visible: false}), maskClosable: false}} {...billCodeProps} >
            <ProFormText label="发票号码" name="invoiceNumber" rules={[{ required: true, message: '请填写发票号码',}]} />
        </Reason>
        <Reason modalProps={{onCancel: () => setContractNumberProps({...contractNumberProps, visible: false}), maskClosable: false}} {...contractNumberProps} >
            <ProFormText label="合同编号" name="contractNumber" rules={[{ required: true, message: '请填写合同编号',}]} />
        </Reason>
        <Reason modalProps={{onCancel: () => setPostInfoProps({...postInfoProps, visible: false}), maskClosable: false}} {...postInfoProps} >
            <ProFormText label="快递公司" name="expressCompany" rules={[{ required: true, message: '请填写快递公司',}]} />
            <ProFormText label="快递单号" name="orderId" rules={[{ required: true, message: '请填写快递单号',}]} />
            <ProFormText label="寄件人姓名" name="senderName" rules={[{ required: true, message: '请填写寄件人姓名',}]} />
            <ProFormText label="寄件人手机号码" name="senderPhone" rules={[{ required: true, message: '请填写寄件人手机号码',}]} />
            <ProFormText label="寄件地址" name="senderAddress" rules={[{ required: true, message: '请填写寄件地址',}]} />
            <ProFormText label="收件人姓名" name="receiverName" rules={[{ required: true, message: '请填写收件人姓名',}]} />
            <ProFormText label="收件人手机号码" name="receiverPhone" rules={[{ required: true, message: '请填写收件人手机号码',}]} />
            <ProFormText label="收件地址" name="receiverAddress" rules={[{ required: true, message: '请填写收件地址',}]} />
        </Reason>
    </div>)
}
