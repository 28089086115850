import React, { useState, useEffect } from "react";
import { InputNumber, Select, TimePicker, Cascader, Button  } from "antd";
import { useDictEnumHooks, useHasAuthCode, useRoleListHooks } from "@hooks/index"
import moment from 'moment'
import { ProColumns } from "@ant-design/pro-table";

export type ActionType = 'dataSource1' | 'dataSource2' | 'dataSource3'

const timeFormat = 'HH:mm:ss'
const styles = {
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '12px'
}
export const dataSource2Item = () => ({
    autoTransferTime: '',
    custType: [],
    days: '',
    editState: true,
    rule: '',
    suitSysScopeId: "",
    time: '',
})

export const dataSource3Item = () => ({
    suitSysScopeId: "",
    rule: '',
    editState: true,
    roleIds: []
})


export const columnsFn1: (operate?: Function, dataSourceChange?: Function) => any[] = (operate, dataSourceChange) => {
    const [, hasAuthCode] = useHasAuthCode()
    const ownChange = (type: ActionType, newRecord: any, index: number, text: string) => {
        dataSourceChange && dataSourceChange(type, newRecord, index)
    }
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>规则</span>,
            dataIndex: 'rule',
            key: 'rule',
            render: (text: string, record: any, index: number) => 
            <div style={{color: 'rgba(0, 0, 0, 0.65)'}}>
                <InputNumber value={text} disabled={!record.editState} min="0" max="99999" onBlur={(e) => ownChange('dataSource1', {...record, rule: e?.target?.value}, index, 'rule')} />&nbsp;&nbsp;天内不能连续领取同一个客户
            </div>
        },
        {
            title: '操作',
            key: 'option',
            render: (text: string, record: any) => [
                hasAuthCode(2144) ? <a key="save" style={{marginRight: 8}} onClick={() => operate && operate(`领回-${record.editState ? '保存' : '修改'}`, record)}>{record.editState ? '保存' : '修改'}</a> : null,
                hasAuthCode(2145) ? <a key="edit" onClick={() => operate && operate('领回-启用禁用', record)}>{ record.status ? '禁用' : '启用'}</a> : null,
            ],
        },
    ]
}


export const columnsFn2: (operate?: Function, dataSourceChange?: Function) => any[] = (operate, dataSourceChange) => {
    const [, hasAuthCode] = useHasAuthCode()
    // 系统范围
    const [salesStageOptions, ,] = useDictEnumHooks({dictId: 23 }, true)
    // 客户状态
    const [userState, ,] = useDictEnumHooks({dictId: 13 }, true)
    // 客户等级
    const [userGrade, ,] = useDictEnumHooks({dictId: 14 }, true)
    const [userTreeData ] = useRoleListHooks({}, true)
    const [selectOptions ] = useDictEnumHooks({dictId: 287 }, true)

    if (userTreeData[0]?.value !== -1){
        userTreeData.unshift({value: -1, label: '全部'})
    }
    // 二级
    const cascaderOptions = [
        {
            value: 13,
            label: '客户状态',
            key:'13',
            children: userState
        },
        {
            value: 14,
            label: '客户等级',
            key:'14',
            children: userGrade
        }
    ]
    // 这里目的是为了把数据转换成后端需要的格式
    const valFilter = (value: any)=>{
        const dataList: any[][] = []
        value.forEach((element: number[]) => {
            if (element.length !== 1) {
               dataList.push(element)
            } else {
                let list = cascaderOptions.filter((v: any) => v.value === element[0])
                list[0].children?.forEach((element2: any) => {
                    dataList.push([element[0], element2.value])
                });
            }
        });
        return dataList
    }
    const ownChange = (type: ActionType, newRecord: any, index: number, text: string) => {
        dataSourceChange && dataSourceChange(type, newRecord, index)
    }

    // old
    // const RenderCustType = ({text, record, index, ownChange}: any) => {
    //     const [value, setValue] = useState(text||[])
    //     const onOwnChange = (val: any) => setValue(val)
    //     const onDropdownVisibleChange = (visible: boolean) => {
    //         !visible && ownChange('dataSource2', {...record, custType: valFilter(value)}, index, 'custType')
    //     }
    //     return <div style={styles}>此规则仅限制 <Cascader maxTagCount={0} multiple  disabled={!record.editState} options={cascaderOptions} style={{width: 100}} value={value} 
    //     onChange={onOwnChange} onDropdownVisibleChange={onDropdownVisibleChange} /> 客户
    //     </div>
    // }
    // const selectOptions = [{label: '全部', value: ''}, {label: '重点客户', value: '1'}, {label: '普通客户', value: '2'}]
    const RenderCustType = ({text, record, index, onChange}: any) => {
        return <div style={styles}>
            此规则仅限制 
            <Select 
                maxTagCount={0}  
                disabled={!record.editState} 
                options={selectOptions} 
                style={{width: 100}} 
                value={text} 
                onChange={(val: any) => ownChange('dataSource2', {...record, custType: val}, index, 'custType')}
            /> 
            客户
        </div>
    }

    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>适用系统范围</span>,
            key: 'suitSysScopeId',
            dataIndex: 'suitSysScopeId',
            render: (text: string, record: any, index: number) => <Select disabled={!record.editState} options={salesStageOptions} style={{width: '100%'}} value={text} 
                onChange={(val) => ownChange('dataSource2', {...record, suitSysScopeId: val}, index, 'suitSysScopeId')} />
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>限制客户类型</span>,
            dataIndex: 'custType',
            key: 'custType',
            render: (text: any, record: any, index: number) => <RenderCustType text={text} record={record} index={index} onChange={ownChange} />
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>规则</span>,
            dataIndex: 'rule',
            key: 'rule',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>超过 <InputNumber disabled={!record.editState} style={{width: 60}} min="0" value={text} 
               onBlur={(e) => ownChange('dataSource2', {...record, rule: e?.target.value}, index, 'rule')} 
                /> 天未跟进
            </div>
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>自动划入时间</span>,
            dataIndex: 'autoTransferTime',
            key: 'autoTransferTime',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>每天 <TimePicker disabled={!record.editState} value={text ? moment(text, timeFormat) : null}
                onChange={(_,val) => ownChange('dataSource2', {...record, autoTransferTime: val}, index, 'autoTransferTime')} />
            </div>
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>适用角色</span>,
            dataIndex: 'roleIds',
            key: 'roleIds',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>此规则针对 <Select mode="multiple" disabled={!record.editState} style={{width: 100}} options={userTreeData} value={text} onChange={(val) => ownChange('dataSource2', {...record, roleIds: val}, index, 'roleIds')} /></div>
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>销售人员提醒时间</span>,
            dataIndex: 'days',
            key: 'days',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>划入前 <InputNumber disabled={!record.editState} style={{width: 60}} min="0" value={text} 
                onBlur={(e) => ownChange('dataSource2', {...record, days: e?.target.value}, index, 'days')} /> 天 
               <TimePicker disabled={!record.editState} value={record.time ? moment(record.time, timeFormat) : null} onChange={(_,val) => ownChange('dataSource2', {...record, time: val}, index, 'time')}  />
            </div>
        },
        {
            title: '操作',
            key: 'option',
            render: (text: string, record: any, index: number) => [
                hasAuthCode(2133) ? <a key="save" style={{marginRight: 8}} onClick={() => operate && operate(`划入-${record.editState ? '保存' : '修改'}`, record, index)}>{record.editState ? '保存' : '修改'}</a> : null,
                hasAuthCode(2135) ? <a key="use" style={{marginRight: 8}} onClick={() => operate && operate('划入-启用禁用', record, index)}>{ Number(record.poolSettingStatus) ? '禁用' : '启用'}</a> : null,
                hasAuthCode(2132) ? index ? <a key="del" style={{marginRight: 8}} onClick={() => operate && operate('划入-删除', record, index)}>删除</a> : null : null,
                hasAuthCode(2133) ? <a key="add" onClick={() => operate && operate('划入-添加', record, index)}>添加</a> : null,
            ],
        },
    ]
}


export const columnsFn3: (operate?: Function, dataSourceChange?: Function) => any[] = (operate, dataSourceChange) => {
    const [, hasAuthCode] = useHasAuthCode()
    // 系统范围
    const [salesStageOptions, ,] = useDictEnumHooks({dictId: 23 }, true)
    const [userTreeData ] = useRoleListHooks({}, true)
    if (userTreeData[0]?.value !== -1){
        userTreeData.unshift({value: -1, label: '全部'})
    }
    const ownChange = (type: ActionType, newRecord: any, index: number, text: string) => {
        dataSourceChange && dataSourceChange(type, newRecord, index)
    }
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>适用系统范围</span>,
            dataIndex: 'suitSysScopeId',
            key: 'suitSysScopeId',
            render: (text: string, record: any, index: number) => 
            <Select disabled={!record.editState}  style={{width: '100%'}} options={salesStageOptions} value={text} onChange={(val) => ownChange('dataSource3', {...record, suitSysScopeId: val}, index, 'suitSysScopeId')} />
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>规则</span>,
            dataIndex: 'rule',
            key: 'rule',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>每个员工拥有的最大客户数为 <InputNumber disabled={!record.editState} style={{width: 60}} value={text} min="0" onBlur={(e) => ownChange('dataSource3', {...record, rule: e?.target.value}, index, 'rule')} /> 个</div>
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>适用角色</span>,
            dataIndex: 'roleIds',
            key: 'roleIds',
            render: (text: string, record: any, index: number) => 
            <div style={styles}>此规则针对 <Select mode="multiple" disabled={!record.editState} style={{width: 100}} options={userTreeData} value={text} onChange={(val) => ownChange('dataSource3', {...record, roleIds: val}, index, 'roleIds')} /></div>
        },
        {
            title: '操作',
            key: 'option',
            render: (text: string, record: any, index: number) => [
                hasAuthCode(2138) ? <a key="use" style={{marginRight: 8}} onClick={() => operate && operate(`数量-${record.editState ? '保存' : '修改'}`, record, index)}>{record.editState ? '保存' : '修改'}</a> : null,
                hasAuthCode(2140) ? <a key="isDisabled" style={{marginRight: 8}} onClick={() => operate && operate('数量-启用禁用', record, index)}>{ Number(record.status) ? '禁用' : '启用'}</a> : null,
                hasAuthCode(2142) ? index ? <a key="del" style={{marginRight: 8}} onClick={() => operate && operate('数量-删除', record, index)}>删除</a> : null : null,
                hasAuthCode(2138) ? <a key="edit" onClick={() => operate && operate('数量-添加', record, index)}>添加</a> : null,
            ],
        },
    ]
}

const statusOptions = [{label: '启用', value: 1}, {label: '禁用', value: 2}]

export const reportColumns1: (operate: Function) => ProColumns<any, any>[] = (operate: Function) => {
    const [, hasAuthCode] = useHasAuthCode()
    const [userTreeData ] = useRoleListHooks({}, true)
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>审批人角色</span>,
            dataIndex: 'reviewRoleId',
            key: 'reviewRoleId',
            valueType: 'select',
            request: () => userTreeData,
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>被审批人角色</span>,
            dataIndex: 'subIds',
            key: 'subIds',
            valueType: 'select',
            fieldProps: { mode: "multiple"},
            request: () => userTreeData,
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>状态</span>,
            dataIndex: 'status',
            key: 'status',
            valueType: 'select',
            request: () => statusOptions,
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '操作',
            valueType: 'option',
            key: 'option',
            render: (text: any, record: any, _: any, action: any) => [
                hasAuthCode(2435) ? <a key="use" style={{marginRight: 8}} onClick={() => action?.startEditable?.(record.id)}>修改</a> : null,
                hasAuthCode(2436) ? <a key="del" style={{marginRight: 8}} onClick={() => operate && operate('报告审批-删除', record)}>删除</a> : null,
            ],
        },
    ]
}

export const reportColumns2: (operate: Function) => ProColumns<any, any>[] = (operate: Function) => {
    const [, hasAuthCode] = useHasAuthCode()
    const [userTreeData ] = useRoleListHooks({}, true)
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>数据统计角色</span>,
            dataIndex: 'roleIds',
            key: 'roleIds',
            valueType: 'select',
            fieldProps: { mode: 'multiple' },
            request: () => [{label: '全部', value: -1}, ...userTreeData],
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        // {
        //     title: <span>是否统计周末</span>,
        //     dataIndex: 'weekends',
        //     key: 'weekends',
        //     request: () => [{label: '周六', value: 1}, {label: '周日', value: 2}],
        //     valueType: 'checkbox',
        // },
        {
            title: <span><i style={{color: 'red'}}>*</i>状态</span>,
            dataIndex: 'status',
            key: 'status',
            valueType: 'select',
            request: () => statusOptions,
            formItemProps: {
                rules: [
                  {
                    required: true,
                    message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: '操作',
            valueType: 'option',
            key: 'option',
            render: (text: any, record: any, _: any, action: any) => [
                hasAuthCode(2432) ? <a key="use" style={{marginRight: 8}} onClick={() => action?.startEditable?.(record.id)}>修改</a> : null,
                hasAuthCode(2433) ? <a key="del" style={{marginRight: 8}} onClick={() => operate && operate('报告统计-删除', record)}>删除</a> : null,
            ],
        },
    ]
}