import { useState } from "react"
import { Modal } from "antd"
import { OperateActionType } from "../../data"
import { RebackClaimModalFormProps } from "."

export const useRebackClaimHooks = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [rebackClaimModalFormProps, setRebackClaimModalFormProps] = useState<RebackClaimModalFormProps>({title: '新建回款记录', visible: false, params: {}})
    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { backClaimId } = record || {}

        const backClaimIds = backClaimId ? [backClaimId] : selectedRowKeys
        // 认领回款
        const claimRebackAction = (props: Object = {}) => setRebackClaimModalFormProps({
            ...rebackClaimModalFormProps,
            title: '认领回款',
            visible: true,
            params: {backClaimId},
            onSuccess: () => {
                setRebackClaimModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        return {
            claimRebackAction
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            claimRebackAction
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '待认领回款-认领':
                    return claimRebackAction()
            }
        }
    }

    return {
        rebackClaimModalFormProps,
        setRebackClaimModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    }

}