import React from "react"
import { useSelector } from "react-redux"
import { ProColumns } from "@ant-design/pro-table"
import { useDocDataHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { Tooltip, TreeSelect } from "antd"
import { Ellipsis } from "@components/Common"
import { calcHref } from "../Home/data"
import { Link } from "react-router-dom"

const docStatusOptions = [
  {
    label: '待审核',
    value: 3
  },
  {
    label: '通过',
    value: 1
  },
  {
    label: '不通过',
    value: 2
  },
]

export type OperateActionType = '审核' | '审核详情'
export type TableListItem = {

}

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({}, true)
    const [, hasAuthCode] = useHasAuthCode()

    const calcOperate = (record: any, type: OperateActionType) => {
      const {status} = record || {}
      if (type === '审核') {
         return [3].includes(status) && hasAuthCode(2344)
      }
    }
  
    return [
      {
        title: '标题',
        dataIndex: 'title',
        fixed: 'left',
        align: 'center',
        order: 100,
        render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
      },
      {
        title: '相关标签',
        dataIndex: 'labelIds',
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: labelTreeData,
          placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text, record: any) => <Ellipsis text={(record.labelNameStr||[]).toString()||'-'} />
      },
      {
        title: '关联客户',
        dataIndex: 'customerName',
        order: 3,
        render: (text: any, record: any) => (record.customerNameStr||'').toString()
      },
      {
        title: '文档分类',
        dataIndex: 'categoryIds',
        order: 6,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          placeholder: '请选择',
          treeNodeFilterProp: 'title',
          treeData: docTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text: any, record: any) => record.categoryNameStr
      },
      {
        title: '提交人',
        dataIndex: 'submitId',
        order: 2,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData,
          placeholder: '请选择'
        },
        search: {
            transform: (ids) => {
            const submitId = (ids||[]).map((v: string) => v.slice(1))
            return { submitId }
            },
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text: any, record: any) => record.submitName
      },
      {
        title: '提交时间',
        dataIndex: 'days',
        order: 1,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text, record: any) => `${record.submitTime||''}`
      },
      { 
        title: '审核状态',
        dataIndex: 'status',
        key: 'status',
        order: 5,
        valueType: 'select',
        hideInSearch: true,
        fieldProps: {
          options: docStatusOptions
        },
        render: (text: any, record: any) => <Tooltip title={record.mark}><span style={{color: `${['','green', 'red', ][record.status]}`}}>{text}</span></Tooltip>
      },
      {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text, record: any) => [
          calcOperate(record, '审核') ? <a key="审核" onClick={() => operate && operate('审核', record)}>审核</a> : null,
          record.status === 2 ? <a key="审核详情" onClick={() => operate && operate('审核详情', record)}>审核详情</a> : null
        ],
      },
    ]
}

export const tabPanes = [
    {
        Tab: '全部',
        key: '',
        Content: null
    },
    {
        Tab: '待审核',
        key: '3',
        Content: null
    },
    {
        Tab: '通过',
        key: '1',
        Content: null
    },
    {
        Tab: '不通过',
        key: '2',
        Content: null
    }
]