import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL, LQBASEURL } from './baseUrl'

/*-----------------------------------------客户管理----------------------------------*/
// diydo的共用接口
export const diyDo2Get = (data?: any) => request({
    url: `${BASEURL}/web/creater/diyDo2Get`,
    // method: 'GET',
    data,
})

// 清空缓存
export const clearCache = (data?: any) => request({
    url: `${BASEURL}/web/creater/clear`,
    // method: 'GET',
    data,
})

// 生成器的枚举数据
export const getBuilderEnums = (data?: any) => request({
    url: `${BASEURL}/web/creater/getBuilderEnums`,
    // method: 'GET',
    data,
})

// 客户列表
export const customerList = (data?: any) => request({
    url: `${BASEURL}/searchPage`,
    // method: 'GET',
    data,
})

export const customerHelperList = (data?: any) => request({
    url: `${BASEURL}/getSynergyByCustomerIds`,
    // method: 'GET',
    data,
})
// 客户关注
export const customerAttention = (data?: any) => request({
    url: `${BASEURL}/followOrUnfollow`,
    data,
})

// 删除客户
export const customerDel = (data?: any) => request({
    url: `${BASEURL}/deleteCustomers`,
    data,
})

// 添加协同人
export const customerHelperSave = (data?: any) => request({
    url: `${BASEURL}/saveSynergy`,
    data,
})

// 删除协同人
export const customerHelperDel = (data?: any) => request({
    url: `${BASEURL}/deleteSyn`,
    data,
})

// 客户列表 => 转移客户: 将我的客户转移给别人
export const customerTransferToWho = (data?: any) => request({
    url: `${BASEURL}/transferCustomer`,
    data,
})

// 客户列表 => 移入线索池: 将我的客户回退到原来的线索池
export const customerBackToPool = (data?: any) => request({
    url: `${BASEURL}/returnCustomerToPool`,
    data,
})

// 根据客户ID 查询客户详细信息
export const customerGetInfoById = (data?: any) => request({
    url: `${BASEURL}/getCustomerById`,
    method: 'GET',
    data,
})

// 根据客户ID 获取客户账号
export const customerGetAccountsById = (data?: any) => request({
    url: `${BASEURL}/getAccountsByCustomerId`,
    method: 'GET',
    data,
})

// 根据客户名称 模糊搜索客户
export const customerListByName = (data?: any) => request({
    url: `${BASEURL}/common/FuzzySearchName`,
    method: 'GET',
    data,
})

// 根据商机名称 模糊搜索商机
export const businessListByName = (data?: any) => request({
    url: `${BASEURL}/business/getFuzzyBusiness`,
    method: 'GET',
    data,
})

// 客户名称校验
export const customerNameCheck = (data?: any) => request({
    url: `${BASEURL}/verifyName`,
    method: 'GET',
    data,
})

// 线索池 => 领取线索: 从线索池里面领到个人线索池
export const customerClueReceive = (data?: {clueIds: any[]}) => request({
    url: `${BASEURL}/clue/receive`,
    data,
})

// [线索池, 我的线索] => 转为客户: 将该线索领取到我的客户列表里面
export const customerClueToCustomer = (data?: {clueIds: any[]}) => request({
    url: `${BASEURL}/clue/tocus`,
    data,
})

// 分配线索
// todo


// 线索池 => 转移线索: 将该线索转移到某个线索池
export const customerClueTransferToPool = (data?: {cluePoolId: string; clueIds: any[]}) => request({
    url: `${BASEURL}/clue/transfer`,
    data: {...data, type: 0},
})

// todo
// 我的线索 => 转移线索: 将我的线索转移给别人
export const customerClueTransferToWho = (data?: {cluePoolId: string; clueIds: any[]}) => request({
    url: `${BASEURL}/clue/transfer`,
    data: {...data, type: 1},
})
// 我的线索 => 移入线索池: 将该线索移回到原本的线索池
export const customerClueBackToPool = (data?: {clueIds: any[]}) => request({
    url: `${BASEURL}/clue/move`,
    data,
})

// 删除线索
export const customerClueDel = (data?: {clueIds: any[]}) => request({
    url: `${BASEURL}/clue/del`,
    data,
})


// 新建跟进
export const customerFollowAdd = (data?: any) => request({
    url: `${BASEURL}/saveFollowup`,
    data,
})

// 跟进记录批量删除
export const customerFollowDel = (data?: any) => request({
    url: `${BASEURL}/followup/batchDelete`,
    data,
})


// 新建跟进---重点项目
export const customerFollowAddImportant = (data?: any) => request({
    url: `${BASEURL}/followup/createEmphasisBusiness`,
    data,
})

// 编辑跟进---重点项目
export const customerFollowEditImportant = (data?: any) => request({
    url: `${BASEURL}/followup/editEmphasisBusiness`,
    data,
})

// 删除跟进---重点项目
export const customerFollowDelImportant = (data?: any) => request({
    url: `${BASEURL}/followup/batchDeleteEmphasisBusiness`,
    data,
})


// 保存客户
export const customerSave = (data?: any) => request({
    url: `${BASEURL}/saveOrUpdateCustomer`,
    data,
})

// 联系人模块
export const customerLqContactList = (data?: any) => request({
    url: `${BASEURL}/getContactInfoByCompanyName`,
    method: 'GET',
    data,
})

export const customerContactList = (data?: any) => request({
    url: `${BASEURL}/contacts/list`,
    data,
})

// 通过客户id获得阿里云的客户
export const customerGetAliccount = (data?: any) => request({
    url: `${BASEURL}/getALYAccount`,
    method: 'GET',
    data,
})

export const customerContactAdd = (data?: any) => request({
    url: `${BASEURL}/contacts/create`,
    data,
})

export const customerContactUpdate = (data?: any) => request({
    url: `${BASEURL}/contacts/update`,
    data,
})

export const customerContactDetail = (data?: any) => request({
    url: `${BASEURL}/contacts/detail`,
    data,
})

// 跟进记录
export const customerFollowList = (data?: any) => request({
    url: `${BASEURL}/followup/list`,
    data,
})

// 商机管理
export const customerBusinessList = (data?: any) => request({
    url: `${BASEURL}/business/listbusinessopp`,
    data,
})

// 商机详情
export const customerBusinessDetail = (data?: any) => request({
    url: `${BASEURL}/business/detail`,
    data,
})

// 创建/修改商机
export const customerBusinessSave = (data?: any) => request({
    url: `${BASEURL}/business/new`,
    data,
})

export const customerBusinessUpdate = (data?: any) => request({
    url: `${BASEURL}/business/edit`,
    data,
})

// 查询全局的消息提醒
export const customerGetMessage = (data?: any) => request({
    url: `${BASEURL}/taskCenter/pushMessage`,
    data,
})

// 查询后台正在进行的进程   导出、导入文件等
export const getImportExcelConsumeOrderStatus = (data?: any) => request({
    url: `${LQBASEURL}/enterpriseSearch/enterpriseProgress`,
    data,
})

// 删除后台已经完成的进程   导出、导入文件等
export const delImportExcelConsumeOrderStatus = (data?: any) => request({
    url: `${LQBASEURL}/enterpriseSearch/taskDelete`,
    data,
})

// 删除商机
export const customerBusinessDel = (data?: any) => request({
    url: `${BASEURL}/business/del`,
    data,
})

// 线索池
export const customerClueList = (data?: any) => request({
    url: `${BASEURL}/clue/search`,
    data,
})

export const customerClueDispatch = (data?: any) => request({
    // url: `${BASEURL}/clue/dis`,  // old
    url: `${BASEURL}/clue/tocus`,
    data,
})

export const customerHasBussiness = (data?: any) => request({
    url: `${BASEURL}/hasOpportunities`,
    method: 'GET',
    data,
})
// 是否能添加线索池
export const customerCluePoolCanAdd = (data?: any) => request({
    url: `${BASEURL}/canAdd`,
    data,
})

// 是否能添加客户线索: type: 1 客户 2 线索
export const customerCanAdd = (data?: any) => request({
    url: `${BASEURL}/canAddCustomer`,
    method: 'GET',
    data,
})

// 查询系统中已存在手机号的个数
export const customerGetPhoneCount = (data?: any) => request({
    url: `${BASEURL}/getPhoneNumberCount`,
    method: 'GET',
    data,
})

// 校验客户账号
export const customerCheckAccount  = (data?: any) => request({
    url: `${BASEURL}/checkAccountDuplicity`,
    method: 'GET',
    data,
})

// 查看非脱敏手机号
export const customerSeePhoneNumber  = (data?: any) => request({
    url: `${BASEURL}/contacts/phoneNumber`,
    // method: 'GET',
    data,
})

// -------客户导出
export const customerExport = (data?: any) => request({
    url: `${BASEURL}/exportCustomers`,
    data,
})

// -------线索导出
export const customerCluesExport = (data?: any) => request({
    url: `${BASEURL}/clue/export`,
    data,
})

// -------商机导出
export const customerBusinessExport = (data?: any) => request({
    url: `${BASEURL}/business/export`,
    data,
})


// 跟进记录All
export const customerFollowAllList = (data?: any) => request({
    url: `${BASEURL}/followup/searchByConditions`,
    data,
})

// 跟进记录根据 customerID 汇总
export const customerFollowsByCustomerId = (data?: any) => request({
    url: `${BASEURL}/followup/getFollowUpsByCustomerId`,
    method: 'GET',
    data,
})


// 联系人All
export const customerContactAllList = (data?: any) => request({
    url: `${BASEURL}/contacts/listPage`,
    data,
})

// 客户返点消费明细  列表查询
export const customerGetconsumptionDetails = (data?: any) => request({
    url: `${BASEURL}/getconsumptionDetails`,
    // method: 'GET',
    data,
})

// 客户账号  列表查询
export const customerGetCustomerAccount = (data?: any) => request({
    url: `${BASEURL}/getCustomerAccount`,
    // method: 'GET',
    data,
})

// 返点比例 列表查询
export const customerGetRebatesPoint = (data?: any) => request({
    url: `${BASEURL}/getRebatesPoint`,
    method: 'GET',
    data,
})

// 新建客户账号
export const customerAddAccount = (data?: any) => request({
    url: `${BASEURL}/addCustomerAccount`,
    data,
})

// 编辑客户账号
export const customerUpdateAccount = (data?: any) => request({
    url: `${BASEURL}/updateCustomerAccount`,
    data,
})

// 新建返点比例
export const customerAddRebatesPoint = (data?: any) => request({
    url: `${BASEURL}/addRebatesPoint`,
    data,
})

// 编辑返点比例
export const customerUpdateRebatesPoint = (data?: any) => request({
    url: `${BASEURL}/updateRebatesPoint`,
    data,
})

// 查询收款人信息
export const customerGetPayeeInfo = (data?: any) => request({
    url: `${BASEURL}/getPayeeInfo`,
    method: 'GET',
    data,
})

// 新建收款人
export const customerAddPayInfo = (data?: any) => request({
    url: `${BASEURL}/addPayInfo`,
    data,
})

// 编辑收款人
export const customerUpdatePayInfo = (data?: any) => request({
    url: `${BASEURL}/updatePayInfo`,
    data,
})

// 查询规则 list
export const customerGetRebatesRule = (data?: any) => request({
    url: `${BASEURL}/rebatesRule/query`,
    data,
})

// 删除规则
export const customerDelRebatesRule = (data?: any) => request({
    url: `${BASEURL}/rebatesRule/delRule`,
    data,
})

// 编辑规则
export const customerUpdateRebatesRule = (data?: any) => request({
    url: `${BASEURL}/rebatesRule/update`,
    data,
})

// 新建规则
export const customerAddRebatesRule = (data?: any) => request({
    url: `${BASEURL}/rebatesRule/add`,
    data,
})

// 返点详情   APS原始消费订单
export const customerGetOriginalRecords = (data?: any) => request({
    url: `${BASEURL}/rebates/getOriginalRecords`,
    data,
})

// 返点详情   汇总明细 查询list
export const customerGetCollectInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/getCollectInfo`,
    method: 'GET',
    data,
})

// 返点详情   返点信息 查询list
export const customerGetRebatesInformation = (data?: any) => request({
    url: `${BASEURL}/rebates/getRebatesInformation`,
    method: 'GET',
    data,
})

// 返点详情   收款信息 查询list
export const customerGetPayeeInformation = (data?: any) => request({
    url: `${BASEURL}/rebates/getPayeeInformation`,
    method: 'GET',
    data,
})

// 返点详情   备注信息 查询list
export const customerGetRemarkInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/getRemarkInfo`,
    method: 'GET',
    data,
})

// 返点详情   凭证图片  查询list
export const customerGetVoucher = (data?: any) => request({
    url: `${BASEURL}/rebates/voucher`,
    method: 'GET',
    data,
})


// 操作记录查询
export const customerGetOperateLog = (data?: any) => request({
    url: `${BASEURL}/operateLog`,
    data,
})

// // 附件查询
// export const customerAffix = (data?: any) => request({
//     url: `${BASEURL}/contract/getContractAttachmentByCustomerId`,
//     data,
// })
// 附件查询
export const customerAffix = (data?: any) => request({
    url: `${BASEURL}/enclosures/listPage`,
    data,
})

// 新增客户附件
export const customerAffixAdd = (data?: any) => request({
    url: `${BASEURL}/enclosures/saveCustomerEnclosures`,
    data,
})

// 删除客户附件
export const customerAffixDel = (data?: any) => request({
    url: `${BASEURL}/enclosures/deleteCustomerEnclosures`,
    data,
})

// 首页搜索客户
export const customerHomeSearch = (data?: any) => request({
    url: `${BASEURL}/queryCustomerOrClue`,
    data,
})

// 更新客户组织架构
export const customerSaveDeptGraph = (data?: any) => request({
    url: `${BASEURL}/editCustomerDept`,
    data,
})


// 商机重点项目-业务调研最新数据
export const customerBusinessSurverySheetGet = (data?: any) => request({
    // url: `${BASEURL}/business/jnsBusinessResearch/getByBusinessId`, // --old
    url: `${BASEURL}/getCustomerResourceRecored`,
    method: 'GET',
    data,
})


// 商机重点项目-业务调研表新增/编辑
export const customerBusinessSurverySheetSave = (data?: any) => request({
    // url: `${BASEURL}/business/jnsBusinessResearch/save`,  // --old
    url: `${BASEURL}/saveOrUpdateCustomerResource`,
    // method: 'GET',
    data,
})

// 商机重点项目-业务调研表-历史列表
export const customerBusinessSurverySheetLog = (data?: any) => request({
    // url: `${BASEURL}/business/jnsBusinessResearch/getPage`,
    url: `${BASEURL}/getCustomerResourceRecored`,
    method: 'GET',
    data,
})

// 商机重点项目-业务逻辑介绍查询
export const customerBusinessGetLogicById = (data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/getLogicById`,
    method: 'GET',
    data,
})

// 商机重点项目-业务逻辑介绍查询
export const customerBusinessGetPictureById = (data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/getPictureById`,
    method: 'GET',
    data,
})

// 商机重点项目-业务逻辑介绍查询
export const customerBusinessSaveLogic = (data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/saveLogic`,
    data,
})

// 商机重点项目-业务逻辑介绍查询
export const customerBusinessSavePicture = (data?: any) => request({
    url: `${BASEURL}/business/jnsBusinessMapResource/saveBusinessPicture`,
    data,
})


// 商机重点项目-转重点项目
export const customerBusinessToImportant = (data?: any) => request({
    url: `${BASEURL}/business/transformEmphasis`,
    data,
})

// 转为重点客户
export const customerToImportant = (data?: any) => request({
    url: `${BASEURL}/businessOpportunities`,
    data,
})

// 校验转为重点客户或跟进前是否有已存在的商机
export const customerVerify = (data?: any) => request({
    url: `${BASEURL}/verifyBusinessOpportunities`,
    // method: 'GET',
    data,
})

// 校验转为重点客户或跟进前是否有已存在的商机
export const customerVerifyFollowup = (data?: any) => request({
    url: `${BASEURL}/verifyFollowup`,
    // method: 'GET',
    data,
})

// 转回个人线索
export const customer2Owner = (data?: any) => request({
    url: `${BASEURL}/clue/transferPersonalClues`,
    // method: 'GET',
    data,
})

// 转回普通
export const customer2Normal = (data?: any) => request({
    url: `${BASEURL}/sendBackCustomer`,
    // method: 'GET',
    data,
})