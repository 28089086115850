import { ProColumns } from "@ant-design/pro-table";
import { Ellipsis } from "@components/Common";
import { useHasAuthCode } from "@hooks/index";
import { StateType } from "@store/index";
import { TreeSelect } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { calcHref } from "../Home/data";

export type TableListItem = {
    key: number;
    id: string;
    mainName: string;
    sonName: string;
};
export type OperateActionType = '下架' | '上架' | '删除' | '创建文档' | '上传文档' | '编辑' | '移动分类'

export const tabPanes = [
    {
        Tab: '解决方案',
        Content: null
    },
    {
        Tab: '客户案例',
        Content: null
    },
    {
        Tab: '问卷模版',
        Content: null
    },
    {
        Tab: '帮助文档',
        Content: null
    },
]

const docTypeOptions = [
    {
        label: '个人文档',
        value: 1,
    },
    {
        label: '企业文档',
        value: 2
    }
]

export const docStateOptions = [
    {
        label: '已上架',
        value: 1,
    },
    {
        label: '已下架',
        value: 2
    }
]

export const columnsFn0: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
    return [
      {
          title: '标题',
          dataIndex: 'title',
          fixed: 'left',
          order: 100,
          render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
      },
      {
          title: '相关标签',
          dataIndex: 'labelNameStr',
          order: 4,
          fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
          },
          renderFormItem: (props) => <TreeSelect />,
          render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
      },
      {
          title: '关联客户',
          dataIndex: 'relatedCustomer',
          order: 3,
          render: (text: any, record: any) => record.relatedCustomer
      },
      {
          title: '文档类型',
          dataIndex: 'ownership',
          order: 2,
          valueType: 'select',
          fieldProps: {
            options: docTypeOptions
          }
     },
      {
           title: '发布人',
           dataIndex: 'publisher',
           order: 5,
           fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData,
                placeholder: '请选择'
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                const ownerNames = (ids||[]).map((v: string) => v.slice(1))
                return { ownerNames }
                },
            },
            render: (text: any, record: any) => record.publisher
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          valueType: 'dateRange',
          order: 1,
          render: (text: any, record: any) => record.publishTime
      },
      {
          title: '状态',
          dataIndex: 'state',
          order: 6,
          valueType: 'select',
          fieldProps: {
            options: docStateOptions
          }
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 160,
          fixed: 'right',
          render: (text, record: any) => {
            return[
              (hasAuthCode(2334) && (record.state === 1)) ? <a key="down" onClick={() => operate && operate('下架', record)}>下架</a> : null,
              (hasAuthCode(2333) && (record.state === 2)) ? <a key="up" onClick={() => operate && operate('上架', record)}>上架</a> : null,
              (hasAuthCode(2347) && (record.format === 1)) ? <a key="update" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
              hasAuthCode(2335) ? <a key="del" onClick={() => operate && operate('删除', record)}>删除</a> : null,
            ]
          },
      },
    ]
}


export const columnsFn1: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
    return [
      {
          title: '标题',
          dataIndex: 'title',
          fixed: 'left',
          order: 100,
          render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
      },
      {
            title: '相关标签',
            dataIndex: 'labelNameStr',
            order: 4,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
      },
      {
          title: '关联客户',
          dataIndex: 'relatedCustomer',
          order: 3,
      },
      {
          title: '文档类型',
          dataIndex: 'ownership',
          order: 2,
          valueType: 'select',
          fieldProps: {
            options: docTypeOptions
          }
     },
      {
          title: '发布人',
          dataIndex: 'publisher',
          order: 5,
          fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData,
                placeholder: '请选择'
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                const ownerNames = (ids||[]).map((v: string) => v.slice(1))
                return { ownerNames }
                },
            },
            render: (text: any, record: any) => record.publisher
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          valueType: 'dateRange',
          order: 1,
          render: (text: any, record: any) => record.publishTime
      },
      {
          title: '状态',
          dataIndex: 'state',
          order: 6,
          valueType: 'select',
          fieldProps: {
            options: docStateOptions
          }
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 160,
          fixed: 'right',
          render: (text, record: any) => {
            return[
                (hasAuthCode(2334) && (record.state === 1)) ? <a key="down" onClick={() => operate && operate('下架', record)}>下架</a> : null,
                (hasAuthCode(2333) && (record.state === 2)) ? <a key="up" onClick={() => operate && operate('上架', record)}>上架</a> : null,
                (hasAuthCode(2347) && (record.format === 1)) ? <a key="update" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
                hasAuthCode(2335) ? <a key="del" onClick={() => operate && operate('删除', record)}>删除</a> : null,
            ]
          },
      },
    ]
}

export const columnsFn2: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
    return [
      {
          title: '问卷标题',
          dataIndex: 'title',
          fixed: 'left',
          order: 100,
          render: (text: any, record: any) => <Link to={calcHref({...record, type: 3})}>{record.title}</Link>
      },
      {
           title: '相关标签',
           dataIndex: 'labelNameStr',
           order: 4,
           fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
      },
      {
          title: '文档类型',
          dataIndex: 'ownership',
          order: 3,
          valueType: 'select',
          fieldProps: {
            options: docTypeOptions
          }
      },
      {
          title: '发布人',
          dataIndex: 'publisher',
          order: 5,
          fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: deptWithUserTreeData,
                placeholder: '请选择'
            },
            renderFormItem: (props) => <TreeSelect />,
            search: {
                transform: (ids) => {
                const ownerNames = (ids||[]).map((v: string) => v.slice(1))
                return { ownerNames }
                },
            },
            render: (text: any, record: any) => record.publisher
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          valueType: 'dateRange',
          order: 1,
          render: (text: any, record: any) => record.publishTime
      },
      {
          title: '状态',
          dataIndex: 'state',
          order: 6,
          valueType: 'select',
          fieldProps: {
            options: docStateOptions
          }
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 160,
          fixed: 'right',
          render: (text, record: any) => {
            return[
                (hasAuthCode(2334) && (record.state === 1)) ? <a key="down" onClick={() => operate && operate('下架', record)}>下架</a> : null,
                (hasAuthCode(2333) && (record.state === 2)) ? <a key="up" onClick={() => operate && operate('上架', record)}>上架</a> : null,
                (hasAuthCode(2347) && (record.format === 1)) ? <a key="update" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
                hasAuthCode(2335) ? <a key="del" onClick={() => operate && operate('删除', record)}>删除</a> : null,
            ]
          },
      },
    ]
}

export const columnsFn3: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
    return [
      {
          title: '标题',
          dataIndex: 'title',
          fixed: 'left',
          order: 100,
          render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
      },
      {
          title: '相关标签',
          dataIndex: 'labelNameStr',
          order: 4,
          fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: labelTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
      },
      {
          title: '关联客户',
          dataIndex: 'relatedCustomer',
          order: 3,
      },
      {
            title: '文档类型',
            dataIndex: 'ownership',
            order: 2,
            valueType: 'select',
            fieldProps: {
                options: docTypeOptions
            }
      },
      {
          title: '发布人',
          dataIndex: 'publisher',
          order: 5,
          fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: deptWithUserTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
            const ownerNames = (ids||[]).map((v: string) => v.slice(1))
            return { ownerNames }
            },
        },
        render: (text: any, record: any) => record.publisher
      },
      {
          title: '发布时间',
          dataIndex: 'publishTime',
          valueType: 'dateRange',
          order: 1,
          render: (text: any, record: any) => record.publishTime
      },
      {
          title: '状态',
          dataIndex: 'state',
          order: 6,
          valueType: 'select',
          fieldProps: {
            options: docStateOptions
          }
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 160,
          fixed: 'right',
          render: (text, record: any) => {
            return [
                (hasAuthCode(2334) && (record.state === 1)) ? <a key="down" onClick={() => operate && operate('下架', record)}>下架</a> : null,
                (hasAuthCode(2333) && (record.state === 2)) ? <a key="up" onClick={() => operate && operate('上架', record)}>上架</a> : null,
                (hasAuthCode(2347) && (record.format === 1)) ? <a key="update" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
                hasAuthCode(2335) ? <a key="del" onClick={() => operate && operate('删除', record)}>删除</a> : null,
            ]
          },
      },
    ]
}
  