import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Button, Dropdown, Menu, Progress, Space, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { OperateActionType } from "../../data"
import { contractAuditStatus } from "@api/index"
import { StatusDot } from "@components/Finance/Contract/data"

const A = ({children, ...rest}: any) => <a {...rest}>{children}</a>

export const RebackMoneyListAuditOperate = ({record, type, operate}: any) => {
  const [, hasAuthCode] = useHasAuthCode()
  const Btn = type === 'primary' ? Button : A
  const style = { marginLeft: 10, whiteSpace: 'nowrap'} as any
  return ([
    (hasAuthCode(2477) && record?.status > 0) ? <Btn type={type} style={style} key="回款记录-审批通过" onClick={() => operate && operate('回款记录-审批通过', record)}>审批通过</Btn> : null,
    (hasAuthCode(2478) && record?.status > 0) ? <Btn type={type} style={style} key="回款记录-审批拒绝" onClick={() => operate && operate('回款记录-审批拒绝', record)}>审批拒绝</Btn> : null,
  ])
}


export const columnsFn: (operate: (type: OperateActionType, record?: any) => void) => ProColumns<any>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '系统编号',
        dataIndex: 'sysNumber',
        order: 15,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '合同编号' : '销售合同编号',
        dataIndex: 'xsContractNumber',
        fieldProps: { placeholder: '请输入销售合同编号'},
        order: 14,
        align: 'center',
        render: (text: any, record: any) => <Link to={`/finance/saleContractDetail?${stringify({contractId: record?.xsContractId, fromPath: '/finance/rebackMoneyAudit'})}`} >{record.xsContractNumber}</Link>
      },
      {
        title: (_, type) => type === 'form' ? '合同名称' : '销售合同名称',
        dataIndex: 'xsContractName',
        fieldProps: { placeholder: '请输入销售合同名称'},
        order: 13,
        align: 'center',
        search: {
          transform: (contractName) => {
            return { contractName }
          },
        },
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 12,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '合同金额' : '合同金额(元)',
        dataIndex: 'contractAmount',
        order: 10,
        align: 'center',
        sorter: true,
        search: {
          transform: (contractAmounts) => {
            return { contractAmounts }
          },
        },
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.contractAmount)
      },
      {
        title: (_, type) => type === 'form' ? '回款金额' : '回款金额(元)',
        dataIndex: 'backAmount',
        sorter: true,
        order: 9,
        align: 'center',
        search: {
          transform: (backedAmounts) => {
            return { backedAmounts }
          },
        },
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.backAmount)
      },
      {
        title: '回款日期',
        dataIndex: 'backTime',
        sorter: true,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        order: 6,
        align: 'center',
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {backDates: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.backTime||''}`
      },
      {
        title: '收款账号',
        dataIndex: 'payAccount',
        order: 11,
        valueType: 'select',
        align: 'center',
        params: { dictId: 270 },
        fieldProps: { mode: 'multiple' },
        request,
        search: {
          transform: (payAccounts) => {
            return { payAccounts }
          },
        },
        render: (text: any, record: any) => record.payAccountStr
      },
      {
        title: '备注',
        dataIndex: 'remark',
        // order: 8,
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => <div style={{width: 300, }}>{record.remark}</div>
      },
      {
        title: '审批状态',
        dataIndex: 'statusStr',
        order: 16,
        align: 'center',
        search: {
          transform: (status) => {
            return { status }
          },
        },
        request: async () => {
          const { data } = await contractAuditStatus({type: 2}) as any
          return data||[]
        },
        fieldProps: { options: [], mode: 'multiple' },
        valueType: 'select',
        render: (text: any, record: any) => <><StatusDot status={record.status} />{`${(record.statusStr||[]).toString()}`}</>
      },
      {
        title: '提交人',
        dataIndex: 'creator',
        order: 8,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const creators = (ids||[]).map((v: string) => v.slice(1))
            return { creators }
          },
        },
        render: (text: any, record: any) => `${(record.creatorStr||[]).toString()}`
      },
      {
        title: '提交时间',
        dataIndex: 'confircreateTimemTime',
        valueType: 'dateRange',
        sorter: true,
        fieldProps: { allowEmpty: [true, true]},
        order: 7,
        align: 'center',
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {createTimes: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.createTime||''}`
      },
      {
        title: '当前审批人',
        dataIndex: 'currentAuditPerson',
        order: 5,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const currentAuditPersons = (ids||[]).map((v: string) => v.slice(1))
            return { currentAuditPersons }
          },
        },
        render: (text: any, record: any) => `${(record.currentAuditPersonStr||[]).toString()}`
      },
      {
        title: '操作',
        key: 'option',
        dataIndex: 'option',
        valueType: 'option',
        width: 210,
        fixed: 'right',
        render: (text: any, record: any) => [
          // @ts-ignore
          <RebackMoneyListAuditOperate key="a" record={record} operate={operate} /> as any,
          (hasAuthCode(-1)) ? <a key="回款记录-详情" onClick={() => operate && operate('回款记录-详情', record)}>详情</a> : null,
        ]
      },
    ]
}