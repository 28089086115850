import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Dropdown, Menu, Progress, Space, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { mailOptions, mailStatus, paidOptions, statusOptions, statusValueEnum, typeOptions } from "../BillManage/data"

export const tabPanes = [
    {
        Tab: '全部',
        key: ' ',
        Content: null
    },
    {
        Tab: '待审批',
        key: 1,
        Content: null
    },
    {
        Tab: '开票中',
        key: 2,
        Content: null
    },
    {
        Tab: '已开票',
        key: 4,
        Content: null
    },
    {
        Tab: '已暂停',
        key: 6,
        Content: null
    }
]

export type TableListItem = {
    
}

export type OperateActionType = '审批通过' | '确认开票' | '撤销开票' | '作废发票' | '驳回申请' | '驳回原因' | '暂停开票' | '暂停原因' | '发票号码' | '邮寄信息' | '合同编号' | '撤销'

export const confirmContent: Record<string, any> = {
  '审批通过': `确定执行此操作，审批通过后数据状态将转为开票中。`,
  '确认开票': `确定执行此操作，确认开票后数据状态将转为已开票`,
  '撤销开票': `确定执行此操作，撤销开票后数据状态将转为开票中`,
  '撤销': `确定执行此操作，撤销后数据状态将返回至上一级状态`,
  '作废发票': `确定执行此操作，发票作废后数据状态将转为已作废。`
}

export const operateMap: Record<any, any> = { '审批通过': 1, '确认开票': 2, '驳回申请': 3, '暂停开票': 4, '撤销开票': 5, '作废发票': 6, '撤销': 8}

export const columnsFn: (operate: Function, activeKey: any) => ProColumns<TableListItem>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '合同编号',
        dataIndex: 'contractNumber',
        fixed: 'left',
        order: 100,
        align: 'center',
      },
      {
        title: '合同名称',
        dataIndex: 'contractName',
        fixed: 'left',
        order: 99,
        align: 'center',
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 98,
        align: 'center',
      },
      {
        title: '企业名称',
        dataIndex: 'companyName',
        order: 10,
        align: 'center',
      },
      {
        title: '发票号码',
        dataIndex: 'invoiceNumber',
        order: 8,
        align: 'center',
      },
      {
        title: '开票金额',
        dataIndex: 'amount',
        order: 1,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.amount)
      },
      {
        title: '申请状态',
        dataIndex: 'status',
        valueType: 'select',
        hideInSearchImportant: !([' '].includes(activeKey)),
        // fieldProps: { options: statusOptions },
        valueEnum: statusValueEnum,
        order: 9,
        align: 'center',
      },
      {
        title: '业务类型',
        dataIndex: 'businessTypeStr',
        order: 6,
        valueType: 'select',
        align: 'center',
        params: { dictId: 263 },
        request,
        search: {
          transform: (businessType) => {
            return { businessType }
          },
        },
        render: (text: any, record: any) => record.businessTypeStr
      },
      {
        title: '开票类型',
        dataIndex: 'type',
        order: 7,
        valueType: 'select',
        fieldProps: { options: typeOptions },
        align: 'center',
      },
      {
        title: '开票内容',
        dataIndex: 'invoiceContent',
        hideInSearch: true,
        align: 'center',
      },
      {
        title: '是否付款',
        dataIndex: 'paid',
        order: 5,
        valueType: 'select',
        fieldProps: { options: paidOptions },
        align: 'center',
      },
      {
        title: '回款时间',
        dataIndex: 'returnTime',
        hideInSearch: true,
        align: 'center',
      },
      // {
      //   title: '邮寄状态',
      //   dataIndex: 'mailStatus',
      //   order: 2,
      //   valueType: 'select',
      //   fieldProps: { options: mailStatus },
      //   align: 'center',
      // },
      {
        title: '提交人',
        dataIndex: 'user',
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userId = (ids||[]).map((v: string) => v.slice(1))
            return { userId }
          },
        },
        render: (text: any, record: any) => `${(record.user||[]).toString()}`
      },

      {
        title: '审批人',
        dataIndex: 'reviewer',
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const reviewerId = (ids||[]).map((v: string) => v.slice(1))
            return { reviewerId }
          },
        },
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.reviewer||[]).toString()}`}</Ellipsis.Nowrap>
      },

      {
        title: '开票人',
        dataIndex: 'confirmMan',
        hideInSearch: true,
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const confirmManId = (ids||[]).map((v: string) => v.slice(1))
            return { confirmManId }
          },
        },
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.confirmMan||[]).toString()}`}</Ellipsis.Nowrap>
      },

      {
        title: '提交时间',
        dataIndex: 'updateTime',
        valueType: 'dateRange',
        hideInSearch: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.updateTime||''}`
      },
      {
        title: '开票时间',
        dataIndex: 'confirmTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {confirmTimeRange: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.confirmTime||''}`
      },
      {
        title: '审批时间',
        dataIndex: 'reviewTime',
        valueType: 'dateRange',
        hideInSearch: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.reviewTime||''}`
      },
      {
        title: '开票比例',
        dataIndex: 'percent',
        hideInSearch: true,
        render: (text: any, record: any) => `${record.percent}%`
      },
      {
        title: '企业税号',
        dataIndex: 'companyTaxNumber',
        hideInSearch: true,
      },
      {
        title: '开户银行',
        dataIndex: 'bank',
        hideInSearch: true,
      },
      {
        title: '银行账号',
        dataIndex: 'bankAccount',
        hideInSearch: true,
      },
      {
        title: '税票电话',
        dataIndex: 'taxPhone',
        hideInSearch: true,
      },
      {
        title: '税票地址',
        dataIndex: 'taxAddress',
        hideInSearch: true,
      },
      {
        title: '电子邮箱',
        dataIndex: 'email',
        hideInSearch: true,
      },
      // {
      //   title: '是否邮寄',
      //   dataIndex: 'mailed',
      //   hideInSearch: true,
      //   valueType: 'select',
      //   fieldProps: { options: mailOptions }
      // },
      // {
      //   title: '收货信息备注',
      //   dataIndex: 'mailRemark',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件人姓名',
      //   dataIndex: 'receiverName',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件人手机号码',
      //   dataIndex: 'mailPhone',
      //   hideInSearch: true,
      // },
      // {
      //   title: '收件地址',
      //   dataIndex: 'receiverAddress',
      //   hideInSearch: true,
      // },
      {
        title: '发票信息备注',
        dataIndex: 'remark',
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.remark||'')}`}</Ellipsis.Nowrap>
      },
     {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        valueType: 'option',
        // width: 120,
        fixed: 'right',
        render: (text: any, record: any) => [
          (hasAuthCode(2404) && [1].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="审批通过" onClick={() => operate && operate('审批通过', record)}>审批通过</a> : null,
          (hasAuthCode(2405) && [1].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="驳回申请" onClick={() => operate && operate('驳回申请', record)}>驳回申请</a> : null,
          (hasAuthCode(2406) && [2, 6].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="确认开票" onClick={() => operate && operate('确认开票', record)}>确认开票</a> : null,
          (hasAuthCode(2407) && [2].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="暂停开票" onClick={() => operate && operate('暂停开票', record)}>暂停开票</a> : null,
          (hasAuthCode(-1) && [3].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="驳回原因" onClick={() => operate && operate('驳回原因', record)}>驳回原因</a> : null,
          (hasAuthCode(2409) && [4].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="撤销开票" onClick={() => operate && operate('撤销开票', record)}>撤销开票</a> : null,
          (hasAuthCode(2409) && [3].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="撤销" onClick={() => operate && operate('撤销', record)}>撤销</a> : null,
          (hasAuthCode(2408) && [4].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="作废发票" onClick={() => operate && operate('作废发票', record)}>作废发票</a> : null,
          (hasAuthCode(-1) && [6].includes(record.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="暂停原因" onClick={() => operate && operate('暂停原因', record)}>暂停原因</a> : null,
          ([4, 2, 1].includes(record.status)) ? <Dropdown key="更多" overlay={<Menu items={([
              {
                label: <a style={{'whiteSpace': 'nowrap'}} key="发票号码" onClick={() => operate && operate('发票号码', record)}>发票号码</a>,
                key: '0',
                auth: hasAuthCode(2400) && [4].includes(record.status)
              },
              // {
              //   label: <a style={{'whiteSpace': 'nowrap'}} key="邮寄信息" onClick={() => operate && operate('邮寄信息', record)}>邮寄信息</a>,
              //   key: '1',
              //   auth: hasAuthCode(2401) && [4].includes(record.status)
              // },
              { // 亚茹说 这个功能现在需要 2023.10.10 11:25
                label: <a style={{'whiteSpace': 'nowrap'}} key="合同编号" onClick={() => operate && operate('合同编号', record)}>合同编号</a>,
                key: '2',
                auth: hasAuthCode(-1) && [1].includes(record.status)
              },
              {
                label: <a style={{'whiteSpace': 'nowrap'}} key="更改开票信息" onClick={() => operate && operate('更改开票信息', record)}>更改开票信息</a>,
                key: '3',
                auth: hasAuthCode(-1) && [2, 4].includes(record.status)
              },
            ].filter(({auth}) => auth) as any[])}
          >
          </Menu>}>
            <a style={{'whiteSpace': 'nowrap'}} onClick={e => e.preventDefault()}><Space>更多</Space></a>
          </Dropdown> : null,
          (hasAuthCode(-1) ) ? <a style={{'whiteSpace': 'nowrap'}} key="详情" onClick={() => operate && operate('详情', record)}>详情</a> : null,
        ],
      },
    ]
}