import React, { useEffect, useRef, useState } from "react"
import { ProForm, ModalForm, ProFormText, ProFormDatePicker, ProFormUploadButton, ProFormDependency, ProFormMoney, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { AutoComplete, Button, Form, message, Tooltip, Image } from "antd";
import { acceptImg, emailReg, getToken, handleUploadFiles, isImageFile, parseSearch, phoneReg, productApi, validatorTel } from "@utils/util";
import { BASEURL, contractAdd, contractGetNumber, contractInfoById, customerListByName, getContractByCustomerId } from "@api/index";
import { debounce } from "lodash";
import { FileItemRender } from "@components/Customer/Business/cpnts/AddBusiness";
import { PreviewProps } from "@components/Common/Preview";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { signOptions, textOptions } from "@components/Finance/Contract/data";
import { useDictEnumHooks } from "@hooks/index";
import moment from "moment";

const customerListByNameApi = productApi(customerListByName)
const contractAddApi = productApi(contractAdd)

type AddPurchaseContractFormContentProps = {
    customerName?: string;
}

export const AddPurchaseContractFormContent = ({customerName}: AddPurchaseContractFormContentProps) => {
    const [customerOptions, setCustomerOptions] = useState([])
    const [, , request] = useDictEnumHooks({}, false)
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }
    useEffect(() => {
        customerName && onSearchCustomer(customerName)
    }, [customerName])
    return (<>
        <ProFormSelect 
            width="md" 
            name="customerId" 
            label="客户名称" 
            options={customerOptions} 
            fieldProps={{
                showSearch: true, 
                filterOption: false, 
                onSearch: debounce(onSearchCustomer, 600)
            }} 
            rules={[
                { required: true, message: '请选择关联客户',}
            ]}
            placeholder="请输入客户名称查询" 
        />
        <ProFormDependency name={['customerId']}>
            {({customerId}) => {
                return (<ProFormSelect 
                    key={customerId}
                    width="md" 
                    name="xsContract" 
                    label="销售合同编号" 
                    fieldProps={{labelInValue: true}}
                    request={async () => {
                        const { data } = await getContractByCustomerId({customerId}) as any
                        return (data||[]).map((v: any) => ({label: v.xsContractNumber, value: v.xsContractId}))
                    }}
                    rules={[
                        { required: true, message: '请选择销售合同编号',}
                    ]} 
                    placeholder="请选择"
                />)
            }}
        </ProFormDependency>
        <ProFormText
            width="md" 
            name="cgContractNumber" 
            fieldProps={{maxLength: 50}} 
            label="采购合同编号" 
            placeholder="请填写" 
            disabled
            rules={[
                { required: true, message: '请填写采购合同编号',}
            ]}
        />
        <ProFormText 
            width="md" 
            name="cgContractName" 
            fieldProps={{maxLength: 50}} 
            label="采购合同名称" 
            rules={[
                { required: true, message: '请填写采购合同名称',}
            ]} 
            placeholder="请填写" 
        />
        <ProFormSelect 
            width="md" 
            name="businessType" 
            label="业务类型" 
            params={{dictId: 269}}
            request={request} 
            // mode="multiple" 
            rules={[
                { required: true, message: '请选择关注业务线',}
            ]} 
            placeholder="请选择"
        />
        <ProFormRadio.Group 
            width="md" 
            name="haveText" 
            label="有无文本合同" 
            options={textOptions} 
            initialValue={1}
            rules={[
                { required: true, message: '请选择有无文本合同'}
            ]} 
        />
        <ProFormMoney 
            width="md" 
            name="contractAmount" 
            fieldProps={{ 
                formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                defaultValue: '',
                addonAfter: '元'
            }} 
            max={999999999} 
            min={0} 
            label="合同金额" 
            rules={[
                { required: true, message: '请输入合同金额',}
            ]} 
            placeholder="请输入" 
        />
        <ProFormText 
            width="md" 
            name="supplier" 
            label="供应商" 
            // fieldProps={{ addonAfter: '%' }} 
            rules={[
                { required: true, message: '请填写供应商',}
            ]} 
            placeholder="请填写" 
        />
        <ProFormDatePicker 
            width="md" 
            name="signDate" 
            label="签约日期" 
            initialValue={moment()}
            rules={[
                { required: true, message: '请选择签约日期',}
            ]} 
            placeholder="请选择" 
        />
        <ProFormDependency name={['haveText']}>
            {({haveText}) => {
                return (<ProFormUploadButton 
                    width="md" 
                    name="files" 
                    label="合同附件" 
                    fieldProps={{ 
                        multiple: true,
                        name: 'file', 
                        listType: 'text', 
                        itemRender: (...props) => FileItemRender(...props, onPreview), 
                        headers: {Authorization: getToken()}
                    }} 
                    rules={[
                        { required: haveText === 1, message: '请选择上传合同附件'}
                    ]}
                    action={`${BASEURL}/upload`} 
                    extra=""
                />)
            }}
        </ProFormDependency>
        <ProFormRadio.Group 
            width="md" 
            name="whetherStamp" 
            label="合同是否盖章" 
            options={signOptions} 
            initialValue={1}
            rules={[
                { required: true, message: '请选择合同是否盖章'}
            ]} 
        />
        <ProFormTextArea 
            width="md" 
            name="remark" 
            label="备注" 
            fieldProps={{maxLength: 300, showCount: true}} 
            rules={[
                {required: false, message: '请填写备注'}, 
                {max: 300, type:'string', message: '300字以内'}
            ]} 
        />
        <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
    </>)
}

export type AddPurchaseContractModalFormProps = {
    params?: { 
        contractId?: any; // 编辑时候的合同 id
        originId?: any;  // 一键申请的 id 
    };
    onSuccess?: () => void;
} & ModalFormProps

const AddPurchaseContractModalForm = ({params ,onSuccess, ...rest}: AddPurchaseContractModalFormProps) => {
    const formRef = useRef<ProFormInstance>()
    const [customerName, setCustomerName] = useState('')
    const contractId = params?.contractId || params?.originId
    const request = async (params: AddPurchaseContractModalFormProps['params']) => {
        if (contractId) {
            const { data } = await contractInfoById({contractId}) as any
            const {
                customerName,
                customerId,
                xsContractId,
                xsContractNumber,
                // cgContractNumber,
                cgContractName,
                businessType,
                haveText,
                contractAmount,
                supplier,
                signDate,
                files,
                whetherStamp,
                remark
            } = data || {}
            let cgContractNumber = data?.cgContractNumber
            if (params?.originId) {
                const { data } = await contractGetNumber({type: 4, xsContractId}) as any
                cgContractNumber = `${data}`
            }
            setCustomerName(customerName)
            return {
                customerId,
                xsContract: xsContractId ? {
                    label: xsContractNumber,
                    value: xsContractId
                } : undefined,
                cgContractNumber,
                cgContractName,
                businessType,
                haveText,
                contractAmount,
                supplier,
                signDate,
                files,
                whetherStamp,
                remark
            }
        } else {
            return {}
        }
    }

    const onValuesChange = async (values: any, allValues: any) => {
        if ('customerId' in values) {
            formRef.current?.setFieldsValue({xsContract: undefined, cgContractNumber: undefined})
        }
        // if ('xsContract' in values && !params?.contractId) {  
        //     const { data } = await contractGetNumber({type: 4, xsContractId: values?.xsContract?.value}) as any
        //     formRef.current?.setFieldsValue({cgContractNumber: `${data}`})
        // }
        if ('xsContract' in values) { // 亚茹说 编辑的时候也要跟着联动 2023.10.10 10:18
            const { data } = await contractGetNumber({type: 4, xsContractId: values?.xsContract?.value}) as any
            formRef.current?.setFieldsValue({cgContractNumber: `${data}`})
        }
    }

    const onFinish = async ({files: filesArr, xsContract, ...rest}: any) => {
        const files = handleUploadFiles(filesArr||[])
        const contractId = params?.contractId
        const { label: xsContractNumber, value: xsContractId } = xsContract
        const { success } = await contractAddApi({...rest, xsContractId, xsContractNumber, files, contractId, contractType: 2}, true, onSuccess)
        return success
    }

    return (<ModalForm<any> 
        modalProps={{maskClosable: false}} 
        layout="horizontal" 
        title={'新建采购合同'}
        width={700} 
        labelCol={{span: 5}} 
        onFinish={onFinish}
        params={{...params}}
        request={ request }
        onValuesChange={onValuesChange}
        formRef={formRef}
        {...rest}
        >
            <AddPurchaseContractFormContent customerName={customerName} />
        </ModalForm>)
}

export default AddPurchaseContractModalForm