import { useReducer } from 'react'
import { ACTIVE, CREATE, CREATED, DESTROY, RESET } from './contact'

const useKeeper = () => useReducer((state: any = {}, action: any) => {
    const { type, payload } = action
    const { cacheId, reactElement, doms } = payload || {}
    switch(type) {
       case CREATE: // 创建时期
            return {
                ...state,
                [cacheId]: {
                    scrolls: {},
                    cacheId,
                    reactElement,
                    status: CREATE,
                }
            }
        case CREATED: // 创建完成
            return {
                ...state,
                [cacheId]: {
                    ...state[cacheId],
                    doms, // 虚拟dom 对应的真实dom
                    status: CREATED
                }
            }
        case ACTIVE: // 激活状态
            return {
                ...state,
                [cacheId]: {
                    ...state[cacheId],
                    status: ACTIVE
                }
            }
        case DESTROY: // 销毁状态
            return {
                ...state,
                [cacheId]: {
                    ...state[cacheId],
                    status: DESTROY
                }
            }
        case RESET: // 重置
            const newState = {} as any
            const cacheIds = Array.isArray(payload) ? payload : typeof payload === 'string' ? [payload] : Object.keys(state)
            Object.keys(state).map(key => {
                const statusObj = cacheIds.includes(key) ? { status: DESTROY } : {}
                newState[key] = {...state[key], ...statusObj}
            })
            return newState
        default: 
            return state;
    }
}, {})
export default useKeeper