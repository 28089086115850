import React from "react";
import { TreeSelect, Button, Input, Row, Col, Cascader } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useHasAuthCode, dimensionList } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { Double } from "@components/Common";
export type TableListItem = {
    key: number;
    id: string;
    mainName: string;
    sonName: string;
};
export type OperateActionType = '新建规则' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData } = useSelector((state: StateType) => state)
  const [, hasAuthCode] = useHasAuthCode()
  const [data, ,] = dimensionList()
  return [
    {
        title: '主维度',
        dataIndex: 'mainDimension',
        fixed: 'left',
        order: 100,
        render: (value, record) => record.mainName,
        fieldProps: {
            showSearch: true,
            options: data
        },
        renderFormItem: (props) => <Cascader />,
    },
    {
        title: '子维度',
        dataIndex: 'sonDimension',
        render: (value, record) => record.sonName,
        hideInSearch: true,
    },
    {
        title: '返点上限',
        dataIndex: 'maxRebatePoint',
        order: 2,
        search: {
          transform: (estimatedSalesAmounts) => {
            return { estimatedSalesAmounts};
          },
        },
        renderFormItem: (props) => <Double props1={{ value: 234}} props2={{}} />
    },
    {
        title: '状态',
        dataIndex: 'status',
        order: 1,
        valueEnum: {
            0: { text: '禁用', status: 'Default' },
            1: { text: '启用', status: 'Processing' },
        },
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        hideInSearch: true,
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text, record: any) => {
          return [
            hasAuthCode(2281) ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑', record)}>编辑</Button>: null
          ]
        },
    },
  ]
}
