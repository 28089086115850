import React, {ReactNode, useMemo} from "react";
import { Tag, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Icon, Ellipsis, Double } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { stringify } from 'qs'
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { yesNoOptions } from "@utils/enum";
import { amountFormat } from "@utils/index";

// ts--types
export type TableListItem = {
    key: number;
    id: number;
    lqId: number;
    isAttention: number;
    hasBusiness: number;
    attention: number | string;
    customerName: string;
    ownerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
    isSyn: string;
    customerLabel: string;
    customerId: any;
} | Record<string, any>;

export type OperateActionType = '新建客户' | '添加协同人' | '转移客户' | '移入线索池' | '批量导入' | '批量导出' | '批量删除' | '关注' | '添加跟进' | '退回普通客户' | '转回个人线索'

export const customerViewOptions = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '我的客户',
    value: '2'
  },
  {
    label: '下属客户',
    value: '3'
  }
]

export const customerScopeOptions = [
  {
    label: '全部',
    value: '1'
  },
  {
    label: '已成交',
    value: '2'
  },
  // {
  //   label: '未成交',
  //   value: '3'
  // },
  // {
  //   label: '30天未跟进',
  //   value: '4'
  // },
  {
    label: '我协作的',
    value: '6'
  },
  {
    label: '重点客户',
    value: '5'
  },
]

export const followUpTypeOptions = [
  {
    label: '商机',
    value: 2
  },
  {
    label: '客户',
    value: 1
  },
]

export const hasBusinessOptions = [
  {
    label: '有商机',
    value: 1
  },
  {
    label: '无商机',
    value: 0
  },
]

type CustomerNameLinkRenderProps = {
  text?: ReactNode;
  hasAuth?: boolean;
  search?: Record<string, any>;
}
export const CustomerNameLinkRender = ({ text, hasAuth = true, search }: CustomerNameLinkRenderProps) => {
  const history = useHistory()
  const onClick = (e: React.MouseEvent) => {
    const url = `/customer/detail?${stringify(search||{})}`
    if (hasAuth) {
      e.ctrlKey ? window.open(url, '_blank') : history.push(url)
    }
  }
  return <a onClick={onClick} onMouseDown={onClick}><Ellipsis text={text as any || '-'} /></a>
}

export const columnsFn: (operate: Function, dynamicFields: Record<string, any>) => ProColumns<TableListItem>[] = (operate, dynamicFields) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({}, false)
  const [, hasAuthCode] = useHasAuthCode()
  const dynamicFieldsColumns = (dynamicFields: any) => {
    return Object.keys(dynamicFields||{}).map((key, i) => ({
      title: key,
      dataIndex: dynamicFields[key],
      key: dynamicFields[key],
      sorter: true,
      order: 8,
      width: 110,
      hideInSearch: true,
      render: (text: any, record: any) => amountFormat((record.consumerInfo||{})[key])
    }))
  }
  return [
    {
      title: (_, type) => type === 'form' ? '关注客户' : '关注',
      dataIndex: 'isAttention',
      key: 'isAttention',
      valueType: 'select',
      fieldProps: {
        options: [{label: '已关注', value: 1,}, {label: '未关注', value: 0}]
      },
      // hideInSearch: true,
      fixed: 'left',
      width: 50,
      align: 'center',
      render: (text: any, record: any) => <Icon type={record?.isAttention ? 'icon-startFill' : 'icon-startEmpty'} style={{cursor: 'pointer'}} onClick={() => operate && operate('关注', record)} />,
    },
    
    {
      title: '客户名称',
      dataIndex: 'customerName',
      fixed: 'left',
      width: 180,
      order: 100,
      render: (text: any, {id, lqId, customerName: name, ownerName}: TableListItem) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, ownerName, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
    },
    {
      title: '所属行业',
      dataIndex: 'labels',
      order: 2,
      width: 150,
      disable: { checkbox: false },
      fieldProps: {
        maxTagCount: 4,
        treeCheckable: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeData: labelTreeData
      },
      renderFormItem: (props) => <TreeSelect />,
      render: (text: any, record: TableListItem) => <Ellipsis text={record.customerLabel||'-'} />
    },
    {
      title: '重点客户',
      dataIndex: 'isEmphasisCustomer',
      order: 104,
      width: 80,
      valueType: 'select',
      fieldProps: { options: yesNoOptions },
      render: (text: any, record: any) => record.isEmphasisCustomer ? '是' : '否'
    },
    {
      title: '项目经理',
      dataIndex: 'projectManager',
      order: 103,
      width: 80,
      fieldProps: {
        maxTagCount: 4,
        treeCheckable: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeData: deptWithUserTreeData
      },
      renderFormItem: (props) => <TreeSelect />,
      search: {
        transform: (ids) => {
          const projectManager = (ids||[]).map((v: string) => v.slice(1))
          return { projectManager }
        },
      },
      render: (text: any, record: any) => record?.projectManager||'无'
    },
    {
      title: '客户类型',
      dataIndex: 'customerType',
      order: 0,
      width: 120,
      valueType: 'select',
      params: {dictId: 275 },
      fieldProps: { mode: 'multiple' },
      request,
    },
    {
      title: '账号来源',
      dataIndex: 'accountSource',
      order: -1,
      width: 120,
      valueType: 'select',
      params: {dictId: 274 },
      fieldProps: { mode: 'multiple' },
      request,
    },
    {
      title: '预计年Cash收入',
      dataIndex: 'estimatedAnnualCashIncome',
      sorter: true,
      order: -2,
      width: 136,
      fieldProps: {precision: 0},
      renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
      render: (text, record: any) => typeof record.estimatedAnnualCashIncome === 'number' ? amountFormat(record.estimatedAnnualCashIncome) : '-'
    },

    {
      title: '客户状态',
      dataIndex: 'state',
      order: 6,
      width: 100,
      params: {dictId: 13 },
      request,
      valueType: 'select',
    },
    {
      title: '归属人',
      dataIndex: 'ownerName',
      order: 8,
      width: 80,
      fieldProps: {
        maxTagCount: 4,
        treeCheckable: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeData: deptWithUserTreeData
      },
      renderFormItem: (props) => <TreeSelect />,
      search: {
        transform: (ids) => {
          const ownerNames = (ids||[]).map((v: string) => v.slice(1))
          return { ownerNames }
        },
      },
    },
    {
        title: '未跟进天数',
        dataIndex: 'daysSinceFollowup',
        valueType: 'digit',
        // sorter: true,
        order: 1,
        width: 90,
        fieldProps: {precision: 0},
        renderFormItem: (props) => <Double props1={{ }} props2={{}} />,
        render: (text, record: any) => typeof record.daysSinceFollowup === 'number' ? Number(record.daysSinceFollowup).toFixed(0) : '-'
        // hideInSearch: true,
    },
    {
        title: '最后跟进',
        dataIndex: 'followupTime',
        valueType: 'dateRange',
        sorter: true,
        width: 120,
        fieldProps: { allowEmpty: [true, true]},
        // hideInSearch: true,
        render: (text, record: any) => `${record.followupTime||''}`
    },
    {
      title: '创建时间',
      dataIndex: 'inputTime',
      order: 1,
      width: 120,
      valueType: 'dateRange',
      sorter: true,
      fieldProps: { allowEmpty: [true, true]},
      search: {
        transform: (value) => {
          return {
            startTime: value[0],
            endTime: value[1],
          };
        },
      },
      render: (text: any, record: any) => `${record.inputTime||''}`
    },

    
    // {
    //   title: '客户等级',
    //   dataIndex: 'customerLevel',
    //   order: 5,
    //   hideInSearch: true,
    //   params: {dictId: 14},
    //   width: 120,
    //   request,
    //   render: (text: any, record: any) => `${record.customerLevelStr||''}`
    // },
    {
      title: '客户来源',
      dataIndex: 'customerSource',
      order: 4,
      width: 120,
      params: {dictId: 15 },
      request,
      render: (text: any, record: any) => `${record.customerSourceStr||''}`
    },
    {
      title: '关注业务线',
      dataIndex: 'businessLine',
      valueType: 'select',
      fieldProps: { mode: 'multiple' },
      order: 4,
      width: 120,
      params: {dictId: 19 },
      request,
      // hideInTable: true,
      render: (text: any, record: any) => `${record.businessLines||''}`
    },
    {
      title: '客户属性',
      dataIndex: 'customerPropertyStr',
      order: 4,
      width: 120,
      params: {dictId: 16 },
      request,
      render: (text: any, record: any) => `${record.customerPropertyStr||''}`
    },
    {
      title: '客户账号',
      dataIndex: 'accountNames',
      order: 1,
      width: 120,
      render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.accountNames||[]).toString()}`}</Ellipsis.Nowrap> 
    },
    {
      title: '合作模式',
      dataIndex: 'accountModes',
      order: 1,
      width: 120,
      valueType: 'select',
      params: {dictId: 25 },
      request,
    },
    
    {
      title: '所属地区',
      dataIndex: 'regionId',
      order: 3,
      width: 150,
      fieldProps: {
        maxTagCount: 4,
        treeCheckable: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeData: cityTreeData
      },
      renderFormItem: (props) => <TreeSelect />,
      render: (text: any, record: any) => `${record.location||''}`
    },
    
    {
      title: '跟进记录',
      dataIndex: 'followUpContent',
      width: 240,
      // hideInSearch: true,
      render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.followUpContent||'').toString()}`}</Ellipsis.Nowrap>
    },
    
    ...dynamicFieldsColumns(dynamicFields),
    {
      title: '协同人',
      dataIndex: 'synids',
      order: 7,
      width: 120,
      fieldProps: {
        maxTagCount: 4,
        treeCheckable: true,
        showSearch: true,
        treeNodeFilterProp: 'title',
        treeData: deptWithUserTreeData
      },
      renderFormItem: (props) => <TreeSelect />,
      search: {
        transform: (ids) => {
          const synids = (ids||[]).map((v: string) => v.slice(1))
          return { synids }
        },
      },
      render: (text: any, record: any) => record?.syns||'无'
    },
    
    
    // {
    //   title: '下次跟进',
    //   dataIndex: 'nextFollowupTime',
    //   valueType: 'dateRange',
    //   sorter: true,
    //   fieldProps: { allowEmpty: [true, true]},
    //   // hideInSearch: true,
    //   render: (text: any, record: any) => `${record.nextFollowupTime||''}`
    // },
    // {
    //   title: '未跟进天数',
    //   dataIndex: 'daysSinceFollowup',
    //   valueType: 'digit',
    //   // hideInSearch: true,
    //   // render: (text: string, record: any) => `${record.daysSinceFollowup||''}`
    // },
    
    { 
      title: '实名认证',
      dataIndex: 'verifiedNames',
      key: 'verifiedNames',
      width: 120,
      // render: (text: string, record: any) => `${record.verifiedNames||''}`
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      width: 180,
      fixed: 'right',
      render: (text: any, record) => [
        <a key="跟进" onClick={() => operate && operate('添加跟进', record)}>跟进</a>,
        hasAuthCode(2034) ? <Link key="update" to={`/customer/add?${stringify({customerId: record.id, sourceType: 0, routeName: '编辑客户', routeTitName: record.customerName, fromPath: '/customer/list'})}`}>编辑</Link> : null,
        hasAuthCode(2034) ? <Link key="createBuiness" to={`/customer/businessAdd?${stringify({customerName: record.customerName, customerId: record.id, routeName: '创建商机', fromPath: '/customer/list'})}`}>创建商机</Link> : null,
        // hasAuthCode(2034) && [0].includes(record.isSyn) ? <Link key="update" to={`/customer/add?${stringify({customerId: record.id, sourceType: 0, routeName: '编辑客户', routeTitName: record.customerName, fromPath: '/customer/list'})}`}>编辑</Link> : null,
        // hasAuthCode(2034) && [0].includes(record.isSyn) ? <Link key="createBuiness" to={`/customer/businessAdd?${stringify({customerName: record.customerName, customerId: record.id, routeName: '创建商机', fromPath: '/customer/list'})}`}>创建商机</Link> : null,
      ],
    },
  ]
}

export const helperListColumnsFn: (operate?: Function) => any[] = (operate) => [
  {
    title: '客户名称',
    dataIndex: 'customerName',
  },
  {
    title: '已添加协同人',
    key: 'info',
    fixed: 'right',
    render: (text: string, record: any) => {
      return (record.info||[]).map((v: any) => 
      <Tag key={v.userId} closable onClose={() => operate && operate('删除协同人', {...v, customerId: record.customerId})}>
        {v.userName}
        {/* ({(v.roles||[]).map((r: any) => r.roleName).join('/')}) */}
      </Tag>)
    },
  },
] 

