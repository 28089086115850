import { ProColumns } from "@ant-design/pro-table";
import React from "react";
import { yesNoOptions } from "@utils/enum";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { TreeSelect } from "antd";
import { treeDataDisabledRules } from "@utils/index";
import { AuthActionArr } from "@components/EnterpriseDetail/type";

export const columns: (operate: any, data: any, authCode?: AuthActionArr) => ProColumns<any>[] = (operate, data, authCode) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    return [
    {
        title: '主键',
        dataIndex: 'id',
        width: 90,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true
    },
    {
        title: '客户id',
        dataIndex: 'customerId',
        width: 90,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true
    },
    {
        title: '云相关业务',
        dataIndex: 'cloudBusiness',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '消费体量',
        dataIndex: 'consumerVolume',
        width: 90,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '合作的原厂/服务商',
        dataIndex: 'cooperator',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: true
    },
    {
        title: '是否与服务商有高层关系',
        dataIndex: 'highLevelRelationship',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: true
    },



    {
        title: '有无商务折扣',
        dataIndex: 'discount',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '是否补贴',
        dataIndex: 'subsidy',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '合作时间',
        dataIndex: 'cooperationTime',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false,
        // sorter: true
    },
    {
        title: '备注',
        dataIndex: 'remark',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '创建者id',
        dataIndex: 'createId',
        width: 90,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true
    },
    {
        title: '更新时间',
        dataIndex: 'updateTime',
        width: 170,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true,
        sorter: true
    },
    {
        title: '创建人',
        dataIndex: 'createId',
        valueType: 'select',
        width: 120,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: true,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const createIds = (ids||[]).map((v: string) => v.slice(1))
            return { createIds }
          },
        },
        render: (text: any, record: any) => record.createIdName,
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        width: 170,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false,
        sorter: true
    },
    {
        title: '操作',
        dataIndex: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        hideInTable: !authCode?.length,
        render: (_text: any, record: any) => [
            authCode?.includes('edit') ? <a key='编辑' onClick={() => operate?.('编辑', record)}>编辑</a> : null,
            authCode?.includes('del') ? <a key='删除' onClick={() => operate?.('删除', record)}>删除</a> : null
        ]
    },
]
}