import React, { useEffect, useRef, useState } from "react"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { columnsFn, tabPanes, TableListItem, OperateActionType } from "./data"
import { Alert, Tabs } from "antd"
import { useHistory } from "react-router-dom"
import { MyTabs } from "@components/EnterpriseDetail"
import { ModalForm, ProFormRadio, ProFormTextArea, ProFormDependency } from '@ant-design/pro-form' 
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { productApi } from "@utils/index"
import { knowledgeDocAuditList, knowledgeDocAudit } from "@api/knowledge"

const { TabPane } = Tabs
const getApi = productApi(knowledgeDocAuditList)
const docAuditApi = productApi(knowledgeDocAudit)

interface DocAuditProps extends ModalFormProps{
    onSuccess?: () => void;
    disabled?: boolean;
    params?: Record<string, any>;
    values?: Record<string, any>;
}
export const DocAudit = ({params, values, disabled, onSuccess, ...rest}: DocAuditProps) => {
    const formRef = useRef<ProFormInstance>()
    const onFinish = async (values: any) => {
        if (!disabled) {
            const { success } = await docAuditApi({...values, ...params}, false, onSuccess)
            return success
        } else {
            onSuccess && onSuccess()
        }
    }
    const onVisibleChange = (visible: boolean) => {
        if(visible) {
            formRef.current?.setFieldsValue(values||{})
        } else {
            formRef.current?.resetFields()
        }
    }
    return (<ModalForm<DocAuditProps> 
            params={params} 
            layout="horizontal" 
            width={600} 
            labelCol={{span: 5}} 
            onFinish={onFinish}  
            formRef={formRef} 
            onVisibleChange={onVisibleChange}
            {...rest}>
            {disabled ? null : <Alert message="审核通过后，文章将被发布到系统上" type="warning" showIcon />}
            <ProFormRadio.Group label="审核结果" disabled={disabled} name="status" fieldProps={{options: [{label: '通过', value: 1}, {label: '不通过', value: 2}]}} rules={[{required: true, message: '请选择结果'}]} />
            <ProFormDependency name={['status']}>
                {({status}) => {
                   return status === 2 ? <ProFormTextArea disabled={disabled} label="审核备注" name="mark" fieldProps={{showCount: true, maxLength: 300}} rules={[{ required: true, message: '请填写审核备注',}]} /> : null
                }}
            </ProFormDependency>
    </ModalForm>)
}


export default () => {
    const history = useHistory()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [docAuditProps, setDocAuditProps] = useState<DocAuditProps>({visible: false, title: '审核', params: {}})
    const [activeKey, setActiveKey] = useState<string>('0')

    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = async (type: OperateActionType, record: any) => {
        const { id, status, mark } = record || {} 
        const initialValues = { status: type === '审核详情' ? 2 : 1, mark }
        if (['审核', '审核详情'].includes(type)) {
            setDocAuditProps({
                ...docAuditProps,
                visible: true,
                title: type,
                params: { id },
                disabled: type === '审核详情',
                values: initialValues,
                onSuccess: () => {
                    setDocAuditProps({...docAuditProps, visible: false})
                    type === '审核详情' ? null : ref.current?.reload()
                }
            })
        }
    }

    // useEffect(() => {
    //     ref.current?.reload()
    // }, [activeKey])
    
    return (<div>
                <MyTabs tabPanes={tabPanes} activeKey={activeKey} onChange={setActiveKey} tabBarStyle={{marginLeft: 10}} style={{background: '#fff'}} />
                <ProTable<TableListItem>
                    // style={{boxSizing: 'border-box', background: '#fff'}}
                    scroll={{x: 1300}}
                    columns={columnsFn(operate)}
                    params={{status: activeKey}}
                    request={getListApi}
                    options={{reload: false, density: false, setting: false}}
                    toolbar={{
                        actions: [],
                    }}
                    formRef={formRef}
                    rowKey="id"
                    pagination={{defaultPageSize: 10}}
                    actionRef={ref as any}
                    search={{
                        optionRender: (searchConfig, formProps, dom) => 
                        [ ...dom.reverse() ]
                    }}
                />
                <DocAudit {...docAuditProps} modalProps={{onCancel: () => setDocAuditProps({...docAuditProps, visible: false}), maskClosable: false}} />
        </div>)
}