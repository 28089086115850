import RebackList from "./cpnts/RebackList"
import RebackClaim from "./cpnts/RebackClaim"

export type OperateActionType = '回款记录-新建' | '回款记录-编辑' | '回款记录-删除' | '回款记录-一键申请' | '回款记录-撤销申请' | '待认领回款-认领' | '回款记录-详情'


export const viewOptions = [
    {
         label: '我的',
         value: '1'
    },
    {
         label: '下属的',
         value: '2'
    },
    {
         label: '全部',
         value: ''
    }
]

export const claimOptions = [
     {
          label: '待认领',
          value: '1'
     },
     {
          label: '已认领',
          value: '2'
     }
 ]
 
 