import { useHasAuthCode } from "@hooks/index";
import { CustomerDetailProps } from ".";
import { AllAuth, AuthActionArr } from "./type";

type useEnterpriseDetailHookProps = {
    type?: CustomerDetailProps['type'];  // 0 客户详情；1: 线索池详情 2: 我的线索
    isSyn?: boolean; // true 是协同人， false 不是协同人
}

type AuthKey = '客户业务调研表' | '决策链地图' | '商务地图' | '业务地图' | '跟进记录' | '商机列表' | '联系人' | '客户账号' | '消费信息' | '附件' | '知识库' | '操作记录'

// 'add:1001' => 如果有权限 返回 'add'; 没有则返回 null
function authCodeMap(codeStr: string, hasAuthCode: any){
    const [codeName, code] = codeStr.split(':')
    return code ? hasAuthCode(parseInt(code)) ? codeName : null : codeName
}

export const useAuthCodeMapBatch = (hasAuthCode: any) => (codeStrArr: string[]) => {
    return codeStrArr?.map((codeStr) => authCodeMap(codeStr, hasAuthCode)).filter(Boolean) as AuthActionArr
}

export const useEnterpriseDetailHook = ({type, isSyn}:useEnterpriseDetailHookProps): Record<AuthKey, AuthActionArr> => {
    const [, hasAuthCode] = useHasAuthCode()
    const authCodeMapBatch = useAuthCodeMapBatch(hasAuthCode)
    switch (type) {
        case '0':
            return ({
                '客户业务调研表': AllAuth,
                '决策链地图': authCodeMapBatch(['add', 'del:2497', 'edit']),
                '商务地图': authCodeMapBatch(['add', 'del:2498', 'edit']),
                '业务地图': authCodeMapBatch(['add', 'del:2499', 'edit']),
                '跟进记录': authCodeMapBatch(['add', 'edit:2495', 'del:2496']),
                '商机列表': authCodeMapBatch(['add', 'del:2494', 'edit', 'copy']),
                '联系人': AllAuth,
                '客户账号': AllAuth,
                '消费信息': AllAuth,
                '附件': authCodeMapBatch(['add:2493', 'del:2492']),
                '知识库': AllAuth,
                '操作记录': AllAuth
            })
        case '1':  // 1: 线索池详情 只看
            return ({
                '客户业务调研表': [],
                '决策链地图': [],
                '商务地图': [],
                '业务地图': [],
                '跟进记录': [],
                '商机列表': [],
                '联系人': [],
                '客户账号': [],
                '消费信息': [],
                '附件': [],
                '知识库': [],
                '操作记录': []
            })
        case '2':  // 我的线索详情
            return ({
                '客户业务调研表': AllAuth,
                '决策链地图': authCodeMapBatch(['add', 'del:2505', 'edit']),
                '商务地图': authCodeMapBatch(['add', 'del:2506', 'edit']),
                '业务地图': authCodeMapBatch(['add', 'del:2507', 'edit']),
                '跟进记录': authCodeMapBatch(['add', 'edit:2503', 'del:2504']),
                '商机列表': authCodeMapBatch(['add', 'del:2502', 'edit', 'copy']),
                '联系人': AllAuth,
                '客户账号': AllAuth,
                '消费信息': AllAuth,
                '附件': authCodeMapBatch(['add:2518', 'del:2508']),
                '知识库': AllAuth,
                '操作记录': AllAuth
            })
        default:
            return ({
                '客户业务调研表': [],
                '决策链地图': [],
                '商务地图': [],
                '业务地图': [],
                '跟进记录': [],
                '商机列表': [],
                '联系人': [],
                '客户账号': [],
                '消费信息': [],
                '附件': [],
                '知识库': [],
                '操作记录': []
            })
    }
}