import { AddFollowRecord, AddFollowRecordProps, customerHasBusinessApi } from "@components/Customer/List"
import { Button, Col, Modal, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { followRecordColumnsFn } from '../../data'
import { customerFollowList, customerFollowAdd, customerFollowDelImportant, customerFollowDel } from '@api/index'
import { productApi } from "@utils/util"
import { useTableHooks } from "@hooks/index"
import { EnterpriseDetailProps } from "../../type"
import { useHistory } from "react-router-dom"
import ProTable, { ActionType } from "@ant-design/pro-table"
import { stringify } from "qs"
import { useCustomerListOperate } from "@components/Customer/List/hook"

const getApi = productApi(customerFollowList)
const addApi = productApi(customerFollowAdd)
const delApi = productApi(customerFollowDel)  // 重点项目跟进删除

interface FollowRecordProps extends EnterpriseDetailProps{
    // readOnly?: boolean; // 是否只读模式
    businessOppId?: number | string; // 商机id
    businessOpportunityTitle?: string;
    // judgeHasBusiness?: boolean; // 是否判断有无商机
    noCheck?: boolean; // 跟进前是否做 能否跟进的 校验
    // isImportProject?: boolean;  // 是否是重点项目
}


export default ({authCode, ...rest}: FollowRecordProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const ref = useRef<ActionType>(null);
    const { id: customerId, name: customerName, businessOppId, businessOpportunityTitle, noCheck } = rest || {}

    // const {tableProps, fetchApi } = useTableHooks(getApi, {customerId, businessOppId})
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId, customerName, businessOppId})

    const { isCanaddFollowAction } = useCustomerListOperate()
    
    const operate = async (type: string, record?: any) => {
        if (type === '新建跟进') {
            if (businessOppId || noCheck) {
                setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName,
                    businessOppId,
                    businessOpportunityTitle,
                    request: undefined,
                    onSuccess: () => {
                        reFush()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
            } else {
                const ok = await isCanaddFollowAction({customerName, customerId, type: 2} as any)
                ok && setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName,
                    businessOppId,
                    businessOpportunityTitle,
                    request: undefined,
                    onSuccess: () => {
                        reFush()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
            }
        } else if (type === '删除跟进') {
            const ids = record ? [record?.id] : selectedRowKeys
            Modal.confirm({
                title: '温馨提示',
                content: '确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        reFush()
                    })
                },
            })
        } else if (type === '编辑跟进') {
            setAddFollowProps({
                ...addFollowProps,
                title: type,
                visible: true,
                customerId,
                customerName,
                businessOppId,
                businessOpportunityTitle,
                params: { id: record.id },
                onSuccess: () => {
                    reFush()
                    setAddFollowProps({...addFollowProps, visible: false})
                    return true
                }
            })
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}) as any
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const columns = followRecordColumnsFn(operate, authCode)
    const calcDisabled = () => !selectedRowKeys.length

    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
        <ProTable<any>
            request={getListApi}
            params={{customerId, businessOppId}}
            scroll={{x: 2600 }}
            columns={columns}
            search={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    authCode?.includes('add') ? <Button type="primary" onClick={() => operate && operate('新建跟进', null)} style={{marginLeft: 10}}>新建跟进</Button> : null,
                ],
            }}
            rowKey="id"
            actionRef={ref as any}
            rowSelection={false ? {
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
            } : false}
        />
        <AddFollowRecord {...addFollowProps} noCheck={noCheck} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false, destroyOnClose: true}} />
    </div>)
}
