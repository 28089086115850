import { DescListItem } from "@components/EnterpriseDetail/type"
import { amountFormat } from "@utils/index"
import React from "react"
import { mailedOptions, paidOptions, typeOptions } from "../../data"
import { Image } from 'antd'

export const billInfo: DescListItem[] = [
    // v4.8.0
    {
        label: '提交时间',
        param: 'updateTime',
        span: 1
    },
    {
        label: '开票时间',
        param: 'confirmTime',
        span: 1
    },
    {
        label: '审批时间',
        param: 'reviewTime',
        span: 1
    },
    // end
    {
        label: '客户名称',
        param: 'customerName',
        span: 1
    },
    {
        label: '合同名称',
        param: 'contractName',
        span: 1
    },
    {
        label: '合同编号',
        param: 'contractNumber',
        span: 1
    },
    {
        label: '业务类型',
        param: 'businessTypeStr',
        span: 1
    },
    {
        label: '是否付款',
        param: 'paid',
        span: 1,
        value: ({paid}: any) => (paidOptions.find(v => v.value === paid)||{}).label
    },
    {
        label: '回款时间',
        param: 'returnTime',
        span: 1
    },
    {
        label: '开票金额(元)',
        param: 'amount',
        span: 1,
        value: ({amount}: any) => amountFormat(amount)
    },
    {
        label: '开票比例',
        param: 'percent',
        span: 1,
        value: ({percent}: any) => `${percent}%`
    },
    {
        label: '开票类型',
        param: 'type',
        span: 1,
        value: ({type}: any) => (typeOptions.find(v => v.value === type)||{}).label
    },
    {
        label: '企业名称',
        param: 'companyName',
        span: 1
    },
    {
        label: '企业税号',
        param: 'companyTaxNumber',
        span: 1
    },
    // {
    //     label: '纳税人识别号',
    //     param: 'taxPayerNumber',
    //     span: 1
    // },
    {
        label: '开户银行',
        param: 'bank',
        span: 1
    },
    {
        label: '银行账号',
        param: 'bankAccount',
        span: 1
    },
    {
        label: '税票电话',
        param: 'taxPhone',
        span: 1
    },
    {
        label: '税票地址',
        param: 'taxAddress',
        span: 2
    },
    {
        label: '电子邮箱',
        param: 'email',
        span: 1
    },
    {
        label: '发票号码',
        param: 'invoiceNumber',
        span: 1
    },
    {
        label: '开票内容',
        param: 'invoiceContent',
        span: 1
    },
    {
        label: '备注',
        param: 'remark',
        span: 3
    },
    {
        label: '付款凭证',
        param: 'proof',
        span: 3,
        value: ({proof}: any) => {
            const imgs = proof ? proof.split(',') : []
            return <div>
                {imgs.map((url: any, i: any) => <span key={i} style={{marginRight: 10}}><Image width={60} height={50} src={url} /></span>)}
            </div>
        }
    }
]

export const gainInfo: DescListItem[] = [
    {
        label: '是否邮寄',
        param: 'mailed',
        span: 1,
        value: ({mailed}: any) => (mailedOptions.find(v => v.value === mailed)||{}).label
    },
    {
        label: '备注',
        param: 'mailRemark',
        span: 1
    },
    {
        label: '收件人姓名',
        param: 'receiverName',
        span: 1
    },
    {
        label: '手机号码',
        param: 'mailPhone',
        span: 1
    },
    {
        label: '收件地址',
        param: 'receiverAddress',
        span: 2
    },
]

export const postInfo: DescListItem[] = [
    {
        label: '快递公司',
        param: 'expressCompany',
        span: 1
    },
    {
        label: '快递单号',
        param: 'orderId',
        span: 1
    },
    {
        label: '寄件人姓名',
        param: 'senderName',
        span: 1
    },
    {
        label: '寄件人手机号码',
        param: 'senderPhone',
        span: 1
    },
    {
        label: '寄件地址',
        param: 'senderAddress',
        span: 3
    },
    {
        label: '收件人姓名',
        param: 'mailReceiverName',
        span: 1
    },
    {
        label: '收件人手机号码',
        param: 'mailReceiverPhone',
        span: 1
    },
    {
        label: '收件地址',
        param: 'mailReceiverAddress',
        span: 3
    },
]