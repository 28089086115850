import React, { useEffect, useRef, useState } from "react"
import { bussinessScopeOptions, bussinessViewOptions, columnsFn, sonColumnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType, ProTableProps } from '@ant-design/pro-table';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Button, Dropdown, Menu, Modal, Select, Table, Tabs } from "antd";
import { Link } from "react-router-dom";
import { AddFollowRecord, AddFollowRecordProps, AddHelper, AddStaff, customerFollowApi } from "../List";
import { customerBusinessList, customerBusinessDel, customerBusinessExport, BASEURL, customerBusinessToImportant } from '@api/index' 
import { amountFormat, productApi } from "@utils/util";
import { ExportButton, FormDragSetting, getFormDragSettingOptions, ImportButton, ProTableWithFormSetting, setColumnsByOptions, ViewScope } from "@components/index";
import { useHasAuthCode } from "@hooks/index";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";
import { mapValues } from "lodash";

// Table 默认展示字段
const columnStateDefaultValue: any = {
    accountIsNew: {show: false},
    businessOpportunityTitle: {show: true, fixed: "left"},
    buyMethod: {show: false},
    cooperationMode: {show: false},
    createTime: {show: true},
    creator: {show: true},
    customerName: {show: true, fixed: "left"},
    estimatedBetweenIncome: {show: true},
    estimatedCashIncome: {show: true},
    estimatedSigningDate: {show: false},
    followBusinessLine: {show: false},
    followUpTime: {show: false},
    followupRecord: {show: true},
    helpPoint: {show: true},
    nextStepContent: {show: true},
    notFollowDays: {show: true},
    opportunityPoint: {show: true},
    option: {show: true, fixed: "right"},
    poc: {show: true},
    primaryContact: {show: false},
    productStr: {show: false},
    salesStage: {show: false},
    setUpGroup: {show: false},
    synergy: {show: true},
    whetherNewCustomer: {show: false},
    whetherVisit: {show: false},
    actualSigningDate: {show: false},
    orderType: {show: false},
    orderMethod: {show: false}
}

const getApi = productApi(customerBusinessList)
const delBusnessApi = productApi(customerBusinessDel)
const businessExportApi = productApi(customerBusinessExport)
const toImportantApi = productApi(customerBusinessToImportant)

interface BussinessSonTableProps extends ProTableProps<any, any>{
    authCode?: any[]
}
export const BussinessSonTable = ({ authCode, ...rest }: BussinessSonTableProps) => {
    return (<ProTable 
        className="no-padding-pro-card-body"
        search={false} 
        pagination={false} 
        options={false}
        scroll={{x: 1800}}
        columns={sonColumnsFn(() => null, authCode)} 
        {...rest}
    />)
}

export default () => {
    const [summary, setSummary] = useState({estimatedCashIncome: 0, estimatedBetweenIncome: 0, singleCashReceiptsSum: 0, intentionSpreadIncomeSum: 0, total: 1})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [view, setView] = useState<string>('')
    const [scope, setScope] = useState<string>('')
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined, businessOppId: undefined,})
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);

    const customerViewOnChange = (val: any, type: any) => type === 'view' ? setView(val) : setScope(val)
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const { customerId, customerName, businessOppId, businessOpportunityTitle} = record || {}
        switch (type) {
            case '批量删除':
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await delBusnessApi({ids: selectedRowKeys}, true, () => {
                            reFresh()
                        })
                    },
                })
                break;
            case '添加跟进':
                setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName,
                    businessOppId,
                    businessOpportunityTitle,
                    onSuccess: () => {
                        ref.current?.reload()
                        setAddFollowProps({...addFollowProps, visible: false})
                        return true
                    }
                })
                break;
            case '转为重点项目':
                Modal.confirm({
                    title: '确认要转为重点项目？',
                    content: '转为重点项目之后不能再转为普通项目。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await toImportantApi({businessOppIds: selectedRowKeys}, true, () => {
                            reFresh()
                        })
                    },
                });
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter: sorter['daysSinceFollowup'] ? {followupTime: sorter['daysSinceFollowup']} : sorter}, false)
        const { values, total, ...rest } = data || {}
        setSummary({...rest, total,})
        return {
            data: values || [],
            success: true,
            summaryData: mapValues(rest, (o: any) => typeof o === 'number' ? o < 0 ? 0 : amountFormat(o) : o),
            total
        }
    }
    const calcDisabled = () => !selectedRowKeys.length
    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    const params = { scope: view, businessOppDataScope: scope }

    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(), 
        ...params,
        type: 1
    })

    const batchParams = () => {
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(), 
            ...params, 
            type: 1,
            businessOppIds: selectedRowKeys
        })
    }

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ViewScope 
                viewOptions={bussinessViewOptions} 
                scopeOptions={bussinessScopeOptions} 
                onChange={customerViewOnChange} 
            />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 1800}}
                scroll={{x: summary?.total ? 'max-content' : 1800}}
                sticky
                columns={columnsFn(operate)}
                params={{...params, type: 1}}
                request={getListApi}
                options={{reload: false, density: false}}
                headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>总落单Cash收入 <span style={{color: 'red'}}>{amountFormat(summary.singleCashReceiptsSum||0)}</span> ,总落单差价收入 <span style={{color: 'red'}}>{amountFormat(summary.intentionSpreadIncomeSum||0)}</span> （汇总列表查询结果）</h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2127) ? <Button key="primary" type="primary"><Link to={'/customer/businessAdd'}>新建商机</Link></Button> : null,
                        // hasAuthCode(2127) ? <Button key="primary" type="primary" disabled={calcDisabled()} onClick={() => operate('转为重点项目')}>转为重点项目</Button> : null,
                        hasAuthCode(2186) ? <AddHelper key="bnt1" title="添加协同人" selectedRowKeys={selectedRowKeys} type={2} params={{type: 2}} trigger={<Button disabled={calcDisabled()}>添加协同人</Button>} onSuccess={reFresh} /> : null,
                        // hasAuthCode(2130) ? <ExportButton key="btn5" disabled={calcDisabled()} param={{businessOppIds: selectedRowKeys}} action={`${BASEURL}/business/export`} /> : null,
                        // hasAuthCode(2130) ? <ExportButton key="btn6" param={getQueryParams} btnText="全部导出" action={`${BASEURL}/business/export`} /> : null,
                        hasAuthCode(2130) ? <ExportButtonWithBatch key="btn6" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/business/export`} /> : null,
                        // <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/exportBackClaim`} /> : null
                        // <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                        //     { hasAuthCode(-1) ? <Menu.Item key="btn2"><ImportButton action={`${BASEURL}/followup/importFollowups`} params={{type: 3}} templateUrl='https://jns-crm.oss-cn-hangzhou.aliyuncs.com/9864A70905F24FD4901A4B0F8A3DFB8E_导入跟进记录-商机标题.xlsx' title="导入跟进" trigger={<a>导入跟进</a>}></ImportButton></Menu.Item> : null }
                        // </Menu>}>
                        //     <Button>批量导入</Button>
                        // </Dropdown>,
                        hasAuthCode(2129) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('批量删除')}>批量删除</Button> : null,
                    ],
                }}
                rowKey="businessOppId"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => setSelectedRowKeys(selectedRowKeys),
                    getCheckboxProps: (record: TableListItem) => ({
                        // disabled: record.isSyn === 1,
                        disabled: false
                    })
                }}
                onChange={(pageInfo,_, columnStatus,{action}) => {
                    if (action === 'sort') {
                        ref.current?.reload(1 as any)
                    }
                }}
                expandable={{
                    expandedRowRender: ({substatements}: any) => <BussinessSonTable dataSource={substatements} authCode={['edit']} />
                }}
                columnsState={{ persistenceKey: 'customer/business', persistenceType: 'localStorage', defaultValue: columnStateDefaultValue as any}}
                searchState={{ persistenceKey: 'customer/business:searchState', persistenceType: 'localStorage' }}
            />
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
    </div>
}
