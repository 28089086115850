import { Tag, Timeline } from "antd"
import React from "react"

type TimelineLabelProps = {
  time?: string;
  version?: string;
}

type TimelineContentProps = {
  items?: any[]
}

type ChangeLogProps = TimelineLabelProps & TimelineContentProps

const TimelineLabel = ({time, version}: TimelineLabelProps) => (<><h3>{time}</h3><Tag>{version}</Tag></>)

const TimelineContent = ({items}: TimelineContentProps) => {
  return (<ul style={{paddingLeft: 20, paddingTop: 20, minHeight: 60}}>
    {(items||[]).map((item, idx) => <li key={idx}>{item}</li>)}
  </ul>)
}

type TimelineItemProps = {
  changeLog: ChangeLogProps
}
const TimelineItem = ({changeLog}: TimelineItemProps) => {
  const {time, version, items} = changeLog
  return (<Timeline.Item label={<TimelineLabel time={time} version={version} />}>
  <TimelineContent items={items} />
</Timeline.Item>)
}

const changeLogs: ChangeLogProps[] = [
  {
    time: '2023.08.08',
    version: 'v4.8.0',
    items: [
      '🛠 客户管理-跟进记录筛选条件: 创建时间查询默认近30天'
    ]
  },
  {
    time: '2022.08.11',
    version: 'v4.7.3',
    items: [
      '🛠 完善组件缓存，可以缓存多个详情页，并且面包屑叉掉清掉对应的缓存页面'
    ],

  }
]

// 变更日志-时间线
export default () => {
    return (<Timeline mode={'left'} style={{boxSizing: 'border-box', padding: 20}}>
            {(changeLogs||[]).map((changeLog, idx) => TimelineItem({changeLog}))}
            <Timeline.Item label={<h3>2021.10.09</h3>}>v2.0.0 版本诞生</Timeline.Item>
    </Timeline>)
}