import { ossUpload } from '@api/index'
import React, { useEffect, useState } from 'react'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IEditorConfig } from "@wangeditor/editor";
import '@wangeditor/editor/dist/css/style.css'

type MyEditorProps = {
    value?: string;
    onChange?: (value: string) => void;
    style?: object
}

const customUpload = async (file: File, insertFn: any) => {
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await ossUpload(formData) as any
    const { url } = data || {}
    insertFn(url, '', url)
}

export default ({value, onChange, style = {height: '500px'}}: MyEditorProps) => {
    const [editor, setEditor] = useState<any>(null) // 存储 editor 实例
    const [html, setHtml] = useState<any>('<p><br></p>')
    const toolbarConfig = { }
    const editorConfig: Partial<IEditorConfig> = { 
        autoFocus: false,
        placeholder: '请输入内容...',
        MENU_CONF: {
            uploadImage: {
                customUpload,
            },
            uploadVideo: {
                customUpload,
            }
        },

    }
    // 及时销毁 editor
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    useEffect(() => {
        setHtml(value)
    }, [value])

    return (
        <>
            <div style={{ border: '1px solid #ccc', zIndex: 100, marginTop: '15px'}}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={editor => {
                        setHtml(editor.getHtml())
                        onChange && onChange(editor.getHtml())
                    }}
                    mode="default"
                    style={style}
                />
            </div>
        </>
    )
 }