import ProTable, { ActionType } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { productApi } from "@utils/util"
import { Button, Modal } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, confirmContent, OperateActionType, TableListItem, tabPanes } from "./data"
import { oaTaskCancel, oaTaskCenterPageList, oaTaskDel, oaTaskPause, oaTaskResume } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"

const getPageListApi = productApi(oaTaskCenterPageList)
const delApi = productApi(oaTaskDel)
const cancelApi = productApi(oaTaskCancel)
const pauseApi = productApi(oaTaskPause)
const resumeApi = productApi(oaTaskResume)

export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [activeKey, setActiveKey] = useState(' ')
    const [, hasAuthCode] = useHasAuthCode()
    const operate = async (action: OperateActionType, record: any) => {
        const { id } = record || {}
        if (['新建任务', '编辑'].includes(action)) {
            history.push(`/oa/addTask?${stringify({id})}`)
        } else {
            Modal.confirm({
                title: `确认要${action}任务吗?`,
                content: confirmContent[action],
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                   if (action === '删除') {
                       await delApi({id}, true, reFush)
                   } else if (action === '取消') {
                       await cancelApi({id}, true, reFush)
                   } else if (action === '暂停') {
                       await pauseApi({id}, true, reFush)
                   } else if (action === '恢复') {
                       await resumeApi({id}, true, reFush)
                   }
                },
            });
        }
    }

    const getApi = async (params: any) => {
        const { taskStatus1, taskStatus, ...rest } = params
        const { data } = await getPageListApi({...rest, taskStatus: taskStatus === ' ' ? taskStatus1 : taskStatus }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        ref.current?.reload()
    }

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
          style={{background: '#fff'}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
        />
        <ProTable<TableListItem>
                scroll={{x: 1200}}
                columns={columnsFn(operate, activeKey)}
                params={{taskStatus: activeKey}}
                request={getApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2371) ? <Button key="btn6" type="primary" onClick={() => operate('新建任务', null)}>新建任务</Button> : null
                    ],
                }}
                rowKey="id"
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ],
                }}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={false}
        />
    </div>)
}
