import { EditableProTable, ProColumns } from "@ant-design/pro-table";
import { DescListItem } from "@components/EnterpriseDetail/type";
import { useDictEnumHooks } from "@hooks/index";
import { toFixedNoRound } from "@utils/index";
import { Form, Input, InputNumber, Select } from "antd";
import moment from "moment";
import React, { useEffect } from "react";

// 线索池-线索详情descList
export const baseInfoDesList: DescListItem[] = [
    {
        label: '客户名称',
        param: 'customerName',
        span: 2,
    },
    {
        label: '客户账号',
        param: 'customerAccount',
        span: 2,
    },
    {
        label: '消费月份',
        param: 'consumeMouth',
        span: 2,
    },
    {
        label: '消费金额',
        // param: 'consumeAmount',
        span: 2,
        value: ({consumeAmount}: any) => consumeAmount + ''
    },
]

export const refundOrdersItem = (n: number = 1) => new Array(n).fill({
    consumeMouth: '2022-12',
    customerAccount: 'A',
    consumeAmount: -800,
    freeConsumeAmount: 100,
    backConsumeAmount: 0,
    restConsumeAmount: 700,
    orderSource: '同客户账号',
}).map((v, idx) => ({...v, id: `zqs-${idx}`}))

export const refundOrdersColumnsFn = (operate: Function) => {
    return [
        {
            title: '消费月份',
            dataIndex: 'consumeMouth',
            render: (text: any, record: any) => `${moment(text).format('YYYY-MM-DD')}`
        },
        {
            title: '客户账号',
            dataIndex: 'customerAccount',
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
        },
        {
            title: '已抵扣消费金额',
            dataIndex: 'freeConsumeAmount',
            render: (text: any, record: any) => text > 0 ? <a onClick={() => operate && operate('已抵扣订单', record)}>{text}</a> : text
        },
        {
            title: '已追回消费金额',
            dataIndex: 'backConsumeAmount',
        },
        {
            title: '剩余消费金额',
            dataIndex: 'restConsumeAmount',
        },
        {
            title: '订单来源',
            dataIndex: 'orderSource',
        },
    ]
}

export const deductionColumns = () => {
    return [
        {
            title: '消费月份',
            key: 'month',
            dataIndex: 'month'
        },
        {
            title: '客户账号',
            key: 'customerAccount',
            dataIndex: 'customerAccount'
        },
        {
            title: '抵扣的消费金额',
            key: 'deductionAmount',
            dataIndex: 'deductionAmount'
        },
        {
            title: '抵扣原因',
            key: 'deductionReason',
            dataIndex: 'deductionReason'
        }
    ]
}

export const rulesTableList = () => {
    return [
        {
            title: '主维度',
            dataIndex: 'mainDimension',
            render: (value: any, record: any) => record.mainName || '-',
        },
        {
            title: '子维度',
            dataIndex: 'sonDimension',
            render: (value: any, record: any) => record.sonName || '-',
        },
        {
            title: '返点上限',
            dataIndex: 'maxRebatePoint',
        },
        // {
        //     title: '状态',
        //     dataIndex: 'status',
        //     order: 1,
        //     valueEnum: {
        //         0: { text: '禁用', status: 'Default' },
        //         1: { text: '启用', status: 'Processing' },
        //     },
        // },
        // {
        //     title: '创建时间',
        //     dataIndex: 'createTime',
        //     hideInSearch: true,
        // },
    ]
}

export const sonTableColumns = () => {
    return [
        {
            title: '收款人',
            key: 'getMoneyer',
            dataIndex: 'getMoneyer'
        },
        {
            title: '银行卡账号',
            key: 'bankAccount',
            dataIndex: 'bankAccount'
        },
        {
            title: '开户行',
            key: 'bank',
            dataIndex: 'bank'
        },
        {
            title: '支付宝账号',
            key: 'aliPayAccount',
            dataIndex: 'aliPayAccount'
        },
    ]
}

export type DataSourceRebateInfoType = {
    id: string;
    mainDimension: string;
    sonDimension: string;
    consumeAmount: number;
    freeConsumeAmount: number;
    isRebate: string;
    rebatePoint: number;
    expectRebateAmount: string;
    realRebateAmount: string;
}
export const rebateInfoItem = (n: number = 1) => new Array(n).fill({
    mainDimension: '订单类型',
    sonDimension: '新升',
    sonDimensionId: 1,
    consumeAmount: 1000.00,
    freeConsumeAmount: 500.00,
    isRebate: '1',
    rebatePoint: 12,
    maxPoint: 50,
    // expectRebateAmount: 50.00,
    // realRebateAmount: 80.00
}).map((v, idx) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))

export const rebateInfoColumnsFn: (operate: Function) => ProColumns<DataSourceRebateInfoType>[] = (operate) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: '主维度',
            dataIndex: 'mainDimension',
            editable: false,
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>子维度</span>,
            dataIndex: 'sonDimension',
            // editable: (_, {id}) => {
            //     return (id+'').startsWith('zqs-')
            // },
            editable: false,
            valueType: 'select',
            params: {dictId: 25 },
            request,
            fieldProps: (_: any, {rowKey}: any) => {
                return {
                   disabled: !((rowKey||[])[0]+'').startsWith('zqs-')
                }
            },
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
            editable: false,
            render: (text: any, record: any) => toFixedNoRound(record.consumeAmount || 0)
        },
        {
            title: '抵扣退款金额',
            dataIndex: 'freeConsumeAmount',
            editable: false,
            render: (text: any, record: any) => toFixedNoRound(record.freeConsumeAmount || 0)
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>有无返点</span>,
            dataIndex: 'isRebate',
            valueType: 'select',
            fieldProps: { options: [{ label: '有', value: 1 }, { label: '无', value: 0 }] },
            // valueEnum: {
            //     0: { text: '无' },
            //     1: { text: '有'},
            // },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>返点比例</span>,
            dataIndex: 'rebatePoint',
            valueType: 'digit',
            formItemProps: { noStyle: true },
            fieldProps: (_: any, { entry }: any) => {
                const { isRebate, maxPoint } = entry || {}
                return {
                   disabled: isRebate === 0,
                   addonAfter: '%',
                   max: typeof maxPoint === 'number' ? maxPoint : 100
                }
            },
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
            editable: false
        },
        {
            title: '操作',
            // valueType: 'option',
            width: 100,
            fixed:'right',
            editable: false,
            render: (text: any, record: any) => [
            //   <a key="link" onClick={() => operate && operate('返点信息-新增', record)}>新增</a>,
              (record.id+'').startsWith('zqs-') ? <a key="del" style={{marginLeft: 10}} onClick={() => operate && operate('返点信息-删除', record)}>删除</a> : null,
            ],
        },
    ]
}

export type DataSourceGetMoneyType = {
    id: string;
    getMoneyerType: string;
    getMoneyer: string;
    getMoneyerAccount: string;
    expectRebateAmount: string;
    realRebateAmount: string;
}
export const getMoneyItem = (n: number = 1) => new Array(n).fill({
    getMoneyerType: 0,
    getMoneyer: '章三',
    // getMoneyerAccount: 'string',
    // payType: 0,
    // payAccount: '136486485756',
    bank: '招商银行',
    bankAccount: '3674657',
    aliPayAccount: 'dhjfhkg',
    expectRebateAmount: 200,
    realRebateAmount: 223,
}).map((v, idx) => ({...v, id: `zqs-${idx}`}))

export const getMoneyColumnsFn: (operate: Function) => ProColumns<DataSourceGetMoneyType>[] = (operate) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>收款人类型</span>,
            dataIndex: 'getMoneyerType',
            valueType: 'select',
            params: {dictId: 258 },
            request,
            editable: false,
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>收款人</span>,
            dataIndex: 'getMoneyer',
            editable: false,
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>收款账号</span>,
            dataIndex: 'getMoneyerAccount',
            width: 300,
            editable: false,
            render: (text: any, {bank, bankAccount, aliPayAccount}: any) => (<div>{ bank ? <>{`${bank}: ${bankAccount}`}<br/></> : null } 支付宝: {aliPayAccount}</div>)
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>预计返点金额</span>,
            dataIndex: 'expectRebateAmount',
            valueType: 'digit',
            fieldProps: {
                min: 0,
                precision: 2,
            },
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    },
                    // {
                    //     validator: async (rules: any, val: number, callback: Function) => {
                    //         if (val <= 0) {
                    //            return Promise.reject('预计返点金额需大于0')
                    //         }
                    //         return Promise.resolve()
                    //     },
                    // }
                ],
            },
        },
        {
            title: '操作',
            // valueType: 'option',
            width: 120,
            fixed:'right',
            editable: false,
            render: (text: any, record: any) => [
            //   <a key="link" onClick={() => operate && operate('收款信息-新增', record)}>新增</a>,
              <a key="update" style={{marginLeft: 10}} onClick={() => operate && operate('收款信息-修改', record)}>修改</a>,
              <a key="del" style={{marginLeft: 10}} onClick={() => operate && operate('收款信息-删除', record)}>删除</a>,
            ],
        },
    ]
}