import { useState } from "react"
import { Modal } from "antd"
import { OperateActionType } from "../../data"
import { RebackAuditModalFormProps } from "../RebackListAudit"
import { rebackAuditDel, rebackAuditUndo } from "@api/index"
import { productApi } from "@utils/index"

const rebackAuditUndoApi = productApi(rebackAuditUndo)
const rebackAuditDelApi = productApi(rebackAuditDel)

export const useRebackClaimAuditHooks = () => {

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [rebackClaimAuditModalFormProps, setRebackClaimAuditModalFormProps] = useState<RebackAuditModalFormProps>({title: '新建回款记录', visible: false, params: {}})
    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { backClaimId } = record || {}

        const backClaimIds = backClaimId ? [backClaimId] : selectedRowKeys
        // 待认领回款-认领
        const claimAction = (props: Object = {}) => setRebackClaimAuditModalFormProps({
            ...rebackClaimAuditModalFormProps,
            title: '认领回款',
            visible: true,
            params: {backClaimId},
            onSuccess: () => {
                setRebackClaimAuditModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        // 待认领回款-撤销
        const undoAction = (params: Object = {}) => Modal.confirm({
            title: '撤销',
            content: '撤销后，数据状态将改为待认领',
            cancelText: '取消',
            onOk: async () => {
                await rebackAuditUndoApi({backClaimIds}, true, onSuccess)
            },
        })

        // 删除-待认领回款
        const delAction = (params: Object = {}) => Modal.confirm({
            title: '删除',
            content: '确定删除已选中的数据？删除成功之后，将无法恢复',
            cancelText: '取消',
            onOk: async () => {
                await rebackAuditDelApi({backClaimIds}, true, onSuccess)
            },
        })

        return {
            claimAction,
            undoAction,
            delAction
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            claimAction,
            undoAction,
            delAction
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '待认领回款-认领':
                    return claimAction()
                case '待认领回款-撤销':
                    return undoAction()
                case '待认领回款-删除':
                    return delAction()
            }
        }
    }

    return {
        rebackClaimAuditModalFormProps,
        setRebackClaimAuditModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled,
    }

}
