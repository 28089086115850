import React, { Key, useRef, useState, useEffect, useMemo } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import ProForm, { ModalForm, ProFormDigit, ProFormText, ProFormRadio } from '@ant-design/pro-form';
import { Button, Form, Input, Modal, Select, TreeSelect, Table } from "antd";
import { ExportButton, ProFormTreeSelect } from '@components/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { treeDataDisabledRules, productApi } from "@utils/index";
import { getListPayeeGather, getListPayeeDetail, BASEURL } from '@api/rebate'
import { useHasAuthCode, useCommonApiHooks } from "@hooks/index";
import { StateType } from "@store/index";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(getListPayeeGather)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { consumeMonth } = params || {} 
        const [startTime, endTime] = consumeMonth ? consumeMonth : [undefined, undefined]
        const { data } = await getApi({...params, startTime, endTime}, false)
        const { records, total } = data || {}
        return {
            data: records,
            success: true,
            total
        }
    }
    const ExpandedRowRender = (record: any) => {
        const { ids } = record
        const [options] = useCommonApiHooks({url: `${BASEURL}/rebates/listPayeeDetail`}, {ids}, true)
        const columns = [
            { title: '客户名称', dataIndex: 'customerName', key: 'customerName' },
            { title: '账号类型', dataIndex: 'accountTypeStr', key: 'accountTypeStr' },
            { title: '客户账号', dataIndex: 'customerAccount', key: 'customerAccount' },
            { title: '返点金额', dataIndex: 'rebateAmount', key: 'rebateAmount' },
        ]
        return (
            <div>
                <Table
                    columns={columns}
                    dataSource={options}
                    pagination={false}
                />
            </div>
        );
    };

    const columns = columnsFn()
    // 通过 useMemo 修复 定时器导致的 页面闪烁问题，同时做性能优化
    return useMemo(() => <div>
        <div style={{boxSizing: 'border-box', paddingTop: '20px'}}>
            <ProTable<TableListItem>
                // scroll={{x: 1300}}
                scroll={{x: 'max-content'}}
                columns={columns}
                expandable={{ expandedRowRender: (props) => <ExpandedRowRender {...props} />}}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        // hasAuthCode(2243) ? <ExportButton key="btn5" btnText="全部导出" param={{}} action={`${BASEURL}/export`} /> : null,
                        // <ExportButton key="btn5" btnText="全部导出" param={{}} action={`${BASEURL}/export`} />
                    ],
                }}
                rowKey="concatIds"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
            />
        </div>
    </div>, [])
}