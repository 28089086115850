import React, { useRef } from "react"
import { BackBan, Icon, ProFormTreeSelect, ProFormWithCache, SearchBox } from "@components/index"
import type { ProFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ProForm, ProFormText, ProFormDatePicker, ProFormDependency, ProFormDigit, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { Button, Form, message, Tooltip } from "antd";
import { feedbackColumnsFn, feedbackItem, seachConfig0, seachConfig1, seachConfig2, taskChargerTypeOptions, taskFeedbackOptions, taskTargetOptions } from "./data";
import { EditableProTable } from "@ant-design/pro-table";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { MyTabs } from "@components/EnterpriseDetail";
import { getToken, handleUploadFiles, parseSearch, productApi } from "@utils/util";
import { BASEURL, oaTaskAddUpdate, oaTaskDetailById } from "@api/index";
import { useHistory } from "react-router-dom";
import moment from "moment";

interface AddTaskProps extends ProFormProps{
    disabledFields?: string[];
    onSuccess?: () => void;
}

const addUpdateApi = productApi(oaTaskAddUpdate)
const infoApi = productApi(oaTaskDetailById)

const { Fields } = SearchBox
const labelCol = { span: 4 }
const layout = {
    labelCol,
    wrapperCol: { span: 20 },
};
const format = "YYYY-MM-DD HH:mm"

const disabledTaskEndTime = (current: any) => {
    return current && current < moment()
}

const FeedbackConfigEditable = ({form, value, onChange, ...rest}: any) => {  
    const columns = feedbackColumnsFn(form)
  
    return (<EditableProTable<any>
            columns={columns}
            rowKey="id"
            value={value} 
            recordCreatorProps={{
              newRecordType: 'dataSource',
              record: feedbackItem()[0],
            }}
            editable={{
              form,
              type: 'multiple',
              deleteText: null,
              editableKeys: (value||[]).map((item: { id: any; }) => item.id),
              actionRender: (row, config, defaultDoms) => {
                return [defaultDoms.delete];
              },
              onValuesChange: (_, editableRows) =>  {
                onChange && onChange(editableRows)
              },
            }}
            onChange={onChange}
            {...rest}
        />
    )
}

const getSubmitParams = ({taskSelectCustomer, taskChargers: taskChargerIds, ...rest}: any) => {
    const taskSelectCustomerConfig = rest[taskSelectCustomer]
    if ('customerManager' in taskSelectCustomerConfig) {
        const customerManager = taskSelectCustomerConfig['customerManager'].map((v: string) => v.startsWith('s') ? v.slice(1) : undefined).filter(Boolean)
        taskSelectCustomerConfig['customerManager'] = customerManager
    }
    if ('file' in taskSelectCustomerConfig) {
        taskSelectCustomerConfig['file'] = handleUploadFiles(taskSelectCustomerConfig['file'])
    }
    const taskChargers = (taskChargerIds||[]).map((v: string) => v.startsWith('s') ? v.slice(1) : undefined).filter(Boolean)
    return { ...rest, taskChargers, taskSelectCustomer, taskSelectCustomerConfig, }
}

const transParams = (taskSelectCustomerConfig: any = {}) => {
    for(let key in taskSelectCustomerConfig) {
        if(key === 'customerManager') {
            taskSelectCustomerConfig['customerManager'] = (taskSelectCustomerConfig['customerManager']||[]).map((v: number) => `s${v}`)
        } else if (taskSelectCustomerConfig[key] === null) {
            taskSelectCustomerConfig[key] = undefined
        }
    }
    return taskSelectCustomerConfig
}

const TaskFeedbackTooptip = () => {
    return(<>说明：配置【跟进客户】表单中的跟进反馈下拉选项。</>)
}

const TaskFinishTargetTooltip = () => {
    return(<div>（1）任务一旦开始，系统将每小时统计一次任务进度。<br />
        （2）任务进度计算公式：任务进度=已完成的客户数量/被指派的客户数量*100%  （进度值取整数，例如，计算结果是23.56%，最终显示23%）</div>)
}

const TaskSelectCustomerTooltip = () => {
    return(<div>
        1.选择客户：根据组合条件筛选客户，筛选出的客户将被指派给具体的负责人进行跟进。<br />
        2.分配客户规则：判断选中的客户与选中的负责人是否已绑定，若已绑定，该客户不重新分配，若没有绑定，客户将被平均分配给选中的负责人。客户被分配至负责人的【客户列表】模块中。<br />
        （1）例如1：筛选出100个客户，负责人是张三、李四、王五
        其中10个客户是张三名下的，剩余(100-10)=90个客户平均分配给张三、李四、王五，90/3=30每人分配30个客户<br />
        （2）例如2：筛选出101个客户，负责人是张三、李四、王五
        其中10个客户是张三名下的，剩余的（101-10）=91个客户平均分配给张三、李四、王五,91/3=30...1每人分配30个客户，按分责任先后顺序将余出的客户进行分配，则张三分配到31个客户
    </div>)
}

const taskSelectCustomerTabPanes = [
    // 亚茹说：去掉 【全部客户， 购买云产品的客户】，修改日期： 2024-06-20
    // {
    //     Tab: <span>全部客户 <Tooltip title="从系统中的所有客户中筛选，包含有归属人和无归属人的客户。"><Icon type="icon-ziyuan" /></Tooltip></span>,
    //     // Content: <SearchBox config={seachConfig0} labelCol={{span: 8}} wrapperCol={{span: 24}} style={{background: '#FCFCFC'}} search={false} />
    //     Content: null,
    //     seachConfig: seachConfig0
    // },
    {
        Tab: <span>指定归属人的客户 <Tooltip title="从指定的客户经理的名下客户中进行筛选。"><Icon type="icon-ziyuan" /></Tooltip></span>,
        // Content: <SearchBox config={seachConfig1} labelCol={{span: 8}} style={{background: '#FCFCFC'}} search={false} />
        Content: null,
        seachConfig: seachConfig1
    },
    // {
    //     Tab: <span>购买云产品的客户 <Tooltip title="从已购买过云产品的客户中进行筛选。"><Icon type="icon-ziyuan" /></Tooltip></span>,
    //     // Content: <SearchBox config={seachConfig2} labelCol={{span: 8}} style={{background: '#FCFCFC'}} search={false} />
    //     Content: null,
    //     seachConfig: seachConfig2
    // },
    {
        Tab: <span>批量导入 </span>,
        // Content: <SearchBox config={seachConfig2} labelCol={{span: 8}} style={{background: '#FCFCFC'}} search={false} />
        Content: null,
    },
]

export default () => {
    const history = useHistory() 
    const { downLoadUrlMap } = useSelector((state: StateType) => state)
    const { search } = history.location
    const { id } = parseSearch(search)
    const formRef = useRef<ProFormInstance>()
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const request = async(params: any) => {
        if (!id) {
            return {
                taskSelectCustomer: 0, 
                taskChargerType: 1, 
                taskFeedback: 1, 
                taskFinishTarget: 1, 
                taskStartTime: moment().format(format)
            }
        }
        const {data} = await infoApi(params)
        const { taskName, taskDesc, taskStartTime, taskEndTime, taskSelectCustomer, taskSelectCustomerConfig, taskChargers, taskFeedback, taskFeedbackConfig, taskFinishTarget } = data || {}
        return { 
            taskName, 
            taskDesc, 
            taskStartTime: moment(taskStartTime).format(format), 
            taskEndTime: moment(taskEndTime).format(format),  
            taskChargers: taskChargers.map((v: number) => `s${v}`), 
            taskFeedback, 
            taskFeedbackConfig: (taskFeedbackConfig||[]).map((v: any, i: number) => ({...v, id: v.value || `zqs-${i}`})), 
            taskFinishTarget,
            taskSelectCustomer: taskSelectCustomer + '',
            [taskSelectCustomer]: transParams(taskSelectCustomerConfig)
        }
    }
    const onFinish = async (values: any) => {
        const willSubmitParams = getSubmitParams(values)
        const { taskStartTime, taskEndTime, taskFeedbackConfig } = willSubmitParams || {}
        if(moment(taskStartTime) > moment(taskEndTime)) {
            return message.error('开始时间不能大于结束时间')
        }
        const { success } = await addUpdateApi({...willSubmitParams, id}, true, (data: any) => {
            history.goBack()
        })
        return success
    }
    return(<div className="oa-task-add" style={{background: '#fff', paddingBottom: 60}}>
        <BackBan title={`${id ? '编辑' : '新建'}任务`} />
        <ProFormWithCache<any> 
            layout="horizontal" 
            key={location.href}
            {...layout} 
            submitter={{render: (props: any, doms: any) => [
                <div className="jns-fix-submit"  key="submit">
                    <Button type="primary" style={{ marginLeft: 124 }} onClick={() => props.form?.submit?.()}>提交</Button>
                </div>
            ]}}
            formRef={formRef}
            params={{id}}
            request={request as any}
            scrollToFirstError={true}
            initialValues={{taskSelectCustomer: 1}}
            onFinish={onFinish}>
            <h3 style={{margin: '20px 0 10px 7%'}}>基本信息</h3>
            <ProFormText width="lg" name="taskName" label="任务标题" fieldProps={{showCount: true,  maxLength: 50}} rules={[{required: true, message: '请填写任务标题',}]} placeholder="请输入任务标题" />
            <ProFormTextArea width="lg" name="taskDesc" label="任务描述" fieldProps={{showCount: true,  maxLength: 300}} rules={[{ required: false,  }]} />
            <ProFormDatePicker width="lg" name="taskStartTime" label="开始时间" fieldProps={{showTime: true, format, disabledDate: disabledTaskEndTime}} rules={[{ required: true, message: '请选择开始时间'}]} />
            <ProFormDatePicker width="lg" name="taskEndTime" label="结束时间" fieldProps={{showTime: true, format, disabledDate: disabledTaskEndTime}} rules={[{ required: true, message: '请选择结束时间'}]} />
            <h3 style={{margin: '20px 0 10px 7%'}}>任务规则</h3>
            <Form.Item name="taskSelectCustomer" label="选择客户" getValueProps={(defaultActiveKey) => ({defaultActiveKey})} rules={[{required: true, message: '请选择客户',}]} tooltip={<TaskSelectCustomerTooltip />} style={{marginBottom: 0}}>
                <MyTabs type="card" tabPanes={taskSelectCustomerTabPanes}  style={{margin: 0, padding: '0 12px'}} tabBarStyle={{marginBottom: 0}}  />
            </Form.Item>
            <ProFormDependency name={['taskSelectCustomer']}>
                {({taskSelectCustomer}) => {
                    const { seachConfig } = (taskSelectCustomerTabPanes?.find((v: any, i: number) => (v.key||i) == taskSelectCustomer) || {}) as any
                    return (<Form.Item wrapperCol={{span: 24}} labelCol={{span: 3}} label=" " colon={false} style={{padding: '0 16px'}} className="jns-search-warp">
                        { seachConfig ? 
                          <Fields config={seachConfig} style={{background: '#FCFCFC', paddingTop: 16}} /> :
                          <ProFormUploadDragger 
                            max={1} 
                            name={[3, 'file']}
                            fieldProps={{name: 'file', headers: {Authorization: getToken()}}}
                            action={`${BASEURL}/upload`} 
                            description=""
                            extra={<a download href={downLoadUrlMap["任务中心-新建任务-批量导入"]}>下载Excel模板</a>}
                          /> 
                        }
                    </Form.Item>)
                }}
            </ProFormDependency>
            <ProFormRadio.Group width="xl" name="taskChargerType" label="负责人" options={taskChargerTypeOptions} rules={[{ required: true, message: '请选择负责人'}]} />
            <ProFormDependency name={['taskChargerType']}>
                {({taskChargerType}) => {
                    return taskChargerType == 2 ? 
                       (<ProFormTreeSelect 
                           width="lg" 
                           name="taskChargers" 
                           label="选择负责人" 
                           fieldProps={{
                               treeData: deptWithUserTreeData, 
                               treeCheckable: true, 
                               showSearch: true, 
                               treeNodeFilterProp: 'title', 
                               style: {width: 440}}} 
                               rules={[{ required: true, message: '请选择负责人',}]} 
                               placeholder="请选择" 
                        />) : null;
                }}
            </ProFormDependency>

            {/* <ProFormRadio.Group width="xl" name="taskFeedback" label=" 配置跟进反馈" options={taskFeedbackOptions} tooltip={<TaskFeedbackTooptip />} rules={[{ required: true, message: '请选择任务完成指标'}]} /> */}
            <ProFormDependency name={['taskFeedback']}>
                {({taskFeedback}) => {
                    return taskFeedback === 2 ? (<Form.Item name="taskFeedbackConfig" wrapperCol={{span: 24}} labelCol={labelCol} label=" " colon={false}>
                        <FeedbackConfigEditable className="no-padding-pro-card-body" />
                    </Form.Item>) : null;
                }}
            </ProFormDependency>

            <ProFormRadio.Group width="xl" name="taskFinishTarget" label="任务完成指标" layout="vertical" tooltip={<TaskFinishTargetTooltip />} options={taskTargetOptions} rules={[{ required: true, message: '请选择任务完成指标'}]} />
        </ProFormWithCache>
    </div>)
}