import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import ProForm, { ModalForm, ProFormDigit, ProFormText, ProFormRadio } from '@ant-design/pro-form';
import { Button, Modal, TreeSelect } from "antd";
import { ProFormTreeSelect } from '@components/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { treeDataDisabledRules, productApi, treeDataToFlatArr } from "@utils/index";
import { sysPoolList, sysPoolAdd, getDetailst, sysPoolDel, sysPoolUpdate } from '@api/index'
import { useDeptDataHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
const { SHOW_CHILD } = TreeSelect
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(sysPoolList)
const addApi = productApi(sysPoolAdd)
const delApi = productApi(sysPoolDel)
const updateApi = productApi(sysPoolUpdate)
const getInfoApi = productApi(getDetailst)

interface AddCluesPoolProps extends ModalFormProps{
    id?: string ;
    disabledFields?: string[];
    onSuccess?: () => void;
}
const AddCluesPool = ({id, disabledFields, onSuccess, ...rest}: AddCluesPoolProps) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const formRef = useRef<ProFormInstance>()
    const [adminList, setAdminList] = useState<any[]>([])
    // 获取组织架构
    const [ treeData ] = useDeptDataHooks({}, true)
     // 获取组织架构
    const userTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => false)
    // 获取选的部门下的children list放到成员上
    const getUserList = (id: any, data: any = userTreeData) =>{
        const allArr = treeDataToFlatArr(data)
        const target = allArr.find(v => v.id === `d${id}`)
        const {children} = target || {}
        setAdminList(target ? [target] : [])
        return target
    }
    const onValuesChange = (values: any) => {
        if ('departmentId' in values) {
            const { departmentId } = values
            const { principalUserId } = getUserList(departmentId) || {}
            formRef.current?.setFieldsValue({
                memberIds: [],
                managerIds: principalUserId ? [`s${principalUserId}`] : undefined
            })
            
        }
    }
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            id && request({id})
        }
    }
    const request = async (param: any) => {
        const { data } = await getInfoApi(param, false)
        const { clueCollectionLimit, cluePoolName, cluePoolStatus, dept: departmentId, managers, maximumCapacity, members } = data || {}
        getUserList(departmentId)
        const managerIds = (managers||[]).map((v: string) => `s${v}`)
        const memberIds = (members||[]).map((v: string) => `s${v}`)
        formRef.current?.setFieldsValue({
            clueCollectionLimit,
            cluePoolName, 
            cluePoolStatus, 
            departmentId, 
            id, 
            managerIds, 
            maximumCapacity, 
            memberIds
        })
    }
    const onFinish = async (values: any) => {
        const whichApi = id ? updateApi : addApi
        const { managerIds: mIds, memberIds: nIds } = values
        const managerIds = mIds.map((v: string) => v.slice(1))
        const memberIds = nIds.map((v: string) => v.slice(1))
        const { success } = await whichApi({...values,managerIds, memberIds, id}, true, onSuccess)
        return success
    }

    return (<ModalForm<AddCluesPoolProps> modalProps={{maskClosable: false}} layout="horizontal" width={500} labelCol={{span: 5}} formRef={formRef} onVisibleChange={onVisibleChange} onValuesChange={onValuesChange} onFinish={onFinish} {...rest}>
    <ProFormText name="cluePoolName" disabled={disabledFields?.includes('cluePoolName')} fieldProps={{ maxLength: 30 }} label="线索池名称" rules={[{required: true, message: '请输入线索池名称'}]} />
    <ProFormTreeSelect name="departmentId" disabled={disabledFields?.includes('departmentId')} label="所属部门" fieldProps={{treeData}} rules={[{required: true, message: '请选择所属部门'}]} />
    <ProFormTreeSelect name="managerIds" label="管理员" fieldProps={{treeData:userTreeData, showCheckedStrategy: SHOW_CHILD, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title'}} rules={[{required: true, message: '请选择管理员'}]} />
    <ProFormTreeSelect name="memberIds" label="成员"  fieldProps={{treeData:adminList, showCheckedStrategy: SHOW_CHILD, maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title'}}  rules={[{required: true, message: '请选择成员'}]} />
    <ProFormDigit name="maximumCapacity" label="最大容量"  initialValue={9999} max={99999999} rules={[{required: true, message: '请填写最大容量'}, {max: 999999999, type: 'number', message: '最大不超过999999999'}]} />
    <ProFormDigit name="clueCollectionLimit" label="领取上限" initialValue={1000} max={99999999} rules={[{required: true, message: '请填写领取上限'}, {max: 999999999, type: 'number', message: '最大不超过999999999'}]} />
    <ProFormRadio.Group name="cluePoolStatus" label="状态" initialValue={1} options={[{label: '启用', value: 1}, {label: '禁用', value: 0}]} rules={[{required: true, message: '请选择性别'}]} />
</ModalForm>)
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const [addPoolProps, setAddPoolProps] = useState<AddCluesPoolProps>({title: '新增角色', visible: false, id: undefined, onSuccess: undefined })
    const ref = useRef<ActionType>(null);
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id, isDefault } = record || {}
        if(['新建线索池'].includes(type)) {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id,
                disabledFields: [],
                onSuccess: () => {
                    ref.current?.reload()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
        } else if (type === '编辑') {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id,
                disabledFields: isDefault ? ['cluePoolName', 'departmentId'] : [],
                onSuccess: () => {
                    ref.current?.reload()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
          
        } else if (type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({poolId: id}, true, () => {
                        ref.current?.reload()
                    })
                },
            });
        }
    }
    const columns = columnsFn(operate)

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                scroll={{x: 1300}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2164) ? <Button key="primary" type="primary" onClick={() => operate('新建线索池')}>新建线索池</Button> : null,
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
                // rowSelection={{}}
            />
        </div>
        <AddCluesPool {...addPoolProps} modalProps={{onCancel: () => setAddPoolProps({...addPoolProps, visible:false}), maskClosable: false }} />
    </div>
}
