import { ProColumns } from "@ant-design/pro-table";
import { knowledgeTree } from "@api/knowledge";
import { Ellipsis } from "@components/index";
import { calcTitle } from "@components/KnowledgeCommunity/cpns/MyDoc/Create";
import { calcHref } from "@components/KnowledgeCommunity/Home/data";
import { useDocDataHooks } from "@hooks/index";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/util";
import { TreeSelect } from "antd";
import { stringify } from "qs";
import React from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export type TableListItem = {
    key: number;
    id: string;
    customerAccount: number,
    getMoneyType: string,
    status: string,
    getMoneyerType: string,
    getMoneyer: string,
    bank: string,
    bankAccount: string,
    alipayAccount: number,
    files: object,
    remark: string,
    changeRates: any,
};
  
export type OperateActionType = '新建返点比例' | '新建收款人' | '编辑返点比例' | '编辑收款人'

// 解决方案
export const solutionColumns: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    // const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: 1}, true)
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
        },
        {
            title: '文档分类',
            dataIndex: 'categoryIds',
            key: 'categoryIds',
            order: 6,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              placeholder: '请选择'
            },
            valueType: 'treeSelect',
            params: {type: 1},
            async request(param){
                const { data } = await knowledgeTree(param) as any
                transferTreeData(data)
                return data||[]
            },
            search: {
                transform: (categoryIds) => {
                    return {categoryIds};
                },
            },
            render: (text: any, record: any) => record.categoryNameStr
        },
        {
            title: '相关标签',
            dataIndex: 'labelNameStr',
            order: 4,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            valueType: 'treeSelect',
            search: {
                transform: (labelIds) => {
                    return {labelIds};
                },
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={(record.labelNameStr||[]).toString()||'-'} />
        },
        {
            title: '文档类型',
            dataIndex: 'typeNameStr',
            key: 'typeNameStr',
            hideInSearch: true,
        },
        {
            title: '发布人',
            dataIndex: 'username',
            key: 'username',
            hideInSearch: true,
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            search: {
                transform: (value) => {
                    return {
                        from: value[0],
                        to: value[1],
                    };
                },
            },
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
    ]
}

// 客户案例
export const customerCaseColumns: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
        },
        {
            title: '文档分类',
            dataIndex: 'categoryIds',
            key: 'categoryIds',
            order: 6,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              placeholder: '请选择'
            //   treeData: docTreeData
            },
            valueType: 'treeSelect',
            params: {type: 2},
            async request(param){
                const { data } = await knowledgeTree(param) as any
                transferTreeData(data)
                return data||[]
            },
            search: {
                transform: (categoryIds) => {
                    return {categoryIds};
                },
            },
            render: (text: any, record: any) => record.categoryNameStr
        },
        {
            title: '相关标签',
            dataIndex: 'labelNameStr',
            order: 4,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            search: {
                transform: (labelIds) => {
                    return {labelIds};
                },
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={(record.labelNameStr||[]).toString()||'-'} />
        },
        {
            title: '文档类型',
            dataIndex: 'typeNameStr',
            key: 'typeNameStr',
            hideInSearch: true,
        },
        {
            title: '发布人',
            dataIndex: 'username',
            key: 'username',
            hideInSearch: true,
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            search: {
                transform: (value) => {
                    return {
                        from: value[0],
                        to: value[1],
                    };
                },
            },
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
    ]
}

// 问卷模版
export const qsTemplateColumns: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    // const [ docTreeData, setDocTreeData, getTreeData] = useDocDataHooks({type: 3}, true)
    return [
        {
            title: '问卷标题',
            dataIndex: 'title',
            key: 'title',
            render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
        },
        {
            title: '文档分类',
            dataIndex: 'categoryIds',
            key: 'categoryIds',
            order: 6,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              placeholder: '请选择'
            },
            valueType: 'treeSelect',
            params: {type: 3},
            async request(param){
                const { data } = await knowledgeTree(param) as any
                transferTreeData(data)
                return data||[]
            },
            search: {
                transform: (categoryIds) => {
                    return {categoryIds};
                },
            },
            render: (text: any, record: any) => record.categoryNameStr
        },
        {
            title: '相关标签',
            dataIndex: 'labelNameStr',
            order: 4,
            valueType: 'treeSelect',
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            search: {
                transform: (labelIds) => {
                    return {labelIds};
                },
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={(record.labelNameStr||[]).toString()||'-'} />
        },
        {
            title: '文档类型',
            dataIndex: 'typeNameStr',
            key: 'typeNameStr',
            hideInSearch: true,
        },
        {
            title: '发布人',
            dataIndex: 'username',
            key: 'username',
            hideInSearch: true,
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            search: {
                transform: (value) => {
                    return {
                        from: value[0],
                        to: value[1],
                    };
                },
            },
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 180,
            hideInSearch: true,
            fixed: 'right',
            render: (text: any, record: any) => {
              return [
                <a key="a" href={`/knowledge/create?${stringify({type: 3.1, docId: record.id, routeName: calcTitle('3.1', '')})}`}>填写问卷</a>
              ]
            },
        },
    ]
}

// 调查问卷
export const surveyColumns: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '问卷标题',
            dataIndex: 'title',
            key: 'title',
            render: (text: any, record: any) => <Link to={calcHref(record)}>{record.title}</Link>
        },
        {
            title: '文档分类',
            dataIndex: 'categoryIds',
            key: 'categoryIds',
            order: 6,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              placeholder: '请选择'
            },
            valueType: 'treeSelect',
            params: {type: 3},
            async request(param){
                const { data } = await knowledgeTree(param) as any
                transferTreeData(data)
                return data||[]
            },
            search: {
                transform: (categoryIds) => {
                    return {categoryIds};
                },
            },
            render: (text: any, record: any) => record.categoryNameStr
        },
        {
            title: '相关标签',
            dataIndex: 'labelNameStr',
            order: 4,
            fieldProps: {
                maxTagCount: 4,
                treeCheckable: true,
                showSearch: true,
                treeNodeFilterProp: 'title',
                treeData: labelTreeData,
                placeholder: '请选择'
            },
            search: {
                transform: (labelIds) => {
                    return {labelIds};
                },
            },
            renderFormItem: (props) => <TreeSelect />,
            render: (text, record: any) => <Ellipsis text={(record.labelNameStr||[]).toString()||'-'} />
        },
        {
            title: '文档类型',
            dataIndex: 'typeNameStr',
            key: 'typeNameStr',
            hideInSearch: true,
        },
        {
            title: '发布人',
            dataIndex: 'username',
            key: 'username',
            hideInSearch: true,
        },
        {
            title: '发布时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            search: {
                transform: (value) => {
                    return {
                        from: value[0],
                        to: value[1],
                    };
                },
            },
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 180,
            hideInSearch: true,
            fixed: 'right',
            render: (text: any, record: any) => {
              return [
                <a key="a" onClick={() => operate && operate('编辑问卷', record)}>编辑问卷</a>
              ]
            },
        },
    ]
}