import { useState } from "react"
import { Modal } from "antd"
import { OperateActionType } from "../../data"
import { RebackAuditModalFormProps } from "."
import { rebackAuditOperate } from "@api/index"
import { productApi } from "@utils/index"
import { stringify } from "qs"
import { useHistory } from "react-router-dom"

const operateApi = productApi(rebackAuditOperate)

export const useRebackAuditHooks = () => {
    const history = useHistory()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [rebackAuditModalFormProps, setRebackAuditModalFormProps] = useState<RebackAuditModalFormProps>({title: '审批通过', visible: false, params: {}})
    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { backId } = record || {}

        const backIds = backId ? [backId] : selectedRowKeys
        // 审批通过
        const passAction = (props: Object = {}) => setRebackAuditModalFormProps({
            ...rebackAuditModalFormProps,
            title: '审批通过',
            visible: true,
            params: {},
            onFinish: async (values: any) => {
                await operateApi({...values, backIds, status: -1}, false, () => {
                    setRebackAuditModalFormProps({visible: false})
                    onSuccess?.()
                })
            },
        })

         // 审批拒绝
         const unPassAction = (props: Object = {}) => setRebackAuditModalFormProps({
            ...rebackAuditModalFormProps,
            title: '审批拒绝',
            visible: true,
            params: {},
            onFinish: async (values: any) => {
                await operateApi({...values, backIds, status: -2}, false, () => {
                    setRebackAuditModalFormProps({visible: false})
                    onSuccess?.()
                })
            },
        })

        // 
        const toRebackDetail = () => history.push(`/finance/rebackDetail?${stringify({backId, fromPath: '/finance/rebackMoneyAudit'})}`)

        return {
            passAction,
            unPassAction,
            toRebackDetail
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            passAction,
            unPassAction,
            toRebackDetail
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '回款记录-审批拒绝':
                    return unPassAction()
                case '回款记录-审批通过':
                    return passAction()
                case '回款记录-详情':
                    return toRebackDetail()
            }
        }
    }

    return {
        rebackAuditModalFormProps,
        setRebackAuditModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    }

}
