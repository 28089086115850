import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn } from './data'
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormDigit, ProFormRadio, ProFormCascader } from '@ant-design/pro-form';
import { Button, Modal } from "antd";
import {  productApi } from "@utils/index";
import { customerAddRebatesRule, customerUpdateRebatesRule, customerDelRebatesRule, customerGetRebatesRule } from '@api/customer'
import { useHasAuthCode, dimensionList } from "@hooks/index";
import type { OperateActionType, TableListItem } from './data'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 


// import { sysDictListRight } from "@api/";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(customerGetRebatesRule)
const addApi = productApi(customerAddRebatesRule)
const delApi = productApi(customerDelRebatesRule)
const updateApi = productApi(customerUpdateRebatesRule)

interface AddRuleProps extends ModalFormProps{
    id?: string ;
    disabledFields?: string[];
    record?: any;
    onSuccess?: () => void;
}
const AddUpdataRule = ({id, record, disabledFields, onSuccess, ...rest}: AddRuleProps) => {
    const [data, ,] = dimensionList()
    const formRef = useRef<ProFormInstance>()
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            if (id) {
                const {mainDimension, sonDimension, maxRebatePoint, status} = record
                formRef.current?.setFieldsValue({
                    mainDimension: [mainDimension, sonDimension],
                    maxRebatePoint,
                    status
                })
            }
        }
    }
    const onFinish = async (values: any) => {
        const { mainDimension } = values
        const [ main, son ] = mainDimension ? mainDimension : ['', '']
        const api = id ? updateApi : addApi
        const { success } = await api({...values, mainDimension: main, sonDimension: son, id}, true, onSuccess)
        return success
    }
    
    return (<ModalForm<AddRuleProps> modalProps={{maskClosable: false}} layout="horizontal" width={500} labelCol={{span: 5}} formRef={formRef} onVisibleChange={onVisibleChange} onFinish={onFinish} {...rest}>
                <ProFormCascader name="mainDimension" label="维度" fieldProps={{options:data, showSearch: true}} rules={[{required: true, message: '请选择维度'}]} />
                <ProFormDigit name="maxRebatePoint" label="返点上限" rules={[{required: true, message: '返点上限'}]} />
                <ProFormRadio.Group name="status" label="状态" initialValue={1} options={[{label: '启用', value: 1}, {label: '禁用', value: 0}]} rules={[{required: true, message: '请选择状态'}]} />
            </ModalForm>)
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [addPoolProps, setAddPoolProps] = useState<AddRuleProps>({title: '新建规则', visible: false, id: undefined, onSuccess: undefined, record: {} })
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { mainDimension, estimatedSalesAmounts } = params || []
        const [ main, min] = mainDimension ? mainDimension : [undefined, undefined]
        const [ minRebatePoint  , maxRebatePoint ] = estimatedSalesAmounts ? estimatedSalesAmounts : [undefined, undefined]
        const { data } = await getApi({...params, sonDimension: min , mainDimension: main,minRebatePoint, maxRebatePoint }, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id } = record || {}
        if(['新建规则'].includes(type)) {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id: undefined,
                disabledFields: [],
                onSuccess: () => {
                    reFush()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
        } else if (type === '编辑') {
            setAddPoolProps({
                ...addPoolProps,
                title: type,
                visible: true,
                id,
                record,
                disabledFields: [],
                onSuccess: () => {
                    reFush()
                    setAddPoolProps({...addPoolProps, visible:false})
                }
            })
        } else if (type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi([...selectedRowKeys], true, () => {
                        reFush()
                        ref.current?.reload()
                    })
                },
            });
        }
    }
    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const columns = columnsFn(operate)
    
    return <div>
        <div style={{boxSizing: 'border-box'}}>
          
            <ProTable<TableListItem>
                // scroll={{x: 1300}}
                scroll={{x: 'max-content'}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2279) ? <Button key="primary" type="primary" onClick={() => operate('新建规则')}>新建规则</Button>: null,
                        hasAuthCode(2280) ? <Button key="primary" disabled={selectedRowKeys.length === 0} type="primary" onClick={() => operate('删除')}>删除</Button>: null
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                }}
            />
        </div>
        <AddUpdataRule {...addPoolProps} modalProps={{onCancel: () => setAddPoolProps({...addPoolProps, visible:false}), maskClosable: false }} />
    </div>
}
