import { request } from '@utils/index'
import {stringify} from 'qs';
import { BASEURL, LQBASEURL } from './baseUrl'
/*-------------------------------------猎奇引擎接口-----------------------------------*/

export const lqGetHeaderInfoByCompanyName = (data?: any) => request({
    url: `${BASEURL}/getHeaderInfoByCompanyName`,
    method: 'GET',
    data,
})

export const lqGetCorpInfo = (data?: any) => request({
    url: `${LQBASEURL}/getCorpInfoByLqId`,
    method: 'GET',
    data,
})

export const lqGetCloudInfoByCompanyName = (data?: any) => request({
    url: `${BASEURL}/getCloudInfoByCompanyName`,
    method: 'GET',
    data,
})

export const lqGetContactInfoByCompanyName = (data?: any) => request({
    url: `${BASEURL}/getContactInfoByCompanyName`,
    method: 'GET',
    data,
})

// 猎奇数据转客户
export const lqCompanyToCustomer = (data?: any) => request({
    url: `${LQBASEURL}/transferLieqiCompanyToCustomer`,
    data,
})

// 猎奇领取线索到聚能石
export const lqGetClueToJns = (data?: any) => request({
    url: `${LQBASEURL}/common/receiveClue`,
    data,
})

// 猎奇查询筛选条件枚举
export const lqSearchConditions = (data?: any) => request({
    url: `${LQBASEURL}/enterpriseSearch/condition`,
    data,
})
  
// 猎奇联想查询
export const lqFuzzySearch = (data?: any) => request({
    url: `${LQBASEURL}/enterpriseSearch/enterpriseFuzzySearch`,
    data,
})

// 猎奇规则-新增/修改
export const lqRuleAddUpdate = (data?: any) => request({
    url: `${LQBASEURL}/lqRule/newOrEdit`,
    data,
})

// 猎奇规则-查询
export const lqRuleQuery = (data?: any) => request({
    url: `${LQBASEURL}/lqRule/query`,
    data,
})

//猎奇规则-删除
export const lqRuleDel = (data?: any) => request({
    url: `${LQBASEURL}/lqRule/delete`,
    data,
})

// 猎奇-根据规则Id查看规则详情
export const lqRuleInfoById = (data?: any) => request({
    url: `${LQBASEURL}/lqRule/selectRuleDetailByRuleId`,
    data,
})


// 猎奇-智能推荐
export const lqGetRecommendData = (data?: any) => request({
    url: `${LQBASEURL}/getRecommendedCompaniesByPage`,
    data,
})

// 猎奇-批量查询上传条件
export const lqImportSearchCondition = (data?: any) => request({
    url: `${LQBASEURL}/importAndSearch`,
    data,
})

// 猎奇-批量查询查询记录
export const lqImportQueryHistory = (data?: any) => request({
    url: `${LQBASEURL}/getBatchQueryList`,
    data,
})

// 猎奇-更新信息
export const lqGetNewestInfo = (data?: any) => request({
    url: `${LQBASEURL}/rescrape`,
    data,
})

// 猎奇-领客户到线索池
export const lqGetClueToPool = (data?: any) => request({
    url: `${LQBASEURL}/common/receiveCLueToPool`,
    // method: 'GET',
    data,
})

// 猎奇-查看操作记录
export const lqOperateLogs = (data?: any) => request({
    url: `${LQBASEURL}/lqOperateLog/queryByPage`,
    data,
})

// 猎奇-企业查询
export const lqSearch = (data?: any) => request({
    url: `${LQBASEURL}/enterpriseSearch/serach`,
    data,
})

// 猎奇-自定义更新简介
export const lqUpdateDesc = (data?: any) => request({
    url: `${LQBASEURL}/updateCustomIntroByCompanyName`,
    data,
})
