import React, {ReactNode, useMemo} from "react";
import { Button, Table, Tag, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Icon, Ellipsis } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { stringify } from 'qs'
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

// ts--types
export type TableListItem = {
    key: number;
    id: number;
    customerAccount: string;
    consumeAmount: number;
    proceedsMessageRespList: any[];
    state: number;
    lqId: number;
    isAttention: number;
    hasBusiness: number;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
    isSyn: string;
} | Record<string, any>;

export const viewOptions = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '我的客户',
    value: '1'
  },
  {
    label: '下属客户',
    value: '2'
  }
]

export const scopeOptions = [
    {
        label: '当月返点',
        value: '1'
    },
    {
        label: '全部返点',
        value: '2'
    }
]

export const rebateListItem = (n: number = 1) => new Array(n).fill({
    month: '2022-12-02',
    customerName: 'AAAA',
    customerAccount: '35467346',
    customerOwner: '马云',
    cooperationMode: '代理',
    hasPaper: '有',
    consumeAmount: -1000.00,
    expectRebateAmount: 500.00,
    realRebateAmount: 500.00,
    remark: '673gjfgjdf',
    // state: Math.floor((Math.random()*10)),
    state: 3,
    type: '正常返点'
}).map((v, idx) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))

export const rebateStateEnum = {
    0: { text: '待认领' },
    1: { text: '倒挂'},
    2: { text: '待确认'},
    3: { text: '待复核'},
    4: { text: '不打款'},
    5: { text: '待打款'},
    6: { text: '部分打款'},
    7: { text: '全部打款'},
    8: { text: '追回待审核'},
    9: { text: '认领待审核'},
}

export const columnsFn: (scope: string, operate?: Function) => ProColumns<TableListItem>[] = (scope, operate) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({}, false)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '消费月份',
        dataIndex: 'month',
        // hideInSearch: scope === '1',
        fixed: 'left',
        width: 100,
        order: 5,
        valueType: 'dateRange',
        fieldProps:(form: any, record: any) => {
            return {
                picker: 'month',
                format: 'YYYY-MM',
                // onOpenChange: (open: any) => {
                // },
                // disabledDate: (current: any) => {
                //     const dates = form.getFieldValue('month')
                //     if (!dates || dates.length === 0) {
                //         return false;
                //     }
                //     const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
                //     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
                //     return tooEarly || tooLate;
                // }
            }
        },
        align: 'center',
        render: (text: any, record: any) => <a onClick={() => operate && operate('详情', record)}>{record.month}</a>
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        // fixed: 'left',
        width: 180,
        order: 1,
    },
    {
        title: '客户账号',
        dataIndex: 'customerAccount',
        order: 100,
    },
    {
        title: '客户经理',
        dataIndex: 'customerOwner',
        order: 10,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: deptWithUserTreeData
        },
        search: {
            transform: (ids) => {
              const customerOwner = (ids||[]).map((v: string) => v.slice(1))
              return { customerOwner }
            },
        },
        renderFormItem: (props) => <TreeSelect />,
    },
    {
        title: '合作模式',
        dataIndex: 'cooperationMode',
        order: 3,
        width: 120,
        valueType: 'select',
        params: {dictId: 25 },
        request,
    },
    {
        title: '有无票据',
        dataIndex: 'hasPaper',
        order: 2,
        valueEnum: {
            0: { text: '无' },
            1: { text: '有'},
        },
    },
    {
        title: '消费金额',
        dataIndex: 'consumeAmount',
        hideInSearch: true,
    },

    {
        title: '预计返点金额',
        dataIndex: 'expectRebateAmount',
        hideInSearch: true,
    },
    {
        title: '实际返点金额',
        dataIndex: 'realRebateAmount',
        hideInSearch: true,
    },
    {
        title: '确认信息备注',
        dataIndex: 'remark',
        hideInSearch: true,
        render: (text, record: any) => <Ellipsis text={record.remark||'-'} len={20} />
    },
    {
        title: '返点状态',
        dataIndex: 'state',
        valueType: 'select',
        order: 9,
        fieldProps: { mode: 'multiple' },
        valueEnum: rebateStateEnum,
    },
    {
        title: '返点类型',
        dataIndex: 'type',
        order: 4,
        valueEnum: {
            1: { text: '追加返点' },
            0: { text: '正常返点'},
        },
    },
    {
        title: '支付宝账号',
        dataIndex: 'aliPayAccount',
        hideInTable: true,
        order: 6,
    },
    {
        title: '银行卡账号',
        dataIndex: 'bankAccount',
        hideInTable: true,
        order: 7,
    },
    {
        title: '收款人',
        dataIndex: 'getMoneyer',
        hideInTable: true,
        order: 8,
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text, record: any) => [
            (hasAuthCode(2254) && [0].includes((record||{}).state)) ? <Button key="a" type="link" disabled={record.orderStatus} onClick={() => operate && operate('认领', record)}>认领</Button> : null,
            (hasAuthCode(2276) && [0].includes((record||{}).state) && record.whetherAudit) ? <a key="k" onClick={() => operate && operate('审核详情-认领', record)}>审核详情(认领)</a> : null,

            (hasAuthCode(2256) && [1].includes((record||{}).state)) ? <a key="b" onClick={() => operate && operate('追回', record)}>追回</a> : null,
            (hasAuthCode(2277) && [1].includes((record||{}).state) && record.whetherAudit) ? <a key="l" onClick={() => operate && operate('审核详情-追回', record)}>审核详情(追回)</a> : null,

            (hasAuthCode(2258) && [2].includes((record||{}).state)) ? <a key="c" onClick={() => operate && operate('确认信息', record)}>确认信息</a> : null,

            (hasAuthCode(2259) && [3].includes((record||{}).state)) ? <a key="d" onClick={() => operate && operate('复核信息', record)}>复核信息</a> : null,
            (hasAuthCode(2262) && [3].includes((record||{}).state)) ? <Button type="link" key="e" disabled={record.orderStatus} onClick={() => operate && operate('撤销', record)}>撤销</Button> : null,

            (hasAuthCode(2267) && [4, 7].includes((record||{}).state)) ? <Button type="link" key="m" disabled={record.orderStatus} onClick={() => operate && operate('追加返点', record)}>追加返点</Button> : null,

            (hasAuthCode(2265) && [5, 6].includes((record||{}).state)) ? <a key="f" onClick={() => operate && operate('确认打款', record)}>确认打款</a> : null,

            (hasAuthCode(2266) && [6].includes((record||{}).state)) ? <a key="g" onClick={() => operate && operate('修改信息', record)}>修改信息</a> : null,

            (hasAuthCode(2257) && [8].includes((record||{}).state)) ? <a key="h" onClick={() => operate && operate('审核-追回', record)}>审核(追回)</a> : null,
            (hasAuthCode(2255) && [9].includes((record||{}).state)) ? <a key="i" onClick={() => operate && operate('审核-认领', record)}>审核(认领)</a> : null,
            // [0].includes((record||{}).state) ? <a key="j" onClick={() => operate && operate('详情', record)}>详情</a> : null,
        ],
    },
  ]
}

export type DataSourcePayerType = {
    id: number;
}

export const mockPayerItem = (n: number = 1) => new Array(n).fill({
    getMoneyer: '章三',
    bankAccount: '56364845455',
    bank: '招商银行',
    aliPayAccount: '6746785',
    consumeAmount: 1000.00,
    expectRebateAmount: 500.00,
    realRebateAmount: 500.00,
    willPayAmount: 454.00,
    payResult: '0',
    payAmount: 345,
    remark: '673gjfgjdf',
}).map((v, idx) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))

// 确认打款可编辑表格Columns
export const payerColumnsFn: () => ProColumns<DataSourcePayerType>[] = () => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: <span>收款人</span>,
            dataIndex: 'getMoneyer',
            editable: false,
            width: 80,
        },
        {
            title: <span>银行卡账号</span>,
            key: 'bankAccount',
            editable: false,
            dataIndex: 'bankAccount',
        },
        {
            title: <span>开户行</span>,
            key: 'bank',
            editable: false,
            dataIndex: 'bank',
        },
        {
            title: <span>支付宝账号</span>,
            key: 'aliPayAccount',
            editable: false,
            dataIndex: 'aliPayAccount',
        },
        {
            title: <span>实际返点金额</span>,
            key: 'realRebateAmount',
            editable: false,
            dataIndex: 'realRebateAmount',
        },
        {
            title: <span>待打款金额</span>,
            key: 'willPayAmount',
            editable: false,
            dataIndex: 'willPayAmount',
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>打款结果</span>,
            key: 'payResult',
            dataIndex: 'payResult',
            fieldProps: {style: {width: '100px'}, options: [{label: '打款成功', value: 1}, {label: '打款失败', value: 0}]},
            valueType: 'select',
            formItemProps: {
                initialValue: 1,
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    },
                ],
            },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>打款金额</span>,
            key: 'payAmount',
            dataIndex: 'payAmount',
            valueType: 'digit',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    }
                ],
            },
            fieldProps: (_: any, {entry}: any) => {
                const {payResult, payAmount, willPayAmount} = entry || {}
                return {
                    max: willPayAmount,
                    disabled: payResult+'' === '0',
                }
            },
        },
        {
            title: <span>打款备注</span>,
            key: 'remark',
            dataIndex: 'remark',
            formItemProps:(_, {entry}: any) => {
                return {
                    rules: [
                        {
                            required: !entry.payResult,
                            message: '此项是必填项',
                        }
                    ],
                }
            }
        },
    ];
}

export const editPayerColumnsFn: () => ProColumns<DataSourcePayerType>[] = () => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: <span>收款人</span>,
            dataIndex: 'getMoneyer',
        },
        {
            title: <span>银行卡账号</span>,
            key: 'bankAccount',
            dataIndex: 'bankAccount',
        },
        {
            title: <span>开户行</span>,
            key: 'bank',
            dataIndex: 'bank',
        },
        {
            title: <span>支付宝账号</span>,
            key: 'aliPayAccount',
            dataIndex: 'aliPayAccount',
        },
    ];
    
}

export const sonTableColumnsFn1: () => any[] = () => {
    return [
        {
            title: '维度子类',
            dataIndex: 'sonDimension',
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
        },
        {
            title: '抵扣退款金额',
            dataIndex: 'deductAmount',
        },
        {
            title: '返点比例',
            dataIndex: 'rebatePoint',
            render: (text: any, record: any) => `${record.rebatePoint}%`
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
        },
    ]
}

export const sonTableColumnsFn2: () => any[] = () => {
    return [
        {
            title: '收款人类型',
            dataIndex: 'getMoneyerType',
        },
        {
            title: '收款人',
            dataIndex: 'getMoneyer',
        },
        {
            title: '银行卡账号',
            dataIndex: 'bankAccount',
        },
        {
            title: '开户行',
            dataIndex: 'bank',
        },
        {
            title: '支付宝账号',
            dataIndex: 'aliPayAccount',
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
        },
        {
            title: '实际返点金额',
            dataIndex: 'realRebateAmount',
        },
        {
            title: '待打款金额',
            dataIndex: 'willPayAmount',
        },
        {
            title: '打款负责人',
            dataIndex: 'payer',
        },
        {
            title: '打款结果',
            dataIndex: 'payResult',
            render: (text: any, record: any) => record.payResult === 1 ? '打款成功' : ( record.payResult === 0 ? '打款失败' : '-')
        },
        {
            title: '打款备注',
            dataIndex: 'remark',
        },
    ]
}

export const ReviewRebateInfoColumns: (operate: Function) => ProColumns<any>[] = (operate) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: '子维度',
            dataIndex: 'sonDimension',
            editable: false,
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
            editable: false
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>抵扣退款金额</span>,
            dataIndex: 'freeConsumeAmount',
            valueType: 'digit',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
        {
            title: '有无返点',
            dataIndex: 'isRebate',
            editable: false,
            // render: () => <Select  />
            valueEnum: {
                0: { text: '无' },
                1: { text: '有'},
            },
        },
        {
            title: '返点比例',
            dataIndex: 'rebatePoint',
            editable: false,
            render: (text: any) => `${text}%`
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
            editable: false
        },
        // {
        //     title: '实际返点金额',
        //     dataIndex: 'realRebateAmount',
        //     editable: false
        // },
    ]
}

export const ReviewGetMoneyColumns: (operate: Function) => ProColumns<any>[] = (operate) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: <span><i style={{color: 'red'}}>*</i>收款人</span>,
            dataIndex: 'getMoneyer',
            editable: false,
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>收款账号</span>,
            dataIndex: 'getMoneyerAccount',
            width: 300,
            editable: false,
            render: (text: any, {bank, bankAccount, aliPayAccount}: any) => (<div>{bank}: {bankAccount}<br/>支付宝: {aliPayAccount}</div>)
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>预计返点金额</span>,
            dataIndex: 'expectRebateAmount',
            valueType: 'digit',
            editable: false,
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>实际返点金额</span>,
            dataIndex: 'realRebateAmount',
            valueType: 'digit',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
    ]
}

export const resultOptions = [
    {
        label: '通过',
        value: 1
    }, 
    {
        label: '不通过',
        value: 0
    }
]