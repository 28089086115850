import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, contractSearch } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import { AddPurchaseContractModalForm } from './cpnts'
import {OperateActionType, TableListItem, tabPanes, viewOptions} from '../../data'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { usePurchaseContractHooks } from "./hooks"
import Table from "@ant-design/pro-table/lib/Table"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"


const getPageListApi = productApi(contractSearch)

const persistenceKey = 'finance/contract/purchase'



export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}>{data.contractAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={13}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={14}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={15}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={16}></Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}

export default ({dataScope}: any) => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any

    const { 
        commonModalFormProps,
        setCommonModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    } = usePurchaseContractHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        action(type, {})
    }

    const columns = [...columnsFn(operate, '')]
    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setAllTableData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const params = {dataScope, contractType: 2}

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            contractIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    return(<div className="oa-task-center">
        <ProTableWithFormSetting<TableListItem>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={ params }
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>合同金额： <span style={{color: 'red'}}>{allTableData.contractAmount || 0}</span> （汇总列表查询结果）</h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2446) ? <Button key="btn6" type="primary" onClick={() => operate('采购合同-新建', null)}>新建采购合同</Button> : null,
                        hasAuthCode(2443) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/contractExport`}></ExportButtonWithBatch> : null,
                        hasAuthCode(2448) ? <Button key="btn6" type="primary" onClick={() => operate('采购合同-删除', null)}>删除</Button> : null,
                    ],
                }}
                rowKey="contractId"
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <AddPurchaseContractModalForm
            modalProps={{
                onCancel: () => setCommonModalFormProps({...commonModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...commonModalFormProps} 
        />
    </div>)
}
