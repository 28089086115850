import React, { useEffect, useRef, useState } from 'react';
import type { ProFormInstance } from '@ant-design/pro-form';
import { Radio, Table } from 'antd';
import ProTable from '@ant-design/pro-table';
import { columnsFn1, columnsFn2 } from './data'
import type { TableListItem } from './data'
import { BASEURL, oaWorkReportAnalysis } from '@api/index'
import { productApi } from '@utils/util';
import { useHasAuthCode } from '@hooks/index';
import { ExportButton } from '@components/index';
import moment from 'moment';

const getApi = productApi(oaWorkReportAnalysis)

const Summary1 = ({summaryData}: any) => {
    const { period, expectedSubmits, submitted, passed, rejected,  pending } = summaryData || {}
    return (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
            <Table.Summary.Cell index={1}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}></Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{expectedSubmits}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>{submitted}</Table.Summary.Cell>
            <Table.Summary.Cell index={4}>{expectedSubmits - submitted}</Table.Summary.Cell>
            <Table.Summary.Cell index={5}>{passed}</Table.Summary.Cell>
            <Table.Summary.Cell index={6}>{rejected}</Table.Summary.Cell>
            {/* <Table.Summary.Cell index={7}>{pending}</Table.Summary.Cell> */}
            <Table.Summary.Cell index={1}></Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
    );
}

const Summary2 = ({summaryData}: any) => {
  const { period, expectedSubmits, submitted, passed, rejected,  pending } = summaryData || {}
  return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}>{expectedSubmits}</Table.Summary.Cell>
          <Table.Summary.Cell index={3}>{submitted}</Table.Summary.Cell>
          <Table.Summary.Cell index={4}>{pending}</Table.Summary.Cell>
          <Table.Summary.Cell index={5}>{passed}</Table.Summary.Cell>
          <Table.Summary.Cell index={6}>{rejected}</Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
  );
}

export default ({type}: any) => {
    const formRef = useRef<ProFormInstance>()
    const [summaryType, setSummaryType] = useState<string>('1')
    const [summaryData, setSummaryData] = useState<Object>({})
    const [, hasAuthCode] = useHasAuthCode()
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi(params, false)
        const { items, summary, total } = data || {}
        setSummaryData(summary)
        return {
            data: items || [],
            success: true,
            total
        }
    }
    const getParams = () => {
      // @ts-ignore
      const param = formRef?.current?.getFieldsFormatValue()
      return {...(param||{}), type: type || 1, aggreType: summaryType}
    }
    const operate = async (type: string, record?: TableListItem | null) => {}

    return (
      <div className="oa-workReport" style={{boxSizing: 'border-box'}}>
        <Radio.Group value={summaryType} onChange={(e) => setSummaryType(e?.target?.value)} buttonStyle="solid" style={{boxSizing: 'border-box', padding: '0 20px'}}>
            <Radio.Button value="1">按提交人汇总</Radio.Button>
            <Radio.Button value="2">按批阅人汇总</Radio.Button>
        </Radio.Group>
        <ProTable<TableListItem>
          scroll={{y: 888}}
          columns={ summaryType === '2' ? columnsFn2(operate, type) : columnsFn1(operate, type) }
          params={{aggreType: summaryType, type: type||1}}
          request={getListApi}
          options={{reload: false, density: false, setting: false}}
          toolbar={{}}
          search={{
            optionRender: (searchConfig, formProps, dom) => 
            [
                ...dom.reverse(),
                hasAuthCode(2117) ? <ExportButton key="btn5" param={getParams()} action={`${BASEURL}/report/exportAnalysis`}></ExportButton> : null,
            ],
          }}
          tableStyle={{boxSizing: 'border-box', padding: '12px'}}
          rowKey={() => Math.random().toString()}
          pagination={false}
          formRef={formRef as any}
          bordered={true}
          summary={(pageData) => summaryType === '1' ? <Summary1 summaryData={summaryData} /> : <Summary2 summaryData={summaryData} /> }
        />
      </div>
    );
};
