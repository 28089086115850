import { StateType } from "@store/index"
import { message, Modal } from "antd"
import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { ModalForm,  ProFormUploadDragger, } from '@ant-design/pro-form';
import { getToken, productApi, request, toFormData } from "@utils/index"

interface BatchImportProps extends ModalFormProps{
    action?: string;
    accept?: string;
    params?: Record<string, any>;
    onSuccess?: () => void;
    templateUrl?: string;
}
export default ({children, onSuccess, params, action, templateUrl, accept, ...rest}: BatchImportProps) => {
    // const { userInfo } = useSelector((state: StateType) => state)
    // const { user } = userInfo || {}
    // const { id: userId } = user || {}
    const formRef = useRef<ProFormInstance>()
    const [fileList, setFileLst] = useState<any[]>([])
    const customRequest = ({file}: any) => setFileLst([file])
    const onRemove = () => setFileLst([])
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            setFileLst([])
            formRef.current?.resetFields()
        }
    }
    const onFinish = async ({poolId, ...vals}: any) => {
        const obj = poolId ? { poolId: poolId * 1 } : {}
        const formData = toFormData({...vals, ...obj, ...(params||{})})
        const api = productApi((data?: object) => request({ url: action, data }))
        const { success, data } = await api(formData, false, onSuccess)
        const { info, msg, file } = data || {}
        if (success) {
            Modal.confirm({
                title: '处理成功',
                content: <div><p>{info||msg}</p>{ file ? <p>错误文件下载地址:<a download={file} href={file}>{file}</a></p> : null}</div>,
                okText: '确认',
                cancelText: '取消',
            });
        } else {
            // message.error(msg)
        }
        return success
    }

    return (<ModalForm<BatchImportProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 4}} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
         {children}
         <ProFormUploadDragger 
           max={1} 
           label="文件导入" 
           name="fileList" 
           fieldProps={{ 
                name: 'file', 
                customRequest, 
                onRemove, 
                fileList, 
                accept,
                headers: {Authorization: getToken()}
           }} 
           rules={[{required: true, message: '文件导入'}]}
        />
         {templateUrl ? <a style={{float: 'right',position: 'relative', top: '-15px'}} download href={templateUrl}>模板下载</a> : null}
    </ModalForm>)
}