
export type OperateActionType = '回款记录-审批通过' | '回款记录-审批拒绝' | '回款记录-详情' | '待认领回款-认领' | '待认领回款-撤销' | '待认领回款-删除'
import { Tooltip } from "antd"
import RebackClaimAudit from "./cpnts/RebackClaimAudit"
import RebackListAudit from "./cpnts/RebackListAudit"
import React from "react"
import { Icon } from "@components/index"

export const tabPanes = [
    {
        Tab: '回款列表',
        key: ' ',
        Content: RebackListAudit
    },
    {
        Tab: <>待认领回款 &nbsp; <Tooltip title="说明：此处回款数据是指客户已打款到公司账户，但运营及财务人员不知晓已打款客户归属于哪个客户经理，需客户经理认领回款记录并创建回款申请"><Icon type="icon-ziyuan" /></Tooltip></>,
        key: 2,
        Content: RebackClaimAudit
    }
]
