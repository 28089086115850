import { DescriptionsList, MyTabs } from "@components/EnterpriseDetail"
import React, { useState } from "react"
import { attachmentColumnsFn, descList } from "./data"
import { Alert, Avatar, Col, Row, Table, Timeline } from "antd"
import { BackBan, Preview } from "@components/index"
import ProTable from "@ant-design/pro-table"
import { AuditStatusTag, AuditTimeLine } from "@components/Finance/Contract/cpnts/SaleContract/cpnts/SaleContractDetail"
import AddRebackModalForm from "../AddReback"
import { useAddRebackHooks } from "../../hooks"
import { OperateActionType } from "@components/Finance/RebackMoney/data"
import { OperateActionType as AuditOperateActionType } from "@components/Finance/RebackMoneyAudit/data"
import { ContractAuditModalForm } from "@components/Finance/ContractAudit/cpnts/SaleContractAudit"
import { useRebackAuditHooks } from "@components/Finance/RebackMoneyAudit/cpnts/RebackListAudit/hooks"
import { RebackMoneyListOperate } from '../../data'
import { parseSearch } from "@utils/index"
import { useLocation } from "react-router-dom"
import { useCommonApiHooks } from "@hooks/index"
import { BASEURL } from "@api/index"
import { RebackMoneyListAuditOperate } from "@components/Finance/RebackMoneyAudit/cpnts/RebackListAudit/data"

const ContractInfo = ({dataObj}: any) => {
    const [previewProps, setPreviewProps] = useState({visible: false, src: undefined})
    const operate = (type: string, record: any) => {
    const { url: src } = record || {}
    if (type === '预览') {
        setPreviewProps({
            visible: true,
            src
        })
    }
    }
    const columns = attachmentColumnsFn(operate)
    return (<div style={{padding: 20}}>
        <div style={{background: '#fff'}}><div className="zen-ban">基本信息</div></div>
        <DescriptionsList data={dataObj||{}} descList={descList} />
        <div style={{background: '#fff', marginBottom: 20}}><div className="zen-ban">附件信息</div></div>
        <Table columns={columns} dataSource={dataObj?.files||[]} pagination={false} rowKey="id" />
        <Preview {...previewProps} modalProps={{maskClosable: false, onCancel: () => setPreviewProps({...previewProps, visible: false})}}></Preview>
    </div>)
}


const aduitLine = [
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '已提交申请，待1级审批（李一一）',
        remark: 'xxxxccxjkhkf',
        status: 1, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '已提交申请，待1级审批（李一一）',
        remark: 'xxxxccxjkhkf',
        status: 2, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '已提交申请，待1级审批（李一一）',
        remark: 'xxxxccxjkhkf',
        status: 3, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '已提交申请，待1级审批（李一一）',
        remark: 'xxxxccxjkhkf',
        status: 4, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '已提交申请，待1级审批（李一一）',
        remark: 'xxxxccxjkhkf',
        status: 5, 
    },
]

type RebackDetailProps = {
    backId: React.Key;
    fromPath?: string;
}

export const RebackDetail = ({backId, fromPath}: RebackDetailProps) => {
    
    const [rebackInfo, setRebackIdInfo, getApi] = useCommonApiHooks({url: `${BASEURL}/contract/backDetail`, method: 'POST'}, {backId}, true) 
    const [aduitLine, setAduitLine, getAduitLineApi] = useCommonApiHooks({url: `${BASEURL}/auditLog/getAuditLog`, method: 'POST'}, {keyId: backId, type: 2}, true) 

    const { 
        addRebackModalFormProps,
        setAddRebackModalFormProps,
        createOperate,
    } = useAddRebackHooks()

    const {
        rebackAuditModalFormProps,
        setRebackAuditModalFormProps,
        createOperate: createAuditOperate,
    } = useRebackAuditHooks()

    const reFush = () => {
        getApi({backId})
        getAduitLineApi({keyId: backId, type: 2})
    }

    const operate = async (type: OperateActionType | AuditOperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        const auditAction = createAuditOperate(record, reFush)
        switch(type) {
            case '回款记录-审批通过':
            case '回款记录-审批拒绝':
                auditAction(type, {})
                break;
            default:
                action(type as any, {})
        }
    }

    return (<>
        <BackBan 
            title={<>{rebackInfo?.sysNumber} <AuditStatusTag status={rebackInfo.status} /></>}
            actions={
                // @ts-ignore
                fromPath === '/finance/rebackMoneyAudit' ? <RebackMoneyListAuditOperate type="primary" record={rebackInfo} operate={operate} /> as any : <RebackMoneyListOperate type="primary" record={rebackInfo} operate={operate} /> as any
            }
        />
        <Row gutter={[24,12]} justify="space-between" style={{width: 'calc(100% + 12px)'}}>
            <Col span={17} >
                <div style={{background: '#fff'}}>
                    <ContractInfo dataObj={rebackInfo} />
                </div>
            </Col>
            <Col span={7} style={{background: '#fff'}}>
                <div style={{background: '#fff', marginBottom: 20, marginTop: 20}}><div className="zen-ban">流程动态</div></div>
                <div style={{background: '#F5F7FE', borderRadius: 8, padding: 20}}>
                    <AuditTimeLine aduitLine={aduitLine} hiddenLevel={true} />
                </div>
            </Col>
        </Row>
        <AddRebackModalForm
            modalProps={{
                onCancel: () => setAddRebackModalFormProps({...addRebackModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...addRebackModalFormProps} 
        />
        <ContractAuditModalForm
            modalProps={{
                onCancel: () => setRebackAuditModalFormProps({...rebackAuditModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...rebackAuditModalFormProps} 
        />
    </>
    )
}


export default () => {
    const { search } = useLocation();
    const { backId, fromPath } = parseSearch(search)
    return <RebackDetail backId={backId} fromPath={fromPath} />
}