import React from 'react'
import { Tag, Row, Col, Tooltip, Badge } from 'antd'
import { BaseInfoType } from '../../type'
import { tranformHomePage } from '@utils/index';
import './index.less'
import { UpdateCompanyInfo } from '@components/EnterpriseDetail';

type BaseInfoPropType = {
    baseInfo: BaseInfoType
}

export default ({ baseInfo }: BaseInfoPropType) => {
    const { companyName, isTech, companyType, businessStatus, registDate, companyAdress, tagNames, ownerName, url, oldNames, businessScope = '', registCapital } = baseInfo || {}
    const reFresh = () => location.reload()
    return (
        <div className="baseinfo" style={{position: 'relative'}}>
            <div style={{position: 'absolute', right: 20, top: 10}}><UpdateCompanyInfo key="update" params={{name: companyName}} onSuccess={reFresh} /></div>
            <div className="baseinfo-logo">企</div>
            <div className="baseinfo-warps">
                <h3>{companyName} &nbsp;&nbsp; </h3>
                <div className="tags">
                    <Tooltip title={(oldNames||[]).map(v => <div key="v">{v}</div>)}><Badge count={(oldNames||[]).length} color="blue"><Tag color="blue">曾用名</Tag></Badge></Tooltip>
                    {businessStatus ? <Tag color="#FFA32F">{businessStatus}</Tag> : null }
                    {companyType ? <Tag color="#945AFF">{companyType}</Tag> : null }
                    {isTech ? <Tag color="blue">高新技术企业</Tag> : null }
                </div>
                <div className="lists">
                    <Row gutter={24} style={{marginBottom: '9px'}}>
                        <Col span={8}>法人代表: <span style={{color: '#2D2E2F'}}>{ownerName}</span></Col>
                        <Col span={16}>官网: <a href={tranformHomePage(url||'')} target="_blank" rel="noopener noreferrer">{url}</a></Col>
                    </Row>
                    <Row gutter={24} style={{marginBottom: '9px'}}>
                        <Col span={8}>成立日期: <span style={{color: '#2D2E2F'}}>{registDate}</span></Col>
                        <Col span={16}>注册资本: <span style={{color: '#2D2E2F'}}>{registCapital}</span></Col>
                    </Row>
                    <Row gutter={24} style={{marginBottom: '9px'}}>
                        <Col span={24}>营业地址: <span style={{color: '#2D2E2F'}}>{companyAdress}</span></Col>
                        <Col span={24} style={{display: 'flex', flexWrap: 'wrap', marginTop: '9px'}}>
                            <span>企业标签: &nbsp;</span>
                            {(tagNames||[]).map(v => <Tag key={v} color="rgba(22, 119, 255, 0.7)" style={{marginBottom: '10px'}}>{v}</Tag>)}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

