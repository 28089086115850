import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, OperateActionType, TableListItem, tabPanes, viewOptions } from "./data"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"

const getPageListApi = productApi(invoicePageList)

const cancelApi = productApi(invoiceCancel)
const persistenceKey = 'finance/billManage'

export const getCheckedColumnsStateByColumns = (columns: any[], persistenceKey: string) => {
    const columnsState = {} as any
    const json = getPersistenceKeyFromStorage(persistenceKey) || {};
    (columns||[]).forEach((v: any) => { columnsState[v.dataIndex] = { show: !v.hideInSetting, fixed: v.fixed } })
    Object.assign(columnsState, json)
    console.log(columnsState)
    return columnsState
}

export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const formRef = useRef<ProFormInstance>()
    const [activeKey, setActiveKey] = useState(' ')
    const [dataScope, setDataScope] = useState('1')
    const [, hasAuthCode] = useHasAuthCode()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [summary, setSummary] = useState<any>({})

    const operate = async (action: OperateActionType, record: any) => {
        const { id, reason, status } = record || {}
        if (['申请开票', '编辑'].includes(action)) {
            const routeName = action === '编辑' ? '编辑发票' : action
            history.push(`/finance/billAdd?${stringify({id, status, routeName, fromPath: '/finance/bill'})}`)
        } else if (action === '撤销申请') {
            Modal.confirm({
                title: '撤销申请',
                content: '确定撤销已提交的申请？',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await cancelApi({invoiceId: id}, true, reFush)
                },
            });
        } else if (['暂停原因', '驳回原因'].includes(action)) {
            Modal.confirm({
                title: action,
                content: reason,
                okText: '确认',
                cancelText: '取消',
            });
        } else if (action === '详情') {
            history.push(`/finance/billDetail?${stringify({id})}`)
        } else if (action === '一键开票') {
            history.push(`/finance/billAdd?${stringify({originId: id, status, routeName: '一键开票', fromPath: '/finance/bill'})}`)
        }
    }

    const columns = [...columnsFn(operate, activeKey)]
    const getApi = async (params: any) => {
        const { data } = await getPageListApi(params, false)
        const { values, total, ...summary } = data || {}
        setSummary(summary)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const params = activeKey === ' ' ? { dataScope } : {status: activeKey, dataScope}

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params,
            searchType: 0
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params,
            searchType: 0
        })
    }

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
          style={{background: '#fff'}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
          tabBarExtraContent={{
            left: <Select options={viewOptions} bordered={false} value={dataScope} onChange={setDataScope} style={{minWidth: 80, marginRight: 30}} />}}
        />
        <ProTableWithFormSetting<TableListItem>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={
                    <h5 style={{fontSize: '14px', margin: 0}}>
                        开票金额：<span style={{color: 'red'}}>{summary?.amount}</span> 
                        （汇总列表查询结果）
                    </h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2396) ? <Button key="btn6" type="primary" onClick={() => operate('申请开票', null)}>申请开票</Button> : null,
                        // hasAuthCode(2398) ? <ExportButton key="btn5" disabled={calcDisabled()} param={getQueryParams} action={`${BASEURL}/invoice/exportInvoices`}></ExportButton> : null,
                        hasAuthCode(2398) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/invoice/exportInvoices`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="id"
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
    </div>)
}
