import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
/*-----------------------------------------协同办公----------------------------------*/
// 工作报告
export const oaWorkReportList = (data?: any) => request({
    url: `${BASEURL}/report/searchByConditions`,
    // method: 'GET',
    data,
})

export const oaWorkReportSave = (data?: any) => request({
    url: `${BASEURL}/report/saveOrUpdateReport`,
    data,
})

export const oaWorkReporStatsNum = (data?: any) => request({
    url: `${BASEURL}/report/showStatsNumbers`,
    method: 'GET',
    data,
})

export const oaWorkReporPageStats = (data?: any) => request({
    url: `${BASEURL}/report/getPageStats`,
    method: 'GET',
    data,
})

export const oaWorkReportAnalysis = (data?: any) => request({
    url: `${BASEURL}/report/getReportAnalysis`,
    data,
})

// 根据id 获取日报详情
export const oaWorkReportDetailById = (data?: any) => request({
    url: `${BASEURL}/report/getReportInfoById`,
    method: 'GET',
    data,
})

// 获取批阅人信息接口
export const oaWorkReportApproverInfo = (data?: any) => request({
    url: `${BASEURL}/report/getSupervisorInfo`,
    method: 'GET',
    data,
})

// 批阅日报
export const oaWorkReportApprove = (data?: any) => request({
    url: `${BASEURL}/report/reviewReport`,
    data,
})

// 导出日报
export const oaWorkReportExport = (data?: any) => request({
    url: `${BASEURL}/report/exportAnalysis`,
    data,
})


// -------------------------------------------消息相关
// 消息中心列表查询
export const oaGetMessageOrNotice = (data?: any) => request({
    url: `${BASEURL}/taskCenter/getMessageOrNotice`,
    data,
})

// 消息中心-已读消息
export const oaGetMessageUpdateMsgStatus = (data?: any) => request({
    url: `${BASEURL}/taskCenter/updateMsgStatus`,
    data,
})


// 我的消息查询
export const oaGetmyMsg = (data?: any) => request({
    url: `${BASEURL}/taskCenter/myMsg`,
    data,
})

// 消息总数量查询
export const oaGetmyMsgNumber = (data?: any) => request({
    url: `${BASEURL}/taskCenter/getUserUnReadMsgNum`,
    method: 'GET',
    data,
})

// 新建编辑公告
export const oaAddEditSystemNotice = (data?: any) => request({
    url: `${BASEURL}/taskCenter/saveOrUpdateSystemNotice`,
    data,
})


// 公告详情查询
export const oaGetSystemNoticeDetails = (data?: any) => request({
    url: `${BASEURL}/taskCenter/getSystemNoticeDetails`,
    data,
})

// 删除公告
export const oaDeleteSystemNotice = (data?: any) => request({
    url: `${BASEURL}/taskCenter/deleteSystemNotice`,
    data,
})

// ------- 任务模块 --------
// 任务中心分页列表
export const oaTaskCenterPageList = (data?: any) => request({
    url: `${BASEURL}/taskCenter/centerList`,
    data,
})

// 我的任务分页列表
export const oaTaskMinePageList = (data?: any) => request({
    url: `${BASEURL}/taskCenter/myTask`,
    data,
})

// 新增/修改任务
export const oaTaskAddUpdate = (data?: any) => request({
    url: `${BASEURL}/taskCenter/saveOrUpdateTask`,
    data,
})

// 删除任务
export const oaTaskDel = (data?: any) => request({
    url: `${BASEURL}/taskCenter/delTask`,
    data,
})

// 取消任务
export const oaTaskCancel = (data?: any) => request({
    url: `${BASEURL}/taskCenter/cancelTask`,
    data,
})

// 暂停任务
export const oaTaskPause = (data?: any) => request({
    url: `${BASEURL}/taskCenter/pauseTask`,
    data,
})

// 恢复任务
export const oaTaskResume = (data?: any) => request({
    url: `${BASEURL}/taskCenter/resumeTask`,
    data,
})

// 根据任务id 获取任务详情
export const oaTaskDetailById = (data?: any) => request({
    url: `${BASEURL}/taskCenter/getTaskById`,
    method: 'GET',
    data,
})

// 根据任务id 获取任务执行进度列表
export const oaTaskDetailProgress = (data?: any) => request({
    url: `${BASEURL}/taskCenter/taskSchedule`,
    data,
})

// 根据任务id 获取客户盘点详情列表
export const oaTaskDetailCustomerList = (data?: any) => request({
    url: `${BASEURL}/taskCenter/customerInventory`,
    data,
})
