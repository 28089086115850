import { Button, Dropdown, Modal, Space } from "antd"
import ProForm, { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDatePicker, ProFormDateTimePicker } from '@ant-design/pro-form';
import { columns } from "./data";
import { useMemo } from "react";
import { useRequest } from "ahooks";
import React from "react";
import { parseSearch, productApi, request } from "@utils/index";
import { usePlusTableHook } from "@hooks/plusTableHook";
import ProTable from "@ant-design/pro-table";
import { BASEURL } from "@api/index";
import { getBuilderEnums } from "@api/customer";
import { useLocation } from "react-router-dom";

// 接口请求的另一种写法，方便 dvm 代码生成 
const getApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/web/sys/sysDiydoField/getPage`,
        // method: 'GET',
        data: params
    }) as any
    const { values, total } = data
    return {
        data: values,  // 列表数据
        total: total,   // 列表总条数
        success: true   // 请求成功，关闭表格 loading 等待
    }
}
const delApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoField/deleteBatch`,
    // method: 'GET',
    data,
})) 
const addApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoField/save`,
    // method: 'GET',
    data,
}))
const updateApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoField/save`,
    // method: 'GET',
    data,
}))
const getInfoApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/web/sys/sysDiydoField/getById`,
        method: 'GET',
        params,
    }) as any
    return data
}

// 获取页面枚举数据
const getInitDataApi = async () => {
    const { data } = await getBuilderEnums() as any
    return data
} 

// 组件区域
const SysDiydoField = () => {
    const { pathname, search } = useLocation();
    const { dfId } = parseSearch(search)
    
    const operate = async (action: any, record: any) => {
        const { id : id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (action) {
            //【新增，编辑，删除】操作可能存在一下几种情况:
            // case 1: 理想情况下，不需要额外配置 弹窗，以及接口体交参数
            // case 2: 非理想情况下，需要额外配置 弹窗，以及接口体交参数
            case '新增':
                addAction({params: {dfId}})        // case 1: 极简模式
                // addAction({     // case 2: 自定义模式
                //     visible: true,             // 配置: 弹窗是否打开
                //     title: '新增弹窗的标题',     // 配置: 弹窗的标题
                //     params: {},                // 配置: 弹窗提交时额外的 提交参数
                //     onFinish: async (values: any) => {  // 复写弹窗提交方法（会覆盖掉 addApi 方法）
                //         console.log('新增弹窗的表单数据', values)
                //         addAction({visible: false})
                //     },
                //     request: async (params: any) => {   // 复写 编辑弹窗时 回填表单数据的方法 (会覆盖掉 getInfoApi 方法)
                //         return {}
                //     },
                // }) 
                break;
            case '编辑':			// editAction 方法 同 addAction 方法一致
                editAction({params: {id}})
                break;
            case '删除':
                delAction({ids: ids})    // case 1: 极简模式
                // Modal.confirm({      			// case 2: 自定义模式
                //     title: '确认要删除该数据吗?',
                //     content: '删除后当前内容将永久删除，不可恢复。',
                //     okText: '确认',
                //     cancelText: '取消',
                //     onOk: async() => {
                //         await delApi?.(params, true, onRefresh)
                //     },
                // });
                break;
        }
    }

    const { data } = useRequest(getInitDataApi)
    const { 
        selectedRowKeys,
        tableProps, 
        modalProps, 
        addAction, 
        editAction, 
        delAction,
        setModalProps,
        onRefresh
    } = usePlusTableHook({
        delApi,
        updateApi,
        addApi,
        getInfoApi,
        columns: useMemo(() => columns(operate, data), [data])  // 可以将 data 枚举值 透传到 columns 中
    })
    const calcDisabled = () => !selectedRowKeys.length

    return (<>
        <ProTable
            request={getApi}
            scroll={{x: 1300}}
            toolbar={{
                actions: [
                    <Button type="primary" key="新增" onClick={() => operate?.('新增', null)}>新增</Button>,
                    // <Button type="primary" key="导入" onClick={() => operate?.('导入', null)}>导入</Button>,
                    // <Button type="primary" key="导出" onClick={() => operate?.('导出', null)}>导出</Button>,
                    <Dropdown key='批量' menu={{items: [
                        {
                            key: '批量删除',
                            disabled: calcDisabled(),
                            label: <a key="批量删除" onClick={() => operate?.('删除', null)}>批量删除</a>,
                        }
                        
                    ]}}>
                        <Button>
                            <Space>批量操作</Space>
                        </Button>
                    </Dropdown>
                ]
            }}
            rowKey="id"
            {...tableProps}
            params={{dfId}}
        />
        <ModalForm<any>
            layout="horizontal"
            labelCol={{span: 8}}
            width={1200}
            {...modalProps}
            grid={true}  // 开启栅格布局，一行允许有多列
            initialValues={{ // 可用于新增时的默认值
                fieldNature: 0,
                isQueryField: 1,
                isColField: 1,
                isShowField: 1,
                isSaveField: 1,
                initMode: 0,
                dataType: 10,
                componentType: 1
            }}
        >
            <ProFormText 
                label="字段别名" 
                name="fieldAlias" 
                colProps={{span: 6}}
            />
            <ProFormText 
                label="字段名称" 
                name="fieldName" 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="字段性质" 
                name="fieldNature" 
                options={data?.fieldNatureList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="初始方式" 
                name="initMode" 
                options={data?.initModeList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormText 
                label="显示名称" 
                name="fieldCaption" 
                colProps={{span: 6}}
            />
            <ProFormText 
                label="提示信息" 
                name="fieldHint" 
                colProps={{span: 12}}
                labelCol={{span: 4}}
            />
            <ProFormText 
                label="排序" 
                name="fieldSort" 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="查询字段" 
                name="isQueryField" 
                options={data?.yesNoList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]}
                colProps={{span: 6}} 
            />
            <ProFormSelect 
                label="列表字段" 
                name="isColField" 
                options={data?.yesNoList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="显示字段" 
                name="isShowField" 
                options={data?.yesNoList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="保存字段" 
                name="isSaveField" 
                options={data?.yesNoList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormSelect 
                label="控件类型" 
                name="componentType" 
                options={data?.componentTypeList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 12}}
                labelCol={{span: 4}}
            />
            <ProFormSelect 
                label="数据类型" 
                name="dataType" 
                options={data?.dataTypeList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 6}}
            />
            <ProFormText 
                label="数据长度" 
                name="dataLength" 
                colProps={{span: 6}}
            />
            
            <ProFormTextArea
                label="控制json" 
                name="controlJson" 
                colProps={{span: 12}}
                labelCol={{span: 4}}
            />
            <ProFormTextArea
                label="备注" 
                name="memo" 
                colProps={{span: 12}}
                labelCol={{span: 4}}
            />
        </ModalForm>
    </>)
}

export default SysDiydoField