import { 
    queryDeptTreeData, 
    oaGetmyMsgNumber, 
    sysDictCitys, 
    sysLabelList, 
    lqSearchConditions, 
    getImportExcelConsumeOrderStatus, 
    customerGetMessage, 
    getDownLoadUrlMap,
    getSystemRuleNotFollowupDay
} from '@api/index'
import { 
    SET_COMMON_DEPTWITHUSERDATR, 
    SET_CITY_TREEDATA, 
    SET_LABEL_TREEDATA, 
    SET_LQ_SEARCHCONDITION, 
    SET_USER_PROCESS, 
    SET_USER_MESSAGES, 
    SET_USER_MESSAGE_NUMBER, 
    SET_DOWNLOAD_URLMAP, 
    SET_SYS_NOTFOLLOWDAY
} from '../contants'
import { 
    getCustomerReturnDesc,
    handleDeptWithUserTreeData, 
    transferTreeData 
} from '@utils/index'
export const setDeptWithUserTreeData = (deptWithUserData: any[]) => {
    return {
        type: SET_COMMON_DEPTWITHUSERDATR,
        deptWithUserData
    }
}

export const setCityTreeData = (cityTreeData: any[]) => {
    return {
        type: SET_CITY_TREEDATA,
        cityTreeData
    }
}

export const setLabelTreeData = (labelTreeData: any[]) => {
    return {
        type: SET_LABEL_TREEDATA,
        labelTreeData
    }
}

export const setDownLoadUrlMap = (downLoadUrlMap: Record<string, string>) => {
    return {
        type: SET_DOWNLOAD_URLMAP,
        downLoadUrlMap
    }
}

export const setSysNotFollowDay = (sysNotFollowDay: Record<string, string>) => {
    return {
        type: SET_SYS_NOTFOLLOWDAY,
        sysNotFollowDay
    }
}

// 猎奇模块
// 猎奇搜索条件查询
export const setLqSearchConditionData = (lqSearchConditionData: any) => {
    return {
        type: SET_LQ_SEARCHCONDITION,
        lqSearchConditionData
    }
}

export type TaskObj = {
    state: boolean;
    processName: string;
    speedProgress: number;
}
// 获取当前正在后台执行（导出、导入）的文件list
export const setTaskObj = (taskObj: Record<string, TaskObj>) => {
    return {
        type: SET_USER_PROCESS,
        taskObj
    }
}

// 获取后端推送过来的消息队列
export const setMessages = (messages: any[]) => {
    return {
        type: SET_USER_MESSAGES,
        messages
    }
}

// 获取未读消息总数量
export const setMessageNumber = (messageNumber: number) => {
    return {
        type: SET_USER_MESSAGE_NUMBER,
        messageNumber
    }
}

// 异步设置组织架构树带人员 action
export const asyncSetDeptWithUserTreeData = () => {
    return async (dispatch: any) => {
        dispatch(setDeptWithUserTreeData([]))
        const { data } = await queryDeptTreeData({type: 2}) as any
        handleDeptWithUserTreeData(data||[])
        dispatch(setDeptWithUserTreeData(data))
    }
}

// 异步获取省市区数据
export const asyncSetCityTreeData = () => {
    return async (dispatch: any) => {
       dispatch(setCityTreeData([]))
       const { data } = await sysDictCitys({}) as any
       transferTreeData(data)
       dispatch(setCityTreeData(data))
    }
}

// 异步获取客户标签树
export const asyncSetLabelTreeData = () => {
    return async (dispatch: any) => {
       dispatch(setLabelTreeData([]))
       const { data } = await sysLabelList({pageCurrent: 1, pageSize: 1000}) as any
       const { values } = data || {}
       transferTreeData(values||[])
       dispatch(setLabelTreeData(values||[]))
    }
}

// 猎奇搜索条件查询
export const asyncSetLqSearchConditionData = () => {
    return async (dispatch: any) => {
       dispatch(setLqSearchConditionData([]))
       const { data } = await lqSearchConditions({}) as any
       dispatch(setLqSearchConditionData(data||[]))
    }
}

// 获取当前正在后台执行（导出、导入）的文件list
export const asyncSetGetConsumeOrderProcess = () => {
    return async (dispatch: any) => {
        const { data } = await getImportExcelConsumeOrderStatus({}) as any
        dispatch(setTaskObj( data || {}))
    }
}

export const asyncSetMessageList = () => {
    return async (dispatch: any) => {
        const { data } = await customerGetMessage({}) as any
        const { list, msgNum } = data || {}
        dispatch(setMessages(list||{}))
        dispatch(setMessageNumber(msgNum||0))
        
    }
}

// 查询未读消息总数量
export const asyncSetMessageNumber = () => {
    return async (dispatch: any) => {
        const { data } = await oaGetmyMsgNumber() as any
        dispatch(setMessageNumber(data))
    }
}

// 设置模版下载url 地址
export const asyncSetDownLoadUrlMap = () => {
    return async (dispatch: any) => {
        const { data } = await getDownLoadUrlMap({}) as any
        dispatch(setDownLoadUrlMap( data || {}))
    }
}

// 获取系统中为跟进天数的设置
export const asyncSetSysNotFollowDay = () => {
    return async (dispatch: any) => {
        const { data } = await getSystemRuleNotFollowupDay({}) as any
        const desc = getCustomerReturnDesc(data)
        dispatch(setSysNotFollowDay( {...data, desc} || {}))
    }
}
