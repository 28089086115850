import { Icon } from "@components/index"
import { Col, Row } from "antd"
import React, { CSSProperties, ReactNode } from "react"
import { useHistory } from "react-router-dom"

type BackBanProps = {
    title?: ReactNode,
    actions?: any
    style?: CSSProperties
}

export default ({title, actions, style}: BackBanProps) => {
    const history = useHistory()

    return <Row justify="space-between" align="middle" style={{background: '#fff', padding: '12px 20px', borderBottom: '1px solid #E8E8E8', ...style}}>
        <Col style={{color: 'rgba(0, 0, 0, 0.85)', fontWeight: 500, fontSize: '16px'}}>
            <Icon type="icon-zuojiantou" style={{marginRight: 8}} onClick={() => history.goBack()} />{title}
        </Col>
        <Col>
           {actions}
        </Col>
    </Row>
}
