import { StateType } from "@store/index";
import { Alert, Button, Card, Col, DatePicker, Divider, message, Row, Segmented, Select, SelectProps, Statistic, Tabs, Tag, TreeSelect, TreeSelectProps } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import { businessStatisticList, dataReportList, dataSelectOptions, doneStatisticList, personBussnissColumns, qnAnalysisColumns, qnManageColumns, ranges1, unDoneStatisticList } from "./data";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { ImportButton, ProTableWithFormSetting } from "@components/Common";
import { BASEURL } from "@api/index";
import { isFunction } from "lodash";
import { amountFormat, productApi, sum } from "@utils/index";
import { workbenchBusinessAnalysis, workbenchBusinessAnalysisStatistic, workBenchCount, workbenchDeptBusinessAnalysis, workbenchDeptKpiAnalysis, workbenchGetKpiById, workbenchKpiAnalysis, workbenchKpiDone, workbenchKpiSave, workbenchKpiSaveWithAuth, workbenchKpiTarget, workbenchKpiUndo } from "@api/workbench";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { ModalForm, ProFormText, ProFormDateRangePicker, ProFormDigit } from '@ant-design/pro-form';
import moment from "moment";

const { RangePicker } = DatePicker;

const workBenchCountApi = productApi(workBenchCount)
const workbenchKpiSaveApi = productApi(workbenchKpiSave)
const workbenchKpiSaveWithAuthApi = productApi(workbenchKpiSaveWithAuth)

const handleDataScope = (dataScope: number | string[]) => {
  return typeof dataScope === 'number' ? {dataScope} : {
    // dataScope: 2,
    userIds: (dataScope||[]).map((v: string) => v.startsWith('s') ? v.slice(1) : undefined).filter(Boolean)
  }
}

const handleDateRange = (dateRange: any[]) => {
  return { dataPaperDateRange: (dateRange||[]).map(v => v.format('YYYY-MM-DD')) }
}

const handleSubmitParams = ({dataScope, dateRange, ...rest}: any) => {
  return {
    ...handleDataScope(dataScope),
    ...handleDateRange(dateRange),
    ...rest
  }
}

const CustOptions = ({options, value, onChange}: any) => {
    return(<>
      {options?.map((v: any) => <Col key={v.label} className={`ant-select-item ant-select-item-option ${value === v.value ? 'ant-select-item-option-selected' : undefined}`} style={{width: '100%'}} onClick={() => onChange && onChange(v.value)}>{v.label}</Col>)}
    </>
    )
}

export interface CustTreeSelectProps extends Omit<TreeSelectProps, 'onChange'>{
    custOptions?: SelectProps['options'];
    onChange?: (value: any, type?: 'treeSelect' | 'cust') => void;
}
  
export const CustTreeSelect = ({value: pvalue, onChange: pOnCahnge, custOptions, ...restProps}: CustTreeSelectProps) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [value, setValue] = useState<any>(pvalue)
    const [custValue, setCustValue] = useState<any>(pvalue)
    const onChange = (value: any, type: string) => {
        if (type === 'treeSelect') {
          const newVal = value.filter((v: any) => typeof v === 'string')
          setValue(newVal)
          setCustValue('')
          pOnCahnge?.(newVal, 'treeSelect')
        } else {
          setValue([value])
          setCustValue(value)
          pOnCahnge?.(value, 'cust')
        }
    }
    const tagRender = ({label, ...rest}: any) => {
      return typeof label === 'string' ? <Tag closable {...rest} key={rest?.value}>{label}</Tag> : <span key={rest?.value}>{(custOptions?.find(v => v.value === label)||{}).label}</span>
    }
    return(
    <TreeSelect 
      treeData={deptWithUserTreeData} 
      maxTagCount={4} 
      value={value} 
      onChange={(val) => onChange(val, 'treeSelect')}
      treeCheckable 
      showSearch 
      treeNodeFilterProp='title' 
      style={{width: 180}} 
      className="jns-cust-select-content"
      tagRender={tagRender}
      dropdownRender={menu => {
          return(<>
            <CustOptions options={custOptions} value={custValue} onChange={(custValue: number) => onChange(custValue, 'cust')} />
            {custOptions?.length ? <div className="ant-select-item ant-select-item-group">自定义</div> : null}
            {menu}
          </>)
      }}
      {...restProps}
    >
    </TreeSelect>)
}
  
export const CardStuffSelect = ({...restProps}: CustTreeSelectProps) => {
    return (<CustTreeSelect
      custOptions={dataSelectOptions}
      {...restProps}
      />)
}

type StatisticListProps = {
  list: any[];
  span?: number;
  dataSource?: any
}
const StatisticList = ({list, span, dataSource}: StatisticListProps) => {
  return (<Row justify="space-around" wrap gutter={[16, 16]}>
    {list.map((v, i) => (<Col key={v.label} span={span||6}>
      <Statistic 
        style={{width: `100%`, background: '#F6F7F9',borderRadius:'4px', textAlign: 'center', padding: 24,}}  
        title={v.label}  
        formatter={() => isFunction(v.value) ? v.value(dataSource||{}) : (v.value || (dataSource||{})[v.param] || 0) }
      /></Col>))}
  </Row>)
}

// 数据简报
const DataReport = () => {
    const [dataObj, setDataObj] = useState<any>({})
    const [dataScope, setDataScope] = useState<any>(undefined)

    const getApi = async(params: Record<string, any>) => {
      await workBenchCountApi(params, false, setDataObj)
    }
    useEffect(() => {
      const params = handleSubmitParams({dataScope})
      getApi(params)
    }, [dataScope])

    return(<Card title="数据简报" 
        extra={<>
            <CardStuffSelect custOptions={[]} placeholder="请选择人员" value={dataScope} onChange={setDataScope} />
        </>}>
        <StatisticList list={dataReportList} dataSource={dataObj} />
  </Card>)
}

// 商机分析 
const BusinessAnalysis = () => {
  const [type, setType] = useState<any>(1)
  const [summaryData, setSummaryData] = useState({})
  const getApi = async (params: any, sorter: any, filter: any) => {
    const whichApi = type === 1 ? workbenchBusinessAnalysis : workbenchDeptBusinessAnalysis
    const { data } = await whichApi({...params, sorter}) as any
    const { data: summary } = await workbenchBusinessAnalysisStatistic(params) as any
    // const { total, values } = data || []
    setSummaryData(summary||{})
    return {
      data: data||[],
      total: (data||[]).length,
      success: true
    }
  }
  const params = { scope: type }
  return (<>
      <ProTable 
        search={{
          defaultCollapsed: false,
          labelWidth: 'auto',
          span: 6,
          optionRender: (searchConfig, formProps, dom) => [
            ...dom.reverse(),
          ],
        }}
        options={false}
        pagination={false}
        columns={personBussnissColumns()?.filter(v => !(['人员姓名'].includes(v?.title as any) && [2].includes(type)) )}
        params={params}
        request={getApi}
        headerTitle={<Segmented value={type} onChange={setType} options={[{label: '个人商机', value: 1}, {label: '部门商机', value: 2}]} />}
        toolBarRender={() => [<span key="unit">(金额单位: 元)</span>]}
        tableExtraRender={(_, data) => <div style={{padding: '0 40px'}}><StatisticList list={businessStatisticList} span={8} dataSource={summaryData} /></div>}
      />
  </>)
}

const QNAnalysis = () => {
  const { user } = useSelector((state: StateType) => (state?.userInfo || {}))
  const [options, , request] = useDictEnumHooks({dictId: 20 }, true)
  const [type, setType] = useState<any>(1)
  const [salesStage, setSalesStage] = useState<any>([34, 35])
  const [summaryData, setSummaryData] = useState({})
  const [undoData, setunDoData] = useState({})
  const formRef = useRef<ProFormInstance>()
  const ref = useRef<ActionType>(null);
  const paramsRef = useRef({})
  
  const getApi = async (params: any, sorter: any) => {
    paramsRef.current = params
    const whichApi = type === 1 ? workbenchKpiAnalysis : workbenchDeptKpiAnalysis
    const { data } = await whichApi({...params, sorter}) as any
    const { data: summary } = await workbenchKpiDone(params) as any
    getUndoApi()
    // const { total, values } = data || {}
    setSummaryData(summary||{})
    return {
      data: data||[],
      total: (data||[]).length,
      success: true
    }
  }

  const getUndoApi = async (otherParams: any = {}) => {
    const params = {...paramsRef.current, salesStage, ...otherParams}
    const { data } = await workbenchKpiUndo(params) as any
    setunDoData(data||{})
  }
  
  const params = { scope: type }

  const initialValues = {
    name: user?.name,
    month: ranges1['本季']
  }

  const onFush = () => ref.current?.reload()

  useEffect(() => {
    getUndoApi()
  }, [salesStage])

  return (<>
      <ProTable 
        search={{
          defaultCollapsed: false,
          labelWidth: 'auto',
          span: 8,
          optionRender: (searchConfig, formProps, dom) => [
            ...dom.reverse(),
            <QNManageModalForm key="btn" initialValues={initialValues} onSuccess={onFush} trigger={<Button type="primary">录入季度目标</Button>} />
          ],
        }}
        options={false}
        pagination={false}
        bordered
        columns={qnAnalysisColumns()?.filter(v => !(['人员姓名'].includes(v?.title as any) && [2].includes(type)) )}
        params={params}
        request={getApi}
        formRef={formRef}
        actionRef={ref as any}
        headerTitle={<Segmented value={type} onChange={setType} options={[{label: '个人业绩', value: 1}, {label: '部门业绩', value: 2}]} />}
        toolBarRender={() => [<span key="unit">(金额单位: 元)</span>]}
        tableExtraRender={(_, data) => <Row gutter={[16, 16]} style={{padding: '0 20px'}}>
          <Col span={17}>
            <Card title="已完成" bordered={true}>
                <StatisticList list={doneStatisticList} dataSource={summaryData} />
            </Card>
          </Col>
          <Col span={7}>
            <Card 
              title="未完成" 
              bordered={true} 
              className="jns-card-extra-no-padding"
              extra={
                <Select 
                  options={options?.filter((v: any) => ![36].includes(v?.value))} 
                  defaultValue={[34, 35]} 
                  maxTagCount={1} 
                  placeholder="请选择项目阶段" 
                  value={salesStage} 
                  onChange={(val) => {
                    if(!val?.length) {
                      return message.warning('项目阶段不能为空')
                    }
                    setSalesStage(val)
                  }} 
                  mode="multiple" 
                  style={{width: 220}} 
                  allowClear 
                />}
              >
                <StatisticList list={unDoneStatisticList} dataSource={undoData} span={24} />
            </Card>
          </Col>
        </Row>}
      />
  </>)
}

function getWhichApi (authCode?: any) {
  switch (authCode) {
    case  2529:
      return workbenchKpiSaveWithAuthApi
    default:
      return workbenchKpiSaveApi
  }
}

interface QNManageModalFormProps extends ModalFormProps {
  onSuccess?: () => void;
  authCode?: any;
}
const QNManageModalForm = ({params, authCode, onSuccess, ...restProps}: QNManageModalFormProps) => {

  const onFinish = async ({month, ...rest}: any) => {
    const quarterStartDate =  moment(month[0]).format('YYYY-MM-DD')
    const quarterEndDate = moment(month[1]).endOf('M').format('YYYY-MM-DD')
    const whichApi = getWhichApi(authCode)
    const { success } = await whichApi({...rest, quarterStartDate, quarterEndDate, ...params}, true, onSuccess)
    return success
  }

  const request = async (params: any) => {
    const { data } = await workbenchGetKpiById(params) as any
    const { userName, quarterCash, quarterProfit, quarterStartDate, quarterEndDate } = data || {}
    return {
      userName,
      quarterCash,
      quarterProfit,
      month: [quarterStartDate, quarterEndDate]
    }
  }

  return (<ModalForm 
    modalProps={{maskClosable: false, destroyOnClose: true}} 
    layout="horizontal" 
    width={640} 
    labelCol={{span: 6}}
    title="录入季度目标"
    onFinish={onFinish}
    params={params}
    request={request}
    {...restProps}
  >
    <Alert message="说明：请谨慎录入季度目标金额，一旦提交不允许修改。如需修改，请联系运营人员。" type="warning" showIcon style={{marginBottom: '10px'}} />
    <ProFormText 
      width="md" 
      name="userName" 
      fieldProps={{
        maxLength: 30,
        disabled: true
      }} 
      label="姓名" 
      rules={[{ required: true, message: '请填写姓名',}]} 
      placeholder="请输入" 
    />
    <ProFormDateRangePicker 
      width="md"
      name="month" 
      label="季度周期" 
      fieldProps={{
        picker: 'month',
        disabled: true,
        format: 'YYYY-MM'
      }}
      rules={[{ required: true, message: '请填写季度周期',}]} 
    />
    <ProFormDigit 
      width="md"
      label="季度cash目标（元）" 
      name="quarterCash" 
      min={0.01} 
      rules={[{ required: true, message: '请填写季度cash目标',}]} 
    />
    <ProFormDigit 
      width="md"
      label="季度差价目标（元）" 
      name="quarterProfit" 
      min={0.01} 
      rules={[{ required: true, message: '请填写季度差价目标',}]} 
    />
  </ModalForm>)
}

// 季度目标管理
const QNManage = () => {
  const ref = useRef<ActionType>(null);
  const [modalProps, setModalProps] = useState<ModalFormProps>({visible: false, params: { id: undefined }})

  const getApi = async (params: any, sorter: any) => {
    const { data } = await workbenchKpiTarget({...params, sorter}) as any
    const { total, values } = data || {}
    const summaryData = {
      quarterCash: amountFormat(sum(values, 'quarterCash')),
      quarterProfit: amountFormat(sum(values, 'quarterProfit'))
    }
    return {
      data: values,
      summaryData,
      total,
      success: true
    }
  }

  const operate = (action: any, record: any) => {
    const { id, userId, userName, } = record || {}
    switch (action) {
      case '修改':
        setModalProps({visible: true, params: { id, userId }, initialValues: { userName }})
    }
  }

  const onFush = () => ref.current?.reload()

  const onSuccess = () => {
    onFush()
    setModalProps({visible: false, params: { }, initialValues: { }})
  }
  return (<>
    <ProTableWithFormSetting 
      columns={qnManageColumns(operate)}
      params={{pageSize: 800}}
      request={getApi}
      pagination={false}
      options={false}
      actionRef={ref}
      rowKey="id"
      search={{
        labelWidth: 'auto', 
        optionRender: (searchConfig, formProps, dom) => [
          ...dom.reverse(),
        ],
      }}
    />
    <QNManageModalForm 
      modalProps={{
        maskClosable: false, 
        destroyOnClose: true, 
        onCancel: () => setModalProps({visible: false, params: {}, initialValues: {}})
      }}
      onSuccess={onSuccess}
      authCode={2529}
      {...modalProps} 
    />
  </>)
}

const items = [
    {
        key: '1',
        label: '商机分析',
        children: <BusinessAnalysis />,
    },
    {
        key: '2',
        label: '季度业绩分析',
        children: <QNAnalysis />,
    },
    {
        key: '3',
        label: '季度目标管理',
        authCode: 2528,
        children: <QNManage />,
    }
]

const Extra = () => {
  const [options, , request] = useDictEnumHooks({dictId: 20 }, true)
  return (<Row gutter={[16,16]}>
    <Col><RangePicker style={{marginLeft: 20}} placeholder={['预计签单日期', '预计签单日期']} /></Col>
    <Col><CardStuffSelect placeholder="请选择人员" /></Col>
    <Col><Select options={options} placeholder="请选择项目阶段" allowClear /></Col>
  </Row>)
}

const Analysis = () => {
  const [, hasAuthCode] = useHasAuthCode()
  const calcItems = items?.filter(item => hasAuthCode(item?.authCode||-1))
  return (<Tabs 
    items={calcItems} 
    style={{background: '#fff'}} 
    tabBarStyle={{paddingLeft: 20}} 
    // tabBarExtraContent={<Extra />}
  />)
}

export default () => {
    return (<div>
        <DataReport />
        <div style={{marginTop: 20}}></div>
        {/* <BusinessAnalysis /> */}
        <Analysis />
    </div>)
}