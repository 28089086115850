import { ProColumns } from "@ant-design/pro-table"
import { TableListItem, rebateStateEnum } from "../Ali/data"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import React from "react"
import { Button, TreeSelect } from "antd"
import { Ellipsis } from "@components/index"

export const columnsFn: (scope: string, operate?: Function) => ProColumns<TableListItem>[] = (scope, operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
    return [
      {
          title: '消费月份',
          dataIndex: 'month',
          // hideInSearch: scope === '1',
          fixed: 'left',
          width: 100,
          order: 5,
          valueType: 'dateRange',
          fieldProps:(form: any, record: any) => {
              return {
                  picker: 'month',
                  format: 'YYYY-MM',
                  // onOpenChange: (open: any) => {
                  // },
                  // disabledDate: (current: any) => {
                  //     const dates = form.getFieldValue('month')
                  //     if (!dates || dates.length === 0) {
                  //         return false;
                  //     }
                  //     const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
                  //     const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;
                  //     return tooEarly || tooLate;
                  // }
              }
          },
          align: 'center',
          render: (text: any, record: any) => <a onClick={() => operate && operate('详情', record)}>{record.month}</a>
      },
      {
          title: '客户名称',
          dataIndex: 'customerName',
          // fixed: 'left',
          width: 180,
          order: 1,
      },
      {
          title: '客户账号',
          dataIndex: 'customerAccount',
          order: 100,
      },
      {
          title: '客户经理',
          dataIndex: 'customerOwner',
          order: 10,
          fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              treeData: deptWithUserTreeData
          },
          search: {
              transform: (ids) => {
                const customerOwner = (ids||[]).map((v: string) => v.slice(1))
                return { customerOwner }
              },
          },
          renderFormItem: (props) => <TreeSelect />,
      },
      {
          title: '合作模式',
          dataIndex: 'cooperationMode',
          order: 3,
          width: 120,
          valueType: 'select',
          params: {dictId: 25 },
          request,
      },
      {
          title: '有无票据',
          dataIndex: 'hasPaper',
          order: 2,
          valueEnum: {
              0: { text: '无' },
              1: { text: '有'},
          },
      },
      {
          title: '消费金额',
          dataIndex: 'consumeAmount',
          hideInSearch: true,
      },
      {
          title: '抵扣退款金额',
          dataIndex: 'deductAmount',
          hideInSearch: true
      },
      {
        title: '返点比例',
        dataIndex: 'rebatePoint',
        hideInSearch: true
      },
      {
          title: '预计返点金额',
          dataIndex: 'expectRebateAmount',
          hideInSearch: true,
      },
      {
          title: '实际返点金额',
          dataIndex: 'realRebateAmount',
          hideInSearch: true,
      },
      {
          title: '确认信息备注',
          dataIndex: 'remark',
          hideInSearch: true,
          render: (text, record: any) => <Ellipsis text={record.remark||'-'} len={20} />
      },
      {
          title: '返点状态',
          dataIndex: 'state',
          valueType: 'select',
          order: 9,
          fieldProps: { mode: 'multiple' },
          valueEnum: rebateStateEnum,
      },
      {
          title: '返点类型',
          dataIndex: 'type',
          order: 4,
          valueEnum: {
              1: { text: '追加返点' },
              0: { text: '正常返点'},
          },
      },
      {
          title: '支付宝账号',
          dataIndex: 'aliPayAccount',
          hideInTable: true,
          order: 6,
      },
      {
          title: '银行卡账号',
          dataIndex: 'bankAccount',
          hideInTable: true,
          order: 7,
      },
      {
          title: '收款人',
          dataIndex: 'getMoneyer',
          hideInTable: true,
          order: 8,
      },
      {
          title: '操作',
          key: 'option',
          valueType: 'option',
          width: 180,
          fixed: 'right',
          render: (text, record: any) => [
              (hasAuthCode(2254) && [0].includes((record||{}).state)) ? <Button key="a" type="link" disabled={record.orderStatus} onClick={() => operate && operate('认领', record)}>认领</Button> : null,
              (hasAuthCode(2276) && [0].includes((record||{}).state) && record.whetherAudit) ? <a key="k" onClick={() => operate && operate('审核详情-认领', record)}>审核详情(认领)</a> : null,
  
              (hasAuthCode(2256) && [1].includes((record||{}).state)) ? <a key="b" onClick={() => operate && operate('追回', record)}>追回</a> : null,
              (hasAuthCode(2277) && [1].includes((record||{}).state) && record.whetherAudit) ? <a key="l" onClick={() => operate && operate('审核详情-追回', record)}>审核详情(追回)</a> : null,
  
              (hasAuthCode(2258) && [2].includes((record||{}).state)) ? <a key="c" onClick={() => operate && operate('确认信息', record)}>确认信息</a> : null,
  
              (hasAuthCode(2259) && [3].includes((record||{}).state)) ? <a key="d" onClick={() => operate && operate('复核信息', record)}>复核信息</a> : null,
              (hasAuthCode(2262) && [3].includes((record||{}).state)) ? <Button type="link" key="e" disabled={record.orderStatus} onClick={() => operate && operate('撤销', record)}>撤销</Button> : null,
  
              (hasAuthCode(2267) && [4, 7].includes((record||{}).state)) ? <Button type="link" key="m" disabled={record.orderStatus} onClick={() => operate && operate('追加返点', record)}>追加返点</Button> : null,
  
              (hasAuthCode(2265) && [5, 6].includes((record||{}).state)) ? <a key="f" onClick={() => operate && operate('确认打款', record)}>确认打款</a> : null,
  
              (hasAuthCode(2266) && [6].includes((record||{}).state)) ? <a key="g" onClick={() => operate && operate('修改信息', record)}>修改信息</a> : null,
  
              (hasAuthCode(2257) && [8].includes((record||{}).state)) ? <a key="h" onClick={() => operate && operate('审核-追回', record)}>审核(追回)</a> : null,
              (hasAuthCode(2255) && [9].includes((record||{}).state)) ? <a key="i" onClick={() => operate && operate('审核-认领', record)}>审核(认领)</a> : null,
              // [0].includes((record||{}).state) ? <a key="j" onClick={() => operate && operate('详情', record)}>详情</a> : null,
          ],
      },
    ]
}


export const HuaWeiReviewRebateInfoColumns: (operate: Function) => ProColumns<any>[] = (operate) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: '有无票据',
            dataIndex: 'hasPaper',
            editable: false,
            valueEnum: {
                0: { text: '无' },
                1: { text: '有'},
            },
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
            editable: false
        },
        // {
        //     title: '对应佣金',
        //     dataIndex: 'consumeAmount',
        //     editable: false
        // },
        {
            title: <span><i style={{color: 'red'}}>*</i>抵扣退款金额</span>,
            dataIndex: 'freeConsumeAmount',
            valueType: 'digit',
            formItemProps: {
                rules: [
                    {
                        required: true,
                        message: '此项是必填项',
                    }
                ],
            },
        },
        {
            title: '有无返点',
            dataIndex: 'isRebate',
            editable: false,
            // render: () => <Select  />
            valueEnum: {
                0: { text: '无' },
                1: { text: '有'},
            },
        },
        {
            title: '返点比例',
            dataIndex: 'rebatePoint',
            editable: false,
            render: (text: any) => `${text}%`
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
            editable: false
        },
        // {
        //     title: '实际返点金额',
        //     dataIndex: 'realRebateAmount',
        //     editable: false
        // },
    ]
}