import { Graph, Shape } from "@antv/x6"
import { edgeConfig } from "./edge"
import { shapeCircleConfig, shapeEllipseConfig, shapePolygonConfig, shapeRectConfig } from "./shape"
import { Transform } from '@antv/x6-plugin-transform'
import { Selection } from '@antv/x6-plugin-selection'
import { Snapline } from '@antv/x6-plugin-snapline'
import { Keyboard } from '@antv/x6-plugin-keyboard'
import { Clipboard } from '@antv/x6-plugin-clipboard'
import { History } from '@antv/x6-plugin-history'
import React from "react"

const graphConfig = {
    grid: true,
    mousewheel: {
      enabled: true,
      zoomAtMousePosition: true,
      modifiers: 'ctrl',
      minScale: 0.5,
      maxScale: 3,
    },
    connecting: {
      router: 'manhattan',
      connector: {
        name: 'rounded',
        args: {
          radius: 8,
        },
      },
      anchor: 'center',
      connectionPoint: 'anchor',
      allowBlank: false,
      snap: {
        radius: 20,
      },
      createEdge() {
        return new Shape.Edge(edgeConfig)
      },
      validateConnection({ targetMagnet }: any) {
        return !!targetMagnet
      },
    },
    highlighting: {
      magnetAdsorbed: {
        name: 'stroke',
        args: {
          attrs: {
            fill: '#5F95FF',
            stroke: '#5F95FF',
          },
        },
      },
    },
}

const useGraphRegisterNode = () => {
    Graph.registerNode('custom-rect', shapeRectConfig, true)
    Graph.registerNode('custom-polygon', shapePolygonConfig, true)
    Graph.registerNode('custom-circle', shapeCircleConfig, true)
    Graph.registerNode('custom-ellipse', shapeEllipseConfig, true)
}

export const createGraph = (config = {}) => {
    useGraphRegisterNode()
    const mergeConfig: any = {...graphConfig, ...config}
    const container = mergeConfig.container
    const graph = new Graph(mergeConfig)

    return graph
}

// 控制连接桩显示/隐藏
const showPorts = (ports: NodeListOf<SVGElement>, show: boolean) => {
    for (let i = 0, len = ports.length; i < len; i += 1) {
      ports[i].style.visibility = show ? 'visible' : 'hidden'
    }
}

export function useGraphBindKey (graph: any) {
    console.log('ggggg', graph)
    graph.bindKey(['meta+c', 'ctrl+c'], () => {
      console.log('ctrl + c')
        const cells = graph.getSelectedCells()
        if (cells.length) {
          graph.copy(cells)
        }
        return false
    })
    graph.bindKey(['meta+x', 'ctrl+x'], () => {
        const cells = graph.getSelectedCells()
        if (cells.length) {
          graph.cut(cells)
        }
        return false
    })

    graph.bindKey(['meta+v', 'ctrl+v'], () => {
        if (!graph.isClipboardEmpty()) {
          const cells = graph.paste({ offset: 32 })
          graph.cleanSelection()
          graph.select(cells)
        }
        return false
    })

    // undo redo
    graph.bindKey(['meta+z', 'ctrl+z'], () => {
        if (graph.canUndo()) {
        graph.undo()
        }
        return false
    })
    graph.bindKey(['meta+shift+z', 'ctrl+shift+z'], () => {
        if (graph.canRedo()) {
        graph.redo()
        }
        return false
    })

    // select all
    graph.bindKey(['meta+a', 'ctrl+a'], () => {
        const nodes = graph.getNodes()
        if (nodes) {
        graph.select(nodes)
        }
    })
    
    // delete
    graph.bindKey('backspace', () => {
        const cells = graph.getSelectedCells()
        console.log('=====', cells, graph)
        if (cells.length) {
          graph.removeCells(cells)
        }
    })

    // zoom
    graph.bindKey(['ctrl+1', 'meta+1'], () => {
        const zoom = graph.zoom()
        if (zoom < 1.5) {
        graph.zoom(0.1)
        }
    })
    graph.bindKey(['ctrl+2', 'meta+2'], () => {
        const zoom = graph.zoom()
        if (zoom > 0.5) {
        graph.zoom(-0.1)
        }
    })

    return graph
}

export function useGraphEvent (graph: any, container: any) {
    graph.on('node:mouseenter', () => {
        const ports = container.querySelectorAll(
          '.x6-port-body',
        ) as NodeListOf<SVGElement>
        showPorts(ports, true)
    })
    graph.on('node:mouseleave', () => {
        const ports = container.querySelectorAll(
          '.x6-port-body',
        ) as NodeListOf<SVGElement>
        showPorts(ports, false)
    })
    return graph
}

export function useGraphPlugins (graph: any) {
    graph.use(new Transform({resizing: true, rotating: true,}))
        .use(new Selection({enabled: true, rubberband: true, showNodeSelectionBox: true,}))
        .use(new Snapline())
        .use(new Keyboard({enabled: true, global: true}))
        .use(new Clipboard({enabled: true}))
        .use(new History())
}

export type NodeType = '胶囊' | '矩形' | '圆角矩形' | '菱形' | '平行四边形' | '圆形' | '椭圆'
const tools = [
    {
      name: 'node-editor',
      args: {
        attrs: {
          backgroundColor: '#EFF4FF',
        },
      },
    },
]

export function useCreateNode(graph: any) {
    return (type: NodeType) => {
        switch (type) {
            case '胶囊':
                return graph.createNode({
                    shape: 'custom-rect',
                    label: '开始',
                    attrs: {
                        body: {
                        rx: 20,
                        ry: 26,
                        },
                    },
                    tools
                })
            case '矩形':
                return graph.createNode({
                    shape: 'custom-rect',
                    label: '过程',
                    tools
                })
            case '圆角矩形':
                return graph.createNode({
                    shape: 'custom-rect',
                    label: '可选过程',
                    attrs: {
                        body: {
                          rx: 6,
                          ry: 6,
                        },
                    },
                    tools
                })
            case '菱形':
                return graph.createNode({
                    shape: 'custom-polygon',
                    attrs: {
                      body: {
                        refPoints: '0,10 10,0 20,10 10,20',
                      },
                    },
                    label: '决策',
                    tools
                })
            case '平行四边形':
                return graph.createNode({
                    shape: 'custom-polygon',
                    attrs: {
                        body: {
                        refPoints: '10,0 40,0 30,20 0,20',
                        },
                    },
                    label: '数据',
                    tools
                })
            case '圆形':
                return graph.createNode({
                    shape: 'custom-circle',
                    label: '连接',
                    tools
                })
            case '椭圆':
                return graph.createNode({
                    shape: 'custom-ellipse',
                    label: '连接',
                    tools
                })
        }
    }
}

