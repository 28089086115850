import { ProColumns } from "@ant-design/pro-table";
import { Double } from "@components/Common";
import { CustomerNameLinkRender } from "@components/Customer/List/data";
import { DescListItem } from "@components/EnterpriseDetail/type";
import { useHasAuthCode } from "@hooks/index";
import { StateType } from "@store/index";
import { isDef } from "@utils/index";
import { Progress, TreeSelect } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { taskStatusOptions } from "../../data";

export type TableListItem = {

}

const format = "YYYY-MM-DD HH:mm"
export const taskDesList: DescListItem[] = [
    {
        label: '任务状态',
        param: 'taskStatus',
        value: ({taskStatus}: any) => {
          return (taskStatusOptions.find(v => v.value === taskStatus)||{}).label
        },
        span: 1
    },
    {
        label: '任务进度',
        param: 'taskProgress',
        value: ({taskProgress}: any) => `${taskProgress||0}%`,
        span: 1
    },
    {
        label: '任务完成指标',
        param: 'taskFinishTarget',
        value: ({taskFinishTarget}: any) => ['', '跟进一次客户', '促成客户成交'][taskFinishTarget],
        span: 1
    },
    {
        label: '创建时间',
        param: 'taskCreateTime',
        value: ({taskCreateTime}: any) => moment(taskCreateTime).format(format),
        span: 1
    },
    {
        label: '开始时间',
        param: 'taskStartTime',
        value: ({taskStartTime}: any) => moment(taskStartTime).format(format),
        span: 1
    },
    {
        label: '结束时间',
        param: 'taskEndTime',
        value: ({taskEndTime}: any) => moment(taskEndTime).format(format),
        span: 1
    },
    {
        label: '发起人',
        param: 'taskLauncherStr',
        span: 1
    },
    {
        label: '负责人',
        param: 'taskChargersStr',
        span: 2
    },
    {
        label: '任务描述',
        param: 'taskDesc',
        span: 3
    }
]

export const columnsTaksProcessFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '负责人',
        dataIndex: 'taskChargers',
        order: 7,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const taskChargers = (ids||[]).map((v: string) => v.slice(1))
            return { taskChargers }
          },
        },
        render: (text: any, record: any) => `${(record.taskChargers||[]).toString()}`
      },
      {
        title: '指派客户数量',
        dataIndex: 'taskCustomerNum',
        hideInSearch: true,
        fixed: 'left',
        align: 'center'
      },
      {
        title: '已执行客户数量',
        dataIndex: 'taskExecuteCustomerNum',
        hideInSearch: true,
        fixed: 'left',
        align: 'center'
      },
      {
        title: '执行进度',
        dataIndex: 'taskProgress',
        renderFormItem: (props) => <Double props1={{addonAfter: '%'}} props2={{addonAfter: '%'}} />,
        render: (text: any, record: any) => isDef(record.taskProgress) ? <Progress percent={record.taskProgress||0} size="small" /> : ''
     },
      {
        title: '完成时间',
        dataIndex: 'finishTime',
        hideInSearch: true,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        render: (text: any, record: any) => `${record.finishTime||''}`
      },
    ]
}

export const columnsCustomerCheckFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '负责人',
        dataIndex: 'taskChargers',
        order: 7,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const taskChargers = (ids||[]).map((v: string) => v.slice(1))
            return { taskChargers }
          },
        },
        render: (text: any, record: any) => `${(record.taskChargers||[]).toString()}`
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        fixed: 'left',
        align: 'center',
        render: (text, {customerId: id, lqId, customerName: name}: any) => <CustomerNameLinkRender text={text} hasAuth={hasAuthCode(2016)} search={{id, name, lqId, type: 0, routeName: '客户详情', fromPath: '/customer/list'}} />
      },
      {
        title: '参考信息',
        dataIndex: 'info',
        hideInSearch: true,
        fixed: 'left',
        align: 'center',
      },
      {
        title: '跟进方式',
        dataIndex: 'followUpMethod',
        hideInSearch: true,
        fixed: 'left',
        align: 'center',
      },
    //   {
    //     title: '跟进反馈',
    //     dataIndex: 'followUpFeedback',
    //     hideInSearch: true,
    //  },
     {
        title: '跟进内容',
        dataIndex: 'followUpContent',
        hideInSearch: true,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.followUpContent}</div>
     },
     {
        title: '下一步动作',
        dataIndex: 'nextStepContent',
        hideInSearch: true,
        render: (text: any, record: any) => <div style={{wordBreak: 'break-all', maxWidth: 460}}>{record.nextStepContent}</div>
        // width: 120,
        // order: 6,
     },
     {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text: any, record: any) => [
          <a key="跟进" onClick={() => operate && operate('添加跟进', record)}>跟进</a>,
        ],
      },
    ]
}