import React from "react";
import { Icon } from "@components/index";
import { Row, Tag, Tooltip } from "antd";
import { ArticleTitleRender, IssueTitleRender, StatusTag, SurveyTitleRender } from "../Home/data";

export const mockArticleItem = (n: number = 1) => new Array(n).fill({
    title: '文档标题',
    author: '章三',
    publicTime: '2022-03-22',
    status: (Math.random() * 10)%4,
    browse: 2000,
    collection: 2334,
}).map((v, idx) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))

export type OperateActionType = '上架' | '下架' | '编辑' | '删除' | '填写' | '详情'

export const MyCollectionColumns: (operate?: Function) => any[] = (operate) => {
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (rext: any, record: any) => <ArticleTitleRender record={record} />
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 80,
            fixed: 'right',
            render: (text: any, record: any) => [
                <Tooltip key="fill" title="取消收藏"><Icon key="del" type="icon-shanchu" onClick={() => operate && operate(record, '删除')} style={{fontSize: 16, marginRight: 10}} /></Tooltip>,
            ],
        },
    ]
} 

export const MyPublicTabPanes = [
    {
        Tab: '全部',
        key: JSON.stringify({}),
        Content: null
    },
    {
        Tab: '待审核',
        key: JSON.stringify({status: 3}),
        Content: null
    },
    {
        Tab: '已上架',
        key: JSON.stringify({state: 1}),
        Content: null
    },
    {
        Tab: '已下架',
        key: JSON.stringify({state: 2}),
        Content: null
    },
    {
        Tab: '审核不通过',
        key: JSON.stringify({status: 2}),
        Content: null
    }
]

const calcSatus = ({status, state}: any) => {
    if (state === 1) {
        return '已上架'
    } else if (state === 2) {
        return '已下架'
    } else if (status === 1) {
        return '已上架'
    } else if (status === 2) {
        return '不通过'
    } else if (status === 3) {
        return '待审核'
    }
}

export const MyPublicColumns: (operate?: Function) => any[] = (operate) => {
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (rext: any, record: any) => <ArticleTitleRender record={record} />
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            fixed: 'right',
            render: (text: any, record: any) => [
                <Tooltip key="state" title={record.mark}><p style={{textAlign: 'center', color: `${['','green', 'red', 'green'][record.status]}`}}>{calcSatus(record)}</p></Tooltip>,
                <Row key="other" justify="center">
                    {record.status === 2 ? <Tooltip key="detail" title="审核详情"><Icon type="icon-shenhexiangqing" style={{fontSize: 16, marginRight: 10}} onClick={() => operate && operate(record, '详情')} /></Tooltip> : null}
                    {
                [1,2].includes(record.state) ? <Tooltip key="down" title={record.state === 1 ? '下架' : '上架'}>
                    <Icon type={record.state === 1 ? 'icon-xiajia' : 'icon-shangjia'} onClick={() => operate && operate(record, record.state === 1 ? '下架' : '上架')} style={{fontSize: 16, marginRight: 10}} />
                </Tooltip> : null}
                {record.format === 1 ? <Tooltip key="edit" title="编辑"><Icon type="icon-bianji" onClick={() => operate && operate(record, '编辑')} style={{fontSize: 16, marginRight: 10}} /></Tooltip> : null}
                <Tooltip key="del" overlayStyle={{overflow: 'hidden'}} title="删除"><Icon type="icon-shanchu" onClick={() => operate && operate(record, '删除')} style={{fontSize: 16, marginRight: 10}} /></Tooltip>
                </Row>
            ],
        },
    ]
}


export const MyAnswerTabPanes = [
    {
        Tab: '我的提问',
        Content: null
    },
    {
        Tab: '我的评论',
        Content: null
    }
]
export const MyAnswerColumns: (operate: Function, type: any) => any[] = (operate, type) => {
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (rext: any, record: any) => <IssueTitleRender record={record} StatusTag={<StatusTag docType={5} status={record.status} />} />
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            // width: 180,
            fixed: 'right',
            render: (text: any, record: any) => type === '1' ? ' ' : [
                <Tooltip key="state" title={record.mark}><p style={{textAlign: 'center', color: `${['','green', 'red', 'green'][record.state]}`}}>{calcSatus(record)}</p></Tooltip>,
                <Row key="other" justify="center">
                    {
                      [1,2].includes(record.state) ? <Tooltip key="down" title={record.state === 1 ? '下架' : '上架'}>
                      <Icon type={record.state === 1 ? 'icon-xiajia' : 'icon-shangjia'} onClick={() => operate && operate(record, record.state === 1 ? '下架' : '上架')} style={{fontSize: 16, marginRight: 10}} />
                     </Tooltip> : null
                    }
                    {record.format === 1 ? <Tooltip key="edit" title="编辑"><Icon type="icon-bianji" onClick={() => operate && operate(record, '编辑')} style={{fontSize: 16, marginRight: 10}} /></Tooltip> : null}
                    <Tooltip key="del" title="删除"><Icon type="icon-shanchu" onClick={() => operate && operate(record, '删除')} style={{fontSize: 16, marginRight: 10}} /></Tooltip>
                </Row>
            ],
        },
    ]
}


export const MySurveyColumns: (operate?: Function) => any[] = (operate) => {
    return [
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title',
            render: (rext: any, record: any) => <SurveyTitleRender record={{...record, type: 3.1}} />
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 120,
            fixed: 'right',
            render: (text: any, record: any) => [
                <Tooltip key="edit" title="填写"><Icon type="icon-bianji" onClick={() => operate && operate(record, '填写')} style={{fontSize: 16, marginRight: 10}} /></Tooltip>,
                <Tooltip key="del" title="删除"><Icon type="icon-shanchu" onClick={() => operate && operate(record, '删除')} style={{fontSize: 16, marginRight: 10}} /></Tooltip>,
            ],
        },
    ]
}
 
