import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Button, Dropdown, Menu, Progress, Space, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { OperateActionType } from "../../data"
import { StatusDot, signOptions, statusOptions, textOptions } from "@components/Finance/Contract/data"
import { contractAuditStatus } from "@api/index"

const A = ({children, ...rest}: any) => <a {...rest}>{children}</a>

export const PurchaseContractAuditOperate = ({record, type, operate}: any) => {
  const [, hasAuthCode] = useHasAuthCode()
  const Btn = type === 'primary' ? Button : A
  const style = { marginLeft: 10, whiteSpace: 'nowrap'} as any
  return ([
    (hasAuthCode(2453) && record?.status > 0) ? <Btn type={type} style={style} key="审批通过" onClick={() => operate && operate('采购合同-审批通过', record)}>审批通过</Btn> : null,
    (hasAuthCode(2454) && record?.status > 0) ? <Btn type={type} style={style} key="审批拒绝" onClick={() => operate && operate('采购合同-审批拒绝', record)}>审批拒绝</Btn> : null,
    (hasAuthCode(2456) && [-1].includes(record?.status)) ? <Btn type={type} style={style} key="作废合同" onClick={() => operate && operate('采购合同-作废合同', record)}>作废合同</Btn> : null,
    (hasAuthCode(-1)) ? <Btn type={type} style={style} key="编辑合同" onClick={() => operate && operate('采购合同-编辑', record)}>编辑合同</Btn> : null,
  ])
}

export const columnsFn: (operate: (type: OperateActionType, record?: any) => void, activeKey: any) => ProColumns<any>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '系统编号',
        dataIndex: 'sysNumber',
        fixed: 'left',
        order: 15,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '采购合同' : '采购合同编号',
        dataIndex: 'cgContractNumber',
        fieldProps: { placeholder: '请输入采购合同编号'},
        order: 14,
        fixed: 'left',
        align: 'center',
        render: (text: any, record: any) => <Link to={`/finance/purchaseContractDetail?${stringify({contractId: record?.contractId, fromPath: '/finance/contractAudit' })}`} >{record.cgContractNumber}</Link>
      },
      {
        title: (_, type) => type === 'form' ? '销售合同' : '销售合同编号',
        dataIndex: 'xsContractNumber',
        fieldProps: { placeholder: '请输入销售合同编号'},
        order: 13,
        align: 'center',
        render: (text: any, record: any) => <Link to={`/finance/saleContractDetail?${stringify({contractId: record?.xsContractId, fromPath: '/finance/contractAudit' })}`} >{record.xsContractNumber}</Link>
      },
      {
        title: (_, type) => type === 'form' ? '采购合同' : '采购合同名称',
        dataIndex: 'contractName',
        fieldProps: { placeholder: '请输入采购合同名称'},
        order: 12,
        align: 'center',
        render: (text: any, record: any) => record.cgContractName
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 11,
        align: 'center',
      },
      {
        title: '供应商',
        dataIndex: 'supplier',
        order: 12,
        align: 'center',
        hideInSearch: true,
      },
      {
        title: '业务类型',
        dataIndex: 'businessTypes',
        order: 4,
        valueType: 'select',
        align: 'center',
        params: { dictId: 269 },
        request,
        search: {
          transform: (businessTypes) => {
            return { businessTypes }
          },
        },
        fieldProps: { mode: 'multiple' },
        render: (text: any, record: any) => record.businessTypeStr
      },
      {
        title: (_, type) => type === 'form' ? '有无文本' : '有无文本合同',
        dataIndex: 'haveText',
        order: 6,
        align: 'center',
        fieldProps: { options: textOptions },
        valueType: 'select',
      },
      {
        title: (_, type) => type === 'form' ? '合同金额' : '合同金额(元)',
        dataIndex: 'contractAmount',
        order: 8,
        sorter: true,
        align: 'center',
        search: {
          transform: (contractAmounts) => {
            return { contractAmounts }
          },
        },
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.contractAmount)
      },
      {
        title: '签约日期',
        dataIndex: 'signDate',
        sorter: true,
        hideInSearch: true,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '是否盖章' : '合同是否盖章',
        dataIndex: 'whetherStamp',
        order: 5,
        align: 'center',
        fieldProps: { options: signOptions, placeholder: '请选择合同是否盖章'  },
        valueType: 'select',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        // order: 8,
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => <div style={{width: 300, }}>{record.remark}</div>
      },
      {
        title: '审批状态',
        dataIndex: 'status',
        order: 16,
        align: 'center',
        fieldProps: { options: statusOptions, mode: 'multiple' },
        request: async () => {
          const { data } = await contractAuditStatus({type: 1}) as any
          return data||[]
        },
        valueType: 'select',
        // render: (text: any, record: any) => `${(record.statusStr||[]).toString()}`
        render: (text: any, record: any) => <><StatusDot status={record.status} />{`${(record.statusStr||[]).toString()}`}</>
      },
      {
        title: '提交人',
        dataIndex: 'creators',
        order: 10,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const creators = (ids||[]).map((v: string) => v.slice(1))
            return { creators }
          },
        },
        render: (text: any, record: any) => `${(record.creatorStr||[]).toString()}`
      },
      {
        title: '提交时间',
        dataIndex: 'createTime',
        valueType: 'dateRange',
        sorter: true,
        fieldProps: { allowEmpty: [true, true]},
        order: 7,
        align: 'center',
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {createTimes: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${(record.createTime||[]).toString()}`
      },
      {
        title: '当前审批人',
        dataIndex: 'currentAuditPerson',
        order: 9,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const currentAuditPersons = (ids||[]).map((v: string) => v.slice(1))
            return { currentAuditPersons }
          },
        },
        render: (text: any, record: any) => `${(record.currentAuditPersonStr||[]).toString()}`
      },
      {
        title: '操作',
        key: 'option',
        dataIndex: 'option',
        valueType: 'option',
        width: 240,
        fixed: 'right',
        // @ts-ignore
        render: (text: any, record: any) => <PurchaseContractAuditOperate record={record} operate={operate} />,
      },
    ]
}