import React from "react";
import { Button } from "antd";
import { AuthActionArr } from "@components/EnterpriseDetail/type";

export type TableListItem = {
    key: number;
    id: string;
};

export type OperateActionType = '新建客户账号' | '编辑' | '删除' 


// 客户账号
export const CustomerNumberColumnsFn: (operate?: Function, authCode?: AuthActionArr) => any[] = (operate, authCode) => {
    return [
        {
            title: '账号类型',
            dataIndex: 'typeStr',
            key: 'typeStr',
        },
        {
            title: '客户账号',
            dataIndex: 'customerAccount',
            key: 'customerAccount',
        },
        {
            title: '账号来源',
            dataIndex: 'accountSourceStr',
            key: 'accountSourceStr',
        },
        {
            title: '合作模式',
            dataIndex: 'cooperationModeStr',
            key: 'cooperationModeStr',
        },
        {
            title: '实名认证名称',
            dataIndex: 'getMoneyer',
            key: 'getMoneyer',
        },
        // {
        //     title: '手机号码',
        //     dataIndex: 'mobilePhoneNumber',
        //     key: 'mobilePhoneNumber',
        // },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            valueType: 'dateRange',
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            readOnly: true,
            render: (text: any, record: any) => {
                return [
                    authCode?.includes('edit') ? <Button key="warn" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('编辑', record)}>编辑</Button> : null,
                    authCode?.includes('del') ? <Button key="del" type="link" disabled={[0].includes(record.isCreator)} onClick={() => operate && operate('删除', record)}>删除</Button> : null,
                ]
            }
        }
    ]
}