import ProTable, { ActionType } from "@ant-design/pro-table"
import { knowledDocDel, knowledDocIssueList, knowledDocUpDown, knowledgeIssueList } from "@api/knowledge"
import { useHasAuthCode } from "@hooks/index"
import { productApi } from "@utils/index"
import { Modal } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, OperateActionType, TableListItem } from "./data"

const getApi = productApi(knowledgeIssueList)
const docDelApi = productApi(knowledDocDel)
const docUpDownApi = productApi(knowledDocUpDown)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record: any) => {
        const { id } = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelApi({ids: [id]}, true, reFush)
                },
            });
        } else if(type === '上架') {
            Modal.confirm({
                title: '确认要上架吗?',
                content: '文档上架后，在系统中可以被用户浏览',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 1}, true, reFush)
                },
            });
        } else if(type === '下架') {
            Modal.confirm({
                title: '确认要下架吗?',
                content: '文档下架后，将不被系统用户搜索到',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 2}, true, reFush)
                },
            });
        }
    }
    const reFush = () => {
        setTimeout(() => {
            ref.current?.reload()
        }, 1000)
    }
    const columns = columnsFn(operate)
    
    return (<ProTable<TableListItem>
        scroll={{x: 1200}}
        columns={columns}
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        rowKey="id"
        pagination={{defaultPageSize: 10}}
        actionRef={ref as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => 
            [ ...dom.reverse() ]
        }}
    />)
}