import { DescriptionsList, MyTabs } from "@components/EnterpriseDetail"
import React, { useState } from "react"
import { attachmentColumnsFn, columnsFn, descList } from "./data"
import { Alert, Avatar, Button, Col, Row, Table, Tag, Timeline } from "antd"
import { BackBan, Preview } from "@components/index"
import ProTable from "@ant-design/pro-table"
import { SaleContractOperate } from "../../data"
import { useSaleContractHooks } from "../../hooks"
import { useAddRebackHooks } from "@components/Finance/RebackMoney/cpnts/RebackList/hooks"
import { OperateActionType } from "@components/Finance/Contract/data"
import { OperateActionType as AuditOperateActionType } from "@components/Finance/ContractAudit/data"
import AddSaleContractModalForm from "../AddSaleContract"
import { AddRebackModalForm } from "@components/Finance/RebackMoney/cpnts/RebackList/cpnts"
import { useCommonApiHooks } from "@hooks/index"
import { BASEURL } from "@api/index"
import { parseSearch } from "@utils/index"
import { useLocation } from "react-router-dom"
import { SaleContractAuditOperate } from "@components/Finance/ContractAudit/cpnts/SaleContractAudit/data"
import { useContractAuditHooks } from "@components/Finance/ContractAudit/cpnts/SaleContractAudit/hooks"
import { ContractAuditModalForm } from "@components/Finance/ContractAudit/cpnts/SaleContractAudit"

const ContractInfo = ({dataObj}: any) => {
    const [previewProps, setPreviewProps] = useState({visible: false, src: undefined})
    const operate = (type: string, record: any) => {
    const { url: src } = record || {}
    if (type === '预览') {
        setPreviewProps({
            visible: true,
            src
        })
    }
    }
    const columns = attachmentColumnsFn(operate)
    return (<div style={{padding: 20}}>
        <div style={{background: '#fff'}}><div className="zen-ban">基本信息</div></div>
        <DescriptionsList data={dataObj} descList={descList} />
        <div style={{background: '#fff', marginBottom: 20}}><div className="zen-ban">附件信息</div></div>
        <Table columns={columns} dataSource={dataObj?.files||[]} pagination={false} rowKey="id" />
        <Preview {...previewProps} modalProps={{maskClosable: false, onCancel: () => setPreviewProps({...previewProps, visible: false})}}></Preview>
    </div>)
}

const RebackInfo = ({contractId}: any) => {
    const [dataSource, setDataSource, getApi] = useCommonApiHooks({url: `${BASEURL}/contract/contractBackLog`, method: 'POST'}, {contractId}, true)
    return (<ProTable<any>
        style={{marginTop: 20}}
        columns={columnsFn(() => null)}
        request={async () => []}
        dataSource={dataSource}
        options={false}
        search={false}
        toolbar={[]}
        rowKey="id"
        pagination={false}
/>)
}

const auditStatusMap: any = {
    '-1': {
        color: '#1DBCA1',
        statusName: '通过'
    },
    '-2': {
        color: '#FF4D4F',
        statusName: '拒绝'
    },
    '-3': {
        color: '#faad14',
        statusName: '作废'
    },
    '-4': {
        color: '#999999',
        statusName: '撤销'
    }
}

export const AuditStatusTag = ({status}: any) => {
    const color = status > 0 ? '#1890FF' : auditStatusMap[status]?.color
    return (<Tag style={{lineHeight: 'initial'}} color={color}>
    {status > 0 ? `待${status}级审批` : auditStatusMap[status]?.statusName}
</Tag>)
}

const AuditTimeLineItem = ({item, idx, hiddenLevel}: any) => {
    const color = item?.status > 0 ? '#1890FF' : auditStatusMap[item?.status||-1]?.color
    const style = {color: '#8F9094', fontSize: 12, lineHeight: '20px'}
    return (<Timeline.Item color={color} className="jns-audit-time-line">
                <Row align="middle">
                    <span style={{fontWeight: 500, marginRight: 8}}>{item?.desc} </span>
                    <Tag style={{lineHeight: 'initial'}} color={color}>
                        {item?.status > 0 ? hiddenLevel ? '待审批' : `待${item.status}级审批` : (auditStatusMap[item?.status]?.statusName || '已提交')}
                    </Tag>
                </Row>
                { item?.time ? <Row style={style}>{item?.time}</Row> : null }
                { item?.name ? <Row style={style}><span style={{marginRight: 4}}>{idx === 0 ? '发起人' : '审批人'}:</span>{item?.name}</Row> : null }
                { item?.remark ? <Row style={style}><span style={{marginRight: 4}}>备注:</span>{item?.remark}</Row> : null }
    </Timeline.Item>)
}

export const AuditTimeLine = ({aduitLine, hiddenLevel}: any) => {
    return (<Timeline>
        { aduitLine?.map((v: any, i: number) => <AuditTimeLineItem key={i} item={v} idx={i} hiddenLevel={hiddenLevel} />) }
    </Timeline>)
}


const aduitLine = [
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '提交申请',
        remark: 'xxxxccxjkhkf',
        status: 1, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '待审批',
        remark: 'xxxxccxjkhkf',
        status: -2, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '待审批',
        remark: 'xxxxccxjkhkf',
        status: -3, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '待审批',
        remark: 'xxxxccxjkhkf',
        status: -4, 
    },
    {
        name: '章三',
        time: '2023-03-06 16:22:20',
        desc: '待审批',
        remark: 'xxxxccxjkhkf',
        status: -5, 
    },
]

type SaleContractDetailProps = {
    contractId: React.Key;
    fromPath?: string;
}


export const SaleContractDetail = ({contractId, fromPath}: SaleContractDetailProps) => {

    const [contractInfo, setContractInfo, getApi] = useCommonApiHooks({url: `${BASEURL}/contract/contractDetail`, method: 'POST'}, {contractId}, true) 
    const [aduitLine, setAduitLine, getAduitLineApi] = useCommonApiHooks({url: `${BASEURL}/auditLog/getAuditLog`, method: 'POST'}, {keyId: contractId, type: 1}, true) 

    const { 
        formRef,
        commonModalFormProps: addSaleContractModalFormProps,
        setCommonModalFormProps: setAddSaleContractModalFormProps,
        createOperate
    } = useSaleContractHooks()

    const { 
        addRebackModalFormProps,
        setAddRebackModalFormProps,
        createOperate: createRebackOperate
    } = useAddRebackHooks()

    const {
        formRef: auditFormRef,
        commonModalFormProps,
        setCommonModalFormProps,
        createOperate: createAuditOperate,
    } = useContractAuditHooks()

    const reFush = () => {
        getApi({contractId})
        getAduitLineApi({keyId: contractId, type: 1})
    }

    const operate = async (type: OperateActionType | AuditOperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        switch (type) {
            case '销售合同-提交并创建回款记录':
                const ok = await formRef.current?.validateFields().catch(err => false)
                break;
            case '销售合同-回款申请':
                // const reBackAction = createRebackOperate(record, reFush)
                // reBackAction('回款记录-新建', {})
                const { contractId, customerId, customerName, xsContractId, xsContractNumber,  } = record || {} 
                setAddRebackModalFormProps({
                    ...addRebackModalFormProps,
                    title: '新建回款记录',
                    visible: true,
                    params: { customerName, noCache: new Date() },
                    request: async () => {
                        return({
                            customerId,
                            xsContract: xsContractId ? {
                                label: xsContractNumber,
                                value: xsContractId
                            } : undefined
                        })
                    },
                    onSuccess: () => {
                        setAddRebackModalFormProps({visible: false})
                        reFush?.()
                    }
                })
                break;
            case '销售合同-审批通过':
            case '销售合同-审批拒绝':
            case '销售合同-作废合同':
                const auditAction = createAuditOperate(record, reFush)
                auditAction(type as any, {})
                break;
            default:
                action(type as any, {})
        }
    }

    const tabPanes = [
        {
            Tab: '合同信息',
            key: ' ',
            Content: <ContractInfo dataObj={contractInfo} />
        },
        {
            Tab: '回款明细',
            key: 2,
            Content: <RebackInfo contractId={contractId} />
        }
    ]

    return (<>
    <BackBan 
        title={<>{contractInfo?.sysNumber} <AuditStatusTag status={contractInfo.status} /></>}
        actions={
            // @ts-ignore
            fromPath === '/finance/contractAudit' ? <SaleContractAuditOperate type="primary" record={contractInfo||{}} operate={operate} /> as any : <SaleContractOperate type="primary" record={contractInfo||{}} operate={operate} /> as any
        }
    />
    <Row gutter={[24,12]} justify="space-between" style={{width: 'calc(100% + 12px)'}}>
        <Col span={17}>
            <MyTabs 
                tabBarStyle={{paddingLeft: 20, marginBottom: 0, background: '#fff'}} 
                style={{background: '#fff'}} 
                tabPanes={tabPanes} 
            />
        </Col>
        <Col span={7} style={{background: '#fff'}}>
            <div style={{background: '#fff', marginBottom: 20, marginTop: 20}}><div className="zen-ban">流程动态</div></div>
            <div style={{background: '#F5F7FE', borderRadius: 8, padding: 20}}>
                <AuditTimeLine aduitLine={aduitLine} />
            </div>
        </Col>
    </Row>
    <AddSaleContractModalForm
        modalProps={{
            onCancel: () => setAddSaleContractModalFormProps({...addSaleContractModalFormProps, visible: false}),
            maskClosable: false
        }}  
        submitter={{
            render: (props, defaultDoms) => {
                return [
                    <Button key="addContact" style={{marginLeft: 10}} onClick={() => operate('销售合同-提交并创建回款记录', null)}>提交并创建回款记录</Button>,
                    ...defaultDoms,
                ];
            },
        }}
        formRef={formRef}
        {...addSaleContractModalFormProps} 
    />
    <AddRebackModalForm
        modalProps={{
            onCancel: () => setAddRebackModalFormProps({...addRebackModalFormProps, visible: false}),
            maskClosable: false
        }}  
        {...addRebackModalFormProps} 
    />
    <ContractAuditModalForm
        modalProps={{
            onCancel: () => setCommonModalFormProps({...commonModalFormProps, visible: false}),
            maskClosable: false
        }}  
        formRef={auditFormRef}
        {...commonModalFormProps} 
    />
    </>
    )
}

export default () => {
    const { search } = useLocation();
    const { contractId, fromPath } = parseSearch(search)
    return <SaleContractDetail contractId={contractId} fromPath={fromPath} />
}