import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
export { BASEURL, LQBASEURL } from './baseUrl'

// export const BASEURL = `https://mock.mengxuegu.com/mock/6253dd7c560e3d1eabd76a57/customer`
// 最新发布, 热门文档, 热门回答
export const knowledgeTop50 = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/hot`,
    method: 'GET',
    data,
})

// 获取首页文档列表
export const knowledgeHomeDoc = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getHomeDocument`,
    // method: 'GET',
    data,
})

// w问答社区
export const knowledgeIssueCommunity = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionCommunity`,
    // method: 'GET',
    data,
})

// 分页全局搜索
export const knowledgeSearchAll = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/searchDocumentsByConditions`,
    // method: 'GET',
    data,
})

// 我的知识库--我的收藏
export const knowledgeMyCollect = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getMyCollection`,
    // method: 'GET',
    data,
})

// 获取我的--我发布的接口
export const knowledgeMyPublic = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/released`,
    // method: 'GET',
    data,
})

// 获取我的--我的问题
export const knowledgeMyQuestion = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getMyQuestion`,
    // method: 'GET',
    data,
})

// 获取我的--我的问题
export const knowledgeMyAnswer = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getMyComment`,
    // method: 'GET',
    data,
})

// 获取我的--我的问卷
export const knowledgeMySurvery = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/listMyQuestionaire`,
    // method: 'GET',
    data,
})

// 获取我的--查看通用文档树
export const knowledgeTree = (data?: any) => request({
    url: `${BASEURL}/common/queryDocumentTypeList`,
    // method: 'GET',
    data,
})

// 获取我的--查看文档列表
export const knowledgeDocList = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/queryDocumentList`,
    // method: 'GET',
    data,
})

// 文档审核列表
export const knowledgeDocAuditList = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/documentReview`,
    // method: 'GET',
    data,
})

// 文档审核
export const knowledgeDocAudit = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/review`,
    // method: 'GET',
    data,
})

// 问题列表
export const knowledgeIssueList = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/searchQuestionsByConditions`,
    // method: 'GET',
    data,
})

// 客户详情文档列表查询
export const knowledgeGetDocListByCustomer = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getDocumentListByCustomer`,
    // method: 'GET',
    data,
})


// 创建编辑文档
export const knowledgeDocCreate = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/saveOrUpdateDocumentForRegularUsers`,
    // method: 'GET',
    data,
})

// 创建编辑问卷模版
export const knowledgeDocTemplateCreate = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/saveOrUpdateQuestionaireForRegularUsers`,
    // method: 'GET',
    data,
})

// 创建编辑问题
export const knowledgeDocIssueCreate = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/saveOrUpdateQuestion`,
    // method: 'GET',
    data,
})

// 创建编辑问卷
export const knowledgeDocSurveryCreate = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/saveOrUpdateAnswer`,
    // method: 'GET',
    data,
})

// 文档新增编辑分类
export const knowledgeDocCategoryAddUpdate = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/newDocumentType`,
    // method: 'GET',
    data,
})

// 文档分类删除
export const knowledgeDocCategoryDel = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/delDocumentType`,
    // method: 'GET',
    data,
})


// 文档删除
export const knowledDocDel = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/del`,
    // method: 'GET',
    data,
})

// 文档上架/下架
export const knowledDocUpDown = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/publishOrUnpublishReleased`,
    // method: 'GET',
    data,
})

// 文档列表
export const knowledDocPageList = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/docList`,
    // method: 'GET',
    data,
})

// 校验文档名称
export const knowledCheckDocTitle = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/verifyTitle`,
    method: 'GET',
    data,
})


// 问题列表
export const knowledDocIssueList = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/docIssueList`,
    // method: 'GET',
    data,
})

// 创建/编辑文档
// export const knowledDocCreate = (data?: any) => request({
//     url: `${BASEURL}/knowledgeBase/docCreate`,
//     // method: 'GET',
//     data,
// })

// 文档详情
export const knowledDocumentDetails = (id?: any) => request({
    url: `${BASEURL}/knowledgeBase/documentDetails/${id}`,
    method: 'GET',
    // data,
})

// 问题详情
export const knowledgeIssueDetail = (data: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionDetail`,
    // method: 'GET',
    data,
})

// 已答问卷详情
export const knowledGetQuestionaireDetail = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionaireDetail`,
    // method: 'GET',
    data,
})

// 问题详情
export const knowledGetQuestionDetail = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionDetail`,
    // method: 'GET',
    data,
})

// 获取模版详情
export const knowledgeTemplateDetail = (data: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionaireTemplate`,
    // method: 'GET',
    data,
})

// 查询评论
export const knowledListComment = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/listComment`,
    // method: 'GET',
    data,
})


// 添加一级评论
export const knowledPublishComment = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/publishComment`,
    // method: 'GET',
    data,
})

// 回复二级评论
export const knowledReplyComment = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/replyComment`,
    // method: 'GET',
    data,
})


// 查询  子评论
export const knowledListSubComment = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/listSubComment`,
    // method: 'GET',
    data,
})

// // 删除文档
// export const knowledDeleteDoc = (data?: any) => request({
//     url: `${BASEURL}/knowledgeBase/deleteDoc`,
//     // method: 'GET',
//     data,
// })


// 收藏
export const knowledCollect = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/collect`,
    // method: 'GET',
    data,
})

// 点踩
export const knowledTrample = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/trample`,
    // method: 'GET',
    data,
})

// 点赞
export const knowledPraise = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/praise`,
    // method: 'GET',
    data,
})

// 确认解决问题
export const knowledSolveQuestion = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/solveQuestion`,
    // method: 'GET',
    data,
})


// // 问卷模板填写  提交
// export const knowledSaveOrUpdateAnswer = (data?: any) => request({
//     url: `${BASEURL}/knowledgeBase/saveOrUpdateAnswer`,
//     // method: 'GET',
//     data,
// })

// 已答问卷
export const knowledgeAnswered = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/getQuestionnaireAnswerList`,
    // method: 'GET',
    data,
})

// 文档列表拖拽排序
export const knowledgeDragSort = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/updateCategorySort`,
    // method: 'GET',
    data,
})

// 文档移动到文件夹下
export const knowledgeDocMove = (data?: any) => request({
    url: `${BASEURL}/knowledgeBase/moveCategory`,
    // method: 'GET',
    data,
})