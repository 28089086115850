import React, {  } from 'react'
import { Icon } from '@components/Common';
import { knowledCollect, knowledTrample, knowledPraise } from "@api/index"

import '../index.less'
import { productApi } from '@utils/util';

const collectApi = productApi(knowledCollect)
const trampleApi = productApi(knowledTrample)
const praiseApi = productApi(knowledPraise)
type iconProps = {
   title?: any;
   type: string;
   onClick?: any;
   className?: string
}
export const IconPro = ({title, type, ...rest}: iconProps) => {
   return <div className='iconDiv'  {...rest}>
       <Icon type={type} className='icon'/>
       <span className='title'>{ title }</span>
   </div>
}
/**
 * @multiconfig : 配置项
 * @type: 1-文档 2-评论    点赞收藏等为通用接口  type区分
 * 
 */
export default ({multiConfig, type, id,  ...rest}:any) => {

   const iconClick = async (iconType: string, actionFlag: boolean, success: any, commentId? :any)=> {
      if (iconType ===  'download') { 
        
      } else if (iconType ===  'Collection') {
         const { success:state } = await collectApi({actionFlag: !actionFlag, type, id}, false)
         state && success()
      } else if (iconType ===  'good') {
         const { success:state } = await praiseApi({actionFlag: !actionFlag, type, id: type === 2 ? commentId : id}, false)
         state && success()
      } else if (iconType ===  'notGood') {
         const { success:state } = await trampleApi({actionFlag: !actionFlag, type, id}, false)
         state && success()
      } else if (iconType ===  'sort') {
         
      } else if (iconType ===  'comment') {
         
      } else if (iconType ===  'reply') {
         console.log(45645)
      }
   }
    return <div className='multiconDiv' {...rest}>
        {
           multiConfig.map((item:any, index:number)=> {

               const { type, title, activation,commentId, onClick, success } = item || {}
               switch (type) {
                  case 'download':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('download', activation, success)} key={index} type='icon-xiazai' title={title}/>
                  case 'delete':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('delete', activation, success)} key={index} type='icon-shanchu' title={title}/>
                  case 'Collection':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('Collection', activation, success)} key={index} type={activation ? 'icon-startFill' : 'icon-shoucang' } title={title}/>
                  case 'good':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('good', activation, success, commentId)} key={index} type={activation ? 'icon-dianzan_kuai' : 'icon-dianzan_kuai-copy' } title={title} />
                  case 'notGood':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('notGood', activation, success)} key={index} type={activation ? 'icon-caishixin-' : 'icon-caishixin--copy'} title={title} />
                  case 'sort':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('sort', activation, success)} key={index} type={activation ? 'icon-anjiangxupailie' : 'icon-anshengxupailie' } title={title} />
                  case 'comment':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('comment', activation, success)} key={index} type={activation ? 'icon-pinglun1-copy' : 'icon-pinglun1' } title={title} />
                  case 'reply':
                     return <IconPro onClick={() => onClick ? onClick() : iconClick('reply', activation, success)} key={index} type={activation ? 'icon-bianjishuru-copy' : 'icon-bianjishuru' }  title={title} />
                            
               }
              
           })
        }
    </div>
 }
