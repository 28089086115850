import React, { Key, MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react"
import { MyTabs } from "@components/EnterpriseDetail"
import { helpTabPanes, mockArticleItem, solutionColumns, customerCaseColumns, qsTemplateColumns, helpDocColumns, issueColumns, calcHref } from "./data"
import { Avatar, Button, Empty, Input, List, Table, Tag, Tooltip } from 'antd'
import { Ellipsis, Icon } from "@components/index"
import { Link, useHistory } from "react-router-dom"
import { isFunction, parseSearch, productApi } from "@utils/index"
import { SearchProps } from "antd/lib/input"
import hotBg1 from '@assets/images/hot_bg1.jpg'
import hotBg2 from '@assets/images/hot_bg2.jpg'
import hotBg3 from '@assets/images/hot_bg3.jpg'
import './index.less'
import WithSearchTree, { WithSearchTreeProps, WithSearchTreeWarp } from "@components/Common/WithSearchTree"
import { useCommonApiHooks, useDeptDataHooks, useDocDataHooks, useTableHooks } from "@hooks/index"
import { customerContactAllList } from "@api/index"
import { BASEURL, knowledgeHomeDoc, knowledgeIssueCommunity, knowledgeSearchAll } from "@api/knowledge"
import { stringify } from "qs"
import { SearchEmpty } from "@components/Common/404"

const { Search } = Input
const getPageListApi = productApi(knowledgeHomeDoc)
const getIssueCommunityApi = productApi(knowledgeIssueCommunity)
const getSearchApi = productApi(knowledgeSearchAll)

const tabPanes = [
    {
        Tab: '社区首页',
        Content: () => <div style={{marginTop: 20}}><HomeList /></div>
    },
    {
        Tab: '解决方案',
        Content: () => <SolutionHOC type={1} title="解决方案" />
    },
    {
        Tab: '客户案例',
        Content: () => <SolutionHOC type={2} title="客户案例" />
    },
    {
        Tab: '问卷模版',
        Content: () => <SolutionHOC type={3} title="问卷模版" />
    },
    {
        Tab: '帮助文档',
        Content: () => <SolutionHOC type={4} title="帮助文档" />
    },
    {
        Tab: '问答社区',
        Content: () => <QACommunity />
    }
]

interface TabBarExtraContentProps extends SearchProps{

}
const TabBarExtraContent = (props: TabBarExtraContentProps) => {
    const history = useHistory()
    return (<div>
            <Search placeholder="热门搜索的问题" style={{ width: 250 }} {...props} />
            <Button type="primary" style={{marginLeft: 8}} onClick={() => history.push(`/knowledge/create?${stringify({type: 5, fromPath: '/knowledge/home', routeName: '创建问题'})}`)}>提问</Button>
            <Button type="primary" style={{marginLeft: 8}} onClick={() => history.push(`/knowledge/create?${stringify({fromPath: '/knowledge/home'})}`)}>写文档</Button>
            <Tooltip title="个人主页"><Avatar icon={<Icon type="icon-gerenzhongxin" onClick={() => history.push(`/knowledge/mine`)} />}  style={{marginLeft: 8, marginRight: 8, cursor: 'pointer'}} /></Tooltip>
    </div>)
}

// 首页 top50
const HomeList = () => {
    const [hotNewData] = useCommonApiHooks({url: `${BASEURL}/knowledgeBase/hot`, method: 'POST'}, {type: 1, pageCurrent: 1, pageSize: 50}, true)
    const [hotDocData] = useCommonApiHooks({url: `${BASEURL}/knowledgeBase/hot`, method: 'POST'}, {type: 2, pageCurrent: 1, pageSize: 50}, true)
    const [hotIssueData] = useCommonApiHooks({url: `${BASEURL}/knowledgeBase/hot`, method: 'POST'}, {type: 3, pageCurrent: 1, pageSize: 50}, true)
    const calcDatasource = (data: any) => {
        return Array.isArray(data) ? data : (data||{}).values||[]
    }
    const Header = ({title, bgSrc}: any) => <div style={{background: `url(${bgSrc})`, backgroundSize: 'cover', padding: '20px', fontSize: '18px', fontWeight: 500}}>{title}</div>
    return(<div className='home-list' style={{display: 'flex', justifyContent: 'space-around'}}>
        <List
            itemLayout="horizontal"
            style={{width: '30%', height: `calc(100vh - 200px)`, overflow: 'scroll', border: '1px solid #E8E8E8'}}
            header={<Header title="最新发布" bgSrc={hotBg1} />}
            dataSource={calcDatasource(hotNewData)}
            renderItem={(item: any, i) => (
            <List.Item style={{width: 'calc(100% - 20px)', margin: '0 10px'}}>
                <List.Item.Meta
                style={{boxSizing: 'border-box',}}
                avatar={''}
                title={<Link to={calcHref(item)} style={{wordBreak: 'break-all'}}>{item.title}</Link>}
                description={item.updateTime}
                />
            </List.Item>
            )}
         />
         <List
            itemLayout="horizontal"
            style={{width: '30%', height: `calc(100vh - 200px)`, overflow: 'scroll', border: '1px solid #E8E8E8'}}
            header={<Header title="热门文档" bgSrc={hotBg2} />}
            dataSource={calcDatasource(hotDocData)}
            renderItem={(item: any, i) => (
            <List.Item style={{width: 'calc(100% - 20px)', margin: '0 10px'}}>
                <List.Item.Meta
                avatar={<span style={{position: 'relative', display: 'inline-block', width: '30px', height: '30px'}}>
                    <b style={{position: 'absolute', width: '100%', height: '100%', textAlign: 'center', top: '7px', color: `${i < 3 ? '#fff' : '#D8D8D8'}`}}>{i + 1}</b>
                    { i < 3 ? <Icon type={['icon-a-1-01','icon-a-2-01', 'icon-a-3-01'][i]} style={{fontSize: 30}} /> : null }
                    </span>}
                title={<Link to={calcHref(item)} style={{wordBreak: 'break-all'}}>{item.title}</Link>}
                description={item.updateTime}
                />
            </List.Item>
            )}
         />
         <List
            itemLayout="horizontal"
            style={{width: '30%', height: `calc(100vh - 200px)`, overflow: 'scroll', border: '1px solid #E8E8E8'}}
            header={<Header title="热门问答" bgSrc={hotBg3} />}
            dataSource={calcDatasource(hotIssueData)}
            renderItem={(item: any, i) => (
            <List.Item style={{width: 'calc(100% - 20px)', margin: '0 10px'}}>
                <List.Item.Meta
                avatar={<span style={{position: 'relative', display: 'inline-block', width: '30px', height: '30px'}}>
                    <b style={{position: 'absolute', width: '100%', height: '100%', textAlign: 'center', top: '7px', color: `${i < 3 ? '#fff' : '#D8D8D8'}` }}>{i + 1}</b>
                    { i < 3 ? <Icon type={['icon-a-1-01','icon-a-2-01', 'icon-a-3-01'][i]} style={{fontSize: 30}} /> : null }
                    </span>}
                title={<Link to={calcHref(item)} style={{wordBreak: 'break-all'}}>{item.title}</Link>}
                description={item.updateTime}
                />
            </List.Item>
            )}
         />
    </div>)
}

interface SolutionProps extends WithSearchTreeProps{
    title?: string;
    children?: ReactNode;
    treeData: any[];
}
const Solution = ({treeData: pTreeData, title, children, ...rest}: SolutionProps) => {
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [treeData, setTreeData] = useState<any[]>([])
    const treeSearch = ({filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }
    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
    }
    useEffect(() => {
        setTreeData(pTreeData)
    }, [pTreeData])
    return <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: '265px', marginRight: '16px', position: 'sticky', top: '130px', border: '1px solid #F3F3F3'}}>
                <div style={{padding: '12px', borderBottom: '1px solid #F3F3F3'}}>{title}</div>
                <WithSearchTreeWarp title={false}>
                    <WithSearchTree 
                        style={{marginTop: '12px'}} 
                        treeData={treeData} 
                        blockNode 
                        showIcons={[]}
                        expandedKeys={expandedKeys} 
                        autoExpandParent={true} 
                        onExpand={onExpand} 
                        onSearch={treeSearch}
                        placeholder="请输入分类名称"
                        {...rest}
                    />
                </WithSearchTreeWarp>
            </div>
            <div style={{flex: 1}}>
                {children}
            </div>
   </div>
}

type SolutionHOCProps = {
    title?: string;
    type: 1 | 2 | 3 | 4,
    params?: Record<string, any>
}
const SolutionHOC = ({title, type, params}: SolutionHOCProps) => {
    // const [treeData, setTreeData, getTreeData] = useDeptDataHooks({type}, true)
    const [treeData, setTreeData, getTreeData] = useDocDataHooks({type}, true)
    const [categoryId, setCategoryId] = useState<any>(undefined)
    const [keyword, setKeyWord] = useState<string>('')
    const {tableProps, fetchApi } = useTableHooks(getPageListApi, {type, ...(params||{})}, false)
    const calcColumns = () => [solutionColumns, customerCaseColumns, qsTemplateColumns, helpDocColumns][type - 1]

    useEffect(() => {
       fetchApi({categoryId, keyword})
    }, [categoryId])

    return(<Solution treeData={treeData} title={title} onSelect={([selectId]) => setCategoryId(selectId)}>
        <Search 
           placeholder="输入文档名称" 
           style={{ width: 300, margin: '20px 12px' }} 
           enterButton 
           onSearch={(keyword) => {
               setKeyWord(keyword)
               fetchApi({categoryId, keyword})
           }} />
        <Table showHeader={false} columns={calcColumns() as any} rowKey="id" {...tableProps} style={{boxSizing: 'border-box',borderTop:'1px solid #F6F6F6', paddingRight: 20}} />
    </Solution>)
}

// 问答社区
const QACommunity = () => {
    const [type, setType] = useState<string>('0')
    const {tableProps, fetchApi } = useTableHooks(getIssueCommunityApi, {}, false)

    useEffect(() => {
        fetchApi({type: 5, condition: Number(type) + 1})
    }, [type])
    return(<div style={{boxSizing: 'border-box', padding: '0 20px'}}>
        <MyTabs tabPanes={helpTabPanes} activeKey={type} onChange={setType} className="zen-vertical-tabpane-normal" />
        <Table showHeader={false} columns={issueColumns as any} rowKey="id" {...tableProps} style={{boxSizing: 'border-box', paddingRight: 20}} />
    </div>) 
}

// 搜索结果
const bans = [
    {
        label: '全部',
        value: ''
    },
    {
        label: '解决方案',
        value: '1'
    },
    {
        label: '客户案例',
        value: '2'
    },
    {
        label: '问卷模版',
        value: '3'
    },
    {
        label: '帮助文档',
        value: '4'
    },
]
type BanItem = {label: ReactNode; value: Key}
type CategoryBanProps = {
    bans?: BanItem[];
    className?: (v: BanItem) => string;
    onClick?: (v: BanItem) => void;
}
const CategoryBan = ({bans, onClick, className}: CategoryBanProps) => {
    return(<>
        { (bans||[]).map((v, i) => <span key={v.value} className={ isFunction(className) ? className(v) : className } onClick={() => onClick && onClick(v)} style={{cursor: 'pointer'}}>{v.label}</span>) }
    </>)
}

type SearchResultProps = {
    keyword?: string;
}
const SearchResult = ({keyword}: SearchResultProps) => {
    const [type, setType] = useState<Key>('')
    const {tableProps, fetchApi } = useTableHooks(getSearchApi, {keyword}, false)
    const calcTableProps = () => {
        const { pagination, ...rest } = tableProps
        return {...rest, pagination: {...pagination, showTotal: (total: number) => `共${total}条`}}
    }

    useEffect(() => {
        fetchApi({type, keyword})
    }, [type, keyword])
    return(<div style={{boxSizing: 'border-box', padding: '20px 20px 20px 20px', background: '#fff'}}>
        {
            tableProps.pagination.total ? <>
                    <div className="search-ban">分类: 
                    <CategoryBan bans={bans} onClick={({value}) => setType(value)} className={(v) => v.value === type ? 'active' : ''} />
                </div>
                <Table showHeader={false} columns={solutionColumns as any} {...calcTableProps()} rowKey="id" style={{boxSizing: 'border-box',marginTop: 8, paddingRight: 20}} />
            </> : <SearchEmpty />
        }
    </div>)
}



export default () => {
    const history = useHistory() 
    const { search } = history.location
    const { activeKey: key } = parseSearch(search)
    const [keyWord, setKeyWord] = useState<string>('')
    const [activeKey, setActiveKey] = useState<string | number>(key)

    const onSearch = (keyWord: string) => {
        setKeyWord(keyWord)
        history.push(`/knowledge/home?activeKey=search`)
    }

    useEffect(() => {
       setActiveKey(key)
    }, [search])

    return (<div className="knowledge-home">
        <MyTabs 
          tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
          style={{background: '#fff'}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={(activeKey: any) => history.push(`/knowledge/home?activeKey=${activeKey}`)} 
          tabBarExtraContent={<TabBarExtraContent onSearch={onSearch} />} 
        />
        { activeKey === 'search' ? <SearchResult keyword={keyWord} /> : null}
    </div>)
}
