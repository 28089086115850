import React, { useEffect, useRef, useState } from "react"
import { message, Tag, Image, DatePickerProps } from "antd";
import ProForm, { ModalForm, ProFormText, ProFormCascader, ProFormDependency, ProFormSelect, ProFormTextArea, ProFormDatePicker, ProFormUploadButton } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import moment from "moment";
import { BASEURL, oaWorkReporStatsNum, oaWorkReportApproverInfo, oaWorkReportSave } from '@api/index'
import { getToken, isImageFile, productApi } from "@utils/util";
import { FileItemRender } from "@components/Customer/Business/cpnts/AddBusiness";
import { PreviewProps } from "@components/Common/Preview";
import { getOaWorkReportDetailByIdApi } from '../ReportDetail'
import { reportTypeOptions } from "../../data";

const getNumsApi = productApi(oaWorkReporStatsNum)
const getApproverInfoApi = productApi(oaWorkReportApproverInfo)
const reportSaveApi = productApi(oaWorkReportSave)

const StatisticItem = ({list}: any) => {
    return Object.keys(list||{}).map(k => <Tag key={k}>{k}{list[k]}</Tag>) as any
}

const weekFormat = 'YYYY-MM-DD';
const customWeekStartEndFormat: DatePickerProps['format'] = value =>
`${moment(value).startOf('isoWeek').format(weekFormat)} ~ ${moment(value)
  .endOf('isoWeek')
  .format(weekFormat)}`;

interface AddReportProps extends ModalFormProps{
   reportId?: string;
   type?: 1 | 2;
   onSuccess?: () => void;
}
export default ({reportId, type, onSuccess, ...rest}: AddReportProps) => {
    const formRef = useRef<ProFormInstance>()
    const [statisticObj, setStatisticObj] = useState<Object>({})
    const [parentUserOptions, setParentUserOptions] = useState<any[]>([])
    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
    
    const getStatisticNumsApi = async({type, date: dateStr, ...rest}: any) => {
       const date = type == 2 ? (dateStr.split('~')[0]||'').trim() : dateStr
       await getNumsApi({type, date}, false, (data: any) => {
            setStatisticObj(data)
       })
    }

    const getApproverInfo = async () => {
      await getApproverInfoApi({}, false, (data: any) => {
         const {name: label, id: value} = data || {}
         setParentUserOptions([{label, value}])
         formRef?.current?.setFieldsValue({parentUserId: value})
      })
    }

    const getDetailApi = async (param: any) => {
      await getOaWorkReportDetailByIdApi(param,false, (data: any) => {
         const { type, todaySummary, tomorrowPlan, dailyTime, enclosures: files } = data || {}
         const enclosures = (files||[]).map(({fileName: name, suffix: format, fileSize: size, ...rest }: any) => ({name, size, format, ...rest}))
         formRef.current?.setFieldsValue({type, todaySummary, tomorrowPlan, dailyTime, enclosures})
         getStatisticNumsApi({date: dailyTime, type})
      })
    }

    const onVisibleChange = (visible: boolean) => {
       if (visible) {
         const date = moment().format('YYYY-MM-DD')
         getApproverInfo()
         if (reportId) {
            getDetailApi({id: reportId})
         }
         if (!reportId) {
            getStatisticNumsApi({date, type})
         }
       } else {
          formRef.current?.resetFields()
       }
       formRef.current?.setFieldsValue({type})
    }

    const onValuesChange = (value: any, allValues: any) => {
       const { type, dailyTime } = allValues
       if ('type' in value || 'dailyTime' in value) {
          if (value.type == 2) {
             formRef.current?.setFieldsValue({dailyTime: moment(dailyTime).startOf('isoWeek').format(weekFormat)})
          }
          getStatisticNumsApi({date: dailyTime, type})
       }
    }

   const onPreview = (file: any) => {
      const { response } = file || {}
      const {name, format, url, } = response ? (response||{}).data : file
      isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
   }

    const onFinish = async ({enclosures: files, dailyTime: date, ...vals}: any) => {
         const enclosures = (files||[]).map(({response, ...rest}: any) => {
            const { url, name: fileName, size: fileSize, format: suffix } = (response||{}).data || rest
            return {url, suffix, fileSize, fileName}
         })
         const dailyTime = (type === 2) ? date.split('~')[0] : date
         const { success } = await reportSaveApi({...vals, enclosures, status: 1, dailyTime, id: reportId}, true, onSuccess)
         return success
    }

    return (<><ModalForm<AddReportProps> 
        modalProps={{maskClosable: false}} 
        layout="horizontal" 
        width={600} 
        labelCol={{span: 4}} 
        onVisibleChange={onVisibleChange}
        onValuesChange={onValuesChange} 
        onFinish={onFinish} 
        initialValues={{type, dailyTime: type == 2 ? moment().startOf('isoWeek').format(weekFormat) : moment().format('YYYY-MM-DD')}}
        formRef={formRef} 
        {...rest}>
        <ProFormSelect width="md" name="type" options={reportTypeOptions.filter(v => v.value == type)} label="报告类型" rules={[{required: true, message: '请选择报告类型'}]} />
        <ProFormDependency name={['type']}>
            {({type}) => {
               return <ProFormDatePicker 
                     width="md" 
                     name="dailyTime" 
                     label={`${type == 2 ? '周' : '日'}报时间`} 
                     fieldProps={{
                        picker: type == 2 ? 'week' : 'date',
                        format: type == 2 ? customWeekStartEndFormat : 'YYYY-MM-DD'
                     }}
                     rules={[{required: true, message: `请选择${type == 2 ? '周' : '日'}报时间`}]} 
               />
            }}
         </ProFormDependency>
        <ProFormSelect width="md" name="parentUserId" options={parentUserOptions} label="批阅人" disabled rules={[{required: true, message: '请选择批阅人'}]} />
        <ProForm.Item label="数据统计"> <StatisticItem list={statisticObj} /> </ProForm.Item>
        <ProFormDependency name={['type']}>
            {({type}) => {
               return <ProFormTextArea 
                        width="md" 
                        name="todaySummary" 
                        label={type == 2 ? '本周总结' : '今日总结'}
                        fieldProps={{showCount: true, maxLength: 1000}} 
                        rules={[{required: true, message: `请填写${type == 2 ? '本周' : '今日'}总结`}]} 
               />
            }}
        </ProFormDependency>
        <ProFormDependency name={['type']}>
            {({type}) => {
               return <ProFormTextArea 
                        width="md" 
                        name="tomorrowPlan" 
                        label={type == 2 ? '下周计划' : '明日计划'}
                        fieldProps={{showCount: true, maxLength: 1000}} 
                        rules={[{required: true, message: `请填写${type == 2 ? '下周' : '明日'}计划`}]} 
               />
            }}
        </ProFormDependency>
        <ProFormUploadButton width="md" name="enclosures" label="附件" max={5} fieldProps={{ name: 'file', listType: 'text', headers: {Authorization: getToken()}, itemRender: (...props) => FileItemRender(...props, onPreview)}} action={`${BASEURL}/upload`} extra=""/>
   </ModalForm>
   <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
   </>)
}