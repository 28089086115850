import { isDef } from "@utils/index"
import React, { useContext, useEffect, useRef } from "react"
import CacheContext from "./cacheContext"
import { DESTROY, RESET } from "./contact"

const defaultCacheId = window.location.pathname

/**
 * 本组件的核心:
 * 我们要通过缓存容器去创建OldComponent对应的真实Dom，并且进行缓存
 * 即使OldComponent 被销毁，缓存还可以保留
 * 以后这个OldComponent再次渲染的时候，可以服用上次缓存的就可以了
*/

export default (OldComponent: any, { cacheId = defaultCacheId, scroll = false }: any) => {
    return function (props: any) {
        cacheId = (isDef(cacheId) && typeof cacheId === 'string') ? cacheId : cacheId()
        const divRef = useRef<any>(null)
        const { cacheStates, dispatch, mount, handleScroll } = useContext<any>(CacheContext)
        useEffect(() => {
            if (scroll) {
                divRef.current.addEventListener('scroll', handleScroll.bind(null, cacheId), true)
            }
        }, [handleScroll])

        useEffect(() => {
            const cacheState = cacheStates[cacheId]
            const { doms, status, scrolls } = cacheState || {}
            if (cacheState && doms && status !== DESTROY) {
                (doms||[]).forEach((dom: any) => divRef?.current.appendChild(dom))
                if (scroll) {
                    (doms||[]).forEach((dom: any) => {
                        if (scrolls[dom]) {
                            dom.scrollTop = scrolls[dom]
                        }
                    })
                }
            } else {
                mount({cacheId, reactElement: <OldComponent {...props} dispatch={dispatch} />})
            }
        }, [cacheStates, mount, props, dispatch])
        return (<div id={`keppalive-${cacheId}`} ref={divRef}></div>)
    }
}
