import { DescriptionsProps } from "antd";
import { ReactNode } from "react";

export type BaseInfoType = {
    companyName?: string;
    ownerName?: string;
    url?: string;
    businessScope?: string;
    registCapital?: string;
    isTech?: number;
    tagNames?: string[];
    companyType?: string;
    businessStatus?: string;
    registDate?: string; 
    companyAdress?: string;
    oldNames?: string[]
}
export interface EnterpriseDetailProps {
    id?: string;
    lqId?: string;
    name?: string;
    authCode?: AuthActionArr;
    [key: string]: any;
}

export type DescListItem = {
    label: ReactNode;
    value?: ReactNode;
    span?: number;
    param?: string
}

export interface DescriptionsListProps extends DescriptionsProps {
    descList?: DescListItem[];
    data?: Record<string, any>
}

export type TabPanesItem = {
    Tab: ReactNode;
    key?: string | number;
    hidden?: boolean;
    Content: ReactNode | ReactNode[]
    href?: string;
}

export type MyTabsProps = {
    tabPanes: TabPanesItem[];
    [key: string]: any;
}

type AuthAction = 'add' | 'del' | 'edit' | 'copy'
export type AuthActionArr = AuthAction[]
export const AllAuth: AuthActionArr = ['add', 'del', 'edit', 'copy']