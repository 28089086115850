import { ProColumns } from "@ant-design/pro-table";
import { AuthActionArr } from "@components/EnterpriseDetail/type";
import React from "react";

export const columns: (operate: any, data: any, authCode?: AuthActionArr) => ProColumns<any>[] = (operate, data, authCode) => [
    {
        title: '主键',
        dataIndex: 'id',
        width: 90,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true
    },
    {
        title: '客户id',
        dataIndex: 'customerId',
        width: 90,
        hideInSearch: true,
        hideInTable: true,
        hideInForm: true
    },
    {
        title: '姓名',
        dataIndex: 'userName',
        width: 255,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '性别',
        dataIndex: 'gender',
        width: 90,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false,
        render:(text: any, record: any) => record.gender == 1 ? '男' : '女'
    },
    {
        title: '年龄',
        dataIndex: 'age',
        width: 90,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '职位',
        dataIndex: 'positionName',
        width: 100,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: false,
    },
    {
        title: '角色',
        dataIndex: 'participationType',
        valueType: 'select',
        width: 90,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: false,
        fieldProps: {
            options: data?.uiFunctionEnum?.participationTypeList?.map(({caption: label, value}: any) => ({label, value}))
        },
    	render: (text: any, record: any) => record.participationTypeName
    },
    {
        title: '是否建立联系',
        dataIndex: 'establishContact',
        valueType: 'select',
        width: 90,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false,
    	render: (text: any, record: any) => record.establishContact == 1 ? '是' : '否'
    },

    
    {
        title: '建联方式',
        dataIndex: 'contactMode',
        valueType: 'select',
        width: 90,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: false,
        fieldProps: {
            options: data?.uiFunctionEnum?.contactModeList?.map(({caption: label, value}: any) => ({label, value}))
        },
        render: (text: any, record: any) => record.contactModeName
    },
    {
        title: '客户态度',
        dataIndex: 'customerManner',
        valueType: 'select',
        width: 90,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: false,
        fieldProps: {
            options: data?.uiFunctionEnum?.customerMannerList?.map(({caption: label, value}: any) => ({label, value}))
        },
    	render: (text: any, record: any) => record.customerMannerName
    },
    {
        title: '决策流程描述',
        dataIndex: 'flowDescribe',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '对接的竞争对手',
        dataIndex: 'competitor',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '是否有企业朋友',
        dataIndex: 'companyFriend',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '综合分析',
        dataIndex: 'analysis',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '备注',
        dataIndex: 'remark',
        width: 255,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false
    },
    {
        title: '创建人',
        dataIndex: 'createId',
        valueType: 'select',
        width: 90,
        hideInSearch: true,
        hideInTable: false,
        hideInForm: false,
        fieldProps: {
            options: data?.uiFunctionEnum?.createIdList?.map(({caption: label, value}: any) => ({label, value}))
        },
    	render: (text: any, record: any) => record.createIdName
    },

    {
        title: '创建时间',
        dataIndex: 'createTime',
        width: 170,
        hideInSearch: false,
        hideInTable: false,
        hideInForm: true
        // sorter: true
    },


    // {
    //     title: '更新时间',
    //     dataIndex: 'updateTime',
    //     width: 170,
    //     hideInSearch: true,
    //     hideInTable: false,
    //     hideInForm: true
    //     // sorter: true
    // },



    {
        title: '操作',
        dataIndex: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        hideInTable: !authCode?.length,
        render: (_text: any, record: any) => [
            authCode?.includes('edit') ? <a key='编辑' onClick={() => operate?.('编辑', record)}>编辑</a> : null,
            authCode?.includes('del') ? <a key='删除' onClick={() => operate?.('删除', record)}>删除</a> : null
        ]
    },
]


export const roleColumns = [
    {
        title: '角色类型',
        dataIndex: 'roleType',
        key: 'roleType',
        width: 120,
    },
    {
        title: '角色描述',
        dataIndex: 'roleDesc',
        key: 'roleDesc',
    },
]

export const roleDataSource = [
    {
        id: 1,
        roleType: '使用者',
        roleDesc: '所要采购物品的实际使用者，通常采购某种物品的要求是由他们首先提出来的，他们在规格型号的决定上有直接作用。我们的客户中，多为运维技术方面的人'
    },
    {
        id: 2,
        roleType: '影响者',
        roleDesc: '企业内外直接或间接影响采购决策的人，其中技术人员是特别重要的影响者。'
    },
    {
        id: 3,
        roleType: '采购者',
        roleDesc: '具体执行采购任务的人员，他们负责选择供应者并与之谈判签约。在比较重要的采购工作中，通常还有企业的高层管理人员参加。'
    },
    {
        id: 4,
        roleType: '决策者',
        roleDesc: '企业里有权决定采购项目和供应者的人。在日常的采购中，采购者就是决策者。在复杂的采购中，决策者通常是企业的主管'
    },
    {
        id: 5,
        roleType: '控制者',
        roleDesc: '可控制信息流向的人员，他们可控制外界与采购有关的信息流入企业。例如，采购代理往往有权阻止供应商的推销人员与使用者或决策者见面，还有技术人员、甚至秘书人员，也可以控制信息'
    }
]