import React, { ReactNode, type FC } from 'react';
import { Image, ImageProps } from 'antd';
import { imgUrlReg, docUrlReg, pptUrlReg, pdfUrlReg, excelUrlReg } from '@utils/index'
// @ts-ignore
import FileViewer from 'react-file-viewer';

import './index.less'

const ErrorComponent = () => {
  return (<div>对不起，发生了错误</div>)
}

const UnsupportedComponent = () => {
  return (<div>
    该文件暂不支持预览
    </div>)
}

type PreviewProps = {
  src: string;
  ErrorComponent?: ReactNode;
  UnsupportedComponent?: ReactNode;
  onError?: () => void;
}

const Preview: FC<PreviewProps> & PreviewStaticProps = ({src, ...restProps}) => {
  if (imgUrlReg.test(src)) {
    return <img src={src} {...restProps} />
  }
  return <iframe src={`http://view.xdocin.com/xdoc?_xdoc=${src}`} style={{width: '100%', height: '780px', border: 'none'}} />
  if (docUrlReg.test(src)) {
    return <FileViewer fileType={'docx'} filePath={src} unsupportedComponent={UnsupportedComponent} errorComponent={ErrorComponent} {...restProps} />
  }
  if (pdfUrlReg.test(src)) {
    return <FileViewer fileType={'pdf'} filePath={src} unsupportedComponent={UnsupportedComponent} errorComponent={ErrorComponent} {...restProps} />
  }
  if (excelUrlReg.test(src)) {
    return <FileViewer fileType={'xslx'} filePath={src} unsupportedComponent={UnsupportedComponent} errorComponent={ErrorComponent} {...restProps} />
  }
  if (pptUrlReg.test(src)) {
    return <FileViewer fileType={'pptx'} filePath={src} unsupportedComponent={UnsupportedComponent} errorComponent={ErrorComponent} {...restProps} />
  }

  return restProps?.UnsupportedComponent ? <>{restProps?.UnsupportedComponent}</> : <UnsupportedComponent />
}

type PreviewStaticProps = {
  Group?: any;
}

type PreviewGroupProps = {
  items: string[];
  fallback?: string;
  preview?: ImageProps['preview'];
  children?: any
}

const PreviewGroup: FC<PreviewGroupProps> = ({items, children, ...restProps}) => {
  return (<Image.PreviewGroup
    items={items}
    preview={{
      imageRender: (_, {current}: any) => {
        const currentSrc = items[current]
        return <Preview src={currentSrc} />
      },
      toolbarRender: () => null,
    }}
    {...restProps}
  >
    {children}
  </Image.PreviewGroup>)
};

Preview.Group = PreviewGroup

export default Preview;
