import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, rebackAuditSearch } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ProForm, ModalForm, ProFormText, ProFormDatePicker,  ProFormTextArea } from '@ant-design/pro-form';
import { OperateActionType } from "../../data"
import { columnsFn } from "./data"
import { useRebackAuditHooks } from "./hooks"
import { ContractAuditModalForm } from "@components/Finance/ContractAudit/cpnts/SaleContractAudit"
import Table from "@ant-design/pro-table/lib/Table"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"

const getPageListApi = productApi(rebackAuditSearch)

const persistenceKey = 'finance/contract/rebackAudit'

export type RebackAuditModalFormProps = {
    onSuccess?: () => void;
} & ModalFormProps


export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}>{data.contractAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}>{data.backAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={13}></Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}

export default () => {
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any
    const {
        rebackAuditModalFormProps,
        setRebackAuditModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    } = useRebackAuditHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        action(type, {ids: selectedRowKeys})
    }

    const columns = [...columnsFn(operate)]
    const params = {}
    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setAllTableData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            backIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    return(<div className="oa-task-center">
        <ProTableWithFormSetting<any>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={
                    <h5 style={{fontSize: '14px', margin: 0}}>
                        {/* 合同金额：<span style={{color: 'red'}}>{allTableData.contractAmount || 0}</span>&nbsp;&nbsp;  */}
                        回款金额：<span style={{color: 'red'}}>{allTableData.backAmount || 0}</span> 
                        （汇总列表查询结果）
                    </h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2477) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('回款记录-审批通过', null)}>审批通过</Button> : null,
                        hasAuthCode(2478) ? <Button key="btn7" type="primary" disabled={calcDisabled()} onClick={() => operate('回款记录-审批拒绝', null)}>审批拒绝</Button> : null,
                        hasAuthCode(2475) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/backAuditExport`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="backId"
                actionRef={ref as any}
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <ContractAuditModalForm
            modalProps={{
                onCancel: () => setRebackAuditModalFormProps({...rebackAuditModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...rebackAuditModalFormProps} 
        />
    </div>)
}
