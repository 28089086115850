import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, contractSearch, contractAuditSearch } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ProForm, ModalForm, ProFormText, ProFormDatePicker,  ProFormTextArea } from '@ant-design/pro-form';
import { OperateActionType } from '../../data'
import { useContractAuditHooks } from "./hooks" 
import { AddSaleContractModalForm } from "@components/Finance/Contract/cpnts/SaleContract/cpnts"
import { AddRebackModalForm } from "@components/Finance/RebackMoney/cpnts/RebackList/cpnts"
import { useSaleContractHooks } from "@components/Finance/Contract/cpnts/SaleContract/hooks"
import Table from "@ant-design/pro-table/lib/Table"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"

const getPageListApi = productApi(contractAuditSearch)

const persistenceKey = 'finance/contract/audit'


export type ContractAuditModalFormProps = {
    onSuccess?: () => void;
} & ModalFormProps


export const ContractAuditModalForm = ({params ,onSuccess, title, ...rest}: ContractAuditModalFormProps) => {

    const request = async (params: object) => {
        return({ 
            reason: ''
        })
    }

    const onFinish = async ({files: filesArr, ...rest}: any) => {

    }
    
    // @ts-ignore
    const label = title === '审批通过' ? '审批备注' : (title as string)?.match(/(作废|拒绝)/)?.[0] + '原因'
    
    return (<ModalForm<any> 
        modalProps={{maskClosable: false}} 
        layout="horizontal" 
        title={title}
        width={700} 
        labelCol={{span: 5}} 
        onFinish={onFinish}
        request={request}
        {...rest}
        >
            <ProFormTextArea 
                width="md" 
                name="reason" 
                label={label} 
                fieldProps={{maxLength: 300, showCount: true, placeholder: `请输入${label}`}} 
                rules={[
                    {required: /(作废|拒绝)/.test(title as string), message: `请填写${label}`}, 
                    {max: 300, type:'string', message: '300字以内'}
                ]} 
            />
        </ModalForm>)
}


export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}>{data.contractAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={13}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={14}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={15}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={16}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={17}>{data.backedAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={18}>{data.noBackAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={19}>{data.openedAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={20}>{data.noOpenAmount}</Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}

export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any

    const {
        formRef: auditFormRef,
        commonModalFormProps,
        setCommonModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    } = useContractAuditHooks()

    const { 
        commonModalFormProps: addSaleContractModalFormProps,
        setCommonModalFormProps: setAddSaleContractModalFormProps,
        createOperate: saleContractCreateOperate
    } = useSaleContractHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        switch (type) {
            case '销售合同-编辑':
                saleContractCreateOperate(record, reFush)(type, {})
                break;
            default:
                action(type, {})
        }
    }

    const columns = [...columnsFn(operate, '')]
    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setAllTableData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const params = {contractType: 1}

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            contractIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    return(<div className="oa-task-center">
        <ProTableWithFormSetting<any>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={
                    <h5 style={{fontSize: '14px', margin: 0}}>
                        合同金额：<span style={{color: 'red'}}>{allTableData.contractAmount || 0}</span> &nbsp;&nbsp;
                        已回款金额：<span style={{color: 'red'}}>{allTableData.backedAmount || 0}</span> &nbsp;&nbsp;
                        已开票金额：<span style={{color: 'red'}}>{allTableData.openedAmount || 0}</span> 
                        （汇总列表查询结果）
                    </h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2453) ? <Button key="btn4" type="primary" disabled={calcDisabled()} onClick={() => operate('销售合同-审批通过', null)}>审批通过</Button> : null,
                        hasAuthCode(2454) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('销售合同-审批拒绝', null)}>审批拒绝</Button> : null,
                        hasAuthCode(2452) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/contractAuditExport`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="contractId"
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <ContractAuditModalForm
            modalProps={{
                onCancel: () => setCommonModalFormProps({...commonModalFormProps, visible: false}),
                maskClosable: false
            }}  
            formRef={auditFormRef}
            {...commonModalFormProps} 
        />
        <AddSaleContractModalForm
            modalProps={{
                onCancel: () => setAddSaleContractModalFormProps({...addSaleContractModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...addSaleContractModalFormProps} 
        />
    </div>)
}
