import React from "react";
import { Input, Select, DatePicker, TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Link } from "react-router-dom";
import { useCluesPoolDataHooks, useDictEnumHooks } from "@hooks/index";
import { stringify } from "qs";
import { Ellipsis } from "@components/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

const { RangePicker } = DatePicker
// ts--types
export type TableListItem = {
    key: number;
    id: string;
    isManager: 0 | 1;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
    owner: string;
};

export type OperateActionType = '领取线索' | '转为客户' | '分配线索' | '转移线索' | '批量导入' | '批量导出' | '批量删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [,,cluePoolRequest] = useCluesPoolDataHooks({}, false)
  const [, , request] = useDictEnumHooks({}, false)

  return [
    {
        title: '客户名称',
        dataIndex: 'customerName',
        fixed: 'left',
        order: 100,
        render: (text: any, {id, customerName: name, owner: ownerName,}) => 
        <Link to={`/customer/detail?${stringify({id, name, ownerName, type: 1, routeName: '线索池详情', fromPath: '/customer/cluesPool'})}`}>{text}</Link>
    },
    {
        title: '所属行业',
        dataIndex: 'label',
        order: 2,
        // hideInSearch: true,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: labelTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        // render: (text, record: any) => <Ellipsis text={(record.label||[]).map(({labelName}: any) => labelName).toString()||'-'} />
        render: (text, record: any) => <Ellipsis text={(record.labelStr||'').toString()||'-'} />
    },
    {
        title: '客户账号',
        dataIndex: 'customerAccount',
        hideInTable: true,
        order: 1,
    },
    // {
    //     title: '合作模式',
    //     dataIndex: 'cooperationMode',
    //     order: 1,
    //     valueType: 'select',
    //     params: {dictId: 25 },
    //     request,
    //     render: (text, record: any) => `${(record||{}).cooperationModeStr || ''}`
    // },
    {
        title: '线索来源',
        dataIndex: 'customerClueSource',
        order: 6,
        valueType: 'select',
        params: {dictId: 15},
        request,
    },
    {
      title: '关注业务线',
      dataIndex: 'businessLine',
      order: 4,
      width: 120,
      params: {dictId: 19 },
      request,
      valueType: 'select',
      fieldProps: { mode: 'multiple' },
      // hideInTable: true,
      render: (text, record: any) => `${record.businessLines||''}`
    },
    // {
    //     title: '线索池',
    //     dataIndex: 'cluePool',
    //     order: 7,
    //     valueType: 'select',
    //     fieldProps: { mode: 'multiple' },
    //     params: {pageCurrent:1, pageSize: 1000},
    //     request: cluePoolRequest,
    //     search: {
    //       transform: (cluePoolIds) => {
    //         return {cluePoolIds};
    //       },
    //     },
    // },
    // {
    //     title: '前线索池',
    //     dataIndex: 'preCluePoolStr',
    //     order: 7,
    //     valueType: 'select',
    //     fieldProps: { mode: 'multiple' },
    //     params: {pageCurrent:1, pageSize: 1000},
    //     request: cluePoolRequest,
    //     search: {
    //       transform: (preCluePool) => {
    //         return {preCluePool};
    //       },
    //     },
    // },
    {
        title: '所属地区',
        dataIndex: 'area',
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: cityTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
    },
    {
        title: '前归属人',
        dataIndex: 'preOwner',
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const preOwner = (ids||[]).map((v: string) => v.slice(1))
            return { preOwner }
          },
        },
    },
    // {
    //     title: '客户状态',
    //     dataIndex: 'customerStatus',
    //     order: 5,
    //     valueType: 'select',
    //     hideInTable: true,
    //     hideInSearch: true,
    //     params: {dictId: 13},
    //     request,
    // },
    {
        title: '最后跟进',
        dataIndex: 'finalFollowUpTime',
        hideInTable: true,
        order: 1,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
    },
    {
      title: '转入时间',
      dataIndex: 'transTime',
      order: 2,
      // hideInTable: true,
      valueType: 'dateRange',
      fieldProps: { allowEmpty: [true, true]},
      render: (text, record: any) => `${record.transTime||''}`
    }
 ]
}


export const dispatchColumnsFn: () => ProColumns<any>[] = () => [
  {
    title: '姓名',
    dataIndex: 'name',
    editable: false,
    render: (text) => `${text}`
  },
  {
    title: '线索数量',
    dataIndex: 'amount',
    valueType: 'digit',
    formItemProps: {
      rules: [
        {
          required: true,
          message: '此项是必填项',
        }
      ],
    },
  },
  {
    title: '操作',
    valueType: 'option',
    width: 50,
    fixed:'right',
    render: () => [
      <a key="link">删除</a>,
    ],
  },
] 