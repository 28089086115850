import React from "react";
import { ProColumns } from "@ant-design/pro-table";
import { useHasAuthCode } from "@hooks/index";

export type TableListItem = {
    key: number;
    id: number | string;
    name: string;
    parentId: string | number;
    labelLevel: string | number;
    industryLabelStatus: string;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
    children?: TableListItem[]
};

export type OperateActionType = '新建标签' | '添加子级' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const [permissions, hasAuthCode] = useHasAuthCode()
   return [
    {
        title: '标签ID',
        dataIndex: 'id',
        hideInSearch: true,
    },
    {
        title: '标签名称',
        dataIndex: 'name',
        order: 100,
        search: {
          transform: (key) => ({key})
        },
    },
    {
        title: '标签层级',
        dataIndex: 'level',
        hideInSearch: true,
    },
    {
      title: '状态',
      dataIndex: 'industryLabelStatus',
      order: 1,
      valueEnum: {
        0: { text: '禁用', status: 'Default' },
        1: { text: '启用', status: 'Processing' },
      },
      search: {
        transform: (status) => ({status})
      },
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text, record) => [
          hasAuthCode(2018) ? <a key="warn" onClick={() => operate && operate('添加子级', record)}>添加子级</a> : null,
          hasAuthCode(2182) ? <a key="edit" onClick={() => operate && operate('编辑', record)}>编辑</a> : null,
          hasAuthCode(2019) ? <a key="del" onClick={() => operate && operate('删除', record)}>删除</a> : null
        ],
    },
 ]
}


