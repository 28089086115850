import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
export { BASEURL, LQBASEURL } from './baseUrl'
// 返点列表
export const rebateList = (data?: any) => request({
    url: `${BASEURL}/rebates/listCustomerReturn`,
    // method: 'GET',
    data,
})

// 认领
export const rebateClaim = (data?: any) => request({
    url: `${BASEURL}/rebates/claim`,
    // method: 'GET',
    data,
})

// 认领审核
export const rebateClaimAudit = (data?: any) => request({
    url: `${BASEURL}/rebates/claimReview`,
    // method: 'GET',
    data,
})

// 认领审核详情
export const rebateClaimAuditInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/claimReviewDetail`,
    // method: 'GET',
    data,
})

// 追回
export const rebateRecover = (data?: any) => request({
    url: `${BASEURL}/rebates/back`,
    // method: 'GET',
    data,
})

// 追回审核
export const rebateRecoverAudit = (data?: any) => request({
    url: `${BASEURL}/rebates/backReview`,
    data,
})

// 追回审核详情
export const rebateRecoverAuditInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/backReviewDetail`,
    data,
})

// 根据id获取详细信息
export const rebateInfoById = (data?: any) => request({
    url: `${BASEURL}/rebates/info`,
    // method: 'GET',
    data,
})

// 确认打款
export const rebateSurePay = (data?: any) => request({
    url: `${BASEURL}/rebates/surePay`,
    data,
})

// 修改打款人信息
export const rebateUpdatePayerList = (data?: any) => request({
    url: `${BASEURL}/rebates/updatePayee`,
    data,
})

// 截至确认
export const rebateStop = (data?: any) => request({
    url: `${BASEURL}/rebates/stopById`,
    data,
})

// 批量确认
export const rebateSure = (data?: any) => request({
    url: `${BASEURL}/rebates/sureById`,
    data,
})

// 批量确认
export const rebateMuchSure = (data?: any) => request({
    url: `${BASEURL}/rebates/muchSureById`,
    data,
})

// 批量复核
export const rebateMuchReview = (data?: any) => request({
    url: `${BASEURL}/rebates/muchReviewById`,
    data,
})

// 批量撤销
export const rebateMuchUndo = (data?: any) => request({
    url: `${BASEURL}/rebates/revocationById`,
    data,
})

// 获取复核信息
export const rebateReviewInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/recheckDetail`,
    data,
})

// 获取详情的抵扣款订单
export const rebatedDeductAmountInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/getRefundOrderByOrderId`,
    data,
})

// 复核信息
export const rebateReview = (data?: any) => request({
    url: `${BASEURL}/rebates/recheck`,
    data,
})

// 收款人汇总 表格查询
export const getListPayeeGather = (data?: any) => request({
    url: `${BASEURL}/rebates/listPayeeGather`,
    data,
})

// 收款人详情  子表格查询
export const getListPayeeDetail = (data?: any) => request({
    url: `${BASEURL}/rebates/listPayeeDetail`,
    data,
})
// 确认信息-----
export const rebateConfirmInfo = (data?: any) => request({
    url: `${BASEURL}/rebates/confirmDetail`,
    data,
})

// 确认信息提交
export const rebateConfirmInfoSubmit = (data?: any) => request({
    url: `${BASEURL}/rebates/confirm`,
    data,
})

// 追加返点
export const rebateAdd = (data?: any) => request({
    url: `${BASEURL}/rebates/addRebates`,
    data,
})

// 返点信息新增
export const rebateInfoAdd = (data?: any) => request({
    url: `${BASEURL}/rebates/infoAdd`,
    data,
})

// 返点信息删除
export const rebateInfoDel = (data?: any) => request({
    url: `${BASEURL}/rebates/infoDel`,
    data,
})

// 收款信息新增
export const rebateGetMoneyAdd = (data?: any) => request({
    url: `${BASEURL}/rebates/getMoneyAdd`,
    data,
})

// 收款信息删除
export const rebateGetMoneyDel = (data?: any) => request({
    url: `${BASEURL}/rebates/getMoneyDel`,
    data,
})

// 返点重新计算
export const rebateCalc = (data?: any) => request({
    url: `${BASEURL}/rebates/calc`,
    data,
})

// 删除-账号
export const rebateCustomerDel = (data?: any) => request({
    url: `${BASEURL}/delCustomerAccount`,
    data,
})

// 删除-账号
export const rebateRateDel = (data?: any) => request({
    url: `${BASEURL}/delRebatesRate`,
    data,
})

// 删除-账号
export const rebatePayeeDel = (data?: any) => request({
    url: `${BASEURL}/delPayee`,
    data,
})

// 获取系统的客户账号(带账号分类的)
export const rebateGetAccountTreeData = (data?: any) => request({
    url: `${BASEURL}/getAccountTypeTreeByCustomerId`,
    method: 'GET',
    data,
})

// 查询收款人的操作记录
export const rebatePayeeInfoUpdateLog = (data?: any) => request({
    url: `${BASEURL}/getPayeeInfoUpdateLog`,
    data,
})
