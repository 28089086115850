import { useRef, useState } from "react"
import { AddGetMoneyProps, AddRebateInfoProps, calcNewRecord, handleSubmitParams, sum } from "./Ali"
import { Form, Modal, message } from "antd"
import { handleUploadFiles, productApi } from "@utils/index"
import ProForm, { ProFormInstance } from '@ant-design/pro-form'
import { useFormDataCache } from "@hooks/index"
import { isFunction } from "lodash"
import { rebateConfirmInfo } from "@api/index"

const confirmInfoApi = productApi(rebateConfirmInfo)

export type OperateActionType = '返点信息-新增' | '返点信息-删除' | '收款信息-新增' | '收款信息-修改' | '收款信息-删除' 

// 新增收款信息
const checkPayAccount = (list: any[], newRecord: any) => {
    const {bank, bankAccount, aliPayAccount} = newRecord || {}
    const bankStr = (bank||'').trim(), bankAccountStr = (bankAccount||'').trim(), aliPayAccountStr = (aliPayAccount||'').trim()
    const bankStrLen = bankStr.length, bankAccountStrLen = bankAccountStr.length, aliPayAccountStrLen = aliPayAccountStr.length
  
    if ((aliPayAccountStrLen === 0) && (bankStrLen === 0) && (bankAccountStrLen === 0)) {
      message.error('银行卡信息或支付宝信息不能为空!')
      return false
    }
    if((bankStrLen > 0 && bankAccountStrLen === 0) || (bankStrLen === 0 && bankAccountStrLen > 0)){
      message.error('请填写完整的银行卡信息(包括开户行,卡号)!')
      return false
    }
    const banks = list.map(({bank, bankAccount}: any) => `${(bank||'').trim()}:${(bankAccount||'').trim()}`)
    const aliPayAccounts = list.map(({aliPayAccount}: any) => `${(aliPayAccount||'').trim()}`)
    if (banks.includes(`${bankStr}:${bankAccountStr}`) && (bankStrLen+bankAccountStrLen) > 0) {
      message.error('列表中已存在重复银行卡信息!')
      return false
    }
    if (aliPayAccounts.includes(aliPayAccountStr) && aliPayAccountStrLen > 0) {
      message.error('列表中已存在重复支付宝账号!')
      return false
    }
    return true
}

export const useOperateHooks = (id: string|number) => {
    const hasPaperRef = useRef(true)
    const formRef = useRef<ProFormInstance>()
    const delGetMoneyFormRef = useRef<ProFormInstance>()
    const [rebateInfoFormRef] = Form.useForm();
    const [getMoneyFormRef] = Form.useForm();

    const [baseData, setBaseData] = useState({})
    const [getCache, setCache, removeCache] = useFormDataCache()

    const [refundOrderDataSource, setRefundOrderDataSource] = useState<any[]>([])
    const [rebateInfoDataSource, setRebateInfoDataSource] = useState<any[]>([])
    const [getMoneyDataSource, setGetMoneyDataSource] = useState<any[]>([])
    const [delGetMoneyDataSource, setDelGetMoneyDataSource] = useState<any[]>([])
  
    const [getMoneyProps, setGetMoneyProps] = useState<AddGetMoneyProps>({visible: false})
    const [delMoneyProps, setDelMoneyProps] = useState<AddGetMoneyProps>({visible: false})
    const [rebateInfoProps, setRebateInfoProps] = useState<AddRebateInfoProps>({visible: false, queryParams: {id}})

    const calcGetMoneyDataSourceTop1 = (getMoneyDataSource: any[], rebateInfoDataSource: any[]) => {
        if (getMoneyDataSource.length === 1) {
          const expectRebateAmount = sum(rebateInfoDataSource, 'expectRebateAmount')
          getMoneyDataSource[0] = {...getMoneyDataSource[0], expectRebateAmount}
          setGetMoneyDataSource([...getMoneyDataSource])
          setCache({...getCache(), confirmCollectionResponses: [...getMoneyDataSource]})
        }
    }

    const fillFormData = (data: any) => {
        const {
          confirmRefundOrderResponses: refundOrderDataSource, 
          confirmCollectionResponses: getMoneyDataSource, 
          confirmReturnOrderResponses: rebateInfoDataSource,
          delConfirmCollectionResponses: delGetMoneyDataSource,
          ...baseData
        } = data || {}
        setBaseData(baseData);
        const newRebateInfoDataSource = (rebateInfoDataSource||[]).map((v: any, idx: number) => ({...v, id: v.id ? v.id : `zqs-${idx}`}));
        (newRebateInfoDataSource||[]).forEach((v: any) => calcNewRecord(v, newRebateInfoDataSource||[], hasPaperRef.current));
        const newRefundOrderDataSource = (refundOrderDataSource||[]).map((v: any, idx: number) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))
        const newGetMoneyDataSource = (getMoneyDataSource||[]).map((v: any, idx: number) => ({...v, id: v.id ? v.id : `zqs-${idx}`}))
        setRefundOrderDataSource(newRefundOrderDataSource)
        setRebateInfoDataSource([...newRebateInfoDataSource])
        setGetMoneyDataSource(newGetMoneyDataSource)
        setDelGetMoneyDataSource(delGetMoneyDataSource||[])
        calcGetMoneyDataSourceTop1(newGetMoneyDataSource, newRebateInfoDataSource)
    } 

    const request = async (params: any, callBack?: Function) => {
        const { data } = await confirmInfoApi(params, false)
        fillFormData(data||{})
        setCache(data||{})
        callBack && isFunction(callBack) && callBack()
        return data
    }

    const requestWithCache = (...args: any[]) => {
        const data = getCache()
        if (data) {
          fillFormData(data)
          return data
        }
        // @ts-ignore
        return request(...args)
    }

    const onValuesChange = (values: any,{hasPaper, ...rest}: any) => {
        hasPaperRef.current = hasPaper;
        (rebateInfoDataSource||[]).forEach(v => calcNewRecord(v, rebateInfoDataSource||[], hasPaper))
        setRebateInfoDataSource([...rebateInfoDataSource])
        calcGetMoneyDataSourceTop1(getMoneyDataSource, rebateInfoDataSource)
        setCache({...getCache(), hasPaper, ...rest})
    }

    const rebateInfoTableOnValuesChange = (record: any, allRecord: any) => {
        const newRebateInfoDataSource = calcNewRecord(record, allRecord||[] , hasPaperRef.current)
        calcGetMoneyDataSourceTop1(getMoneyDataSource, newRebateInfoDataSource)
        setRebateInfoDataSource([...newRebateInfoDataSource])
        setCache({...getCache(), confirmReturnOrderResponses: [...newRebateInfoDataSource]})
    }

    const getMoneyTableOnValuesChange = (value: any) => {
        setGetMoneyDataSource(value)
        setCache({...getCache(), confirmCollectionResponses: value})
    }

    const createAction = (record: any, onSuccess?: Function) => {
        const { id: rid } = record || {}
        const rebateInfoList = rebateInfoDataSource.filter(v => v.id !== rid)
        const getMoneyList = getMoneyDataSource.filter(v => v.id !== rid)
        // 返点信息-新增
        const addRebateInfoAction = (props: Object = {}) => setRebateInfoProps({
            visible: true,
            params: { list: (rebateInfoDataSource||[]) },
            initialValues: {
              mainDimension: '产品分类'
            },
            async onFinish(values: any) {
              const params = handleSubmitParams({
                refundOrderDataSource, 
                rebateInfoDataSource: [...rebateInfoDataSource, {...values, id: `zqs-${rebateInfoDataSource.length}`}], 
                getMoneyDataSource,
                delGetMoneyDataSource
              })
              request({...params, id, hasPaper: hasPaperRef.current ? 1 : 0}, () => setRebateInfoProps({visible: false}))
            }
        })

        // 返点信息-删除
        const delRebateInfoAction = (props: Object = {}) => Modal.confirm({
            title: '温馨提示',
            content: '确定执行此操作？撤销之后, 不可恢复',
            cancelText: '取消',
            async onOk() {
              const params = handleSubmitParams({
                refundOrderDataSource, 
                rebateInfoDataSource: rebateInfoList, 
                getMoneyDataSource,
                delGetMoneyDataSource
              })
              request({...params, id, hasPaper: hasPaperRef.current ? 1 : 0})
            },
        })

        // 收款信息-新增
        const addGetMoneyAction = (props: Object = {}) => setGetMoneyProps({
            visible: true,
            hiddenFileds: ['modifyCase'],
            async onFinish(values: any) {
              const isNext = checkPayAccount(getMoneyDataSource, values)
              if (isNext) {
                const {files:filesArr} = values || {}
                const files = handleUploadFiles(filesArr||[])
                const params = handleSubmitParams({
                  refundOrderDataSource, 
                  rebateInfoDataSource, 
                  getMoneyDataSource: [...getMoneyDataSource, {...values, id: `zqs-${getMoneyDataSource.length}`, files}],
                  delGetMoneyDataSource
                })
                request({...params, id, hasPaper: hasPaperRef.current ? 1 : 0}, () => setGetMoneyProps({visible: false}))
              }
            }
        })

        // 收款信息-修改
        const updateGetMoneyAction = (props: Object = {}) => setGetMoneyProps({
            visible: true,
            title: '修改收款信息',
            formRef,
            hiddenFileds: ['addReason'],
            onVisibleChange: (visible: boolean) => {
              if (visible) {
                formRef.current?.resetFields()
                console.log('rrrecord', record)
                formRef.current?.setFieldsValue({...record})
              }
            },
            async onFinish(values: any) {
              const otherArrs = getMoneyDataSource.filter(v => v.id !== rid)
              const idx = getMoneyDataSource.findIndex(v => v.id === rid)
              const isNext = checkPayAccount(otherArrs, values)
              if (isNext) {
                getMoneyDataSource[idx] = {...getMoneyDataSource[idx], ...values, id: rid}
                const params = handleSubmitParams({
                  refundOrderDataSource, 
                  rebateInfoDataSource, 
                  getMoneyDataSource,
                  delGetMoneyDataSource
                })
                request({...params, id, hasPaper: hasPaperRef.current ? 1 : 0}, () => setGetMoneyProps({visible: false}))
              }
            }
        })

        // 收款信息-删除
        const delGetMoneyAction = (props: Object = {}) => setDelMoneyProps({
            visible: true,
            formRef:delGetMoneyFormRef,
            onVisibleChange: (visible: boolean) => {
              if (visible) {
                delGetMoneyFormRef.current?.resetFields()
              }
            },
            async onFinish(values: any) {
              const {files:filesArr, ...rest} = values || {}
              const files = handleUploadFiles(filesArr||[])
              Modal.confirm({
                title: '温馨提示',
                content: '确定执行此操作？撤销之后, 不可恢复',
                cancelText: '取消',
                async onOk() {
                  const params = handleSubmitParams({
                    refundOrderDataSource, 
                    rebateInfoDataSource, 
                    getMoneyDataSource: getMoneyList,
                    delGetMoneyDataSource: [...delGetMoneyDataSource, {...record, files, ...rest}]
                  })
                  request({...params, id, hasPaper: hasPaperRef.current ? 1 : 0}, () => setDelMoneyProps({visible: false}))
                }
              })
            }
        })
        return {
            addRebateInfoAction,
            delRebateInfoAction,
            addGetMoneyAction,
            updateGetMoneyAction,
            delGetMoneyAction
        }
    }
    
    const createOperate = (record: any, onSuccess?: Function) => {
        const { id } = record || {}
        const { 
            addRebateInfoAction,
            delRebateInfoAction,
            addGetMoneyAction,
            updateGetMoneyAction,
            delGetMoneyAction
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params?: any) => {
            switch (type) {
                case '返点信息-新增':
                    return addRebateInfoAction()
                case '返点信息-删除':
                    return delRebateInfoAction()
                case '收款信息-新增':
                    return addGetMoneyAction()
                case '收款信息-修改':
                    return updateGetMoneyAction()
                case '收款信息-删除':
                    return delGetMoneyAction()
            }
        }
    }

    return {
        hasPaperRef,
        formRef,
        rebateInfoFormRef,
        getMoneyFormRef,
        baseData,
        setBaseData,
        getCache,
        setCache,
        removeCache,
        refundOrderDataSource,
        setRefundOrderDataSource,
        rebateInfoDataSource,
        setRebateInfoDataSource,
        getMoneyDataSource,
        setGetMoneyDataSource,
        delGetMoneyDataSource,
        setDelGetMoneyDataSource,
        getMoneyProps,
        setGetMoneyProps,
        delMoneyProps,
        setDelMoneyProps,
        rebateInfoProps,
        setRebateInfoProps,
        fillFormData,
        request,
        requestWithCache,
        onValuesChange,
        rebateInfoTableOnValuesChange,
        getMoneyTableOnValuesChange,
        createAction,
        createOperate,
    }
}