import { Button, Col, Modal, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { contactColumnsFn } from '../../data'
import ProForm, { ModalForm, ProFormText, ProFormCheckbox, ProFormSelect, ProFormTextArea, ProFormRadio, ProFormDatePicker } from '@ant-design/pro-form';
import { EnterpriseDetailProps } from "../../type";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { customerContactAdd, customerContactList, customerLqContactList, customerContactUpdate, customerContactDetail, bindPhone } from '@api/index'
import { emailReg, fixedPhone, phoneReg, productApi, validatorMobilePhone } from "@utils/index";
import { useDictEnumHooks, useTableHooks } from "@hooks/index";
import ProTable, { ActionType } from "@ant-design/pro-table";

interface ContactListProps extends EnterpriseDetailProps{
    readOnly?: boolean;
    whichApi?: 'lq' | 'jns';
}

// 区分调用猎奇接口 还是 crm 接口
const useLqContactApi = (whichApi: string = 'lq') => {
    return {
        getApi: ['lq'].includes(whichApi||'lq') ? productApi(customerLqContactList) : productApi(customerContactList),
        addApi: productApi(customerContactAdd),
        updateApi: productApi(customerContactUpdate),
        bindPhoneApi: productApi(bindPhone)
        // getInfoApi: productApi()
    }
}

const getContactDetailApi = productApi(customerContactDetail)

interface AddContactProps extends ModalFormProps{
    onSuccess?: () => void;
    customerId?: string | number;
    contactId?: string | number | null | undefined; // 编辑时需要
}
export const AddContact = ({onSuccess, contactId, customerId, ...rest}: AddContactProps) => {
    const formRef = useRef<ProFormInstance>()
    const [positionOptiions] = useDictEnumHooks({dictId: 22}, true)
    const [feedbackOptiions] = useDictEnumHooks({dictId: 26}, true)
    const { addApi, updateApi } =  useLqContactApi()
    const request = async (param: any) => {
        const { data } = await getContactDetailApi(param, false)
        const { name, gender, position, phone, fxPhone, email, wechat, qq, dingNumber, remarks, primaryContact, feedback } = data || {}
        formRef.current?.setFieldsValue({ 
            name, 
            gender, 
            position, 
            phone, 
            fxPhone, 
            email, 
            wechat, 
            qq, 
            dingNumber, 
            remarks, 
            feedback,
            primaryContact: [primaryContact]
         })
    }
    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
            contactId && request({id: contactId})
        }
    }

    const onFinish = async (values: any) => {
        const { primaryContact: arr } = values || {}
        const whichApi = contactId ? updateApi : addApi
        const { success } = await whichApi({...values, id: contactId, customerId, primaryContact: (arr||[]).length}, true, () => {
            onSuccess && onSuccess()
        })
        return success
    }

    return (<ModalForm<AddContactProps> layout="horizontal" width={600} labelCol={{span: 5}} onVisibleChange={onVisibleChange} formRef={formRef} onFinish={onFinish} {...rest}>
            <ProFormText width="md" name="name" label="姓名" rules={[{required: true, message: '请填写姓名'}, {max: 30, type:'string', message: '30字以内'}]} />
            <ProFormRadio.Group width="md" name="gender" label="性别" options={[{label: '男', value: '男'}, {label: '女', value: '女'}]} rules={[{required: false, message: '请选择性别'}]} />
            {/* <ProFormSelect width="md" name="position" label="职位" options={positionOptiions} rules={[{required: true, message: '请选择职位'}]} /> */}
            <ProFormText width="md" name="position" label="职位" rules={[{required: true, message: '请填写职位'}]} />

            <ProFormText width="md" name="phone" label="手机号" rules={[{required: true, validator: validatorMobilePhone}]} />
            <ProFormText width="md" name="fxPhone" label="固定电话" rules={[{required: false, message: '请填写固定电话'}, { pattern: fixedPhone, message: '请填写正确的固定电话'}]} />
            <ProFormText width="md" name="email" label="邮箱" rules={[{required: false, message: '请填写邮箱'}, {pattern: emailReg, message: '请填写正确的邮箱'}]} />
            <ProFormText width="md" name="wechat" label="微信号" rules={[{required: false, message: '请填写微信号'}, {max: 20, type:'string', message: '20字以内'}]} />
            {/* <ProFormText width="md" name="qq" label="QQ号" rules={[{required: false, message: '请填写QQ号'}, {max: 20, type:'string', message: '20字以内'}]} /> */}
            <ProFormText width="md" name="dingNumber" label="钉钉号" rules={[{required: false, message: '请填写钉钉号'}, {max: 20, type:'string', message: '20字以内'}]} />
            <ProFormTextArea width="md" name="remarks" label="备注" fieldProps={{showCount: false, maxLength: 100}} rules={[{required: false, message: '请填写备注'}, {max: 100, type:'string', message: '100字以内'}]} />

            {/* { contactId ? <ProFormSelect width="md" name="feedback" label="联系结果反馈" options={feedbackOptiions} rules={[{required: true, message: '请选择联系结果反馈'}]} /> : null } */}

            {/* <ProFormCheckbox.Group width="md" name="primaryContact" label="设为主要" options={[{label: '设为主要联系人', value: '1'}]} rules={[{required: false, message: '请选择所属角色'}]} /> */}

        </ModalForm>)
}

export default ({ authCode, id, name, whichApi }: ContactListProps) => {
    const { getApi, addApi, updateApi, bindPhoneApi } = useLqContactApi(whichApi|| 'lq')
    const ref = useRef<ActionType>(null);
    // const { tableProps, fetchApi } = useTableHooks(getApi, {customerId: id, name})
    const [addContactProps, setAddContactProps] = useState<AddContactProps>({title: '新增联系人', visible: false, customerId: id, onSuccess: undefined})
    
    const operate = async (type: string, record?: any) => {
        const {id: contactId, type: contactType  } = record || {}
        if (type === '新建联系人') {
            setAddContactProps({
                ...addContactProps,
                title: type,
                visible: true,
                customerId: id,
                contactId: undefined,
                onSuccess: () => {
                    reFush()
                    setAddContactProps({...addContactProps, visible: false})
                }
            })
        } else if (type === '编辑联系人') {
            setAddContactProps({
                ...addContactProps,
                title: type,
                visible: true,
                customerId: id,
                contactId,
                onSuccess: () => {
                    reFush()
                    setAddContactProps({...addContactProps, visible: false})
                }
            })
        } else if (type === '查看号码') {
            await bindPhoneApi({id: contactId, type: contactType}, false, (data: any) => {
                const { smallPhone } = data || {}
                Modal.confirm({
                    title: '查看',
                    content: `手机号码：${smallPhone}`,
                    okText: '确定',
                    cancelText: '取消',
                    onOk: async() => {
                    },
                })
            })
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}) as any
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const columns = contactColumnsFn(operate, authCode)
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
        <ProTable<any>
            request={getListApi}
            params={{customerId: id, name}}
            columns={columns}
            search={false}
            options={{reload: false, density: false, setting: false, fullScreen: false}}
            toolbar={{
                actions: [
                    authCode?.includes('add') ? <Button type="primary" onClick={() => operate && operate('新建联系人')}>新建联系人</Button> : null
                ],
            }}
            rowKey="id"
            actionRef={ref as any}
        />
        <AddContact {...addContactProps} modalProps={{onCancel: () => setAddContactProps({...addContactProps, visible:false}), maskClosable: false}} />
    </div>)
}