import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Checkbox, Col, Popover, Row, Tooltip } from "antd";
import { CheckboxGroupProps, CheckboxProps } from 'antd/es/checkbox'
import type { SortableContainerProps } from 'react-sortable-hoc'
import { ProColumnType } from "@ant-design/pro-table";
import { arrayMoveImmutable } from 'array-move';
import './index.less'
import { Icon } from "..";
import { isFunction } from "lodash";

interface DragSortWarpProps extends SortableContainerProps {
    warpStyle?: CSSProperties;
    warpClassName?: string;
    children?: ReactNode;
}

type OptionsItemType = {
    label: string;
    value: string | number;
    checked?: boolean;
}
type FormDragSettingProps = {
    options: OptionsItemType[];
    onChange: (options: OptionsItemType[], type: string) => void;
}

interface PopoverContentProps extends CheckboxGroupProps{
    onSortEnd?: (val: any) => void
}

interface PopoverTitleProps extends CheckboxProps{
    onReset?: () => void;
}

interface OwnProColumnType extends ProColumnType{
    hideInSearchImportant?: boolean
}

export const getFormDragSettingOptions = (columns: ProColumnType[]) => columns.filter(({hideInSearch, key}) => !hideInSearch && key !== 'option').sort((a,b) => (b.order||0) - (a.order||0)).map(({dataIndex: value, title: label}: any) => ({label: isFunction(label) ? label({}, 'form') : label, value, checked: true}))
export const setColumnsByOptions = (options: OptionsItemType[], columns: OwnProColumnType[]) => {
    let len = (options||[]).length;
    (options||[]).forEach((v, order) => {
        const thatItem = columns.find(item => item.dataIndex === v.value)||{} as any
        Object.assign(thatItem, {
           order: len--,
           hideInSearch: (thatItem?.hideInSearchImportant as any) || !v.checked,
        })
    })
    return columns
}

const getCheckedValue = (options: any[]) => options.filter(v => v.checked).map(v => v.value)

const DragHandle = SortableHandle(() => <Icon type="icon-tuozhuai" style={{marginRight: 10}} />) as any
const DragSortWarp: any = SortableContainer(({ children, warpStyle, warpClassName }: DragSortWarpProps) => <div className={warpClassName} style={warpStyle}>{children}</div>)
const DragSortItem = SortableElement(({ children }: any) => children) as any

const PopoverTitle = ({onReset, ...rest}: PopoverTitleProps) => <Row justify="space-between"><Col><Checkbox {...rest}>筛选项设置</Checkbox></Col><a onClick={() => onReset && onReset()}>重置</a><Col></Col></Row>
const PopoverContent = ({options, onSortEnd, ...rest}: PopoverContentProps) => {
    return (<DragSortWarp helperClass="dargging" lockAxis="y" useDragHandle onSortEnd={onSortEnd} warpClassName="jns-form-drag-warp">
        <Checkbox.Group style={{width: '100%'}} value={getCheckedValue(options as any)} {...rest}>
        {
            (options||[]).map(({label, value}: any, idx) => 
            <DragSortItem key={value} index={idx} >
                <Row align="middle" className="jns-form-drag-item">
                    <DragHandle /><Checkbox value={value}>{label}</Checkbox>
                </Row>
            </DragSortItem>)
        }
        </Checkbox.Group>
    </DragSortWarp>)
}

export const FormDragSetting = ({options, onChange}: FormDragSettingProps) => {
    const [checkAll, setCheckAll] = useState<boolean>(false);
    const [indeterminate, setIndeterminate] = useState<boolean>(false);

    const onOwnChange = (type: string, val: any) => {
        if (type === 'all') {
           const newOptions = options.map(v => ({...v, checked: val}))
           onChange && onChange(newOptions, type)
        } else if (type === 'item') {
           const newOptions = options.map(v => ({...v, checked: val.includes(v.value)}))
           onChange && onChange(newOptions, type)
        } else if (type === 'sortEnd') {
            const {oldIndex, newIndex} = val
            const newOptions = arrayMoveImmutable(options, oldIndex, newIndex);
            onChange && onChange(newOptions, type)
        } else if (type === 'reset') {
            onChange && onChange([], 'reset')
        }
    }

    useEffect(() => {
        const checkedLen = getCheckedValue(options).length
        if (checkedLen > 0) {
            if (checkedLen === options.length) {
                setCheckAll(true)
                setIndeterminate(false)
            } else {
                setCheckAll(false)
                setIndeterminate(true)
            }
        } else {
            setCheckAll(false)
            setIndeterminate(false)
        }
    }, [options])

    return (<Popover 
                trigger={['click']} 
                content={<PopoverContent options={options} onChange={(val) => onOwnChange && onOwnChange('item', val)} onSortEnd={(val) => onOwnChange && onOwnChange('sortEnd', val)} />} 
                title={<PopoverTitle checked={checkAll} indeterminate={indeterminate} onChange={(e) => onOwnChange && onOwnChange('all', e?.target?.checked)} onReset={() => onOwnChange && onOwnChange('reset', null)} />} placement="bottomRight" overlayClassName="ant-pro-table-column-setting-overlay">
                <Tooltip placement="top" title="筛选条件设置">
                    <Icon type="icon-shezhi" />
                </Tooltip>
        </Popover>)
}
