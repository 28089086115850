import { Button, Col, Input, Modal, Popover, Row, Timeline } from "antd"
import React, { useEffect, useState } from "react"
import { ClockCircleOutlined } from '@ant-design/icons';
import './index.less'
import { AddFollowRecord, AddFollowRecordProps } from "@components/Customer/List";
import { useLocation } from "react-router-dom";
import { parseSearch, productApi } from "@utils/index";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { customerFollowDel, customerFollowsByCustomerId } from '@api/customer'
import { useHasAuthCode } from "@hooks/index";
import { followUpTypeOptions } from "@components/Customer/List/data";
const getFollowsApi = productApi(customerFollowsByCustomerId)
const delApi = productApi(customerFollowDel)

moment.locale('zh-cn')

const TimelineLabel = ({item}: any) => {
    const { createTime, creator } = item
    const time = moment(createTime)
    return (<div>
        <div>{creator}</div>
        <div style={{fontSize: 16}}>{createTime ? time.format('YYYY-MM-DD') : ''}</div>
        <div style={{color: 'rgba(0,0,0,0.45)' }}>{createTime ? time.format('dddd') : ''}  <ClockCircleOutlined className="timeline-clock-icon" />  {time.format('HH:mm')}</div>
    </div>)
}

const TimelineItem = ({item, operate, keyword}: any) => {
    const [, hasAuthCode] = useHasAuthCode()
    const {followUpContent, nextActionContent, stickingPoint, followUpMethod, contactsName, businessOpportunityTitle, followUpTime, synergName, projectStage, helpPoint,
        accountRegister, createGroup, makeSuccess, poc, procurementMethod, orderMethod, followUpType
    } = item || {}
    return (<div className="follow-item-time-line">
        <Row justify="end">
            { hasAuthCode(2512) ? <Button type="primary" size="small" onClick={() => operate?.('编辑', item)}>编辑</Button> : null } 
            { hasAuthCode(2513) ? <Button type="primary" danger size="small" onClick={() => operate?.('删除', item)} style={{marginLeft: 10}}>删除</Button> : null }
        </Row>
        <Popover 
           content={<>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>本次沟通联系人:</Col> {contactsName}</Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>商机标题:</Col><Col>{businessOpportunityTitle}</Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>本次沟通时间:</Col><Col>{followUpTime}</Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>本次跟进协同人:</Col><Col>{synergName||'无'}</Col></Row>
            {/* <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>项目阶段:</Col><Col>{projectStage}</Col></Row> */}
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>求助点:</Col><Col>{helpPoint}</Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>账号是否注册:</Col><Col>{accountRegister == 1 ? '是' : '否'}</Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>建立沟通群:</Col><Col>{createGroup}</Col></Row>
            {/* <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>是否80%以上概率落单:</Col><Col>{makeSuccess == 1 ? '是' : '否'}</Col></Row> */}
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>是否poc测试:</Col><Col>{poc}</Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>采购方式:</Col><Col>{procurementMethod}</Col></Row>
            {/* <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>下单方式:</Col><Col>{orderMethod}</Col></Row> */}
            {/* <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>跟进类型:</Col><Col>{followUpTypeOptions.find(v => v.value === followUpType)?.label}</Col></Row>  */}
            {/* <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>跟进来源:</Col><Col>{orderMethod}</Col></Row>  */}

           </>}>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>跟进方式:</Col> {followUpMethod}</Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>跟进内容:</Col><Col dangerouslySetInnerHTML={{__html: (followUpContent||'').replaceAll(keyword, `<span style="color: red">${keyword}</span>`)}}></Col></Row>
            <Row style={{marginBottom: 8}}><Col style={{fontWeight: 600, marginRight: 8}}>下一步动作:</Col><Col>{nextActionContent}</Col></Row>
            <Row><Col style={{fontWeight: 600, marginRight: 8}}>待突破的卡点:</Col><Col>{stickingPoint}</Col></Row>
        </Popover>
    </div>)
}


const mockData = [
    {
        time: moment(),
        followUpContent: '我是跟进内容',
        nextActionContent: '我是下一步动作'
    },
    {
        time: moment(),
        followUpContent: '我是跟进内容',
        nextActionContent: '我是下一步动作'
    },
]

export default () => {
    const { pathname, search } = useLocation();
    const { customerId, customerName } = parseSearch(search)
    const [dataSource, setDataSource] = useState([])
    const [keyword, setKeyword] = useState('')
    
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId, customerName, businessOppId: undefined})

    const operate = (type: any, record: any) => {
        const { id } = record || {}
        switch(type){
            case '新建':
                setAddFollowProps({
                    ...addFollowProps,
                    visible: true,
                    params: undefined,
                    title: '新建跟进',
                    onSuccess: () => {
                        setAddFollowProps({...addFollowProps, visible: false})
                        getApi()
                        return true
                    }
                })
                break;
            case '编辑':
                setAddFollowProps({
                    ...addFollowProps,
                    visible: true,
                    title: '编辑跟进',
                    params: { id },
                    onSuccess: () => {
                        setAddFollowProps({...addFollowProps, visible: false})
                        getApi()
                        return true
                    }
                })
                break;
            case '删除':
                Modal.confirm({
                    title: '确认要删除该数据吗?',
                    content: '删除后当前内容将永久删除，不可恢复。',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await delApi({ids: [id]}, true, getApi)
                    },
                })
                break;
        }
    }

    const getApi = async () => {
        const { data } = await getFollowsApi({customerId})
        const { values } = data || {}
        setDataSource(values||[])
    }

    useEffect(() => {
        getApi()
    }, [])

    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 40}}>
            <Row justify="center">
                <Input.Search 
                    style={{width: '360px', marginBottom: 20}} 
                    onSearch={setKeyword} 
                    placeholder="请输入跟进内容进行查询" 
                />
            </Row>
            <Timeline mode={'left'} style={{transform: 'translateX(-300px)'}}>
                <Timeline.Item>
                    <Button 
                        type="primary" 
                        onClick={() => operate?.('新建', null)} 
                        shape="circle" 
                        size="large" 
                        style={{
                            transform: 'translateX(-47px)', 
                            marginBottom: 46,
                            width: 52,
                            height: 52,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <PlusOutlined />
                            <span style={{marginLeft:0, fontSize: 12}}>记录</span>
                    </Button>
                </Timeline.Item>
                { dataSource.map((v: any, k: number) => <Timeline.Item key={k} label={<TimelineLabel item={v} />}>
                    <TimelineItem item={v} operate={operate} keyword={keyword} />
                </Timeline.Item>) }

            </Timeline>
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
    </div>
    )
}