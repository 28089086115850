import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, productApi, treeDataDisabledRules } from "@utils/util"
import { Alert, Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, rebackAuditClaim, getContractByUserId, rebackClaimSearch } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ProForm, ModalForm, ProFormSelect, ProFormDatePicker,  ProFormDependency } from '@ant-design/pro-form';
import { useRebackClaimHooks } from "./hooks"
import { OperateActionType } from "../../data"
import { Icon, ProFormTreeSelect, WithSearchTree } from '@components/index'
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import Table from "@ant-design/pro-table/lib/Table"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"

const getPageListApi = productApi(rebackClaimSearch)

const persistenceKey = 'finance/contract/rebackClaim'

const rebackAuditClaimApi = productApi(rebackAuditClaim)

export type RebackClaimModalFormProps = {
    hiddenFields?: ('userId' | 'xsContract')[];
    onSuccess?: () => void;
} & ModalFormProps


export const RebackClaimModalForm = ({params ,onSuccess, hiddenFields, ...rest}: RebackClaimModalFormProps) => {
    const { deptWithUserTreeData, userInfo } = useSelector((state: StateType) => state)
    const treeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const { user } = userInfo || {}
    const { id: currentUserId } = user || {}

    const request = async (params: object) => {
        return({ })
    }

    const onFinish = async ({ userId, xsContract, ...rest }: any) => {
        const uId = (userId||`s${currentUserId}`)?.slice(1) 
        const { label: xsContractNumber, value: xsContractId } = xsContract || {}
        await rebackAuditClaimApi({userId: uId, xsContractId, xsContractNumber, backClaimId: params?.backClaimId }, false, onSuccess)
    }
    
    return (<ModalForm<any> 
        modalProps={{maskClosable: false}} 
        layout="horizontal" 
        title={'认领回款'}
        width={500} 
        wrapperCol={{span: 24}}
        labelCol={{span: 5}} 
        params={{...params, noCache: rest?.visible}}
        onFinish={onFinish}
        request={request}
        {...rest}
        >
            <Alert message="提示：请确认打款客户为您名下客户，再进行认领" style={{marginBottom: 20}} />
            { !hiddenFields?.includes('userId') ?
                <ProFormTreeSelect  
                    fieldProps={{
                        treeData, 
                        showSearch: true, 
                        treeNodeFilterProp: 'title',
                        allowClear:true
                    }}  
                    name="userId" 
                    label="认领人" 
                    rules={[
                        {required: true, message: '请选择认领人'}
                    ]} 
                />
                : null
            }
            <ProFormDependency name={['userId']}>
                {({userId}) => {
                    return (<ProFormSelect 
                        name="xsContract" 
                        label="销售合同编号"
                        params={{userId: userId || `s${currentUserId}`}}
                        fieldProps={{ labelInValue: true }}
                        request={async (params) => {
                            const { userId: userIdStr } = params
                            const userId = userIdStr.slice(1)
                            const { data } = await getContractByUserId({userId}) as any
                            return (data||[]).map((v: any) => ({label: v.xsContractNumber, value: v.xsContractId}))
                        }} 
                        rules={[
                            {required: false, message: '请选择合同'}
                        ]} 
                    />)
                }}
            </ProFormDependency>
        </ModalForm>)
}


export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}>{data.payAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}

export default ({dataScope}: any) => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any

    const {
        rebackClaimModalFormProps,
        setRebackClaimModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    } = useRebackClaimHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        action(type, {})
    }

    const columns = [...columnsFn(operate, '')]
    const params = { dataScope }
    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setAllTableData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            backIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    return(<div className="oa-task-center">
        <ProTableWithFormSetting<any>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>打款金额： <span style={{color: 'red'}}>{allTableData.payAmount || 0}</span> （汇总列表查询结果）</h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2469) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/exportBackClaim`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="backClaimId"
                actionRef={ref as any}
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <RebackClaimModalForm
            modalProps={{
                onCancel: () => setRebackClaimModalFormProps({...rebackClaimModalFormProps, visible: false}),
                maskClosable: false
            }}  
            hiddenFields={['userId']}
            {...rebackClaimModalFormProps} 
        />
    </div>)
}
