import ProTable, { ActionType } from "@ant-design/pro-table";
import { Button, Modal, message, Tabs, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react"
import { columnsFn, OperateActionType, TableListItem } from "./data";
import { Icon, WithSearchTree } from '@components/index'
import { WithSearchTreeWarp } from "@components/Common/WithSearchTree";
import ProForm, { ModalForm, ProFormText, ProFormTextArea, ProFormRadio, } from '@ant-design/pro-form';
import { productCategoryTree, productList, productCategorySave, productCategoryDel, productSave, productDel } from '@api/index'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { productApi, transferTreeData } from "@utils/util";
import { useHasAuthCode } from "@hooks/index";
import { TreeDataItem } from "types";
const { TabPane } = Tabs

const getApi = productApi(productCategoryTree)
const getRightApi = productApi(productList)
const saveProdCategoryApi = productApi(productCategorySave)
const deleteProdCategoryApi = productApi(productCategoryDel)
const saveProdApi = productApi(productSave)
const delProdApi = productApi(productDel)

// 添加产品分类
interface AddProDuctCategoryProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void | null;
}
export const AddProDuctCategory = ({params, onSuccess, ...rest}: AddProDuctCategoryProps) => {
    const onFinish = async (values: any) => {
        const {id, ...rest} = params || {} // 添加params = { parentId, }; 修改params = { parentId, id }
        const { success } = await saveProdCategoryApi({...values, ...params}, false, onSuccess)
        return success
    }
    return (<ModalForm<any> layout="horizontal" modalProps={{maskClosable: false}} width={500} labelCol={{span: 4}} onFinish={onFinish} {...rest}>
            {/* <ProFormText width="md" name="parentName" disabled label="上级分类" rules={[{required: false, message: '请填写'}]} /> */}
            <ProFormTextArea fieldProps={{ rows:1, showCount: true,  maxLength: 30 }} width="md" name="name" label="分类名称" rules={[{required: true, message: '请填写分类名称'}]} />
        </ModalForm>)
}

interface AddProductProps extends ModalFormProps{
    params?: any;
    onSuccess?: () => void;
}
// 配置/编辑产品
export const AddProduct = ({params, onSuccess, ...rest}: AddProductProps) => {
    const onFinish = async (values: any) => {
        const { success } = await saveProdApi({...values, ...params}, true, onSuccess)
        return success
    }
    return (<ModalForm<AddProductProps> modalProps={{maskClosable: false}} layout="horizontal" width={500} labelCol={{span: 4}} onFinish={onFinish} {...rest}>
            <ProFormText width="md" name="categoryName" disabled label="分类名称" rules={[{required: false, message: '请填写'}]} />
            <ProFormTextArea fieldProps={{ rows:1, showCount: true,  maxLength: 20 }} width="md" name="productName" label="产品名称" rules={[{required: true, message: '请填写产品名称'}]} />
            <ProFormTextArea fieldProps={{ rows:3, showCount: true,  maxLength: 20 }} width="md" name="productDesc" label="产品描述" rules={[{required: false, message: '请填写产品描述'}]} />
            <ProFormRadio.Group width="md" name="state" label="状态" options={[{label: '启用', value: 1}, {label: '禁用', value: 0}]} rules={[{required: true, message: '请选择状态'}]} />
        </ModalForm>)
}

type CustomerViewProps = {
    view?: string;
    scope?: string;
    onChange?: (val: any) => void;
}
// 客户视图
const CustomerView = ({view, scope, onChange}: CustomerViewProps) => {
    return <Tabs onChange={(val) => onChange && onChange(val)} tabBarStyle={{background: '#fff', margin: 0, padding: '0 10px'}}>
        <TabPane tab="通用产品" key="1" />
        <TabPane tab="阿里产品" key="2" />
    </Tabs>
}

export default () => {
    const [view, setView] = useState('1')
    const [loading, setLoading] = useState<boolean>(true)
    const [treeData, setTreeData] = useState<TreeDataItem[]>([])
    const [expandedKeys, setExpandedKeys] = useState<string[]>([])
    const [currentTreeDataItem, setCurrentTreeDataItem] = useState<any>(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const [addProDuctCategoryProps, setAddProDuctCategoryProps] = useState<AddProDuctCategoryProps>({visible: false, title: '添加分类', onSuccess: undefined})
    const [addProDuctProps, setAddProductProps] = useState<AddProductProps>({visible: false, title: '添加产品', onSuccess: undefined})

    const addProDuctCategoryRef = useRef<ProFormInstance>()
    const addProductRef = useRef<ProFormInstance>()
    const [, hasAuthCode] = useHasAuthCode()

    const ref = useRef<ActionType>(null);

    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id: categoryId, name: categoryName } = currentTreeDataItem || {}
        const { id, productName, productDesc, state = 0 } = record || {}
        addProductRef.current?.resetFields()
        if(['新建产品'].includes(type)) {
            if (categoryId === undefined) {
                message.warning('请先选择一个分类');
                return
            }
            addProductRef.current?.setFieldsValue({categoryName})
            setAddProductProps({
                ...addProDuctProps,
                title: '新建产品',
                visible: true,
                params: { categoryId, productType: view },
                onSuccess: () => {
                    setAddProductProps({...addProDuctProps, visible: false})
                    reFresh()
                }
            })
        } else if (type === '编辑') {
            addProductRef.current?.setFieldsValue({categoryName, productName, productDesc, state})
            setAddProductProps({
                ...addProDuctProps,
                title: '编辑',
                visible: true,
                params: { categoryId, productType: view, id },
                onSuccess: () => {
                    setAddProductProps({...addProDuctProps, visible: false})
                    reFresh()
                }
            })
        } else if (type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delProdApi({ids: selectedRowKeys}, true, () => {
                        reFresh()
                    })
                },
            });
        }
    }
    const columns = columnsFn(operate)
    const treeSearch = ({filterData, expandedKeys }: any) => {
        setTreeData(filterData)
        setExpandedKeys(expandedKeys)
    }
    const onExpand = (expandedKeys: any) => {
        setExpandedKeys(expandedKeys)
    }

    const getTreeDataApi = async (param: any) => {
        setLoading(true)
        await getApi(param, false, (data: TreeDataItem[]) => {
            transferTreeData(data)
            setTreeData(data)
        })
        setLoading(false)
    }

    const getTableDataApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getRightApi({...params, categoryId: currentTreeDataItem?.id}, false)
        const { total = 0, values = []}  = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const titleOperate = (nodeData: any, type: string) => {
        // dictId = 0 时添加一级字典类型   其余正常传id
        const { id = 0, name, parentId = undefined } = nodeData || {}
        if (type === 'add') {
            addProDuctCategoryRef.current?.setFieldsValue({ name: ''})
            setAddProDuctCategoryProps({
                ...addProDuctCategoryProps,
                title: '添加分类',
                visible: true,
                params: { parentId: id, type: view },
                onSuccess: () => {
                    setAddProDuctCategoryProps({...addProDuctCategoryProps, visible: false})
                    getTreeDataApi({type: view})
                }
            })
         } else if (type === 'update') {
            addProDuctCategoryRef.current?.setFieldsValue({ name})
            setAddProDuctCategoryProps({
                ...addProDuctCategoryProps,
                title: '修改分类',
                visible: true,
                params: {parentId, id, type: view},
                onSuccess: () => {
                    setAddProDuctCategoryProps({...addProDuctCategoryProps, visible: false})
                    getTreeDataApi({type: view})
                }
            })
         } else if (type === 'titleClick') {
            //再次点击字典选项时置空  
            (nodeData?.id === currentTreeDataItem?.id) ? setCurrentTreeDataItem(null) : setCurrentTreeDataItem(nodeData)
         } else if (type === 'del') {
             Modal.confirm({
                 title: '确认要删除该数据吗?',
                 content: '删除后当前内容将永久删除，不可恢复。',
                 okText: '确认',
                 cancelText: '取消',
                 onOk: async (val: any) => {
                    await deleteProdCategoryApi({id, type: view}, true, () => {
                        getTreeDataApi({type: view})
                        setSelectedRowKeys([])
                    })
                 },
            });
         }
    }
    const calcShowIcons = (v: any) => {
        const add = hasAuthCode(2204) ? 'add' : undefined
        const update = hasAuthCode(2205) ? 'update' : undefined
        const del = hasAuthCode(2210) ? 'del' : undefined
        return [add, update, del].filter(Boolean)
    }

    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    useEffect(() => {
        getTreeDataApi({type: view})
        setCurrentTreeDataItem(null)
    }, [view])
    
    useEffect(() => {
        ref?.current?.reload()
    }, [currentTreeDataItem])

    return <div>
            <CustomerView view={view} onChange={setView} />
            <Spin spinning={loading}>
                <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                    <div style={{width: '265px', marginRight: '15px'}}>
                        <WithSearchTreeWarp title="产品管理" Icon={hasAuthCode(2204) ? false : ' '} onOperate={() => titleOperate(null, 'add')}>
                            <WithSearchTree 
                                style={{marginTop: '12px'}} 
                                treeData={treeData} 
                                blockNode 
                                expandedKeys={expandedKeys} 
                                showIcons={calcShowIcons as any} 
                                autoExpandParent={true} 
                                onExpand={onExpand} 
                                onSearch={treeSearch} 
                                titleOperate={titleOperate} 
                            />
                        </WithSearchTreeWarp>
                    </div>
                    <ProTable<TableListItem>
                        style={{flex: 1, width: `calc('100% - 280px')`}}
                        columns={columns}
                        params={{type: view}}
                        request={getTableDataApi}
                        options={{reload: false, density: false, setting: false}}
                        toolbar={{
                            actions: [
                                hasAuthCode(2212) ? <Button key="primary" type="primary" onClick={() => operate('新建产品')}>新建产品</Button> : null,
                                hasAuthCode(2214) ? <Button key="del" disabled={!selectedRowKeys.length} onClick={() => operate('删除')}>删除</Button> : null,
                            ],
                        }}
                        search={{
                            optionRender: (searchConfig, formProps, dom) => 
                            [ ...dom.reverse() ]
                        }}
                        rowKey="id"
                        pagination={{defaultPageSize: 10}}
                        actionRef={ref as any}
                        rowSelection={{
                            preserveSelectedRowKeys: true,
                            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)},
                        }}
                    />
                </div>
            </Spin>
            <AddProDuctCategory {...addProDuctCategoryProps} formRef={addProDuctCategoryRef} modalProps={{onCancel: () => setAddProDuctCategoryProps({...addProDuctCategoryProps, visible: false}), maskClosable: false}} />
            <AddProduct {...addProDuctProps} formRef={addProductRef} modalProps={{onCancel: () => setAddProductProps({...addProDuctProps, visible: false}), maskClosable: false}} />
        </div>
}