import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL, EasyMockUrl, LQBASEURL } from './baseUrl'
/*-----------------------------------------工作台----------------------------------*/
// 查询数据简报的数量的数据
export const workBenchCount = (data?: any) => request({
    url: `${BASEURL}/index/dataBulletin`,
    // method: 'GET',
    data,
})

// 业务统计 线索查询
export const workBenchClueQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewClue`,
    // method: 'GET',
    data,
})

// 业务统计 客户查询
export const workBenchCustomerQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewCustomer`,
    // method: 'GET',
    data,
})

// 业务统计 商机查询
export const workBenchBusinessQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewBusinessOpp`,
    // method: 'GET',
    data,
})

// 业务统计 联系人
export const workBenchContactQuery = (data?: any) => request({
    url: `${BASEURL}/workbench/getNewContact`,
    // method: 'GET',
    data,
})

// 业务统计 客户数
export const workBenchCustomerOrClueNum = (data?: any) => request({
    url: `${BASEURL}/workbench/getFollowUp`,
    // method: 'GET',
    data,
})

// 销售漏斗
export const workBenchBusinessStic = (data?: any) => request({
    url: `${BASEURL}/workbench/getDailyBusinessStatistics`,
    // method: 'GET',
    data,
})

// 销售漏斗
export const workBenchFunnel = (data?: any) => request({
    url: `${BASEURL}/workbench/getSalesFunnelData`,
    // method: 'GET',
    data,
})

// 我的任务分页列表
export const workBenchTaskPageList = (data?: any) => request({
    url: `${BASEURL}/workbench/myTask`,
    data,
})

// 工作报告
export const workbenchReportList = (data?: any) => request({
    url: `${BASEURL}/workbench/searchByConditions`,
    // method: 'GET',
    data,
})

/*-----------------------------------------工作台 1----------------------------------*/

// 商机分析 -- 个人列表
export const workbenchBusinessAnalysis = (data?: any) => request({
    url: `${BASEURL}/index/persionBusiness`,
    // method: 'GET',
    data,
})

// 商机分析 -- 部门列表
export const workbenchDeptBusinessAnalysis = (data?: any) => request({
    url: `${BASEURL}/index/deptBusiness`,
    // method: 'GET',
    data,
})

// 商机分析 -- 统计
export const workbenchBusinessAnalysisStatistic = (data?: any) => request({
    url: `${BASEURL}/index/businessAnalyse`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 个人业绩-列表
export const workbenchKpiAnalysis = (data?: any) => request({
    url: `${BASEURL}/index/persionQuarterlyPerformanceAnalyse`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 部门业绩-列表
export const workbenchDeptKpiAnalysis = (data?: any) => request({
    url: `${BASEURL}/index/deptQuarterlyPerformanceAnalyse`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 未完成数据
export const workbenchKpiDone = (data?: any) => request({
    url: `${BASEURL}/index/doneQuarterlyPerformanceAnalyse`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 未完成数据
export const workbenchKpiUndo = (data?: any) => request({
    url: `${BASEURL}/index/unDoneQuarterlyPerformanceAnalyse`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 季度目标
export const workbenchKpiTarget = (data?: any) => request({
    url: `${BASEURL}/index/quarterlyPerformanceList`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 录入季度目标
export const workbenchKpiSave = (data?: any) => request({
    // url: `${EasyMockUrl}/workBench/kpiSave`,
    url: `${BASEURL}/index/saveQuarterlyPerformance`,
    // method: 'GET',
    data,
})

// 季度业绩分析 --- 更新季度目标列表
export const workbenchKpiSaveWithAuth = (data?: any) => request({
    // url: `${EasyMockUrl}/workBench/kpiSave`,
    url: `${BASEURL}/index/updateQuarterlyPerformance`,
    // method: 'GET',
    data,
})

// 按id或返回当前登录最后一条业绩信息

export const workbenchGetKpiById = (data?: any) => request({
    // url: `${EasyMockUrl}/workBench/kpiSave`,
    url: `${BASEURL}/index/getQuarterlyUserPerformance`,
    method: 'GET',
    data,
})