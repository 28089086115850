import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormProps, Input, Select, Tag, Tree, TreeSelect } from "antd";
import type { ProFormInstance } from '@ant-design/pro-form';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { treeDataDisabledRules } from "@utils/index";
import ProForm, {  ProFormList, ProFormSelect } from '@ant-design/pro-form';
import { sysAuditRuleSave, sysGetAuditRule } from "@api/index";

const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
};

const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};

const dataSelectOptions = [
    {
        label: '上级主管',
        value: '1'
    },
    {
        label: '上级组长',
        value: '2'
    }
]


const CustOptions = ({options, value, onChange}: any) => {
    return(<>
      {options.map((v: any) => <Col key={v.label} className={`ant-select-item ant-select-item-option ${value === v.label ? 'ant-select-item-option-selected' : undefined}`} style={{width: '100%'}} onClick={() => onChange && onChange(v.label)}>{v.label}</Col>)}
    </>
    )
}
  
const CustomTreeSelect = ({value, onChange, ...rest}: any) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const treeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)

    return(<TreeSelect 
                treeData={treeData} 
                showSearch 
                treeNodeFilterProp='title' 
                style={{width: 180}} 
                className="jns-cust-select-content"
                dropdownRender={menu => {
                    return(<>
                        <CustOptions options={dataSelectOptions} value={value} onChange={onChange} />
                        <div className="ant-select-item ant-select-item-group">自定义</div>
                        {menu}
                    </>)
                }}
                value={value}
                onChange={onChange}
                {...rest}
            />)
}
  

const AuditForm = ({...rest}: FormProps) => {
  return (<Form
      {...formItemLayoutWithOutLabel}
      initialValues={{names: ['']}}
      {...rest}
      >
      <Form.List
          name="rules"
      >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              {...formItemLayout}
              label={`${index + 1}级审批人`}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    message: "请选择审批人",
                  },
                ]}
                noStyle
              >
                <CustomTreeSelect placeholder="请选择" style={{ width: '60%' }} />
              </Form.Item>
              {fields.length > 1 ? (
                <MinusCircleOutlined
                  style={{marginLeft: 6}}
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: '60%' }}
              icon={<PlusOutlined />}
            >
              添加审批层级
            </Button>
          </Form.Item>
        </>
      )}
      </Form.List>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{width: '60%'}}>
          保存
        </Button>
      </Form.Item>
  </Form>)
}


export const ContractAuditRule = () => {
  const [formRef] = Form.useForm()
  const onRequest = async () => {
    const { data } = await sysGetAuditRule({type: 1}) as any
    const { requests } = data || {}
    const rules = requests?.map((v: any) => {
      if (v.auditUserType === 1) {
        return '上级主管'
      } else if (v.auditUserType === 2) {
        return '上级组长'
      } else {
        return `s${v.auditUserId}`
      }
    })
    formRef.setFieldsValue({rules})
  }
  const onFinish = async ({rules}: any) => {
    const requests = rules?.map((v: any) => {
      if (v === '上级主管') {
        return {
          auditUserType: 1,
        }
      } else if (v === '上级组长') {
        return {
          auditUserType: 2,
        }
      } else {
        return {
          auditUserType: 0,
          auditUserId: v?.slice(1)
        }
      }
    })
    await sysAuditRuleSave({requests, type: 1})
  }
  useEffect(() => {
    onRequest()
  }, [])
  return (<AuditForm
    onFinish={onFinish}
    form={formRef}
  />)
}

export const RebackAuditRule = () => {
  const [formRef] = Form.useForm()
  const onRequest = async () => {
    const { data } = await sysGetAuditRule({type: 2}) as any
    const { requests } = data || {}
    const rules = requests?.map((v: any) => {
      if (v.auditUserType === 1) {
        return '上级主管'
      } else if (v.auditUserType === 2) {
        return '上级组长'
      } else {
        return `s${v.auditUserId}`
      }
    })
    formRef.setFieldsValue({rules})
  }
  const onFinish = async ({rules}: any) => {
    const requests = rules?.map((v: any) => {
      if (v === '上级主管') {
        return {
          auditUserType: 1,
        }
      } else if (v === '上级组长') {
        return {
          auditUserType: 2,
        }
      } else {
        return {
          auditUserType: 0,
          auditUserId: v?.slice(1)
        }
      }
    })
    await sysAuditRuleSave({requests, type: 2})
  }
  useEffect(() => {
    onRequest()
  }, [])
  return (<AuditForm
    onFinish={onFinish}
    form={formRef}
  />)
}
