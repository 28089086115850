import { useTableHooks, useDictEnumHooks, useCantactsByCustomerIdHooks } from "@hooks/index"
import { Button, Col, Row, Table, Image, Modal } from "antd"
import React, { Key, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { BASEURL, customerAffix, customerAffixAdd, customerAffixDel } from '@api/index'
import type { ModalFormProps, ProFormInstance, } from '@ant-design/pro-form'
import { ModalForm, ProFormText, ProFormSelect, ProFormUploadButton, ProFormTextArea} from '@ant-design/pro-form';
import { productApi, parseSearch } from "@utils/util"
import { StateType } from "@store/index";
import { useLocation } from "react-router"
import { AuthActionArr, EnterpriseDetailProps } from "../../type"
import { columnsFn } from './data'
import ProTable, { ActionType } from "@ant-design/pro-table";
import { PreviewPlus } from "@components/index";
import { getToken, handleUploadFiles, request } from "@utils/index";
import { saveAs } from 'file-saver'

const getApi = productApi(customerAffix)
const addApi = productApi(customerAffixAdd)
const delApi = productApi(customerAffixDel)


// url 预览模式
// const imgSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/9E6B5DD9C75D400F9EF4B515C6E409D7_5294d977d96773e6ea9a81d3119b51c9.jpg'
// const imgSrc1 = 'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp'
// const imgSrc2 = 'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp'
// const imgSrc3 =  'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp'
// const wordSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A74090AF17F146D8BD667F5FF8188D12_云罟使用指南（精简）.docx'
// const excelSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/30B167B900F04769878916CBB4E3AF16_星辰数智产品报价模板v1.0_0513.xlsx'
// const pptSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A8ADCCE12F3E410FAD2AF860F4905666_新建 PPTX 演示文稿.pptx'
// const pdfSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/D86E4B42D89F411982CE150C644C7EBC_云罟云安全产品介绍.pdf'
// const vedioSrc = ''

// 

interface AddAffixProps extends ModalFormProps{
    onSuccess?: any;
}
const AddAffix = ({params, onSuccess, ...rest}: AddAffixProps) => {
    const [affixTypeOptions,] = useDictEnumHooks({dictId: 284}, true)
    const onFinish = async ({files, ...rest}: any) => {
        const fileArr = handleUploadFiles(files).map((v) => ({url: v.url, suffix: v.format, fileSize: v.size, fileName: v.name}))
        const addApiArr = fileArr?.map(file => ({...file, ...rest, ...params}))  // 来自陈曦的 非 resultFull 规范
        await addApi(addApiArr, true, onSuccess)
    }
    return (<ModalForm 
                layout="horizontal" 
                width={600} 
                labelCol={{span: 5}} 
                onFinish={onFinish} 
                {...rest}
            >
            <ProFormSelect 
                label="附件类型"
                name="subType"
                fieldProps={{
                    options: affixTypeOptions
                }}
                rules={[{ required: true, message: '请上传凭证',}]} 
            />
            <ProFormUploadButton
                style={{width: 200}}
                label="选择附件"
                name="files" 
                max={5} 
                fieldProps={{ 
                    // onPreview, 
                    name: 'file', 
                    // accept: acceptImg, 
                    listType: "picture-card", 
                    headers: {Authorization: getToken()},
                }} 
                action={`${BASEURL}/upload`} 
                extra="" 
                rules={[{ required: true, message: '请上传凭证',}]} 
            />
            <ProFormTextArea 
                name="remark" 
                label="备注" 
                fieldProps={{maxLength: 1000, showCount: true}} 
                rules={[{max: 1000, type:'string', message: '1000字以内'}]} 
            />
        </ModalForm>)
}

export default ({id: customerId,Collaborator,searchType,businessOppId, name, authCode, ...rest}: EnterpriseDetailProps) => {
    const [visible, setVisible] = useState(false)
    const [item, setItem] = useState<any>({})
    const [affixProps, setAffixProps] = useState({title: '新增附件', visible: false,})
    const ref = useRef<ActionType>(null);
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
       
    }

    const reFresh = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    const operate = async (type: any, record: any) => {
        const ids = record ? [record?.id] : []
        if (type === '预览') {
            setVisible(true)
            setTimeout(() => {
                setItem(record)
            }, 200)
        } else if (type === '下载') {
            saveAs(record.url, record?.fileName)
        } else if (type === '新增附件') {
            setAffixProps({
                visible: true,
                title: '上传附件'
            })
        } else if (type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({ids}, true, () => {
                        reFresh()
                    })
                },
            })
        }
    }
   
    return (<div style={{background: '#fff', boxSizing: 'border-box', padding: 20}}>
            <ProTable<any>
                request={getListApi}
                columns={columnsFn(operate, authCode)}
                params={{customerId}}
                options={{reload: false, density: false, setting: false, fullScreen: false}}
                toolbar={{
                    actions: [
                       (authCode||[]).includes('add') ? <Button key="add" type="primary" onClick={() => operate?.('新增附件', null)}>上传附件</Button> : null
                    ],
                }}
                actionRef={ref as any}
                rowKey="id"
                // search={false}
                pagination={{defaultPageSize: 10}}
                // actionRef={ref as any}
            />
            <AddAffix 
                {...affixProps} 
                params={{keyId: customerId}} 
                modalProps={{onCancel: () => setAffixProps({...affixProps, visible: false}), destroyOnClose: true}} 
                onSuccess={() => {
                    setAffixProps({...affixProps, visible: false})
                    reFresh()
                }}
            />
            <Modal width={860} visible={visible} title={item?.name||item?.fileName} onCancel={() => setVisible(false)} footer={false}>
                <PreviewPlus src={item?.url} style={{width: '100%'}} />
            </Modal>
    </div>)
}
