import React, { useEffect, useRef, useState } from "react"
import { columnsFn} from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
// import { ModalForm, ProFormText } from '@ant-design/pro-form';
// import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { Button, Modal, Tabs } from "antd";
import { Link, useHistory } from "react-router-dom";
import { oaGetMessageOrNotice, oaDeleteSystemNotice } from '@api/index' 
import { productApi } from "@utils/util";
import { stringify } from "qs";
import { useHasAuthCode } from "@hooks/index";
const { TabPane } = Tabs


const getApi = productApi(oaGetMessageOrNotice)
const deleteApi = productApi(oaDeleteSystemNotice)  //删除公告


export default () => {
    const [salesStages, setSalesStages] = useState<number>(1)
    const [, hasAuthCode] = useHasAuthCode()
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const { id } = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await deleteApi({ids: [id]}, true, () => {
                        ref.current?.reload()
                    })
                },
            });
        } else if(type === '编辑') {
            history.push(`/oa/addMessage?${stringify({id, routeName: '编辑公告' , msgType: 1})}`, )
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { msgTitle:msgKeyword, publishStatus  } = params || {}
        const { data } = await getApi({
            ...params,
            condition: salesStages,
            msgKeyword,
            publishStatus: publishStatus ? Number(publishStatus) : undefined,
            msgTitle: undefined
            }, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    useEffect(()=> {
        ref.current?.reload()
    }, [salesStages]) 
    return <div>
        <div style={{boxSizing: 'border-box'}}>
        <Tabs onChange={(val) => setSalesStages(Number(val))} style={{background: '#fff'}} tabBarStyle={{paddingLeft: 20}}>
            { hasAuthCode(2378) ? <TabPane tab="业务消息" key={1}></TabPane> : null}
            { hasAuthCode(2379) ? <TabPane tab="系统公告" key={2}></TabPane> : null}
        </Tabs>
            <ProTable<TableListItem>
                columns={columnsFn(operate, salesStages)}
                request={getListApi}
                options={{reload: false, density: false, fullScreen: false, setting: false}}
                toolbar={{
                    actions: [
                        (salesStages === 2 && hasAuthCode(2380)) ? <Button key="primary" type="primary"><Link to={'/oa/addMessage'}>新建公告</Link></Button> : null,
                    ],
                }}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ],
                }}
                rowKey="id"
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
            />
        </div>
    </div>
}
