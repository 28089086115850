import React from "react";
import { TreeSelect, Button, DatePicker  } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { Double } from "@components/Common";
const { RangePicker } = DatePicker
export type TableListItem = {
    key: number;
    id: string;
    attention: number | string;
    isDefault: number;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '新建规则' | '编辑' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({}, false)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '消费月份',
        dataIndex: 'consumeMonth',
        key: 'consumeMonth',
        fixed: 'left',
        render: (text: any, record: any) => `${record.consumeMonth||'-'}`,
        valueType: 'dateRange',
        fieldProps: {
            picker:"month",
            format: 'YYYY-MM'
        },
    },
    {
        title: '云厂家',
        dataIndex: 'cloudFactory',
        key: 'cloudFactory',
        valueType: 'select',
        fieldProps: { showSearch: true },
        initialValue: 54,
        params: {dictId: 24 },
        request,
        render: (text: any, record: any) => `${record.cloudFactoryStr||'-'}`,
    },
    {
        title: '收款人',
        key: 'payee',
        fixed: 'left',
        dataIndex: 'payee',
        order: 5,
    },
    {
        title: '收款人类型',
        key: 'payeeType',
        dataIndex: 'payeeType',
        hideInSearch: true,
    },
    {
        title: '银行卡账号',
        dataIndex: 'bankAccount',
        key: 'bankAccount',
        order: 4,
    },
    {
        title: '开户行',
        dataIndex: 'bank',
        key: 'bank',
        hideInSearch: true,
    },
    {
        title: '支付宝账号',
        dataIndex: 'alipayAccount',
        key: 'alipayAccount',
        order: 3,
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        key: 'customerName',
        hideInTable: true,
        order: 2,
        
    },
    {
        title: '客户账号',
        dataIndex: 'customerAccount',
        key: 'customerAccount',
        hideInTable: true,
        order: 1,
    },
    {
        title: '总返点金额',
        fixed: 'right',
        dataIndex: 'totalRebateAmount',
        hideInSearch: true,
        key: 'totalRebateAmount',
    },
  ]
}
