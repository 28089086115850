import React, { useState } from "react"
import { Form, FormItemProps, Input, Select, SelectProps } from "antd"
import ProForm, { ProFormField, ProFormSelect } from '@ant-design/pro-form';

type ValueType = 'value1' | 'value2'
type CompactValue = {
    value1?: any;
    value2?: any
}

type CompactSelectProps = {
    fieldProps1: SelectProps;
    fieldProps2: SelectProps;
    changeClear?: boolean;
    width?: number;
    value?: CompactValue;
    onChange?: (value: CompactValue) => void;
}

interface ProFormCompactSelectProps extends FormItemProps, CompactSelectProps {
    fieldProps1: SelectProps,
    fieldProps2: SelectProps,
}

export const CompactSelect = ({fieldProps1, fieldProps2, value: pValue, width, changeClear, onChange}: CompactSelectProps) => {
    const defaultValue: CompactValue = { value1: fieldProps1?.value || fieldProps1?.defaultValue, value2: fieldProps2?.value || fieldProps2?.defaultValue }
    const value: CompactValue = pValue||defaultValue
    const { onChange: onChange1 } = fieldProps1 || {}
    const { onChange: onChange2 } = fieldProps2 || {}
    const onOwnChange = (key: ValueType, args: any) => {
        const [val] = args
        const newVal = (changeClear && key === 'value1') ? { ...value, [key]: val, value2: undefined } : { ...value, [key]: val }
        // @ts-ignore
        key === 'value1' && onChange1 && onChange1(...args)
        // @ts-ignore
        key === 'value2' && onChange2 && onChange2(...args)
        onChange && onChange(newVal)
    }

    return (<Input.Group compact style={{width}}>
        <Select
           style={{ width: '30%' }}
           {...fieldProps1}
           value={value?.value1}
           onChange={(...args) => onOwnChange('value1', args)}
        />
        <Select
           style={{ width: '70%' }}
           {...fieldProps2}
           value={value?.value2}
           onChange={(...args) => onOwnChange('value2', args)}
        />
    </Input.Group>)
}

export const ProFormCompactSelect = ({fieldProps1, fieldProps2, changeClear, width, ...rest}: ProFormCompactSelectProps) => {
    return (<Form.Item className="pro-field-md" {...rest}>
        <CompactSelect fieldProps1={fieldProps1} fieldProps2={fieldProps2} changeClear={changeClear} width={width} />
    </Form.Item>)
}
