import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import ProForm, { ModalForm,  ProFormUploadDragger, } from '@ant-design/pro-form';
import { MyTabs } from "@components/EnterpriseDetail"
import { getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, handleUploadFiles, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn } from "./data"
import { OperateActionType, TableListItem, tabPanes, viewOptions } from "../../data"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, contractSearch, contractAdd, contractInfoById } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import { AddSaleContractModalForm } from './cpnts'
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import type { AddSaleContractModalFormProps } from "./cpnts/AddSaleContract";
import { useSaleContractHooks } from "./hooks";
import { AddRebackModalForm } from "@components/Finance/RebackMoney/cpnts/RebackList/cpnts";
import { useAddRebackHooks } from "@components/Finance/RebackMoney/cpnts/RebackList/hooks";
import Table from "@ant-design/pro-table/lib/Table";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";

const getPageListApi = productApi(contractSearch)
const contractAddApi = productApi(contractAdd)

const persistenceKey = 'finance/contract/sale'



export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}>{data.contractAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={13}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={14}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={15}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={16}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={17}>{data.backedAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={18}>{data.noBackAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={19}>{data.openedAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={20}>{data.noOpenAmount}</Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
}

export default ({dataScope}: any) => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any

    const { 
        formRef: addFormRef,
        commonModalFormProps: addSaleContractModalFormProps,
        setCommonModalFormProps: setAddSaleContractModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    } = useSaleContractHooks()

    const { 
        addRebackModalFormProps,
        setAddRebackModalFormProps,
        createOperate: createRebackOperate
    } = useAddRebackHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const { contractId, customerId, customerName, xsContractId, xsContractNumber,  } = record || {} 
        const action = createOperate(record, reFush)
        switch (type) {
            case '销售合同-回款申请':
                // const reBackAction = createRebackOperate(record, reFush)
                // reBackAction('回款记录-新建', {})
                setAddRebackModalFormProps({
                    ...addRebackModalFormProps,
                    title: '新建回款记录',
                    visible: true,
                    params: { customerName, noCache: new Date() },
                    request: async () => {
                        return({
                            customerId,
                            xsContract: xsContractId ? {
                                label: xsContractNumber,
                                value: xsContractId
                            } : undefined
                        })
                    },
                    onSuccess: () => {
                        setAddRebackModalFormProps({visible: false})
                        reFush?.()
                    }
                })
                break;
            default:
                action(type, {})
        }
    }

    const columns = columnsFn(operate, '')
    const params = { dataScope, contractType: 1 }

    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...summaryData } = data || {}
        setAllTableData(summaryData)
        return {
            data: values || [],
            // summaryData,  // table 底部总结栏统计数据，有值自动显示
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            contractIds: selectedRowKeys,
            fields,
            ...params
        })
    }

    return(<div className="oa-task-center">
        <ProTableWithFormSetting<TableListItem>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={
                    <h5 style={{fontSize: '14px', margin: 0}}>
                        合同金额：<span style={{color: 'red'}}>{allTableData.contractAmount || 0}</span>&nbsp;&nbsp;
                        已回款金额：<span style={{color: 'red'}}>{allTableData.backedAmount || 0}</span> &nbsp;&nbsp;
                        {/* 未回款金额：<span style={{color: 'red'}}>{allTableData.noBackAmount || 0}</span> &nbsp;&nbsp; */}
                        已开票金额：<span style={{color: 'red'}}>{allTableData.openedAmount || 0}</span> &nbsp;&nbsp;
                        {/* 未开票金额：<span style={{color: 'red'}}>{allTableData.noOpenAmount || 0}</span>  */}
                        （汇总列表查询结果）
                    </h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2446) ? <Button type="primary" key="新建销售合同" onClick={() => operate('销售合同-新建', null)}>新建销售合同</Button> : null,
                        hasAuthCode(2448) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('销售合同-删除', null)}>删除</Button> : null,
                        hasAuthCode(2443) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/contractExport`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="contractId"
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <AddSaleContractModalForm
            modalProps={{
                onCancel: () => setAddSaleContractModalFormProps({...addSaleContractModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...addSaleContractModalFormProps} 
        />
        <AddRebackModalForm
            modalProps={{
                onCancel: () => setAddRebackModalFormProps({...addRebackModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...addRebackModalFormProps} 
        />
    </div>)
}
