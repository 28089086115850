import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table"
import { Alert, Button, Card, DatePicker, Descriptions, Form, Input, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { debounce } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { LabelValueMaps, logColumns, descColumns } from "./data"
import { customerBusinessSurverySheetSave, customerBusinessSurverySheetLog, customerBusinessSurverySheetGet } from '@api/index' 
import { clacCustomCellByDataSource, parseSearch, productApi } from "@utils/index"
import { useLocation } from "react-router-dom"
import moment from "moment"
import ProForm, { ModalForm, } from '@ant-design/pro-form';
import { ProFormSelect, ProFormItem, BetaSchemaForm, ProFormText, ProFormTextArea, ProFormDatePicker, ProFormDigit, ProFormMoney } from '@ant-design/pro-form';
import { EnterpriseDetailProps } from "@components/EnterpriseDetail/type"

const saveApi = productApi(customerBusinessSurverySheetSave)
const getApi = productApi(customerBusinessSurverySheetLog)

const format = "YYYY-MM-DD HH:mm:ss"

type FormItemProps = {
    column: ProColumns<any>;
    disabled?: boolean
}

const getFormRuleRequired = (column: any) => {
    const { formItemProps } = column || {}
    const { rules } = formItemProps || {}
    return rules?.some((rule: any) => rule?.required)
}

const FormItem = ({column, disabled}: FormItemProps) => {
    const { valueType, request, params, renderFormItem } = column
    const fieldProps = Object.assign(column.fieldProps||{}, {disabled, bordered: false, placeholder: disabled ? '' : column.fieldProps?.placeholder})
    let Comp: any =  ProFormText
    switch (valueType) {
        case 'date':
            Comp = ProFormDatePicker
            break;
        case 'textarea':
            Comp = ProFormTextArea
            break;
        case 'digit':
            Comp = ProFormDigit
            break;
        case 'select':
            Comp = ProFormSelect
            break;
        case 'money':
            Comp = ProFormMoney
            break;
    }
    
    return (<ProForm.Item
                name={column.dataIndex} 
                {...column.formItemProps}
            >
        {renderFormItem ? renderFormItem(column) :  <Comp formItemProps={{style: {marginBottom: 0}}} params={params} request={request} fieldProps={{...(fieldProps||{}) }} />}
    </ProForm.Item>)
}

const FormLabel = ({column}: any) => {
    return (<> { getFormRuleRequired(column) ? <span style={{color: 'red'}}>*</span> : null } {column?.title}</>)
}

const handleBusinessResearchRecords = (businessResearchRecords = []) => {
    const res = businessResearchRecords?.map((v: any) => {
        const children = v.researchRecordDetail?.map((child: any) => ({...child, ...v}))
        return children
    })
    return res.flat()
}



export default ({authCode, ...rest}: EnterpriseDetailProps) => {
    const [form] = Form.useForm();
    const ref = useRef<ActionType>(null);
    const { pathname, search } = useLocation();
    const { id: customerId, name } = parseSearch(search)
    const [disabled, setDisabled] = useState(true)
    const [params, setParams] = useState({customerId, id: undefined})

    const getPageList = async (params: any, sorter: any) => {
        const { data } = await getApi({...params, sorter}) as any
        const { businessResearchRecords, total } = data || {}
        const list = businessResearchRecords?.map((v: any) => ({...v, nameDate: `${v.updateName}-${v.updateTime}`}))
        const values = handleBusinessResearchRecords(list)
        const records = clacCustomCellByDataSource(values || [])
        return {
            total: records.length,
            data: records,
            // data: businessResearchRecords || [],
            success: true
        }
    }

    const getLatestDataApi = async () => {
        const { data } = await getApi(params) as any
        const { businessResearchRecords, id, industry, ...rest } = data || {}
        setParams({...params, id})
        return {
            industry: industry ? industry : undefined,
            ...rest,
            customerName: name
        }
    }

    const onSave = async (values: any) => {
        const registeredDate = values?.registeredDate ? moment(values?.registeredDate).format('YYYY-MM-DD') : values?.registeredDate
        await saveApi({...values, registeredDate, ...params }, true, reFush)
    }

    const reFush = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
            getLatestDataApi()
            setDisabled(!disabled)
        }, 1000)
    }

    const columns = descColumns()
    const baseColumns = columns.filter((column) => !['潜力情报', '合作情报'].includes((column as any)?.belong))
    const qianliColumns = columns.filter((column) => ['潜力情报'].includes((column as any)?.belong))
    const hezuoColumns = columns.filter((column) => ['合作情报'].includes((column as any)?.belong))
    return(<div style={{background: '#fff', padding: 20}}>
        {/* <Row justify="end">
            <ModalForm  title="客户业务调研表更新历史列表" trigger={<Button type="primary">历史更新记录</Button>} onFinish={async () => true}>
                <ProTable
                    // scroll={{x: 1300}}
                    params={params}
                    request={getPageList}
                    columns={columns()}
                    rowSelection={false}
                    search={false}
                    options={false}
                    actionRef={ref as any}
                />
            </ModalForm>
        </Row> */}
        <Alert
            message="客户业务调研表，目的用于收集客户详细信息，建立客户档案，了解客户需求和行业背景。（销售填写）"
            type="warning"
            closable
        />
        { authCode?.includes('edit') ? <Button type="primary" style={{marginTop: 16}} onClick={() => setDisabled(!disabled)}>编辑信息</Button> : null }
        <ProForm
          onValuesChange={(values: any) => {
            console.log(values)
          }}
          onFinish={onSave}
          request={getLatestDataApi}
          className={'jns-disabled-normal'}
          submitter={ !disabled ? {render: (props, doms) => [
            <div className="jns-fix-submit" key="submit">
                <Button  type="primary" style={{marginLeft: 130}} onClick={() => props.form?.submit?.()}>提交</Button>
            </div>,
        ]} : false}
        >
            <div className="zen-ban" style={{margin: '30px 0 15px 0'}}>客户基本信息</div>
            <Descriptions bordered contentStyle={{padding: 0}}>
                {
                    baseColumns?.map(column => <Descriptions.Item label={<FormLabel column={column} />} key={column.key}>
                        <FormItem column={column} disabled={ ['customerName'].includes(column?.dataIndex) ? true : disabled} />
                    </Descriptions.Item>)
                }
                
            </Descriptions>
            <div className="zen-ban" style={{margin: '30px 0 15px 0'}}>潜力情报</div>
            <Descriptions bordered contentStyle={{padding: 0}}>
                {
                    qianliColumns?.map(column => <Descriptions.Item label={<FormLabel column={column} />} key={column.key}>
                        <FormItem column={column} disabled={disabled} />
                    </Descriptions.Item>)
                }
                
            </Descriptions>
            <div className="zen-ban" style={{margin: '30px 0 15px 0'}}>合作情报</div>
            <Descriptions bordered contentStyle={{padding: 0}}>
                {
                    hezuoColumns?.map(column => <Descriptions.Item label={<FormLabel column={column} />} key={column.key}>
                        <FormItem column={column} disabled={disabled} />
                    </Descriptions.Item>)
                }
            </Descriptions>
        </ProForm>

        {/* <Form 
            form={form}
            onBlur={onSave}
            // onChange={onSave}
            // onValuesChange={ debounce(onSave, 2000)}
        >
            <Descriptions bordered contentStyle={{padding: 0}}>
                <Descriptions.Item label="客户企业官方全称">
                    <Form.Item name="customerName" noStyle>
                        <Input placeholder="请输入" bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="注册资金">
                    <Form.Item name="registeredCapital" noStyle>
                        <Input placeholder="请输入" bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="企业人数">
                    <Form.Item name="employees" noStyle>
                        <Input placeholder="请输入" bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="成立时间">
                    <Form.Item name="registeredDate" noStyle>
                        <DatePicker placeholder="请输入" showTime format={format} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="一年IT占总花费规模包括所有IT投入 ">
                    <Form.Item name="itExpense" noStyle>
                        <Input placeholder="请输入" bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="一句话介绍客户 ">
                    <Form.Item name="introduceCustomer" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="正在考虑建设/优化的IT系统 ">
                    <Form.Item name="itConsider" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="客户对云的认可度">
                    <Form.Item name="cloudRecognition" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="尚未上云的主要IT系统梳理 ">
                    <Form.Item name="yetUpCloud" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="是否在用第三方软件，如有请列出是何系统，isv厂商是哪个 ">
                    <Form.Item name="thirdParty" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="近期的业务挑战/发展重点 ">
                    <Form.Item name="recentDevelop" noStyle>
                        <TextArea placeholder="请输入" showCount maxLength={40} bordered={false} />
                    </Form.Item>
                </Descriptions.Item>
            </Descriptions>
        </Form> */}
        <Card title="客户业务调研表更新历史列表" size="small" bordered={true} style={{marginTop: 20}}>
            <ProTable
                // scroll={{x: 1300}}
                scroll={{ x: 1500, y: 600 }}
                params={params}
                request={getPageList}
                columns={logColumns()}
                rowSelection={false}
                search={false}
                options={false}
                pagination={false}
                bordered
                actionRef={ref as any}
            />
        </Card>
    </div>)
}
