import React from 'react'
import { MyTabs } from '@components/EnterpriseDetail'
import { Ali, HuaWei, Tencent } from './cpnts'
import { Tabs } from 'antd'

const items = [
    { Tab: '阿里云', key: '0', Content: <Ali /> },
    { Tab: '华为云', key: '1', Content: <HuaWei /> },
    { Tab: '腾讯云', key: '2', Content: <Tencent /> },
]

export default () => {
    return <MyTabs tabBarStyle={{background: '#fff',}}  tabPosition="left" tabPanes={items} />
}