import { ellipsis, isDef } from "@utils/index"
import { Tooltip } from "antd"
import React, { ReactNode } from "react"

type EllipseProps = {
  text?: string;
  len?: number;
  width?: number;
  line?: number;
  showTooltip?: boolean;
  children?: ReactNode;
}

const Nowrap = ({text, width, line, children, showTooltip}: EllipseProps) => (<div style={{display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: line || 1, textOverflow: 'ellipsis', overflow: 'hidden', width: width||100}}>
  <Tooltip placement="top" title={(!isDef(showTooltip) || showTooltip) ? (children || text) : ''}>{children || text}</Tooltip>
</div>)

const Ellipsis = ({text, len}: EllipseProps) => {
    return <Tooltip placement="top" title={text}>
      {ellipsis(text, len)}
  </Tooltip>
}

Ellipsis.Nowrap = Nowrap

export default Ellipsis