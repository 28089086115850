import React from "react";
import { Button, TreeSelect } from "antd";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";

export type TableListItem = {
    key: number;
    id: string;
};

export type OperateActionType = '新建客户账号' | '编辑' | '删除' 


// 客户账号
export const columnsFn: (operate?: Function, Collaborator?: boolean) => any[] = (operate, Collaborator) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '功能模块',
            dataIndex: 'module',
            order: 4,
        },
        {
            title: '操作类型',
            dataIndex: 'type',
            order: 9,
        },
        {
            title: '操作时间',
            dataIndex: 'operateTime',
            valueType: 'dateRange',
            fieldProps: { 
              format:"YYYY-MM-DD HH:mm",
              showTime:{ format: 'HH:mm' }
            },
            render: (text: any, record: any) => `${record.operateTime|| '-'}`,
            order: 7,
            sorter: true,
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            order: 8,
            fieldProps: {
              maxTagCount: 4,
              treeCheckable: true,
              showSearch: true,
              treeNodeFilterProp: 'title',
              treeData: deptWithUserTreeData
            },
            renderFormItem: (props: any) => <TreeSelect />,
            search: {
              transform: (ids: any) => {
                const operatorId = (ids||[]).filter((v: any) => v[0] === 's').map((v: string) => v.slice(1))
                return { operatorId }
              },
            },
        },
        {
            title: '操作内容',
            dataIndex: 'dailyRecord',
            hideInSearch: true,
        }
    ]
}