import React from 'react';
import {
    moneyFromColumns,
    foreignInvestmentColumns,
    publicReportColumns,
    biddingInfoColumns,
    customerInformationColumns,
    selfProductColumns,
    otherProductColumns,
    taxRateColumns,
    punishColumns,
    recordInfoColumns,
    legalActionColumns,
    courtNoticeColumns,
    patentInfoColumns,
    copyrightColumns,
    brandColumns,
    supplierColumns,
    appInfoColumns,
    bossColumns,
    businessDescList,
    useCorpInfo,
    cdnColumns,
    wafColumns,
    ddosColumns,
    icpColumns,
    holdersColumns,
    mapColumns,
    certificatesColumns,
    newsColumns,
    homePages,
    mainStuffColumns,
    mainTeamColumns,
    weixinColumns
} from '../../data';

import { BaseInfoType, EnterpriseDetailProps } from '../../type'
import './index.less';
import { BaseTable, CreateBrief, DescriptionsList, WithAnchorTables } from '../..';
import { areaInfo } from '../../data'
import { Button, Card, Col, Divider, Row } from 'antd';

const BaseInfoTabPanes = (props: EnterpriseDetailProps) => {
    const [baseInfo,] = useCorpInfo(props, 1)
    const { briefIntroduction, holders, lqRegisterInfo, invests, companyParkVo, staff, maps, introduction, companyName } = baseInfo || {}
    const reFresh = () => location.reload()
    return [
        {
            Tab: () => <>企业简介</>,
            href: 'base-info',
            Content: [
                <div key="a">
                    <div className="zen-ban">公司简介 <CreateBrief key="addDesc" introduction={introduction} params={{companyName}} onSuccess={reFresh} /></div>
                    {(briefIntroduction||[]).map(({about, source}: any) => 
                        <div className="txt" key={source} style={{ marginTop: '16px' }}>
                            {about}
                            <span style={{float: 'right', color: 'red'}}>———{source}</span>
                        </div>
                    )}
                </div>
            ]
        },
        {
            Tab: () => <>股东信息</>,
            href: 'gudong-info',
            Content: [
                <BaseTable key="a" title="股东信息" columns={holdersColumns} dataSource={holders||[]} />,
            ]
        },
        {
            Tab: () => <>主要人员</>,
            href: 'zhuyaorenyuan-info',
            Content: [
                <BaseTable key="a" title="主要人员" columns={mainStuffColumns} dataSource={staff||[]} />,
            ]
        },
        {
            Tab: () => <>工商信息</>,
            href: 'gongShang-info',
            Content: [
                <div key="a">
                    <div className="zen-ban">工商信息</div>
                    <DescriptionsList descList={businessDescList} data={lqRegisterInfo||{}} style={{boxSizing: 'border-box', padding: '20px 0', background: '#fff'}} />
                </div>,
            ]
        },
        {
            Tab: () => <>对外投资</>,
            href: 'duiwaitouzi-info',
            Content: [
                <BaseTable key="a" title="对外投资" columns={foreignInvestmentColumns} dataSource={invests||[]} />,
            ]
        },
        {
            Tab: () => <>园区信息</>,
            href: 'yuanqu-info',
            Content: [
                <div key="a">
                    <div className="zen-ban">园区信息</div>
                    <DescriptionsList descList={areaInfo} data={companyParkVo} style={{boxSizing: 'border-box', padding: '20px 0', background: '#fff'}} />
                </div>
            ]
        },
        {
            Tab: () => <>地图信息</>,
            href: 'ditu-info',
            Content: [
                <BaseTable key="a" title="地图信息" columns={mapColumns} dataSource={maps||[]} />,
            ]
        },
    ]
};

const ConditionTabPanes = (props: EnterpriseDetailProps) => {
    const [conditionInfo, ] = useCorpInfo(props, 2)
    const { relates, bids, positions, taxes, customers, supplies } = conditionInfo || {}
    return [
        {
            Tab: () => <>招聘信息</>,
            href: 'zhaopin-info',
            Content: [
                <BaseTable key="e" title="招聘信息" columns={bossColumns} dataSource={positions||[]} />,
            ]
        },
        {
            Tab: () => <>招投标</>,
            href: 'zhaotoubiao-info',
            Content: [
                <BaseTable key="b" title="招投标" columns={biddingInfoColumns} dataSource={bids||[]} />,
            ]
        },
        {
            Tab: () => <>客户信息</>,
            href: 'kehu-info',
            Content: [
                <BaseTable key="g" title="客户信息" columns={customerInformationColumns} dataSource={customers||[]}   />,
            ]
        },
        {
            Tab: () => <>税务评级</>,
            href: 'shuiwupingji-info',
            Content: [
                <BaseTable key="g" title="税务评级" columns={taxRateColumns} dataSource={taxes||[]}   />,
            ]
        },
        {
            Tab: () => <>供应商</>,
            href: 'gongyinshang-info',
            Content: [
                <BaseTable key="i" title="供应商" columns={supplierColumns} dataSource={supplies||[]}   />,
            ]
        },
        {
            Tab: () => <>公告研报</>,
            href: 'gonggaoyanbao-info',
            Content: [
                <BaseTable key="a" title="公告研报" columns={publicReportColumns} dataSource={relates||[]} />,
            ]
        },
    ]
}

//todo
const DevelopmentTabPanes = (props: EnterpriseDetailProps) => {
    const [companyDev, ] = useCorpInfo(props, 3)
    const { finances, news, members } = companyDev || {}
    return [
        {
            Tab: () => <>融资信息</>,
            href: 'rongzi-info',
            Content: [
                <BaseTable key="a" title="融资信息" columns={moneyFromColumns} dataSource={finances||[]} />,
            ]
        },
        {
            Tab: () => <>企业新闻</>,
            href: 'qiyexinwen-info',
            Content: [
                <BaseTable key="b" title="企业新闻" showHeader={false} columns={newsColumns} dataSource={news||[]} />,
            ]
        },
        {
            Tab: () => <>核心团队</>,
            href: 'hexinteam-info',
            Content: [
                <BaseTable key="c" title="核心团队" columns={mainTeamColumns} dataSource={members||[]} />,
            ]
        },
    ]
}

const RightsTabPanes = (props: EnterpriseDetailProps) => {
    const [copyRight, ] = useCorpInfo(props, 4)
    const { patents, rights, tminfo, certificates, wechats } = copyRight || {}

    return [
        {
            Tab: () => <>专利信息</>,
            href: 'zhuanli-info',
            Content: [
                <BaseTable key="a" title="专利信息" columns={patentInfoColumns} dataSource={patents||[]}   />,
            ]
        },
        {
            Tab: () => <>作品著作权</>,
            href: 'zuopingzhuzuoquan-info',
            Content: [
                <BaseTable key="b" title="作品著作权" columns={copyrightColumns} dataSource={rights||[]}   />,
            ]
        },
        {
            Tab: () => <>商标信息</>,
            href: 'shangbiao-info',
            Content: [
                <BaseTable key="c" title="商标信息" columns={brandColumns} dataSource={tminfo||[]}   />,
            ]
        },
        {
            Tab: () => <>资质证书</>,
            href: 'zhizizhengshu-info',
            Content: [
                <BaseTable key="b" title="资质证书" columns={certificatesColumns} dataSource={certificates||[]} />,
            ]
        },
        {
            Tab: () => <>微信公众号</>,
            href: 'weixin-info',
            Content: [
                //todo
                <BaseTable key="b" title="微信公众号" columns={weixinColumns} dataSource={wechats||[]} />,
            ]
        },
    ]
}

const RiskTabPanes = (props: EnterpriseDetailProps) => {
    const [legalRisk, ] = useCorpInfo(props, 5)
    const { courts, lawsuits, cases, punishments } = legalRisk || {}

    return [
        {
            Tab: () => <>开庭公告</>,
            href: 'kaitinggonggao-info',
            Content: [
                <BaseTable key="a" title="开庭公告表" columns={courtNoticeColumns} dataSource={courts||[]}   />,
            ]
        },
        {
            Tab: () => <>法律诉讼</>,
            href: 'falvsusong-info',
            Content: [
                <BaseTable key="b" title="法律诉讼" columns={legalActionColumns} dataSource={lawsuits||[]}   />,
            ]
        },
        {
            Tab: () => <>立案信息</>,
            href: 'lianxinxi-info',
            Content: [
                <BaseTable key="c" title="立案信息" columns={recordInfoColumns} dataSource={cases||[]}   />,
            ]
        },
        {
            Tab: () => <>行政处罚</>,
            href: 'xingzhengchufa-info',
            Content: [
                <BaseTable key="a" title="行政处罚" columns={punishColumns} dataSource={punishments||[]}   />,
            ]
        },
    ]
}

const ProductTabPanes = (props: EnterpriseDetailProps) => {
    const [productInfo, ] = useCorpInfo(props, 6)  
    const {apps, products, competes, cdn, waf, ddos, icp } = productInfo || {}

    return [
        {
            Tab: () => <>APP信息</>,
            href: 'app-info',
            Content: [
                <BaseTable key="c" title="App信息" columns={appInfoColumns} dataSource={apps||[]}   />,
            ]
        },
        {
            Tab: () => <>产品信息</>,
            href: 'chanpin-info',
            Content: [
                <BaseTable key="d" title="产品信息" columns={selfProductColumns} dataSource={products||[]}   />,
            ]
        },
        {
            Tab: () => <>竞品信息</>,
            href: 'jingpin-info',
            Content: [
                <BaseTable key="b" title="竞品信息" columns={otherProductColumns} dataSource={competes||[]}   />,
            ]
        },
        {
            Tab: () => <>CDN产品</>,
            href: 'cdn-info',
            Content: [
                <BaseTable key="企业CDN信息" title="企业CDN信息" columns={cdnColumns} dataSource={cdn||[]} />
            ]
        },
        {
            Tab: () => <>WAF产品</>,
            href: 'waf-info',
            Content: [
                <BaseTable key="企业WAF信息" title="企业WAF信息" columns={wafColumns} dataSource={waf||[]}/>
            ]
        },
        {
            Tab: () => <>DDOS产品</>,
            href: 'ddos-info',
            Content: [
                <BaseTable key="企业DDOS信息" title="企业DDOS信息" columns={ddosColumns} dataSource={ddos||[]} />
            ]
        },
        {
            Tab: () => <>ICP产品</>,
            href: 'icp-info',
            Content: [
                <BaseTable key="企业ICP信息" title="企业ICP信息" columns={icpColumns} dataSource={icp||[]} />
            ]
        },
    ]
}

// 基本信息
export const CompanyBaseInfo = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={BaseInfoTabPanes(props)} />

// 经营情况
export const CompanyCondition = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={ConditionTabPanes(props)} />

// 企业发展
export const CompanyDevelopment = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={DevelopmentTabPanes(props)} />

// 知识产权
export const CompanyRights = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={RightsTabPanes(props)} />

// 风险信息
export const CompanyRisk = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={RiskTabPanes(props)} />

// 产品信息
export const CompanyProduct = (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={ProductTabPanes(props)} />

// 员工人脉
export const HomePages = (props: EnterpriseDetailProps) => {
    const { name } = props
    const linkTo = (queryUrl: any) => {
        const url = queryUrl(name)
        window.open(url, '_blank')
        // e.ctrlKey ? window.open(url, '_blank') : history.push(url)
    }
    return (<Row gutter={24} justify="space-around" style={{boxSizing: 'border-box', padding: 20}}>
           {
               homePages.map(v => (<Col key={v.name} span={6}><Card hoverable style={{ width: 224, height: 268, boxSizing: 'border-box', paddingTop: 40 }}>
                        <Col style={{textAlign: 'center'}}><img style={{height: '74px'}} alt={v.name} src={v.logo} /></Col>
                        <Col style={{textAlign: 'center', marginTop: 8, marginBottom: 20}}>{v.name}</Col>
                        <Col style={{textAlign: 'center'}}><Button onClick={() => linkTo(v.queryUrl)}>进入官网</Button></Col>
                </Card></Col>))
           }
   </Row>)
}

// export default (props: EnterpriseDetailProps) => <WithAnchorTables tabPanes={TabPanes(props)} />

