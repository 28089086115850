import React from "react";
import { Button, Row, Tooltip, TreeSelect } from "antd";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { FileItemRender } from "@components/Customer/Business/cpnts/AddBusiness";
import { Icon } from "@components/index";
import { DownloadOutlined } from '@ant-design/icons';
import { ProColumns } from "@ant-design/pro-table";
import { useDictEnumHooks } from "@hooks/index";
import { AuthActionArr } from "@components/EnterpriseDetail/type";

export type TableListItem = {
    key: number;
    id: string;
};

export type OperateActionType = '预览' | '下载' | '删除' 

const FileList = ({files, operate}: any) => {
    return files.map((file: any, i: any) => (<Row key={i}>
        <a>
            <span onClick={() => operate && operate('预览', file)}>
                <Tooltip title="预览"><Icon type="icon-fujian" style={{color: 'inherit'}} /> {file?.name||file?.fileName} </Tooltip>
            </span>
            <Tooltip title="下载"><DownloadOutlined style={{marginLeft: 50, cursor: 'pointer'}} onClick={() => operate && operate('下载', file)} /></Tooltip>
        </a> 
    </Row>))
}

// // url 预览模式
// const imgSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/9E6B5DD9C75D400F9EF4B515C6E409D7_5294d977d96773e6ea9a81d3119b51c9.jpg'
// const imgSrc1 = 'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp'
// const imgSrc2 = 'https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp'
// const imgSrc3 =  'https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp'
// const wordSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A74090AF17F146D8BD667F5FF8188D12_云罟使用指南（精简）.docx'
// const excelSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/30B167B900F04769878916CBB4E3AF16_星辰数智产品报价模板v1.0_0513.xlsx'
// const pptSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/A8ADCCE12F3E410FAD2AF860F4905666_新建 PPTX 演示文稿.pptx'
// const pdfSrc = 'https://jns-crm.oss-cn-hangzhou.aliyuncs.com/D86E4B42D89F411982CE150C644C7EBC_云罟云安全产品介绍.pdf'
// const vedioSrc = ''

// const files = [
//     {
//         name: '附件img',
//         src: imgSrc,
//     },
//     {
//         name: '附件ppt',
//         src: pptSrc,
//     },
//     {
//         name: '附件pdf',
//         src: pdfSrc,
//     },
// ]

const typeMaps: any = {
    1: '销售合同',
    2: '采购合同'
}

// 客户账号
export const columnsFn: (operate?: Function, authCode?: AuthActionArr) => ProColumns[] = (operate, authCode) => {
    const [, , request] = useDictEnumHooks({}, false)
    return [
        {
            title: '附件类型',
            dataIndex: 'subType',
            valueType: 'select',
            params: { dictId: 284 },
            request,
        },
        {
            title: '附件名称',
            dataIndex: 'fileName',
            hideInSearch: true,
            render: (text: any, record: any) => <FileList files={[record]} operate={operate} />
        },
        {
            title: '上传时间',
            dataIndex: 'createTime',
            hideInSearch: true,
        },
        {
            title: '上传人员',
            dataIndex: 'uploader',
            hideInSearch: true,
        },
        // {
        //     title: '项目类型',
        //     dataIndex: 'type',
        //     render: (text: any, record: any) => typeMaps[record.type]
        // },
        {
            title: '备注',
            dataIndex: 'remark',
            hideInSearch: true,
        },
        {
            title: '操作',
            key: 'option',
            valueType: 'option',
            width: 180,
            hideInSearch: true,
            fixed: 'right',
            render: (text: any, record: any) => {
              return [
                // <Button key="warn" type="link"  onClick={() => operate && operate('预览', record)}>预览</Button>,
                // <Button key="del" type="link"  onClick={() => operate && operate('下载', record)}>下载</Button>,
                (authCode||[]).includes('del') ? <Button key="del" type="link"  onClick={() => operate && operate('删除', record)}>删除</Button> : null
              ]
            },
        },
    ]
}
