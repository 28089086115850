import ProTable, { ActionType } from "@ant-design/pro-table"
import { oaTaskMinePageList } from "@api/index"
import { MyTabs } from "@components/EnterpriseDetail"
import { productApi } from "@utils/util"
import { Button, Select } from "antd"
import React, { useRef, useState } from "react"
import { columnsFn, customerViewOptions, OperateActionType, TableListItem, tabPanes } from "./data"

const getPageListApi = productApi(oaTaskMinePageList)
export default () => {
    const ref = useRef<ActionType>(null);
    const [activeKey, setActiveKey] = useState(' ')
    const [dataScope, setDataScope] = useState('1')
    const operate = async (action: OperateActionType) => {

    }

    const getApi = async (params: any) => {
        const { data } = await getPageListApi(params, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 10, marginBottom: 0}} 
          style={{background: '#fff'}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
          tabBarExtraContent={{
            left: <Select options={customerViewOptions} bordered={false} defaultValue={'1'} onChange={setDataScope} style={{minWidth: 80, marginRight: 30}}></Select>,
          }}
        />
        <ProTable<TableListItem>
                scroll={{x: 1200}}
                columns={columnsFn(operate)}
                params={{dataScope, taskStatus: activeKey}}
                request={getApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [],
                }}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ],
                }}
                rowKey="id"
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={false}
            />
    </div>)
}
