import React from "react";
import { TreeSelect } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Link } from "react-router-dom";
import { stringify } from "qs";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { Ellipsis } from "@components/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { yesNoOptions } from "@utils/index";

// ts--types
export type TableListItem = {
    key: number;
    id: number;
    attention: number | string;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    owner?: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
};

export type OperateActionType = '新建线索' | '转为客户' | '移入线索池' | '转移线索' | '批量导入' | '批量导出' | '批量删除' | '添加跟进'

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [, , request] = useDictEnumHooks({}, false)
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '客户名称',
        dataIndex: 'customerName',
        fixed: 'left',
        order: 100,
        render: (text: any, {id, owner: ownerName, customerName: name}) => <Link to={`/customer/detail?${stringify({id, name,ownerName, type: 2, routeName: '我的线索详情', fromPath: '/customer/myPool'})}`}>{text}</Link>
    },
    {
        title: '所属行业',
        dataIndex: 'label',
        // hideInSearch: true,
        order: 2,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: labelTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        // render: (text, record: any) => <Ellipsis text={(record.label||[]).map(({labelName}: any) => labelName).toString()||'-'} />,
        render: (text: any, record: any) => <Ellipsis text={record?.labelStr||'-'} len={30} />, 
    },
    {
        title: '重点客户',
        dataIndex: 'isEmphasisCustomer',
        order: 104,
        valueType: 'select',
        fieldProps: { options: yesNoOptions },
        render: (text: any, record: any) => record.isEmphasisCustomer ? '是' : '否'
    },
    {
        title: '客户类型',
        dataIndex: 'customerType',
        order: 0,
        width: 120,
        valueType: 'select',
        params: {dictId: 275 },
        fieldProps: { mode: 'multiple' },
        request,
    },
    {
        title: '账号来源',
        dataIndex: 'accountSource',
        order: -1,
        width: 120,
        valueType: 'select',
        params: {dictId: 274 },
        fieldProps: { mode: 'multiple' },
        request,
    },
    {
        title: '客户账号',
        dataIndex: 'customerAccount',
        order: 1,
        render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.customerAccount||[]).toString()}`}</Ellipsis.Nowrap> 
    },
    {
      title: '关注业务线',
      dataIndex: 'businessLine',
      order: 4,
      width: 120,
      params: {dictId: 19 },
      request,
      // hideInTable: true,
      render: (text, record: any) => `${record.businessLines||''}`
    },
    {
      title: '跟进记录',
      dataIndex: 'followUpContent',
      // hideInTable: true,
      order: 1,
      render: (text: any, record: any) => <Ellipsis.Nowrap>{`${(record.followUpContent||'').toString()}`}</Ellipsis.Nowrap> 
    },
    {
        title: '合作模式',
        dataIndex: 'cooperationMode',
        order: 1,
        valueType: 'select',
        params: {dictId: 25 },
        request,
        render: (text, record: any) => `${(record||{}).cooperationModeStr || ''}`
    },
    {
        title: '线索来源',
        dataIndex: 'customerClueSource',
        order: 6,
        valueType: 'select',
        params: {dictId: 15},
        request,
    },
    {
        title: '所属地区',
        dataIndex: 'area',
        order: 3,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: cityTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
    },
    {
        title: '归属人',
        dataIndex: 'owner',
        order: 4,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const owner = (ids||[]).map((v: string) => v.slice(1))
            return { owner }
          },
        },
    },
    {
        title: '最后跟进',
        dataIndex: 'finalFollowUpTime',
        // hideInTable: true,
        order: 1,
        valueType: 'dateRange',
        sorter: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text, record: any) => `${(record||{}).finalFollowUpTime||''}`
    },
    {
        title: '未跟进天数',
        dataIndex: 'daysSinceFollowup',
        // hideInTable: true,
        hideInSearch: true,
        order: 1,
        valueType: 'dateRange',
        // sorter: true,
        fieldProps: { allowEmpty: [true, true]},
        render: (text, record: any) => `${(record||{}).daysSinceFollowup||''}`
    },
    {
      title: '转入时间',
      dataIndex: 'transTime',
      order: 2,
      valueType: 'dateRange',
      sorter: true,
      // hideInSearch: true,
      fieldProps: { allowEmpty: [true, true]},
      render: (text, record: any) => `${(record||{}).transTime||''}`
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 180,
        fixed: 'right',
        render: (text, record) => [
          hasAuthCode(2196) ? <a key="跟进" onClick={() => operate && operate('添加跟进', record)}>跟进</a> : null,
          hasAuthCode(2197) ? <Link key="update" to={`/customer/add?${stringify({customerId: record.id, sourceType: 1, routeName: '编辑线索', routeTitName: record.customerName, fromPath: '/customer/myPool'})}`}>编辑</Link> : null,
        ],
    },
]
}
