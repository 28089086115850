import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { MyTabs } from "@components/EnterpriseDetail"
import { acceptExcel, getCheckedColumnsStateByColumns, getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume, rebackClaimSearch } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ImportButton, ProTableWithFormSetting } from "@components/index"
import { columnsFn } from "./data"
import { OperateActionType } from "../../data"
import { useRebackClaimAuditHooks } from "./hooks"
import { RebackClaimModalForm } from "@components/Finance/RebackMoney/cpnts/RebackClaim"
import Table from "@ant-design/pro-table/lib/Table"
import { ExportButtonWithBatch } from "@components/Common/ExportButton"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"

const getPageListApi = productApi(rebackClaimSearch)

const persistenceKey = 'finance/contract/rebackClaimAudit'

export const TableSummary = ({data}: any) => {
    return (<Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align='center' index={0}>合计</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={1}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={2}>{data.payAmount}</Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={3}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={4}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={5}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={6}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={7}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={8}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={9}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={10}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={11}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={12}></Table.Summary.Cell>
          <Table.Summary.Cell align='center' index={13}></Table.Summary.Cell>
        </Table.Summary.Row>
    </Table.Summary>)
  }

export default () => {
    const history = useHistory()
    const ref = useRef<ActionType>(null);
    const [, hasAuthCode] = useHasAuthCode()
    const formRef = useRef<ProFormInstance>()
    const [allTableData, setAllTableData] = useState({}) as any
    const { downLoadUrlMap } = useSelector((state: StateType) => state)

    const {
        rebackClaimAuditModalFormProps,
        setRebackClaimAuditModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled,
    } = useRebackClaimAuditHooks()

    const operate = async (type: OperateActionType, record: any) => {
        const action = createOperate(record, reFush)
        action(type, {ids: selectedRowKeys})
    }

    const columns = [...columnsFn(operate, '')]
    const params = { }
    const getApi = async (params: any, sorter: any) => {
        const { data } = await getPageListApi({...params, sorter}, false)
        const { values, total, ...rest } = data || {}
        setAllTableData(rest)
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            backIds: selectedRowKeys,
            fields,
            ...params
        })
    }



    return(<div className="oa-task-center">
        <ProTableWithFormSetting<any>
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getApi}
                options={{reload: false, density: false,}}
                headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>打款金额： <span style={{color: 'red'}}>{allTableData.payAmount || 0}</span> （汇总列表查询结果）</h5>}
                toolbar={{
                    actions: [
                        hasAuthCode(2485) ? <ImportButton key="btn8" params={{}} accept={acceptExcel} action={`${BASEURL}/contract/importBackClaim`} templateUrl={downLoadUrlMap['回款审批-导入回款记录']} title="导入回款记录" trigger={<Button type="primary">导入回款记录</Button>}></ImportButton> : null,
                        hasAuthCode(2484) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('待认领回款-撤销', null)}>撤销</Button> : null,
                        hasAuthCode(2486) ? <Button key="btn7" type="primary" disabled={calcDisabled()} onClick={() => operate('待认领回款-删除', null)}>删除</Button> : null,
                        hasAuthCode(2481) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/contract/exportBackClaim`}></ExportButtonWithBatch> : null,
                    ],
                }}
                rowKey="backClaimId"
                // summary={(dataSource) => <TableSummary data={allTableData} />}
                actionRef={ref as any}
                formRef={formRef}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    columnWidth: '46px',
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)}
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', }}
                searchState={{ persistenceKey:`${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
        />
        <RebackClaimModalForm
            modalProps={{
                onCancel: () => setRebackClaimAuditModalFormProps({...rebackClaimAuditModalFormProps, visible: false}),
                maskClosable: false
            }}  
            {...rebackClaimAuditModalFormProps} 
        />
    </div>)
}
