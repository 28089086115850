import { ProColumns } from "@ant-design/pro-table";
import { BASEURL } from "@api/index";
import { useCommonApiHooks, useDictEnumHooks } from "@hooks/index";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { DatePicker, Input, Select, TreeSelect } from "antd";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
const { RangePicker } = DatePicker;

export const taskFeedbackOptions = [
    {
        label: '使用原始反馈选项',
        value: 1
    },
    {
        label: '设置新反馈选项',
        value: 2
    }
]

export const taskChargerTypeOptions = [
    {
        label: '客户原归属人',
        value: 1
    },
    {
        label: '自定义负责人',
        value: 2
    }
]
export const taskTargetOptions = [
    {
        label: <span>跟进一次客户<span style={{color: 'rgba(0, 0, 0, 0.65)', marginLeft: 10}}>（在任务时间范围内，该客户被跟进了一次，表示该客户为已执行）</span></span>,
        value: 1
    },
    {
        label: <span>促成客户成交<span style={{color: 'rgba(0, 0, 0, 0.65)', marginLeft: 10}}>（在任务时间范围内，客户状态为已成交，表示该客户为已执行）</span></span>,
        value: 2
    }
]

export type FeedbackItemType = {
    id: string | number;
    question: string
}
export const feedbackItem = (n: number = 1) => new Array(n).fill({
    label: ''
}).map((v, idx) => ({...v, id: `zqs-${idx}-${Date.now()}`}))

export const feedbackColumnsFn: (form: any) => ProColumns<FeedbackItemType>[] = (form) => {
    return [
        {
            title: '序号',
            dataIndex: 'id',
            width: 50,
            editable: false,
            render: (text: any, record: any, idx: number) => idx + 1
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>选项名称</span>,
            dataIndex: 'label',
            formItemProps: (form, {rowKey}) => {
                const thatKey = (rowKey||[])[0]
                const values = form.getFieldsValue()
                const otherValues: string[] = []
                Object.keys(values).forEach(k => {
                    if (k !== thatKey) {
                        const { label } = values[k]
                        otherValues.push(label)
                    }
                })
                return {
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            // message: '此项是必填项',
                            validator: (rules: any, val: string, callback: Function, params?: Record<string, any>) => {
                                if (!val) {
                                    return Promise.reject('此项是必填项')
                                } else if (val.length > 30) {
                                    return Promise.reject('30字以内')
                                } else if (otherValues.includes(val)) {
                                    return Promise.reject('该项已存在')
                                } else {
                                    return Promise.resolve()
                                }
                            }
                        }
                    ]
                }
            },
        },
        {
            title: '操作',
            valueType: 'option',
            fixed:'right',
            width: 100,
            render: () => [
              <a key="link">删除</a>,
            ],
        },
    ]
}

const itemStyle = { marginBottom: 12, width: '100%' }
const Label = (label: ReactNode) => <span style={{display: 'inline-block', }}>{label}</span>

const wrapperCol = { span: 12 }

const customerSourceItem = { 
    label: Label('客户来源'), 
    name: ['taskSelectCustomerConfig', 'customerSource'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 15}, true)
        return <Select options={options} mode="multiple" {...props} />
    } 
}

const customerStateItem = { 
    label: Label('客户状态'), 
    name: ['taskSelectCustomerConfig', 'customerState'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 13}, true)
        return <Select options={options} mode="multiple" {...props} />
    }  
}

const customerLevelItem = { 
    label: Label('客户等级'),  
    name: ['taskSelectCustomerConfig', 'customerLevel'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 14}, true)
        return <Select options={options} mode="multiple" {...props} />
    }  
}

const customerAreaItem = { 
    label: Label('所属地区'), 
    name: ['taskSelectCustomerConfig', 'customerArea'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const { cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
        return <TreeSelect treeData={cityTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' {...props} />
    }
}

const customerTagsItem = { 
    label: Label('客户标签'), 
    name: ['taskSelectCustomerConfig', 'customerTags'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const { cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
        return <TreeSelect treeData={labelTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' {...props} />
    }
}

const cooperationModeItem = { 
    label: Label('合作模式'),
    name: ['taskSelectCustomerConfig', 'cooperationMode'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 25}, true)
        return <Select options={options} mode="multiple" {...props} />
    } 
}

const customerBelongItem = { 
    label: Label('有无归属人'), 
    name: ['taskSelectCustomerConfig', 'customerBelong'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        return <Select options={[{label: '有', value: 1},{label: '无', value: 2}]} {...props} />
    }
}

const customerManagerItem = { 
    label: Label('客户经理'), 
    name: ['taskSelectCustomerConfig', 'customerManager'], 
    rules: [{required: true, message: '请选择客户经理'}], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const { cityTreeData, deptWithUserTreeData } = useSelector((state: StateType) => state)
        return <TreeSelect treeData={deptWithUserTreeData} maxTagCount={4} treeCheckable showSearch treeNodeFilterProp='title' {...props} />
    }
}

const cloudProductsItem = { 
    label: Label('云产品'), 
    name: ['taskSelectCustomerConfig', 'cloudProducts'], 
    rules: [{required: true, message: '请选择云产品'}], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        // const [options,] = useDictEnumHooks({dictId: 25}, true)
        return <Select options={[{label: '阿里云', value: 1}]} mode="multiple" {...props} />
    }
}

const consumeDateRangeItem = { 
    label: Label('消费月份'), 
    name: ['taskSelectCustomerConfig', 'consumeDateRange'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: RangePicker 
}

const orderTypeItem = { 
    label: Label('订单类型'), 
    name: ['taskSelectCustomerConfig', 'orderType'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 254}, true)
        return <Select options={options} mode="multiple" {...props} />
    }
}

const orderServerLifeItem = { 
    label: Label('订单服务周期'), 
    name: ['taskSelectCustomerConfig', 'orderServerLife'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 256}, true)
        return <Select options={options} mode="multiple" {...props} />
    }
}
const productCategoryItem = { 
    label: Label('产品分类'), 
    name: ['taskSelectCustomerConfig', 'productCategory'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle,  
    Item: (props: any) => {
        // const [options,] = useDictEnumHooks({dictId: 257}, true)
        // return <TreeSelect options={options} mode="multiple" {...props} />
        const [treeData,] = useCommonApiHooks({url: `${BASEURL}/product/getProductCategoryTree`, method: 'GET'}, {type: 1}, true)
        return <TreeSelect treeData={transferTreeData(treeData)} maxTagCount={4} treeCheckable showSearch treeNodeLabelProp="name" treeNodeFilterProp='title' {...props} />
    }
}
const billTypeItem = { 
    label: Label('账单类型'), 
    name: ['taskSelectCustomerConfig', 'billType'], 
    rules: [], 
    colProps: {span: 8}, 
    style: itemStyle, 
    Item: (props: any) => {
        const [options,] = useDictEnumHooks({dictId: 255}, true)
        return <Select options={options} mode="multiple" {...props} />
    }
}

export const seachConfig0 = [
    Object.assign({}, customerSourceItem, {name: [0, 'customerSource']}),
    Object.assign({}, customerLevelItem, {name: [0, 'customerLevel']}),
    Object.assign({}, customerAreaItem, {name: [0, 'customerArea']}),
    Object.assign({}, customerTagsItem, {name: [0, 'customerTags']}),
    Object.assign({}, cooperationModeItem, {name: [0, 'cooperationMode']}),
    Object.assign({}, customerBelongItem, {name: [0, 'customerBelong']}),
    Object.assign({}, customerStateItem, {name: [0, 'customerState']}),
]

export const seachConfig1 = [
    Object.assign({}, customerManagerItem, {name: [1, 'customerManager']}),
    Object.assign({}, customerStateItem, {name: [1, 'customerState']}),
    Object.assign({}, customerLevelItem, {name: [1, 'customerLevel']}),
    Object.assign({}, customerSourceItem, {name: [1, 'customerSource']}),
    Object.assign({}, customerAreaItem, {name: [1, 'customerArea']}),
    Object.assign({}, customerTagsItem, {name: [1, 'customerTags']}),
    Object.assign({}, cooperationModeItem, {name: [1, 'cooperationMode']}),
]

export const seachConfig2 = [
    Object.assign({}, cloudProductsItem, {name: [2, 'cloudProducts']}),
    Object.assign({}, customerSourceItem, {name: [2, 'customerSource']}),
    Object.assign({}, customerStateItem, {name: [2, 'customerState']}),
    Object.assign({}, customerAreaItem, {name: [2, 'customerArea']}),
    Object.assign({}, customerTagsItem, {name: [2, 'customerTags']}),
    Object.assign({}, customerLevelItem, {name: [2, 'customerLevel']}),
    Object.assign({}, cooperationModeItem, {name: [2, 'cooperationMode']}),
    Object.assign({}, productCategoryItem, {name: [2, 'productCategory']}),
    Object.assign({}, billTypeItem, {name: [2, 'billType']}),
    Object.assign({}, orderTypeItem, {name: [2, 'orderType']}),
    Object.assign({}, consumeDateRangeItem, {name: [2, 'consumeDateRange']}),
    Object.assign({}, orderServerLifeItem, {name: [2, 'orderServerLife']}),
]