/**
 * 测试实验室
*/
import { Alert, Card, DatePicker, Input, Row, Table } from "antd"
import React, { useEffect, useState } from "react"
import { Link, Route, Switch } from "react-router-dom";
import { VirtualTable, KeepAliveDemo, ListPage } from "./cpnts";

type CardsProps = {
    config: any[]
}
const Cards = ({config}: CardsProps) => {
    return <Row wrap>
      {
        (config||[]).map(({more, title, Component}: any) => (<Card title={title} extra={more ? <Link to={more}>更多</Link> : null} style={{ width: '30%', margin: 10 }} key={title}><Component /></Card>))
      }
    </Row>
}

const cardConfig = [
    {
        title: '虚拟列表',
        Component: VirtualTable.Demo,
    },
    {
        title: '组件缓存',
        Component: ListPage,
    },
]

const Demo1 = () => {
    return (<div style={{boxSizing: 'border-box', padding: '0 20px'}}>
        <Alert message="大家好呀，我是测试实验室，可以把你的想法形成代码，欢迎造轮子!!!" type="warning" showIcon closable />
        <Cards config={cardConfig} />
        <KeepAliveDemo />
    </div>)
}

export default Demo1