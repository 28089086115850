import { Badge } from "antd"
import React from "react"

export type OperateActionType = '销售合同-新建' | '销售合同-编辑' | '销售合同-撤销申请' | '销售合同-删除' | '销售合同-提交并创建回款记录' | '销售合同-一键申请' | '销售合同-回款申请' | '销售合同-发票申请' | '采购合同-新建' | '采购合同-编辑' | '采购合同-删除' | '采购合同-撤销申请' | '采购合同-一键申请'


export const tabPanes = [
  {
      Tab: '销售合同',
      key: ' ',
      Content: null
  },
  {
      Tab: '采购合同',
      key: 2,
      Content: null
  }
]

export const statusOptions = [
  {
    label: '待n级审批',
    value: 'n'
  },
  {
    label: '审批通过',
    value: -1
  },
  {
    label: '审批拒绝',
    value: -2
  },
  {
    label: '已作废',
    value: -3
  },
  {
    label: '已撤销',
    value: -4
  }
]

export const statusValueEnum = {
  1: { text: '待审批', status: 'Processing' },
  2: { text: '开票中', status: 'Processing' },
  3: { text: '已驳回', status: 'Error' },
  4: { text: '已开票', status: 'Success' },
  5: { text: '已撤销', status: 'Default' },
  6: { text: '已暂停', status: 'Warning' },
  7: { text: '已作废', status: 'Default' },
}

const transStatus = (status: any) => {
  switch (status) {
    case -1:
      return 'success'
    case -2: 
      return 'error'
    case -3:
      return 'warning'
    case -4:
      return 'default'
    default:
      return 'processing'
  }
}

export const StatusDot = ({status}: any) => {
  return <Badge status={transStatus(status)} />
}

export const viewOptions = [
 {
      label: '我的',
      value: '1'
 },
 {
      label: '下属的',
      value: '2'
 },
 {
      label: '全部',
      value: ''
 }
]

// 是否盖章
export const signOptions = [
  {
      label: '盖章',
      value: 1
  },
  {
      label: '不盖章',
      value: 0
  }
]

// 有无文本合同
export const textOptions = [
  {
      label: '有文本',
      value: 1
  },
  {
      label: '无文本',
      value: 0
  }
]


export type TableListItem = {
  
}