import { Select, SelectProps, Tabs } from "antd";
import React from "react";
const { TabPane } = Tabs

// 通用客户视图
type Action = 'view' | 'scope'
type ViewScopeProps = {
    viewOptions?: SelectProps['options'],
    scopeOptions?: SelectProps['options'],
    onChange?: (val?: any, action?: Action) => void;
}
const ViewScope = ({viewOptions, scopeOptions, onChange}: ViewScopeProps) => {
    return <Tabs 
            onChange={(val) => onChange?.(val, 'scope')} 
            tabBarExtraContent={{
                left: viewOptions ? <Select options={viewOptions} bordered={false} defaultValue="" onChange={(val) => onChange?.(val, 'view')} style={{minWidth: 80, marginRight: 30}} /> : null
            }}
            style={{background: '#fff'}}
            tabBarStyle={viewOptions ? undefined : {marginLeft: 10, marginBottom: 0}}
        >
        { scopeOptions?.map(v => <TabPane tab={v.label||v.Tab} key={v.value||v.key}></TabPane>) }
    </Tabs>
}

export default ViewScope