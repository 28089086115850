import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
// import enUS from 'antd/lib/locale/en_US';
import 'antd/dist/antd.css'
import '@assets/css/index.less'
import "@ant-design/flowchart/dist/index.css";

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <App />
    </ConfigProvider>,
    document.getElementById('root')
)
