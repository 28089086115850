import React, { useEffect, useState } from "react"
import { BackBan } from '@components/index';
import ProTable from '@ant-design/pro-table';
import { productApi, parseSearch, CLOUD_VENDOR } from "@utils/index";
import { useCommonApiHooks } from "@hooks/index"
import { useLocation } from "react-router"
import { BASEURL, rebatePayeeInfoUpdateLog } from "@api/index"
import './index.less';
import { customerGetOriginalRecords } from '@api/index'
import { apsColumns, summaryColumns, rebateColumns, collectionColumns, remarksColumns, voucherColumns, getMoneyLogColumns, huaWeiApsColumns, tencentApsColumns } from './data'
import { RefundOrders } from '@components/CustomerRebate/RebateConfirm/cpnts/Ali';
import { reviewInfoApi } from "../RebateList/cpnts/hooks";
import { Table } from "antd";
import { useRebateDetailHooks } from "./hooks";
const getApiAps = productApi(customerGetOriginalRecords)
const getPayeeInfoUpdateLogApi = productApi(rebatePayeeInfoUpdateLog)

// 增加新增/修改/删除收款信息的操作记录。
const ExpandedRowRender = ({id}: any) => {
    // const [options] = useCommonApiHooks({url: `${BASEURL}/getPayeeInfoUpdateLog`}, {orderId}, true)
    const [dataSource, setDataSource] = useState([])
    const getPayeeInfoUpdateLogApi = async (params: any = {id, pageNum: 1, pageSize: 1000}) => {
        const { data } = await rebatePayeeInfoUpdateLog(params) as any
        const { values } = data || {}
        setDataSource(values)
    }

    useEffect(() => {
        getPayeeInfoUpdateLogApi()
    }, [id])

    return (
        <Table
            columns={getMoneyLogColumns}
            dataSource={dataSource}
            pagination={false}
        />
    );
}

const valueEnum = {
    0: '待认领',
    1: '倒挂',
    2: '待确认',
    3: '待复核',
    4: '不打款',
    5: '待打款',
    6: '部分打款',
    7: '全部打款',
    8: '追回待审核',
    9: '认领待审核',
} as any

type RebateDetailPropsType = {
    id?: string | number;
}

// 阿里返点详情
const AliRebateDetail = ({id}: RebateDetailPropsType) => {
    const {  
        refundOrderDataSource,
        summaryDataSource,
        rebateInfoDataSource,
        collectionInfoDataSource,
        remarksInfoDataSource,
        voucherDataSource,
        getListApi,
        getOperateLogApi
    } = useRebateDetailHooks({id})
    
    return (<>
    <div style={{background: '#fff', boxSizing: 'border-box'}}>
        <div className="tableHeader"><span>阿里云原始消费订单</span></div>
        <ProTable headerTitle="返点订单明细" request={getListApi}  className="table" scroll={{ x: 1800 }} pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={apsColumns} options={false} search={false} key="1" />
        <div className="tableHeader"><span>返点订单明细</span></div>  
        <ProTable headerTitle="汇总明细" dataSource={summaryDataSource||[]}  className="table" columns={summaryColumns} pagination={false} options={false} search={false} key="2" />
        <div style={{boxSizing: 'border-box', padding: '8px 25px'}}>
            <h3 style={{paddingLeft: 20, marginBottom: 0}}>抵扣款订单</h3>
            <RefundOrders dataSource={refundOrderDataSource} />
        </div>
        <ProTable headerTitle="返点信息" dataSource={rebateInfoDataSource||[]} className="table" pagination={false} columns={rebateColumns as any} options={false} search={false} key="3" />
        <ProTable headerTitle="收款信息" rowKey="id" dataSource={collectionInfoDataSource||[]} className="table" pagination={false} columns={collectionColumns} options={false} search={false} key="4" />
        <ProTable headerTitle="操作信息" rowKey="id" request={getOperateLogApi} className="table" pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={getMoneyLogColumns} options={false} search={false} key="5" />
        <ProTable headerTitle="备注信息" dataSource={remarksInfoDataSource||[]}  className="table" pagination={false} columns={remarksColumns} options={false} search={false} key="6" />
        <ProTable headerTitle="凭证图片" dataSource={voucherDataSource||[]} className="table" pagination={false} columns={voucherColumns()} options={false} search={false} key="7" />
    </div>
    </>)
}


// 华为云返点详情
const HuaWeiRebateDetail = ({id}: RebateDetailPropsType) => {
    const { search } = useLocation()
    const {  
        refundOrderDataSource,
        summaryDataSource,
        rebateInfoDataSource,
        collectionInfoDataSource,
        remarksInfoDataSource,
        voucherDataSource,
        getListApi,
        getOperateLogApi
    } = useRebateDetailHooks({id})
    
    return (<>
    <div style={{background: '#fff', boxSizing: 'border-box'}}>
        <div className="tableHeader"><span>华为云原始消费订单</span></div>
        <ProTable headerTitle="返点订单明细" request={getListApi}  className="table" scroll={{ x: 4800 }} pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={huaWeiApsColumns} options={false} search={false} key="1" />
        <div className="tableHeader"><span>返点订单明细</span></div>  
        <ProTable headerTitle="汇总明细" dataSource={summaryDataSource||[]}  className="table" columns={summaryColumns} pagination={false} options={false} search={false} key="2" />
        <div style={{boxSizing: 'border-box', padding: '8px 25px'}}>
            <h3 style={{paddingLeft: 20, marginBottom: 0}}>抵扣款订单</h3>
            <RefundOrders dataSource={refundOrderDataSource} />
        </div>
        <ProTable headerTitle="返点信息" dataSource={rebateInfoDataSource||[]} className="table" pagination={false} columns={rebateColumns.filter(v => v.dataIndex !== 'sonDimension') as any} options={false} search={false} key="3" />
        <ProTable headerTitle="收款信息" rowKey="id" dataSource={collectionInfoDataSource||[]} className="table" pagination={false} columns={collectionColumns} options={false} search={false} key="4" />
        <ProTable headerTitle="操作信息" rowKey="id" request={getOperateLogApi} className="table" pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={getMoneyLogColumns} options={false} search={false} key="5" />
        <ProTable headerTitle="备注信息" dataSource={remarksInfoDataSource||[]}  className="table" pagination={false} columns={remarksColumns} options={false} search={false} key="5" />
        <ProTable headerTitle="凭证图片" dataSource={voucherDataSource||[]} className="table" pagination={false} columns={voucherColumns()} options={false} search={false} key="6" />
    </div>
    </>)
}

// 腾讯返点详情
const TencentRebateDetail = ({id}: RebateDetailPropsType) => {
    const {  
        refundOrderDataSource,
        summaryDataSource,
        rebateInfoDataSource,
        collectionInfoDataSource,
        remarksInfoDataSource,
        voucherDataSource,
        getListApi,
        getOperateLogApi
    } = useRebateDetailHooks({id})
    
    return (<>
    <div style={{background: '#fff', boxSizing: 'border-box'}}>
        <div className="tableHeader"><span>腾讯云原始消费订单</span></div>
        <ProTable headerTitle="返点订单明细" request={getListApi}  className="table" scroll={{ x: 1800 }} pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={tencentApsColumns} options={false} search={false} key="1" />
        <div className="tableHeader"><span>返点订单明细</span></div>  
        <ProTable headerTitle="汇总明细" dataSource={summaryDataSource||[]}  className="table" columns={summaryColumns} pagination={false} options={false} search={false} key="2" />
        <div style={{boxSizing: 'border-box', padding: '8px 25px'}}>
            <h3 style={{paddingLeft: 20, marginBottom: 0}}>抵扣款订单</h3>
            <RefundOrders dataSource={refundOrderDataSource} />
        </div>
        <ProTable headerTitle="返点信息" dataSource={rebateInfoDataSource||[]} className="table" pagination={false} columns={rebateColumns.filter(v => v.dataIndex !== 'sonDimension') as any} options={false} search={false} key="3" />
        <ProTable headerTitle="收款信息" rowKey="id" dataSource={collectionInfoDataSource||[]} className="table" pagination={false} columns={collectionColumns} options={false} search={false} key="4" />
        <ProTable headerTitle="操作信息" rowKey="id" request={getOperateLogApi} className="table" pagination={{showSizeChanger: true, defaultPageSize:5, pageSizeOptions:['5','10','20']}} columns={getMoneyLogColumns} options={false} search={false} key="5" />
        <ProTable headerTitle="备注信息" dataSource={remarksInfoDataSource||[]}  className="table" pagination={false} columns={remarksColumns} options={false} search={false} key="5" />
        <ProTable headerTitle="凭证图片" dataSource={voucherDataSource||[]} className="table" pagination={false} columns={voucherColumns()} options={false} search={false} key="6" />
    </div>
    </>)
}


const DetailMaps = {
    [CLOUD_VENDOR.ALI]: AliRebateDetail,
    [CLOUD_VENDOR.HUAWEI]: HuaWeiRebateDetail,
    [CLOUD_VENDOR.TENCENT]: TencentRebateDetail
}

export default () => {
    const { search } = useLocation()
    const { id, state, cloudVendor } = parseSearch(search)
    // @ts-ignore
    const Detail = DetailMaps[cloudVendor]
    return (<div>
        <BackBan title={valueEnum[state]} style={{paddingLeft: 20, position: 'sticky', top: -24, zIndex: 9}} />
        <Detail id={id} />
    </div>)
}