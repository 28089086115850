import React, { useEffect, useRef, useState } from 'react'
import './index.less'

export default ({ content }: any) => {
    const [openState, setOpenState] = useState<boolean>(true)

    useEffect(() => {
        setOpenState(content?.length > 1500)
    }, [content])
    console.log(content)
    return (<div className='OpenTextMain'>
        <div className={`OpenTextDiv  ${openState ?  'OpenTextDivClose':  'OpenTextDivOpen' }`}>
            <div dangerouslySetInnerHTML={{ __html: content }} >

            </div>
        </div>
        {content?.length > 1500 ? <div className='openButton' style={{ boxShadow: openState ? '0 -20px 20px 0px rgb(158, 158, 158)' : ''}}>
            <div className='button' onClick={() => setOpenState(!openState)}>{openState ? `展开剩余的 ${Math.floor((content?.length - 1000) / content?.length  * 100)}%` : '收起全文' }</div>
        </div> : null}
    </div>
    )
}