import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select, Tooltip } from "antd"
import React, { useRef, useState } from "react"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume } from "@api/index"
import { useHistory } from "react-router-dom"
import { viewOptions } from "./data"
import RebackList from "./cpnts/RebackList"
import RebackClaim from "./cpnts/RebackClaim"
import { Icon } from "@components/index"


export default () => {
    const [activeKey, setActiveKey] = useState(' ')
    const [dataScope, setDataScope] = useState('1')
    const tabPanes = [
      {
          Tab: '回款列表',
          key: ' ',
          Content: <RebackList dataScope={dataScope} />
      },
      {
          Tab: <>待认领回款 &nbsp; <Tooltip title="说明：此处回款数据是指客户已打款到公司账户，但运营及财务人员不知晓已打款客户归属于哪个客户经理，需客户经理认领回款记录并创建回款申请"><Icon type="icon-ziyuan" /></Tooltip></>,
          key: 2,
          Content: <RebackClaim dataScope={dataScope} />
      }
    ]

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{marginLeft: 0, marginBottom: 0, background: '#fff'}} 
          style={{marginTop: 0}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
          tabBarExtraContent={{
            left: <Select options={viewOptions} bordered={false} value={dataScope} onChange={setDataScope} style={{minWidth: 80, marginRight: 30}} />
          }}
        />
        
    </div>)
}
