import { BackBan } from "@components/index"
import { DescriptionsList } from "@components/EnterpriseDetail"
import React from "react"
import { billInfo, gainInfo, postInfo } from "./data"
import { useCommonApiHooks } from "@hooks/index"
import { BASEURL } from "@api/index"
import { useHistory } from "react-router-dom"
import { parseSearch } from "@utils/index"
import { statusOptions } from "../../data"

export default () => {
    const history = useHistory() 
    const { search } = history.location
    const { id } = parseSearch(search)
    const [dataObj] = useCommonApiHooks({url: `${BASEURL}/invoice/getInvoiceDetails`, method: 'GET'}, {invoiceId: id}, true)

    return(<div>
        <BackBan title={(statusOptions.find(v => v.value === dataObj.status)||{}).label} />
        <div style={{background: '#fff', paddingLeft: 20, paddingTop: 30}}><div className="zen-ban">开票信息</div></div>
        <DescriptionsList data={dataObj||{}} descList={billInfo} />
        {/* <div style={{background: '#fff', paddingLeft: 20}}><div className="zen-ban">收货信息</div></div>
        <DescriptionsList data={dataObj||{}} descList={gainInfo} />
        <div style={{background: '#fff', paddingLeft: 20}}><div className="zen-ban">邮寄信息</div></div>
        <DescriptionsList data={dataObj||{}} descList={postInfo} /> */}
    </div>)
}