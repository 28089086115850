import { Button, message, Modal, Space, Tabs } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ProTable, { ActionType } from '@ant-design/pro-table';
import ProForm, { ProFormUploadDragger, ProFormSelect } from '@ant-design/pro-form';
import type { ProFormInstance } from '@ant-design/pro-form' 
import { columnsFn, TableListItem } from './data';
import { useDictEnumHooks, useHasAuthCode } from '@hooks/index';
import { getToken, handleSorter, productApi, toFormData } from '@utils/index';
import { sysPoolList, lqImportSearchCondition, LQBASEURL, lqImportQueryHistory } from '@api/index';
import { AddPool, ClueToCustomerButton, GetCluesButton } from '@components/Customer/CluesPool';
import { ExportButton } from '@components/index';
import { lqCompanyToCustomerApi, lqGetClueToJnsApi, lqGetClueToPoolApi } from '../Search';
import { useSelector } from 'react-redux';
import { StateType } from '@store/index';

const { TabPane } = Tabs
const getApi = productApi(sysPoolList)
const lqImportSearchConditionApi = productApi(lqImportSearchCondition)
const lqImportQueryHistoryApi = productApi(lqImportQueryHistory)

const MuchQuery = () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<any>(null);
    const { downLoadUrlMap } = useSelector((state: StateType) => state)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [customerSourceOptions ] = useDictEnumHooks({dictId: 15 }, true)
    const [fileList, setFileLst] = useState<any[]>([])
    const [version, setVersion] = useState<string>('')
    const formRef = useRef<ProFormInstance>()
    const customRequest = ({file}: any) => setFileLst([file])
    const onRemove = () => setFileLst([])
    const columns = columnsFn({type: '2-1'}, () => {})

    const calcDisabled = (str: string) => {
        return !selectedRowKeys.length
    }

    const onValuesChange = async (values: any, allValues: any) => {
        const {size} = values.fileList[0] || {}
        if (size/1024/1024 > 2) {
            onRemove()
            formRef.current?.resetFields(['fileList'])
            return message.warning('文件最大不超过 2 M, 请重新上传')
        }
        if ('fileList' in values && values['fileList'].length) {
            const source = customerSourceOptions.find((v: any) => v.value === allValues.sourceId).label
            const formData = toFormData({...allValues, source})
            const { success, data } = await lqImportSearchConditionApi(formData, false, () => null)
            const {version, info} = data || {}
            Modal.confirm({
                title: '处理成功',
                content: <div><p>{info}</p></div>,
                okText: '确认',
                cancelText: '取消',
            });
            setVersion(version)
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const newSorter = handleSorter(sorter)
        const { data } = await lqImportQueryHistoryApi({...params, version, sorter: newSorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const reFresh = () => {
        setTimeout(() => {
            ref.current?.reload()
            // setSelectedRowKeys([])
            // @ts-ignore
            ref.current?.clearSelected()
        }, 1000)
    }

    useEffect(() => {
        version && reFresh()
    },[version])

    return (<>
        <ProForm layout="horizontal" formRef={formRef} onValuesChange={onValuesChange} submitter={false} style={{background: '#fff', marginBottom: '20px', boxSizing: 'border-box', padding: '20px'}}>
            <ProFormSelect width="md" name="sourceId" label="客户来源" initialValue={7} options={customerSourceOptions} />
            <ProFormUploadDragger 
               width="lg" 
               max={1} 
               label="上传文件" 
               name="fileList" 
               fieldProps={{ 
                name: 'file', 
                customRequest, 
                onRemove, 
                fileList, 
                headers: {Authorization: getToken()}
              }} 
              help={<a download href={downLoadUrlMap['猎奇-批量查询']}>下载Excel模版</a>} 
              rules={[{required: true, message: '文件导入'}]}
            />
        </ProForm>
        <ProTable<TableListItem>
            scroll={{x: 1600}}
            columns={columns}
            options={{reload: false, density: false, setting: false}}
            rowKey="id"
            pagination={{defaultPageSize: 10}}
            manualRequest={true}
            request={getListApi}
            actionRef={ref as any}
            search={false}
            rowSelection={{
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                    setSelectedRowKeys(selectedRowKeys)
                },
                getCheckboxProps: (record: TableListItem) => ({
                    disabled: !record.isReceive,
                })
            }}
            toolbar={{
                actions: [
                    hasAuthCode(2223) ? <AddPool key="bnt3" title="转移线索" trigger={<Button type="primary" disabled={calcDisabled('转移线索')}>转移线索</Button>} onFinish={async({cluePoolId}) => { await lqGetClueToPoolApi({cluePoolId, ids: selectedRowKeys, module: 2}, true, reFresh) }}/> : null,
                    hasAuthCode(2224) ? <GetCluesButton key="primary" disabled={calcDisabled('领取线索')} onOk={() => lqGetClueToJnsApi({ids: selectedRowKeys, module: 2}, true, reFresh)}>领取线索</GetCluesButton> : null,
                    hasAuthCode(2225) ? <ClueToCustomerButton key="btn1" disabled={calcDisabled('转为客户')} onOk={() => lqCompanyToCustomerApi({ids: selectedRowKeys, module: 2}, true, reFresh)}>转为客户</ClueToCustomerButton> : null,
                    hasAuthCode(2226) ? <ExportButton key="btn5" param={{version}} btnText="全部导出" action={`${LQBASEURL}/batchQueryExport`}></ExportButton> : null
                ],
            }}
        />
    </>)
}

const QueryHistory = () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const formRef = useRef<ProFormInstance>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const columns = columnsFn({type: '2-2'}, () => {})

    const calcDisabled = (str: string) => {
        return !selectedRowKeys.length
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const newSorter = handleSorter(sorter)
        const { data } = await lqImportQueryHistoryApi({...params, sorter: newSorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const addPoolOnFinish = async({cluePoolId}: any) => {
        const { success } = await lqGetClueToPoolApi({cluePoolId, ids: selectedRowKeys, module: 2}, true, reFresh) 
        return success
    }

    const reFresh = () => {
        setTimeout(() => {
            ref.current?.reload()
            // setSelectedRowKeys([])
            // @ts-ignore
            ref.current?.clearSelected()
        }, 1000)
    }

    return (<ProTable<TableListItem>
        scroll={{x: 1600}}
        columns={columns}
        request={getListApi}
        options={{reload: false, density: false, setting: false}}
        rowKey="id"
        pagination={{defaultPageSize: 10}}
        actionRef={ref as any}
        formRef={formRef as any}
        search={{
            optionRender: (searchConfig, formProps, dom) => 
            [ ...dom.reverse() ]
        }}
        rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRowKeys(selectedRowKeys)
            },
            getCheckboxProps: (record: TableListItem) => ({
                disabled: !record.isReceive,
            })
        }}
        toolbar={{
            actions: [
                hasAuthCode(2234) ? <AddPool key="bnt3" title="转移线索" trigger={<Button type="primary" disabled={calcDisabled('转移线索')}>转移线索</Button>} onFinish={addPoolOnFinish}/> : null,
                hasAuthCode(2235) ? <GetCluesButton key="primary" disabled={calcDisabled('领取线索')} onOk={() => lqGetClueToJnsApi({ids: selectedRowKeys, module: 2}, true, reFresh)}>领取线索</GetCluesButton> : null,
                hasAuthCode(2236) ? <ClueToCustomerButton key="btn1" disabled={calcDisabled('转为客户')} onOk={() => lqCompanyToCustomerApi({ids: selectedRowKeys, module: 2}, true, reFresh)}>转为客户</ClueToCustomerButton> : null,
                hasAuthCode(2237) ? <ExportButton key="btn5" param={{...formRef.current?.getFieldsValue()}} btnText="全部导出" action={`${LQBASEURL}/batchQueryExport`} /> : null
            ],
        }}
    />)
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    return  <Tabs tabBarStyle={{background: '#fff', margin: 0, padding: '0 10px'}}>
                { hasAuthCode(2231) ? <TabPane tab="批量查询" key="1"><MuchQuery /></TabPane> : null } 
                { hasAuthCode(2232) ? <TabPane tab="查询记录" key="2"><QueryHistory /></TabPane> : null }
            </Tabs>
}