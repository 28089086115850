import React, { Key, useRef, useState, useEffect, Children, useMemo } from "react"
import { columnsFn, searchConfig, OtherConditions, searchTypeEnums, sortOptions } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Col, Form, Input, Modal, Row, Select, Tag, Tooltip, TreeSelect, AutoComplete } from "antd";
import { ExportButton, Icon, ProFormTreeSelect, SearchBox } from '@components/index'
import { productApi, isDef, ellipsis } from "@utils/index";
import { lqCompanyToCustomer, BASEURL, LQBASEURL, lqGetClueToJns, lqFuzzySearch, lqGetClueToPool, lqSearch } from '@api/index'
import { useHasAuthCode } from "@hooks/index";
import { AddPool, ClueToCustomerButton, GetCluesButton } from "@components/Customer/CluesPool";
import { SearchProps } from "antd/lib/input";
import { SearchBoxProps } from "@components/Common/SearchBox";
import { mapKeys, debounce } from "lodash";
import { asyncSetGetConsumeOrderProcess } from "@store/actions"
import { useDispatch } from "react-redux";
import { SelectProps } from 'antd/es/select';
import './index.less'
import moment from "moment";
import { mapValues } from "lodash";

const { Search } = Input;

const getApi = productApi(lqSearch)
const getSearch = productApi(lqFuzzySearch)
export const lqCompanyToCustomerApi = productApi(lqCompanyToCustomer)
export const lqGetClueToJnsApi = productApi(lqGetClueToJns)
export const lqGetClueToPoolApi = productApi(lqGetClueToPool)

interface SearchTypeProps extends SearchProps {
    onSearch?: (param: any) => void;
    onChange?: (param: any) => void;
}

const SearchType = ({onSearch, onChange, ...rest}: SearchTypeProps) => {
    const [value, setValue] = useState<any>({searchType: 1, keyWords: ''})
    const [loading, setLoading] = useState<boolean>(false)
    const [keyWordList, setKeyWordList] = useState<any[]>([])
    const calcPlaceholder = () => searchTypeEnums.find(v => v.value === value.searchType)?.placeholder
    const onOwnChange = async (keyWords: string) => {
        setLoading(true)
        const { data: keyWordList } = await getSearch({...value, keyWords}, false)
        setKeyWordList((keyWordList||[]).map((v: string) => ({label: v, value: v})))
        setValue({...value, keyWords})
        setLoading(false)
    }
    onChange && useEffect(() => {
        onChange(value)
    }, [value])
    useEffect(() => {
        setKeyWordList([])
    }, [value.searchType])
    return (<div className="search-type-box">
            <div>{searchTypeEnums.map(({label, value: searchType}) => <span key={searchType} onClick={() => {onSearch && onSearch({...value, searchType}); setValue({...value, searchType})}} className={`item ${value.searchType === searchType ? 'active' : ''}`}>{label}</span>)}</div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '14px'}}>
               <b style={{fontSize: '26px', color: '#fff', fontWeight: 400}}>猎奇</b>
                  {/* <Search placeholder={calcPlaceholder()} loading={loading} enterButton="查询" allowClear size="large" onSearch={(keyWords) => {onSearch && onSearch({...value, keyWords})}} {...rest} /> */}
               {/* </AutoComplete> */}
               <Input.Group compact style={{width: '60%',height: '48px', marginLeft: '16px'}} className='searchInput'>
                    <AutoComplete options={keyWordList} placeholder={calcPlaceholder()} onSelect={(keyWords: string) => {setValue({...value, keyWords}), onSearch && onSearch({...value, keyWords})}} style={{ width: 'calc(100% - 88px)'}} onChange={debounce(onOwnChange, 20)}/>
                    <Button onClick={() => onSearch && onSearch({...value})} type="primary">查询</Button>
               </Input.Group>
        </div>
    </div>)
}

interface LqSearchBoxProps extends SearchBoxProps {
    onSearch?: (params?: any) => void;
    onValuesChange?: (allValues: any) => void;
}

const LqSearchBox = ({ onSearch, onValuesChange: onPValuesChange, form: pform, ...rest }: LqSearchBoxProps) => {
    const [choosedObj, setChoosedObj] = useState<any>({})
    const [expand, setExpand] = useState<Boolean>(true)
    const [form] = Form.useForm(pform);
    const lqSearchConfig = searchConfig()
    const allConditions = [...lqSearchConfig, ...OtherConditions()]
    const productChooseObjByValues = (allValues: any) => {
        const choosedObj: Record<any, any> = {}
        Object.keys(allValues).map((key) => {
            const findKey = key?.startsWith('cust-') ? key.split('-')[1] : key
            let val = (isDef(allValues[key]) && !Array.isArray(allValues[key])) ? [allValues[key]] : allValues[key]
            const thatConfigObj = allConditions.find(v => v.name === findKey)
            const { text, name, options } = thatConfigObj || {}
            if (isDef(val) && val.length && text) {
                const isMoment = moment.isMoment(val[0])
                if (isMoment) {
                    val = val.map((v: any) => moment(v).format('YYYY-MM-DD'))
                }
                choosedObj[text] = {
                    param: key,
                    str: key?.startsWith('cust-') ? [val.join('-')] : val.map((id: any) => {
                        const thatObj = (options||[]).find((s: any) => s.value === id) || {}
                        return thatObj?.label || thatObj?.name
                    }),
                }
            }
        })
        return choosedObj
    }
    const onValuesChange = (values: any, allValues: any) => {
        const key = Object.keys(values)[0]
        // 解决自定义与原有条件不可并存问题
        if (key.startsWith('cust-')) {
            const key2 = key.split('-')[1]
            form.resetFields([key2])
        }
        if (`cust-${key}` in allValues) {
            form.resetFields([`cust-${key}`])
        }
        const choosedObj = productChooseObjByValues(form.getFieldsValue())
        setChoosedObj(choosedObj)
    }
    const choosedOnClose = (v: any) => {
        const {param} = v
        form.resetFields([param])
        const allValues = form.getFieldsValue()
        onValuesChange({[param]: ''}, allValues)
    }
    
    const clearForm = () => {
        form.resetFields()
        setChoosedObj({})
        onSearch && onSearch()
    }

    onPValuesChange && useEffect(() => {
        onPValuesChange(form.getFieldsValue())
    }, [choosedObj])

    return (<div className="lq-search-box">
        <Row justify="space-between" align="middle" style={{boxSizing: 'border-box', padding: '10px 20px', background: '#F7F8F9'}}>
            <Col><Icon type="icon-guolv" />&nbsp;高级筛选</Col>
            <Col onClick={() => setExpand(!expand)} style={{color: '#999', cursor: 'pointer'}}><span>{expand ? '收起' : '展开'}&nbsp;<Icon type={ expand ? "icon-shouqi" : 'icon-zhankai2' } /></span></Col>  
        </Row>
        {
            expand ? <SearchBox config={lqSearchConfig} form={form} onValuesChange={onValuesChange} onFinish={onSearch} colon={false} labelAlign="left" search={false} style={{boxSizing: 'border-box', padding: '0 20px'}} {...rest} /> : null
        }
        {
            Object.keys(choosedObj).length ? 
            <Row gutter={24} style={{boxSizing: 'border-box', padding: '15px 20px 20px', borderTop: '1px solid #F3F3F3'}}>
                <Col span={2}>已选条件</Col>
                <Col span={18} style={{lineHeight: '30px'}}>
                    {
                        Object.keys(choosedObj).map(v => {
                            return <Tag color="blue" closable onClose={() => choosedOnClose(choosedObj[v])} key={choosedObj[v].param}>{v}:{ellipsis(choosedObj[v].str.join(), 20)}</Tag>
                        })
                    }
                </Col>
                <Col span={4}>
                    <a onClick={clearForm}>清空所选</a>
                    <Button type="primary" style={{marginLeft: 15}} onClick={() => onSearch && onSearch()}>查询</Button>
                </Col>
            </Row> : null
        }
    </div>)
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const queryParam = useRef({searchType: 1, keyWords: ''})
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [esTotal, setEsTotal] = useState<number>(0)
    const dispatch = useDispatch();
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const newSorter = {} as any
        Object.keys(sorter).map(k => newSorter[k] = sorter[k].replace('end', ''))
        const { data } = await getApi({...params, ...queryParam.current, sorter: newSorter}, false)
        const { values, total, esTotal } = data || {}
        setEsTotal(esTotal||0)
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {}
    const columns = columnsFn(operate)

    const reFresh = () => {
        setTimeout(() => {
            ref.current?.reload()
            // setSelectedRowKeys([])
            // @ts-ignore
            ref.current?.clearSelected()
        }, 1000)
    }

    const addPoolOnFinish = async({cluePoolId}: any) => {
        const { success } = await lqGetClueToPoolApi({cluePoolId, ids: selectedRowKeys, module: 1}, true, reFresh) 
        return success
    }

    const onSearch = (params?: any) => {
        setQuery(params||{})
        // @ts-ignore
        ref.current?.setPageInfo({current: 1})
        ref.current?.reload()
    }

    const setQuery = (param?: any) => {
        let mergedQueryParam = {...queryParam.current, ...(param||{})}
        mergedQueryParam = mapValues(mergedQueryParam, (value: any) => {
            return moment.isMoment(value) ? moment(value).format('YYYY-MM-DD') : (Array.isArray(value) && moment.isMoment(value[0])) ? value.map((v: any) => moment(v).format('YYYY-MM-DD')) : value
        })
        queryParam.current = mapKeys(mergedQueryParam, (value: any, key: string) => {
            return key.replace(/\-(\w)/g, (all, letter) => letter.toUpperCase())
        })
        return queryParam.current
    }

    const calcDisabled = (str: string) => {
        return !selectedRowKeys.length
    }
    
    const onSuccess = () => {
        dispatch(asyncSetGetConsumeOrderProcess())
    }

    return <div className="lq-search" style={{boxSizing: 'border-box'}}>
            <SearchType onChange={setQuery} onSearch={() => onSearch({})} />
            <LqSearchBox onValuesChange={setQuery} onSearch={() => onSearch({})} />
            <ProTable<TableListItem>
                style={{marginTop: '16px'}}
                scroll={{x: 1600}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                onChange={(pagination, filters, sorter, extra) => {
                   if (extra?.action === 'sort') {
                      onSearch({})
                   }
                }}
                actionRef={ref as any}
                headerTitle={<h5 style={{fontSize: '14px'}}>企业库中共 <span style={{color: 'red'}}>{esTotal}</span> 家企业</h5>}
                search={false}
                toolbar={{
                    actions: [
                        hasAuthCode(2240) ? <AddPool key="bnt3" title="转移线索" trigger={<Button type="primary" disabled={calcDisabled('转移线索')}>转移线索</Button>} onFinish={addPoolOnFinish as any}/> : null,
                        hasAuthCode(2241) ? <GetCluesButton key="primary" disabled={calcDisabled('领取线索')} onOk={() => lqGetClueToJnsApi({ids: selectedRowKeys, module: 1}, true, reFresh)}>领取线索</GetCluesButton> : null,
                        hasAuthCode(2242) ? <ClueToCustomerButton key="btn1" disabled={calcDisabled('转为客户')} onOk={() => lqCompanyToCustomerApi({ids: selectedRowKeys, module: 1}, true, reFresh)}>转为客户</ClueToCustomerButton> : null,
                        hasAuthCode(2243) ? <ExportButton key="btn5" onSuccess={onSuccess} btnText="全部导出" param={() => ({...setQuery(), whetherPage: 0})} action={`${LQBASEURL}/enterpriseSearch/export`} /> : null,
                        <span key="byn6"><Icon type="icon-paixu" /><Select onChange={(val) => onSearch({sort: val})} bordered={false} options={sortOptions} placeholder="默认排序"></Select></span>
                    ],
                }}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                    getCheckboxProps: (record: TableListItem) => ({
                        disabled: !record.isReceive,
                    })
                }}
            />
        </div>
}
