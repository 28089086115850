import ProTable, { ActionType } from "@ant-design/pro-table"
import { BASEURL, oaTaskDetailCustomerList, oaTaskDetailProgress } from "@api/index"
import { BackBan } from "@components/Common"
import { AddFollowRecord, AddFollowRecordProps, customerHasBusinessApi } from "@components/Customer/List"
import { DescriptionsList, MyTabs } from "@components/EnterpriseDetail"
import { useCommonApiHooks } from "@hooks/index"
import { parseSearch, productApi } from "@utils/index"
import { Modal } from "antd"
import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import type { ProFormInstance } from '@ant-design/pro-form';
import { columnsCustomerCheckFn, columnsTaksProcessFn, TableListItem, taskDesList } from "./data"

const getProgressApi = productApi(oaTaskDetailProgress)
const getCustomerListApi = productApi(oaTaskDetailCustomerList)

const TaskProcess = ({id}: any) => {
    const ref = useRef<ActionType>(null);
    const getApi = async (params: any) => {
        const {data} = await getProgressApi(params, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }
    return(<ProTable<TableListItem>
        columns={columnsTaksProcessFn()}
        params={{taskCenterId: id}}
        request={getApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        search={{
            optionRender: (searchConfig, formProps, dom) => 
            [ ...dom.reverse() ],
        }}
        rowKey="id"
        actionRef={ref as any}
        pagination={{defaultPageSize: 10}}
        rowSelection={false}
    />
    )
}

const CustomerCheckDetail = ({id}: any) => {
    const ref = useRef<ActionType>(null);
    const history = useHistory()
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined, businessOppId: undefined})
    const getApi = async (params: any) => {
        const {data} = await getCustomerListApi(params, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const operate = async (action: any, record: any) => {
        const { customerId, customerName } = record || {}
        if (action === '添加跟进') {
            // const { data } = await customerHasBusinessApi({id: customerId}, false)
            // data ? Modal.confirm({
            //     title: '温馨提示',
            //     content: '此客户已创建了商机，请在商机中写跟进小记',
            //     okText: '前往',
            //     cancelText: '取消',
            //     onOk: async() => {
            //         history.push('/customer/business')
            //     },
            // })
            // : 
            setAddFollowProps({
                ...addFollowProps,
                visible: true,
                customerId,
                customerName,
                params: { from: '任务详情' },
                onSuccess: () => {
                    reFush()
                    setAddFollowProps({...addFollowProps, visible: false})
                }
            })
        }
    }

    const reFush = () => {
        ref.current?.reload()
    }

    return(<><ProTable<TableListItem>
        columns={columnsCustomerCheckFn(operate)}
        params={{taskCenterId: id}}
        request={getApi}
        options={{reload: false, density: false, setting: false}}
        toolbar={{
            actions: [],
        }}
        search={{
            optionRender: (searchConfig, formProps, dom) => 
            [ ...dom.reverse() ],
        }}
        rowKey="id"
        actionRef={ref as any}
        pagination={{defaultPageSize: 10}}
        rowSelection={false}
    />
    <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
    </>
    )
}

const tabPanes = (params: any) => [
    {
        Tab: '任务执行进度',
        Content: <TaskProcess {...params} />
    },
    {
        Tab: '客户盘点详情',
        Content: <CustomerCheckDetail {...params} />
    },
]

export const TaskDetail = ({id}: any) => {
    const [dataObj, ,getApi] = useCommonApiHooks({url: `${BASEURL}/taskCenter/getTaskById`, method: 'GET'}, {id}, true)
    return(<div className="oa-task-detail">
        <BackBan title={(dataObj||{}).taskName} />
        <DescriptionsList data={dataObj||{}} descList={taskDesList} />
        <MyTabs 
          tabBarStyle={{margin: 10, marginBottom: 0}} 
          style={{background: '#fff', marginTop: 20}} 
          tabPanes={tabPanes({id})} 
        />
    </div>)
}

export default () => {
    const history = useHistory() 
    const { search } = history.location
    const { id } = parseSearch(search)
    return <TaskDetail id={id} />
}