import React, { CSSProperties, useEffect, useRef, useState } from "react"
import { Badge, Button, Col, Input, Modal, Row, Table } from "antd"
import { newBusinessColumns, followCustomerColumns, attachmentColumnsFn, newCustomerColumns } from './data'
import AddReport from "../AddReport"
import '../../index.less'
import { TableListItem, reportStatus } from "../../data"
import { oaWorkReporPageStats, oaWorkReportDetailById, oaWorkReportApprove } from '@api/index'
import { productApi } from '@utils/util';
import { useHasAuthCode, useTableHooks } from "@hooks/index"
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { ModalForm, ProFormMoney, ProFormTextArea } from '@ant-design/pro-form';
import { Preview, SearchBox } from "@components/index"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"

type ReportDetailProps = {
    record?: TableListItem | null;
    range?: string;
    onSuccess?: () => void;
}
interface ApprovalProps extends TableListItem {
    titleStyle?: CSSProperties;
    onSuccess?: () => void;
}

type NewCustomerProps = {
    titleStyle?: CSSProperties;
    num?: number;
    text?: string;
    userId?: string;
    date?: any;
    title?: any
}

type NewBusinessProps = {
    titleStyle?: CSSProperties;
    total?: number;
    dataSource?: any[];
    userId?: string;
    date?: any;
    title?: any
}

interface HeaderProps extends TableListItem{
    onSuccess?: () => void;
}

const { TextArea } = Input
const getPageListApi = productApi(oaWorkReporPageStats)
export const getOaWorkReportDetailByIdApi = productApi(oaWorkReportDetailById)
const reportApproveApi = productApi(oaWorkReportApprove)

const Header = ({createrId, creator, id, dailyTime, title, time, status, parentName, createTime, reviewTime, todaySummary, tomorrowPlan, onSuccess, type }: HeaderProps ) => {
    const [, hasAuthCode] = useHasAuthCode()
    const { userInfo } = useSelector((state: StateType) => state) as {userMenuRoute: any; userInfo: any};
    const { id: currentLoginUserId } = (userInfo || {}).user || {}

    const calcHasEditAuth = () => {
        return hasAuthCode(2116) && ['1'].includes(status) && (currentLoginUserId === createrId)
    }

    return (<div className="header">
        <h3>{time} {title}</h3>
        <div className="infos">
            <span className="infos-item">状态: {(reportStatus[status]||{}).text}</span>
            {
                ['1', '2', '3'].includes(status) && <>
                   <span className="infos-item">提交人: {creator}</span>
                   <span className="infos-item">批阅人: {parentName}</span>
                   <span className="infos-item">提交时间: {dailyTime}</span>
                </>
            }
            {
                ['2', '3'].includes(status) && <>
                <span className="infos-item">批阅时间: {reviewTime}</span>
             </>
            }
        </div>
        <div className="time">
            {
                calcHasEditAuth() ? <AddReport title="编辑" reportId={id} type={type} onSuccess={() => onSuccess && onSuccess()} trigger={<Button key="编辑" type="primary" style={{marginRight: 12}}>编辑</Button>} /> : null
            }
           
        </div>
    </div>)
}

const NewCustomer = ({titleStyle, num, text, userId, date}: NewCustomerProps) => {
    const [visible, setVisible] = useState<boolean>(false)
    const {tableProps, fetchApi } = useTableHooks(getPageListApi, {userId, date, type: 1}, false)

    const seachConfig = [{ label: '客户名', name: 'key', rules: [], colProps: {span: 12}, Item: Input },]

    const onSearch = (values: any) => {
        fetchApi({userId, date, type: 1, ...values})
    }

    const onReset = () => {
        fetchApi({userId, date, type: 1})
    }

    useEffect(() => {
        visible && onSearch({})
    }, [visible])

    return (<div>
        <Row className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}} justify="space-between">
            <Col>新增客户<span>{num||0}</span></Col>
            <Col><a onClick={() => setVisible(true)}>详情</a></Col>
        </Row>
        <div style={{color: '#999999', boxSizing: 'border-box'}}>{text}</div>
        <Modal title="详情" maskClosable={false} width={800} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
            <SearchBox config={seachConfig} onFinish={onSearch} onReset={onReset} />
            <Table columns={newCustomerColumns} {...tableProps} rowKey="id" />
        </Modal>
    </div>)
}

const NewClues = ({titleStyle, num, text, userId, date}: NewCustomerProps) => {
    const [visible, setVisible] = useState<boolean>(false)
    const {tableProps, fetchApi } = useTableHooks(getPageListApi, {userId, date, type: 2}, false)

    const seachConfig = [{ label: '客户名', name: 'key', rules: [], colProps: {span: 12}, Item: Input },]

    const onSearch = (values: any) => {
        fetchApi({userId, date, type: 2, ...values})
    }
    const onReset = () => {
        fetchApi({userId, date, type: 2})
    }

    useEffect(() => {
        visible && onSearch({})
    }, [visible])

    return (<div>
        <Row className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}} justify="space-between">
            <Col>新增线索<span>{num||0}</span></Col>
            <Col><a onClick={() => setVisible(true)}>详情</a></Col>
        </Row>
        <div style={{color: '#999999', boxSizing: 'border-box'}}>{text}</div>
        <Modal maskClosable={false} title="详情" width={800} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
            <SearchBox config={seachConfig} onFinish={onSearch} onReset={onReset} />
            <Table columns={newCustomerColumns} {...tableProps} rowKey="id" />
        </Modal>
    </div>)
}

const NewBusiness = ({titleStyle, dataSource, total}: NewBusinessProps) => {
    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>新增商机<span>{total||0}</span></div>
        <Table columns={newBusinessColumns} dataSource={dataSource||[]} pagination={false} rowKey="id" />
    </div>)
}

const FollowCustomer = ({titleStyle, dataSource, total}: NewBusinessProps) => {
    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>跟进客户<span>{total||0}</span></div>
        <Table columns={followCustomerColumns} dataSource={dataSource||[]} pagination={false} rowKey="id" />
    </div>)
}

const TodaySummary = ({titleStyle, text, title}: NewCustomerProps) => {
    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>{title||'今日总结'}</div>
        <div style={{color: '#999999', boxSizing: 'border-box'}}>{text}</div>
    </div>)
}

const TomorrowPlan = ({titleStyle, text, title}: NewCustomerProps) => {
    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>{title||'明日计划'}</div>
        <div style={{color: '#999999', boxSizing: 'border-box'}}>{text}</div>
    </div>)
}

const Attachment = ({titleStyle, dataSource}: NewBusinessProps) => {
    const [previewProps, setPreviewProps] = useState({visible: false, src: undefined})
    const operate = (type: string, record: any) => {
       const { url: src } = record || {}
       if (type === '预览') {
          setPreviewProps({
            visible: true,
            src
          })
       }
    }
    const columns = attachmentColumnsFn(operate)
    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>附件</div>
        <Table columns={columns} dataSource={dataSource} pagination={false} rowKey="id" />
        <Preview {...previewProps} modalProps={{maskClosable: false, onCancel: () => setPreviewProps({...previewProps, visible: false})}}></Preview>
    </div>)
}

const Approval = ({titleStyle, status, review, id, parentUserId, onSuccess}: ApprovalProps) => {
    const formRef = useRef<ProFormInstance>()
    const [permissions, hasAuthCode] = useHasAuthCode()
    const { userInfo } = useSelector((state: StateType) => state)
    const { user } = userInfo || {}
    const { id: userId } = user || {}

    const onSubmit = async (status: string) => {
        const values = formRef.current?.getFieldsValue()
        await reportApproveApi({...values, status, id}, false, onSuccess as any)
    }

    const calcApprovalAuth = () => hasAuthCode(2122) && ['1'].includes(status) && (userId === parentUserId)

    const calcApprovalResultAuth  = () => ['1'].includes(status) && (userId !== parentUserId)

    return (<div>
        <div className="jns-ban-title" style={{marginBottom: '16px', ...titleStyle}}>批阅</div>
        {
            calcApprovalAuth() ? <ProForm formRef={formRef} submitter={{ render: (props, doms) => {
                return [
                  <Button key="but1" type="primary" onClick={() => onSubmit && onSubmit('2')}>合格</Button>,
                  <Button key="btn2" style={{marginLeft: 20}} onClick={() => onSubmit && onSubmit('3')}>不合格</Button>
                ];
            }}}>
                <ProFormTextArea name="review" fieldProps={{showCount: true, maxLength: 500 }} />
            </ProForm> : null
        }
        {
            !calcApprovalResultAuth() ? <Row style={{color: '#999999', boxSizing: 'border-box'}}>
                <Col>批阅结果:</Col><Col span={22}>{(reportStatus[status]||{}).text}</Col>
                <Col>批阅内容:</Col><Col span={22}>{review}</Col>
            </Row> : null
        }
    </div>)
}

const ReportDetail = ({record, range, onSuccess }: ReportDetailProps) => {
    const {tableProps: customerTableProps, fetchApi: customerFetchApi } = useTableHooks(getPageListApi, {}, false)
    const {tableProps: cluesTableProps, fetchApi: cluesFetchApi } = useTableHooks(getPageListApi, {}, false)
    const {tableProps: businessTableProps, fetchApi: businessFetchApi } = useTableHooks(getPageListApi, {}, false) 
    const {tableProps: followTableProps, fetchApi: followFetchApi } = useTableHooks(getPageListApi, {}, false) 

    const [detailObj, setDetailObj] = useState<any>({})
    const { userId, time: date, id, type } = record || {}

    const getDetailApi = async (param: any) => {
        await getOaWorkReportDetailByIdApi(param, false, (data: any) => {
            setDetailObj(data)
        })
    }

    const reFush = () => {
        customerFetchApi({userId, date, type: 1})
        cluesFetchApi({userId, date, type: 2})
        businessFetchApi({userId, date, type: 3})
        followFetchApi({userId, date, type: 4})
        id && getDetailApi({id})
    }

    useEffect(() => {
        reFush()
    }, [record])

    return <div className="report-detail">
        <div style={{borderBottom: '1px solid #E8E8E8'}}><Header {...(detailObj||{} as any)} onSuccess={() => { reFush(); onSuccess && onSuccess() }} /></div>
        <div style={{boxSizing: 'border-box', padding: 20, borderBottom: '1px solid #E8E8E8'}}>
            {
                detailObj?.canView ? <><NewCustomer num={customerTableProps.pagination.total} userId={(record||{}).userId} date={(record||{}).time} text={customerTableProps.dataSource.map((v: { name: string }) => v.name).toString()} />
                <NewClues  titleStyle={{marginTop: 30}} num={cluesTableProps.pagination.total} userId={(record||{}).userId} date={(record||{}).time} text={cluesTableProps.dataSource.map((v: { name: string }) => v.name).toString()} />
                <NewBusiness titleStyle={{marginTop: 30}} total={businessTableProps.pagination.total} dataSource={businessTableProps.dataSource} />
                <FollowCustomer titleStyle={{marginTop: 30}} total={followTableProps.pagination.total} dataSource={followTableProps.dataSource} />
                </> : null
            }
            {
                !['0'].includes((record||{}).status as string) && <>
                    <TodaySummary titleStyle={{marginTop: 30}} text={(detailObj||{}).todaySummary|| '暂无'} title={type == 2 ? '本周总结' : undefined} />
                    <TomorrowPlan titleStyle={{marginTop: 30}} text={(detailObj||{}).tomorrowPlan|| '暂无'} title={type == 2 ? '下周计划' : undefined} />
                    <Attachment titleStyle={{marginTop: 30}} dataSource={(detailObj||{}).enclosures} />
                </>
            }
        </div>
        {
            !['0'].includes((record||{}).status as string) && <div style={{boxSizing: 'border-box', padding: 20}}>
                <Approval {...(detailObj||{}) as any} onSuccess={() => { reFush(); onSuccess && onSuccess() }} />
            </div>
        }
    </div>
}

export default ReportDetail