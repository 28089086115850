import { ProColumns } from "@ant-design/pro-table"
import React from "react"
import { DataSourceRebateInfoType } from "../Ali/data"
import { toFixedNoRound } from "@utils/index"

export const rebateInfoColumnsFn: (operate: Function) => ProColumns<DataSourceRebateInfoType>[] = (operate) => {
    return [
        {
            title: '有无票据',
            dataIndex: 'hasPaper',
            editable: false,
            valueEnum: {
                0: { text: '无' },
                1: { text: '有'},
            },
            render: (text: any, record: any) => record?.hasPaper ? '有票据' : '无票据'
        },
        {
            title: '消费金额',
            dataIndex: 'consumeAmount',
            editable: false,
            render: (text: any, record: any) => toFixedNoRound(record.consumeAmount || 0)
        },
        {
            title: '抵扣退款金额',
            dataIndex: 'freeConsumeAmount',
            editable: false,
            render: (text: any, record: any) => toFixedNoRound(record.freeConsumeAmount || 0)
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>有无返点</span>,
            dataIndex: 'isRebate',
            valueType: 'select',
            fieldProps: { options: [{ label: '有', value: 1 }, { label: '无', value: 0 }] },
            // valueEnum: {
            //     0: { text: '无' },
            //     1: { text: '有'},
            // },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>返点比例</span>,
            dataIndex: 'rebatePoint',
            valueType: 'digit',
            formItemProps: { noStyle: true },
            fieldProps: (_: any, { entry }: any) => {
                const { isRebate, maxPoint } = entry || {}
                return {
                   disabled: isRebate === 0,
                   addonAfter: '%',
                   max: typeof maxPoint === 'number' ? maxPoint : 100
                }
            },
        },
        {
            title: '预计返点金额',
            dataIndex: 'expectRebateAmount',
            editable: false
        }
    ]
}
