import React, {  } from 'react'

import '../index.less'
export default ({dataList}:any) => {
    return <div>
        {
            dataList.map((element: { children: Array<any>; title: string }, index: number) => {
                const { children, title } = element
                const showChildren = (children && children.length > 0)
                return <div key={index} className='multiLabelDiv'>
                    <div className='labelTitle'>{title}:</div> 
                    <div className='labelBody'>
                        <div className='multiLabel'>
                            {showChildren ? children.map((text: any, key2) => {
                                return <div key={key2} className='labelName'>{text}</div>
                            }) : '-'}
                        </div>
                    </div>
                    
                </div>
            })
        }
    </div>
 }
