import { BASEURL, customerGetOriginalRecords, rebatePayeeInfoUpdateLog, rebatedDeductAmountInfo } from "@api/index"
import { useCommonApiHooks } from "@hooks/index"
import { productApi } from "@utils/index"
import { useState } from "react"
const rebatedDeductAmountInfoApi = productApi(rebatedDeductAmountInfo) // 复核信息详情

const getApiAps = productApi(customerGetOriginalRecords)
const getLogApi = productApi(rebatePayeeInfoUpdateLog)

export const useRebateDetailHooks = ({id}: any) => {
    const [refundOrderDataSource, setRefundOrderDataSource] = useState<any[]>([])
    const [summaryDataSource] = useCommonApiHooks({url: `${BASEURL}/rebates/getCollectInfo`, method: 'GET'}, {orderId: id}, true)
    const [rebateInfoDataSource] = useCommonApiHooks({url: `${BASEURL}/rebates/getRebatesInformation`, method: 'GET'}, {orderId: id}, true)
    const [collectionInfoDataSource] = useCommonApiHooks({url: `${BASEURL}/rebates/getPayeeInformation`, method: 'GET'}, {orderId: id}, true)
    const [remarksInfoDataSource] = useCommonApiHooks({url: `${BASEURL}/rebates/getRemarkInfo`, method: 'GET'}, {orderId: id}, true)
    const [voucherDataSource] = useCommonApiHooks({url: `${BASEURL}/rebates/voucher`, method: 'GET'}, {orderId: id}, true)
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApiAps({...params, orderId: id}, false)
        const { data: resData } = await rebatedDeductAmountInfoApi({...({id})}, false)
        const { confirmRefundOrderResponses: refundOrderDataSource } = resData || {};
        const { values, total } = data || {}
        setRefundOrderDataSource(refundOrderDataSource||[])
        return {
            data: values,
            success: true,
            total
        }
    }
    const getOperateLogApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getLogApi({...params, orderId: id}) as any
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }
    return {
        refundOrderDataSource,
        summaryDataSource,
        rebateInfoDataSource,
        collectionInfoDataSource,
        remarksInfoDataSource,
        voucherDataSource,
        getListApi,
        getOperateLogApi
    }
}