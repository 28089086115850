import axios from 'axios';
import { RequestOptions } from './requestInterface';
import successHandler from './successHandler';
import { requestError } from './errorHandler';
import { message } from 'antd'
import { getStorage } from '../util';
import { stringify } from 'qs';

const server = axios.create({
    baseURL: '',
    withCredentials: true,
    timeout: 360000, // 请求超时时间
})

// 添加请求拦截器
server.interceptors.request.use(
    (config: any) => config,
    (error: any) => Promise.reject(error)
);

// 添加响应拦截器
server.interceptors.response.use(
    (response: any) => {
        const { data } = response
        response.data = Object.assign({}, {code: 200}, data)
        return Promise.resolve(response)
    },
    (error: any) => {
        const {data} = error.response || {};
        message.error(`接口响应错误: ${JSON.stringify(data)}`);
        return Promise.reject(error)
    }
)

export default function request<T>(options: RequestOptions): Promise<T>{
    const { url, headers = {}, data, ...rest } = options
    const newOptions: Record<string, any> = {
        credentials: 'include',
        method: 'POST',
        headers: new Headers( headers || {Accept: 'application/json'} ),
        data,
        ...rest
    }

    if (['POST', 'PUT'].includes(newOptions.method)) {
        if (!(newOptions.body instanceof FormData)) {
            newOptions.headers = Object.assign({
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            }, headers)
        } else {
            newOptions.headers = Object.assign({
                Accept: 'application/json',
            },headers)
        }
    }
    const requestUrl = !['GET'].includes(newOptions.method) ? url : `${url}?${stringify(data)}`
    getStorage('Admin-Token') && Object.assign(newOptions.headers, {
        Authorization: 'Bearer ' + getStorage('Admin-Token')
    })
    return server(requestUrl as string, newOptions)
           .then((response: any) => response.data )
           .then((source: RequestOptions) => successHandler(source, newOptions))
           .catch((error: any) => requestError(error, newOptions))
}
