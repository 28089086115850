import React, { useEffect, useState } from "react"
import { BackBan } from "@components/Common"
import { parseSearch, productApi } from "@utils/util"
import { useHistory, useLocation } from "react-router-dom"
import { oaGetMessageUpdateMsgStatus, oaGetSystemNoticeDetails } from "@api/index"
import './index.less'
import { asyncSetMessageNumber } from "@store/actions/common"
import { useDispatch } from "react-redux"
import { TaskDetail } from '../../../TaskCenter/cpnts/TaskDetail'
import { SaleContractDetail } from '@components/Finance/Contract/cpnts/SaleContract/cpnts/SaleContractDetail';
import { PurchaseContractDetail } from '@components/Finance/Contract/cpnts/PurchaseContract/cpnts/PurchaseContractDetail';
import { RebackDetail } from '@components/Finance/RebackMoney/cpnts/RebackList/cpnts/RebackDetail';
import { stringify } from "qs"


/**
 * msgType: 1 开头的为业务消息，2开头的为系统公告 
 *   1.1: 是任务, 
 *   1.2: 是公告, 
 *   1.3: 【合同管理】销售合同详情
 *   1.4: 【合同审批】销售合同详情
 *   1.5: 【合同管理】采购合同详情
 *   1.6: 【合同审批】采购合同详情
 *   1.7: 【回款管理】回款详情
 *   1.8: 【回款审批】回款详情
 *   1.9: 【客户列表】客户详情
 *   2: 为系统公告 
*/

const getApiDetails = productApi(oaGetSystemNoticeDetails)

// 消息详情
type MsgDetailProps = {
    messageData: any;
    title?: any
}
const MsgDetail = ({messageData, title}: MsgDetailProps) => {
    return(<div className="MessageDetailMain">
        <BackBan title={title} style={{marginBottom: 20}} />
        <div className="MessageDetailBody">
            <div className="messageTitle">
                <div className="title1">{messageData.msgTitle}</div>
                <div className="title2">
                    <span>发布人：{messageData.publisher}</span>
                    <span>发布时间： {messageData.publishTime}</span>
                </div>
                <div className="lineDiv"></div>
            </div>
            <div className="messageBody"  dangerouslySetInnerHTML={{ __html: messageData.content }} ></div>
        </div>
    </div>)
}

export default () => {
    const [messageData, setMessageData] = useState<any>({})
    const dispatch = useDispatch();
    const history = useHistory()
    const { search } = useLocation()
    const { id, msgId, msgType, customerName } = parseSearch(search)
    const getMessage = async () => {
        const { data, success } = await getApiDetails({id: Number(msgId), msgType: Number(msgType)}, false, )
        success && setMessageData(data)
    }
    useEffect(() => {
        ['1.2', '2'].includes(msgType) && getMessage()
        oaGetMessageUpdateMsgStatus({ids: [id]})
        setTimeout(() => {
           dispatch(asyncSetMessageNumber())
        }, 500);
    }, [msgId])

    switch (msgType) {
        case '1.1':
            return <TaskDetail id={msgId} />
        case '1.3':
            return <SaleContractDetail contractId={msgId} fromPath="/finance/contract" />
        case '1.4':
            return <SaleContractDetail contractId={msgId} fromPath="/finance/contractAudit" />
        case '1.5':
            return <PurchaseContractDetail contractId={msgId} fromPath="/finance/contract" />
        case '1.6':
            return <PurchaseContractDetail contractId={msgId} fromPath="/finance/contractAudit" />
        case '1.7':
            return <RebackDetail backId={msgId} fromPath="/finance/rebackMoney" />
        case '1.8':
            return <RebackDetail backId={msgId} fromPath="/finance/rebackMoneyAudit" />
        case '1.9': 
            history.replace(`/customer/detail?${stringify({id: msgId, name: customerName, type: 0, routeName: '消息详情', fromPath: '/oa/myMessage'})}`)
            // return <CustomerDetail />
        default:
            return <MsgDetail messageData={messageData} title={msgType === '1.2' ? '批阅消息' : '公告详情'}/>
    }

    // return msgType === '1.1' ? <TaskDetail id={id} /> : <MsgDetail messageData={messageData} title={msgType === '1.2' ? '批阅消息' : '公告详情'}/>
}
