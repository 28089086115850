import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Button, Dropdown, Menu, Progress, Space, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { OperateActionType, StatusDot, TableListItem, signOptions, statusOptions, textOptions } from "../../data"
import { contractAuditStatus } from "@api/index"

const A = ({children, ...rest}: any) => <a {...rest}>{children}</a>

export const SaleContractOperate = ({record, type, operate}: any) => {
  const [, hasAuthCode] = useHasAuthCode()
  const Btn = type === 'primary' ? Button : A
  const style = { marginLeft: 10, whiteSpace: 'nowrap'} as any
  return ([
    (hasAuthCode(2447) && [1, -2, -4].includes(record.status)) ? <Btn type={type} style={style} key="编辑" onClick={() => operate && operate('销售合同-编辑', record)}>编辑</Btn> : null,
    (hasAuthCode(2449) && [1].includes(record.status)) ? <Btn type={type} style={style} key="撤销申请" onClick={() => operate && operate('销售合同-撤销申请', record)}>撤销申请</Btn> : null,
    (hasAuthCode(2450)) ? <Btn type={type} style={style} key="一键申请" onClick={() => operate && operate('销售合同-一键申请', record)}>一键申请</Btn> : null,
    <Dropdown key="更多" overlay={<Menu items={([
      {
        label: <a style={{'whiteSpace': 'nowrap'}} key="回款申请" onClick={() => operate && operate('销售合同-回款申请', record)}>回款申请</a>,
        key: '0',
        auth: hasAuthCode(-1)
      },
      {
        label: <a style={{'whiteSpace': 'nowrap'}} key="发票申请" onClick={() => operate && operate('销售合同-发票申请', record)}>发票申请</a>,
        key: '1',
        auth: hasAuthCode(-1)
      }
    ].filter(({auth}) => auth) as any[])}
  >
  </Menu>}>
    <Btn style={style} onClick={e => e.preventDefault()}><Space>更多</Space></Btn>
  </Dropdown>,
  ])
}

export const columnsFn: (operate: (type: OperateActionType, record?: any) => void, activeKey: any) => ProColumns<TableListItem>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: '系统编号',
        dataIndex: 'sysNumber',
        fixed: 'left',
        order: 15,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '合同编号' : '销售合同编号',
        dataIndex: 'xsContractNumber',
        fieldProps: { placeholder: '请输入销售合同编号'},
        order: 14,
        fixed: 'left',
        align: 'center',
        render: (text: any, record: any) => <Link to={`/finance/saleContractDetail?${stringify({contractId: record?.contractId, fromPath: '/finance/contract'})}`} >{record.xsContractNumber}</Link>
      },
      {
        title: (_, type) => type === 'form' ? '合同名称' : '销售合同名称',
        dataIndex: 'xsContractName',
        fieldProps: { placeholder: '请输入销售合同名称'},
        order: 13,
        align: 'center',
        search: {
          transform: (contractName) => {
            return { contractName }
          },
        },
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 12,
        align: 'center',
      },
      {
        title: '业务类型',
        dataIndex: 'businessTypeStr',
        order: 11,
        valueType: 'select',
        align: 'center',
        fieldProps: { mode: 'multiple' },
        params: { dictId: 269 },
        request,
        search: {
          transform: (businessTypes) => {
            return { businessTypes }
          },
        },
        render: (text: any, record: any) => record.businessTypeStr
      },
      {
        title: (_, type) => type === 'form' ? '有无文本' : '有无文本合同',
        dataIndex: 'haveText',
        order: 1,
        align: 'center',
        fieldProps: { options: textOptions,placeholder: '请选择有无文本合同' },
        valueType: 'select',
        // render: (text: any, record: any) => record.haveTextStr
      },
      {
        title: (_, type) => type === 'form' ? '合同金额' : '合同金额(元)',
        dataIndex: 'contractAmount',
        order: 8,
        sorter: true,
        align: 'center',
        search: {
          transform: (contractAmounts) => {
            return { contractAmounts }
          },
        },
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.contractAmount)
      },
      {
        title: (_, type) => type === 'form' ? '回款比例' : '合同回款比例',
        dataIndex: 'backRate',
        sorter: true,
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => text + '%'
      },
      {
        title: '优惠折扣率',
        dataIndex: 'discountRate',
        sorter: true,
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => text + '%'
      },
      {
        title: '签约日期',
        dataIndex: 'signDate',
        sorter: true,
        hideInSearch: true,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '是否盖章' : '合同是否盖章',
        dataIndex: 'whetherStamp',
        order: 2,
        align: 'center',
        fieldProps: { options: signOptions, placeholder: '请选择合同是否盖章'  },
        valueType: 'select',
        // render: (text: any, record: any) => `${(record.whetherStampStr||[]).toString()}`
      },
      {
        title: '备注',
        dataIndex: 'remark',
        // order: 8,
        hideInSearch: true,
        align: 'center',
        render: (text: any, record: any) => <div style={{width: 300, }}>{record.remark}</div>
      },
      {
        title: '审批状态',
        dataIndex: 'status',
        order: 16,
        align: 'center',
        fieldProps: { options: statusOptions, mode: 'multiple' },
        request: async () => {
          const { data } = await contractAuditStatus({type: 1}) as any
          return data||[]
        },
        valueType: 'select',
        render: (text: any, record: any) => <><StatusDot status={record.status} />{`${(record.statusStr||[]).toString()}`}</>
      },
      {
        title: '提交人',
        dataIndex: 'creatorStr',
        order: 10,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userId = (ids||[]).map((v: string) => v.slice(1))
            return { creators: userId }
          },
        },
      },
      {
        title: '提交时间',
        dataIndex: 'createTime',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        order: 3,
        sorter: true,
        align: 'center',
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {createTimes: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.createTime||''}`
      },
      {
        title: '当前审批人',
        dataIndex: 'currentAuditPerson',
        order: 9,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userId = (ids||[]).map((v: string) => v.slice(1))
            return { currentAuditPersons: userId }
          },
        },
        render: (text: any, record: any) => `${(record.currentAuditPersonStr||[]).toString()}`
      },
      {
        title: (_, type) => type === 'form' ? '已回款金额' : '已回款金额(元)',
        dataIndex: 'backedAmount',
        order: 7,
        sorter: true,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.backedAmount),
        search: {
          transform: (backedAmounts) => {
            return { backedAmounts }
          },
        },
      },
      {
        title: (_, type) => type === 'form' ? '未回款金额' : '未回款金额(元)',
        dataIndex: 'noBackAmount',
        order: 6,
        sorter: true,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.noBackAmount),
        search: {
          transform: (noBackAmounts) => {
            return { noBackAmounts }
          },
        },
      },
      {
        title: (_, type) => type === 'form' ? '已开票金额' : '已开票金额(元)',
        dataIndex: 'openedAmount',
        order: 5,
        sorter: true,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.openedAmount),
        search: {
          transform: (openedAmounts) => {
            return { openedAmounts }
          },
        },
      },
      {
        title: (_, type) => type === 'form' ? '未开票金额' : '未开票金额(元)',
        dataIndex: 'noOpenAmount',
        order: 4,
        sorter: true,
        align: 'center',
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        render: (text: any, record: any) => amountFormat(record.noOpenAmount),
        search: {
          transform: (noOpenAmounts) => {
            return { noOpenAmounts }
          },
        },
      },
      {
        title: '操作',
        key: 'option',
        dataIndex: 'option',
        valueType: 'option',
        width: 210,
        fixed: 'right',
        // @ts-ignore
        render: (text: any, record: any) => <SaleContractOperate record={record} operate={operate} />,
      },
    ]
}