import { useRef, useState } from "react";
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { ActionType } from "@ant-design/pro-table";
import { useDispatch } from "react-redux";
import { useOperateHooks, OperateActionType } from "../hooks";
import { CLOUD_VENDOR, acceptExcel, getCheckedColumnsStateByColumns, productApi } from "@utils/index";
import { BASEURL, rebateList } from "@api/index";
import React from "react";
import { ExportButton, ImportButton, ProTableWithFormSetting, ViewScope } from "@components/index";
import { Alert, Button, Dropdown, Menu, Table } from "antd";
import { Claim, ClaimAudit, ClaimAuditDetail, ConfirmPay, Recover, RecoverAudit, ReviewInfo } from "../Ali";
import { asyncSetGetConsumeOrderProcess } from "@store/actions";
import { TableListItem, scopeOptions, sonTableColumnsFn2, viewOptions } from "../Ali/data";
import { columnsFn } from "./data";
import { HuaWeiReviewInfo } from "../HuaWei";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";

const listPageApi = productApi(rebateList) // 分页列表
const persistenceKey = 'customerRebate/list'

// SonTable
export const SonTable = (props: any) => {
    const { rebatesMessageRespList, proceedsMessageRespList } = props || {}
    return (<div style={{background: '#FAFAFA'}}>
       {/* <Table columns={sonTableColumnsFn1()} dataSource={rebatesMessageRespList||[]} pagination={false} rowKey="id" /> */}
       <Table columns={sonTableColumnsFn2()} dataSource={proceedsMessageRespList||[]} pagination={false} rowKey="id" />
    </div>)
}

export default () => {
    const [view, setView] = useState<string>('')
    const [scope, setScope] = useState<string>('1')

    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    const dispatch = useDispatch();

    const {
        history,
        hasAuthCode,
        downLoadUrlMap,
        selectedRowKeys,
        setSelectedRowKeys,
        calcDisabled,
        recoverProps,
        setRecoverProps,
        recoverAuditProps,
        setRecoverAuditProps,
        claimProps,
        setClaimProps,
        claimAuditProps,
        setClaimAuditProps,
        claimAuditDetailProps,
        setClaimAuditDetailProps,
        confirmPayProps,
        setConfirmPayProps,
        reviewInfoProps,
        setReviewInfoProps,
        createAction,
        createOperate
    } = useOperateHooks(CLOUD_VENDOR.TENCENT)

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await listPageApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values,
            total,
            success: true,
        }
    }
    const customerViewOnChange = (val: string, type: any) => type === 'view' ? setView(val) : setScope(val)
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const action = createOperate(record, reFush)
        action(type, {ids: selectedRowKeys})
    }

    const columns = columnsFn(scope, operate)

    const params = {view, scope, orderSource: CLOUD_VENDOR.TENCENT}

    const getQueryParams = () => ({
        // @ts-ignore
        ...formRef.current?.getFieldsFormatValue(), 
        ...params
    })

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params
        })
    }

    const reFush = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }
    const onSuccess = () => {
        dispatch(asyncSetGetConsumeOrderProcess())
    }
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ViewScope
                viewOptions={viewOptions}
                scopeOptions={scopeOptions}
                onChange={customerViewOnChange}
            />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 2400}}
                scroll={{x: 'max-content'}}
                columns={columns}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                expandable={{
                    // childrenColumnName: 'mouth',
                    expandedRowRender: (props: any) => <SonTable {...props} />
                }}
                toolbar={{
                    actions: ['1'].includes(scope) ? [
                        hasAuthCode(2264) ? <Button key="btn1" type="primary" danger onClick={() => operate && operate('截至确认')}>截止确认</Button> : null,
                        <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                            { hasAuthCode(2294) ? <Menu.Item key="btn2">
                                <ImportButton action={`${BASEURL}/rebates/importExcelConsumeOrder`} accept={acceptExcel} params={{orderSource: CLOUD_VENDOR.TENCENT}} templateUrl={downLoadUrlMap['返点列表-导入腾讯云消费订单']} onSuccess={onSuccess} title="导入消费订单" trigger={<a>导入消费订单</a>}>
                                  <Alert message="提示：每月只能导入一次消费订单，请谨慎操作" type="warning" showIcon style={{marginBottom: '10px'}} />
                                </ImportButton>
                            </Menu.Item> : null }
                            {/* { hasAuthCode(2301) ? <Menu.Item key="btn3"><ImportButton params={{orderSource: CLOUD_VENDOR.TENCENT}}  action={`${BASEURL}/rebates/importExcelSpecialRebate`} templateUrl={downLoadUrlMap['返点列表-导入腾讯云特殊返点']} title="导入特殊返点" trigger={<a>导入特殊返点</a>}></ImportButton></Menu.Item> : null } */}
                            { hasAuthCode(2261) ? <Menu.Item key="btn4"><ImportButton params={{orderSource: CLOUD_VENDOR.TENCENT}} accept={acceptExcel}  action={`${BASEURL}/rebates/importPayOrders`} templateUrl={downLoadUrlMap['返点列表-导入打款表']} title="导入打款表" trigger={<a>导入打款表</a>}></ImportButton></Menu.Item> : null }
                            { hasAuthCode(2302) ? <Menu.Item key="btn5"><ImportButton params={{orderSource: CLOUD_VENDOR.TENCENT}} accept={acceptExcel}  action={`${BASEURL}/rebates/importReviewOrder`} title="导入复核表" trigger={<a>导入复核表</a>}></ImportButton></Menu.Item> : null}
                        </Menu>}>
                            <Button>更多操作</Button>
                        </Dropdown>,
                        // <Button key="btn6" disabled={calcDisabled()} onClick={() => operate && operate('批量确认信息')}>确认信息</Button>,
                        hasAuthCode(2259) ? <Button key="btn7" disabled={calcDisabled()} onClick={() => operate && operate('批量复核信息')}>复核信息</Button> : null,
                        hasAuthCode(2262) ? <Button key="btn8" disabled={calcDisabled()} onClick={() => operate && operate('批量撤销')}>批量撤销</Button> : null,
                        hasAuthCode(2260) ? <ExportButton key="btn9" btnText="导出待打款表" param={{orderSource: CLOUD_VENDOR.TENCENT, scope}}  action={`${BASEURL}/rebates/exportPay`} /> : null,
                        // hasAuthCode(2291) ? <ExportButton key="btn10" onSuccess={onSuccess} btnText="全部导出" param={getQueryParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                        hasAuthCode(2291) ? <ExportButtonWithBatch key="btn10" onSuccess={onSuccess} disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                    ] : [
                        hasAuthCode(2260) ? <ExportButton key="btn9" btnText="导出待打款表" param={{orderSource: CLOUD_VENDOR.TENCENT, scope}}  action={`${BASEURL}/rebates/exportPay`} /> : null,
                        hasAuthCode(2261) ? <ImportButton key="btn1" title="导入打款表" params={{orderSource: CLOUD_VENDOR.TENCENT}} accept={acceptExcel}  action={`${BASEURL}/rebates/importPayOrders`} templateUrl={downLoadUrlMap['返点列表-导入打款表']} trigger={<Button>导入打款表</Button>}></ImportButton> : null,
                        // hasAuthCode(2291) ? <ExportButton key="btn2" onSuccess={onSuccess} btnText="全部导出" param={getQueryParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null,
                        hasAuthCode(2291) ? <ExportButtonWithBatch key="btn10" onSuccess={onSuccess} disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/rebates/exportAllOrders`} /> : null
                    ],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10, showSizeChanger: true}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {setSelectedRowKeys(selectedRowKeys)},
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage'}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
            />
            <Recover modalProps={{onCancel: () => setRecoverProps({...recoverProps, visible: false}), maskClosable: false}} {...recoverProps} />
            <RecoverAudit modalProps={{onCancel: () => setRecoverAuditProps({...recoverAuditProps, visible: false}), maskClosable: false}} {...recoverAuditProps} />
            <Claim cloudVendor={CLOUD_VENDOR.TENCENT} modalProps={{onCancel: () => setClaimProps({...claimProps, visible: false}), maskClosable: false}} {...claimProps} />
            <ClaimAudit modalProps={{onCancel: () => setClaimAuditProps({...claimAuditProps, visible: false}), maskClosable: false, destroyOnClose: true}} {...claimAuditProps} />
            <ClaimAuditDetail modalProps={{onCancel: () => setClaimAuditDetailProps({...claimAuditDetailProps, visible: false}), maskClosable: false}} {...claimAuditDetailProps} />
            <ConfirmPay modalProps={{onCancel: () => setConfirmPayProps({...confirmPayProps, visible: false}), maskClosable: false}} {...confirmPayProps} />
            <HuaWeiReviewInfo modalProps={{onCancel: () => setReviewInfoProps({...reviewInfoProps, visible: false}), maskClosable: false}} {...reviewInfoProps} />
        </div>
    </div>
}
