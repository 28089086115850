import React, { useEffect, useRef, useState } from "react"
import { BaseTable, DescriptionsList } from '@components/EnterpriseDetail'
import { baseInfoDesList, refundOrdersColumnsFn, rebateInfoColumnsFn, getMoneyColumnsFn, DataSourceRebateInfoType, DataSourceGetMoneyType, deductionColumns, sonTableColumns, rulesTableList } from './data'
import { Button, Form, Input, message, Modal, Radio, Row, Space, Table, TableProps, Tooltip, Image, Col } from "antd"
import ProTable, { EditableProTable } from "@ant-design/pro-table"
import ProForm, { ProFormText, ProFormTextArea, ProFormDependency, ProFormSelect, ProFormUploadButton, ProFormRadio, ModalForm, ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { BackBan, Icon, ProFormWithCache } from "@components/index"
import { BASEURL, customerGetRebatesRule, rebateCalc, rebateConfirmInfo, rebateConfirmInfoSubmit, rebateGetMoneyAdd, rebateGetMoneyDel, rebateInfoAdd, rebateInfoDel, rebateSure } from "@api/index"
import { getToken, handleUploadFiles, isImageFile, parseSearch, productApi, toFixedNoRound } from "@utils/index"
import { EditableProTableProps } from "@ant-design/pro-table/lib/components/EditableTable"
import { useLocation } from "react-router"
import { useCommonApiHooks, useDictEnumHooks, useFormDataCache } from "@hooks/index"
import { PreviewProps } from "@components/Common/Preview"
import { useHistory } from "react-router-dom"
import { isFunction } from 'lodash';
import { OperateActionType, useOperateHooks } from "../hooks"

export const sum = (data: any[], param: string) => toFixedNoRound((data||[]).map((v: any) => (v||{})[param]).reduce((a: number, b: number) => (a||0)*1 + (b||0)*1, 0))

const rebateInfoAddApi = productApi(rebateInfoAdd)
const sureApi = productApi(rebateConfirmInfoSubmit)
const confirmInfoApi = productApi(rebateConfirmInfo)
const getRulesApi = productApi(customerGetRebatesRule)

export const RefundOrderSummary = ({data}: any) => {
  return (<Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}>{sum(data, 'consumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={3}>{sum(data, 'freeConsumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={4}>{sum(data, 'backConsumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={5}>{sum(data, 'restConsumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={5}></Table.Summary.Cell>
      </Table.Summary.Row>
  </Table.Summary>)
}

const RebateInfoSummary = ({data}: any) => {
  return (<Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}>{sum(data, 'consumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={3}>{sum(data, 'freeConsumeAmount')}</Table.Summary.Cell>
        <Table.Summary.Cell index={4}></Table.Summary.Cell>
        <Table.Summary.Cell index={5}></Table.Summary.Cell>
        <Table.Summary.Cell index={6}>{sum(data, 'expectRebateAmount')}</Table.Summary.Cell>
        {/* <Table.Summary.Cell index={7}>{sum(data, 'realRebateAmount')}</Table.Summary.Cell> */}
        <Table.Summary.Cell index={8}></Table.Summary.Cell>
      </Table.Summary.Row>
  </Table.Summary>)
}

const GetMoneySummary = ({data}: any) => {
  return (<Table.Summary fixed>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
        <Table.Summary.Cell index={1}></Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}>{sum(data, 'expectRebateAmount')}</Table.Summary.Cell>
        {/* <Table.Summary.Cell index={4}>{sum(data, 'realRebateAmount')}</Table.Summary.Cell> */}
        <Table.Summary.Cell index={5}></Table.Summary.Cell>
      </Table.Summary.Row>
  </Table.Summary>)
}

interface RefundOrdersProps extends TableProps<any> {
  
}
export const RefundOrders = ({dataSource, ...rest}: RefundOrdersProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const operate = (type: string, record: any) => {
    if (type === '已抵扣订单') {
      setVisible(true)
    }
  }
  return (<>
     <Table 
        columns={refundOrdersColumnsFn(operate)} 
        summary={(dataSource) => <RefundOrderSummary data={dataSource} />}
        expandable={{
          childrenColumnName: 'month',
          expandedRowRender: (props) => <Table columns={sonTableColumns()} dataSource={props?.payeeInfoOfRefunds||[]} rowKey="id" pagination={false} />
        }}
        pagination={false} 
        rowKey="id"
        style={{boxSizing: 'border-box', padding: 20}} 
        dataSource={dataSource}
        {...rest}
      />
      <ModalForm<any> modalProps={{maskClosable: false, onCancel: () => setVisible(false)}} width={1200} title="已抵扣订单" visible={visible}  >
        <Table columns={deductionColumns()} dataSource={(dataSource||{} as any).confirmMinusOrderDetailResponses || []} />
      </ModalForm>
  </>)
}
// 添加返点信息
export interface AddRebateInfoProps extends ModalFormProps{
  params?: any;
  queryParams?: Record<string, any>;
  disabled?: boolean;
  onSuccess?: (data: any) => void;
}
const AddRebateInfo = ({params, queryParams, onSuccess, ...rest}: AddRebateInfoProps) => {
  // 获取子维度
  const [options] = useCommonApiHooks({url: `${BASEURL}/product/getAliOneProductCategory`}, {...(queryParams||{})}, true)
  const formRef = useRef<ProFormInstance>()
  const onFinish = async (values: any) => {
    const { list } = params
    const { success } = await rebateInfoAddApi({list: [...list, values]}, true, onSuccess)
    return success
  }
  const onVisibleChange = (visible: boolean) => {
     if (visible) {
       formRef.current?.resetFields()
     }
  }
  const calcDisabledOptions = () => {
    const { list } = params || {}
    const sonDimensionIds = (list||[]).map((v: any) => v.sonDimensionId)
    return options.map(({id: value, name: label}: any) => ({label, value, disabled: sonDimensionIds.includes(value)}))
    // return options.map(({id: value, name: label}: any) => ({label, value}))
  }
  return (<ModalForm<AddRebateInfoProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 5}} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
  <ProFormText label="主维度" name="mainDimension" disabled />
  <ProFormSelect label="子维度" name="sonDimensionId" showSearch options={calcDisabledOptions()} rules={[{required: true, message: '请选择子维度'}]} />
</ModalForm>)
}

export interface RebateInfoTableProps extends EditableProTableProps<DataSourceRebateInfoType, any>{
  form?: any;
  onValuesChange?: (...args: any) => void;
  operate?: (type: OperateActionType, record: any) => void;
}
// 计算返点金额 expectRebateAmount 字断值
const calcExpectRebateAmount = (record: DataSourceRebateInfoType, hasPaper: boolean) => {
  const {rebatePoint, consumeAmount, freeConsumeAmount} = record || {}
  if (hasPaper) { // 若有票据: (消费金额 - 抵扣款金额) * 返点比例
     return toFixedNoRound(((consumeAmount - freeConsumeAmount) * (rebatePoint/100)||0))
  } else { // 若无票据: (消费金额 - 抵扣款金额) * 返点比例 * 90%
     return toFixedNoRound(((consumeAmount - freeConsumeAmount) * (rebatePoint/100) * 0.9 || 0))
  }
}

// 重新计算列表, 返回新列表
export const calcNewRecord = ({id, isRebate, rebatePoint, ...rest}: DataSourceRebateInfoType, allRecord: DataSourceRebateInfoType[], hasPaper: boolean) => {
  const idx = allRecord.findIndex(v => v.id === id)
  const newRecord = {...rest, isRebate, id, rebatePoint: isRebate+'' === '0' ? 0 : rebatePoint, hasPaper}
  const newExpectRebateAmount = calcExpectRebateAmount(newRecord, hasPaper)
  Object.assign(newRecord, {
    expectRebateAmount: newExpectRebateAmount,
  })
  allRecord[idx] = newRecord
  return allRecord
}

export const RebateInfoTable = ({value, onValuesChange, onChange, form, operate, ...rest}: RebateInfoTableProps) => {
  return (<>
    <EditableProTable<DataSourceRebateInfoType>
        rowKey="id"
        value={value}
        summary={(dataSource) => <RebateInfoSummary data={dataSource} />}
        recordCreatorProps={false}
        controlled
        editable={{
          form,
          type: 'multiple',
          deleteText: null,
          editableKeys: (value||[]).map(v => v.id),
          actionRender: (row, config, defaultDoms) => {
            return [defaultDoms.delete];
          },
          onValuesChange
        }}
        {...rest}
    />
 </>)
}

export interface AddGetMoneyProps extends ModalFormProps{
  params?: any;
  disabled?: boolean;
  hiddenFileds?: string[];
  onSuccess?: (data: any) => void;
}
export const AddGetMoney = ({children, hiddenFileds, params, ...rest}: AddGetMoneyProps) => {
  const [options,] = useDictEnumHooks({dictId: 258}, true)
  const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
  const formRef = useRef<ProFormInstance>()
  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      formRef.current?.resetFields()
    }
  }
  const onPreview = (file: any) => {
    const { response } = file || {}
    const {name, format, url, } = response ? (response||{}).data : file
    isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
  }
  
  return (<ModalForm<AddGetMoneyProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 5}} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
  {children}
  <ProFormSelect label="收款人类型" name="getMoneyerType" options={options} tooltip="若收款人为本人，请务必上传本人垫付的支付凭证" rules={[{required: true, message: '请选择收款人类型'}]} />
  <ProFormText label="收款人" name="getMoneyer" rules={[{required: true, message: '请填写收款人'}]} />
  <ProForm.Item label="银行卡信息" rules={[{required: true, message: '请填写银行卡信息'}]}>
      <Input.Group compact>
          <Form.Item name="bank" noStyle rules={[{required: false, message: '请填写开户行'}]}>
             <Input style={{width: '36%'}} placeholder="开户行" />
          </Form.Item>
          <Form.Item name="bankAccount" noStyle rules={[{required: false, message: '请填写账号'}]}>
              <Input style={{width: '64%'}} placeholder="账号" />
          </Form.Item>
      </Input.Group>
  </ProForm.Item>
  <ProFormText label="支付宝账号" name="aliPayAccount" rules={[{required: false, message: '请填写账号'}]} />
  <ProFormDependency name={['getMoneyerType']}>
    {({getMoneyerType}) => {
      return (<>
        <ProFormUploadButton label="上传凭证" name="files" tooltip="若新增收款人信息，请上传证明凭证" max={10} fieldProps={{ name: 'file', accept: ".png, .jpg, .jpeg, .bmp, .gif", listType: 'picture-card', onPreview, headers: {Authorization: getToken()}}} action={`${BASEURL}/upload`} rules={[{required: getMoneyerType === 99, message: '请上传凭证'}]}/>
      </>)
    }}
  </ProFormDependency>
  { hiddenFileds?.includes('addReason') ? null : <ProFormTextArea name="remark" label="新增原因" placeholder="请填写新增收款人的原因" rules={[{required: true, message: '请填写新增原因'}]} />}
  { hiddenFileds?.includes('modifyCase') ? null : <ProFormText label="修改原因" name="modifyCase" rules={[{required: true, message: '请填写修改原因'}]} /> }
  <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
</ModalForm>)
}

export const DelGetMoney = ({children, hiddenFileds, params, ...rest}: AddGetMoneyProps) => {
  const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})
  const formRef = useRef<ProFormInstance>()
  const onVisibleChange = (visible: boolean) => {
    if (visible) {
      formRef.current?.resetFields()
    }
  }
  const onPreview = (file: any) => {
    const { response } = file || {}
    const {name, format, url, } = response ? (response||{}).data : file
    isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
  }
  
  return (<ModalForm<AddGetMoneyProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 5}} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
  <ProFormTextArea name="deleteCase" label="删除原因" placeholder="请填写删除收款人的原因" rules={[{required: true, message: '请填写删除原因'}]} />
  <ProFormUploadButton label="上传凭证" name="files" tooltip="请上传删除收款人信息的证明截图" max={10} fieldProps={{ name: 'file', accept: ".png, .jpg, .jpeg, .bmp, .gif", listType: 'picture-card', onPreview, headers: {Authorization: getToken()}}} action={`${BASEURL}/upload`} rules={[{required: false, message: '请上传凭证'}]}/>
  <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
</ModalForm>)
}

interface GetMoneyTableProps extends EditableProTableProps<DataSourceGetMoneyType, any>{
  form?: any;
  onChange?: (newValue: any[]) => void;
}

export const GetMoneyTable = ({value, onChange, form, ...rest}: GetMoneyTableProps) => {
  return (<>
    <EditableProTable<DataSourceGetMoneyType>
      rowKey="id"
      value={value}
      summary={(dateSource) => <GetMoneySummary data={dateSource||[]} />}
      recordCreatorProps={false}
      controlled
      editable={{
        form,
        type: 'multiple',
        deleteText: null,
        editableKeys: (value||[]).map((item) => item.id),
        actionRender: (row, config, defaultDoms) => {
          return [defaultDoms.delete];
        },
        onValuesChange: (_, editableRows) =>  {
          onChange && onChange(editableRows)
        },
      }}
      {...rest}
    />
 </>)
}

export const RuleInfo = (props: any) => {
  const getListApi = async (params: any, sorter: any, filter: any) => {
      const { data } = await getRulesApi({...params, status: "1"}, false)
      const { values, total } = data || {}
      return {
          data: values,
          success: true,
          total
      }
  }

  return(<ModalForm title="规则说明" {...props}>
      <h3 style={{marginBottom: 5}}>【返点信息】</h3>
      <p style={{textIndent: 20}}>{`（1）当月的消费订单，系统将同一个客户账号的多笔订单合并成一笔订单，一笔订单中再区分出不同维度，先按{账单类型}区分出预付费和后付费维度，后付费维度默认无返点比例（返点比例为0），预付费维度中再按{订单服务周期}区分单年和多年维度，{多年}维度的返点比例上限受规则约束，{单年}维度中再按{订单类型}区分新升和续费维度，{新升}维度和{续费}维度的返点比例上限受规则约束`}</p>
      <p style={{textIndent: 20}}>{`（2）如遇特殊情况，支持按产品分类新增返点信息，不同的产品分类返点比例上限不同。`}</p>
      <h3 style={{margin: '5px 0'}}>【返点比例】</h3>
      <p style={{textIndent: 20}}>每个维度的返点比例上限不同，规则如下：</p>
      <ProTable
        columns={rulesTableList()}
        request={getListApi}
        toolBarRender={false}
        search={false}
      />
      <h3 style={{margin: '5px 0'}}>【返点金额的计算】</h3>
      <p style={{textIndent: 20}}>1.不需要抵扣退款订单，返点金额计算公式如下：</p>
      <p style={{textIndent: 25}}>（1）若有票据，返点金额=消费金额*返点比例</p>
      <p style={{textIndent: 25}}>（2）若无票据，返点金额=消费金额*返点比例*90%</p>
      <p style={{textIndent: 20}}>2.需要抵扣退款订单，返点金额计算公式如下：</p>
      <p style={{textIndent: 25}}>（1）若有票据，返点金额=（消费金额-抵扣退款金额）*返点比例</p>
      <p style={{textIndent: 25}}>（2）若无票据，返点金额=（消费金额-抵扣退款金额）*返点比例*90%</p>
  </ModalForm>)
}

// 复核信息也要用
export const handleSubmitParams = ({refundOrderDataSource, rebateInfoDataSource, getMoneyDataSource, delGetMoneyDataSource}: any) => {
    return {
      confirmRefundOrderResponses: ((refundOrderDataSource||[]).map(({id, ...rest}: any, idx: number) => (id+'').startsWith('zqs-') ? rest : ({...rest, id}))),
      confirmReturnOrderResponses: ((rebateInfoDataSource||[]).map(({id, ...rest}: any, idx: number) => (id+'').startsWith('zqs-') ? rest : ({...rest, id}))),
      confirmCollectionResponses: ((getMoneyDataSource||[]).map(({id, files: filesArr, ...rest}: any, idx: number) => (id+'').startsWith('zqs-') ? ({...rest, files: handleUploadFiles(filesArr||[])}) : ({...rest, id, files: handleUploadFiles(filesArr||[])}))),
      delConfirmCollectionResponses: ((delGetMoneyDataSource||[]).filter(({id, ...rest}: any, idx: number) => !(id+'').startsWith('zqs-')))
    }
}

export type AliRebateConfirmPropsType = {
    id: number | string;
    routeName: string;
}

export default ({id, routeName}: AliRebateConfirmPropsType) => {
    const history = useHistory()

    const { 
        rebateInfoFormRef, 
        getMoneyFormRef,
        requestWithCache,
        onValuesChange,
        rebateInfoTableOnValuesChange,
        getMoneyTableOnValuesChange,
        baseData,
        refundOrderDataSource,
        rebateInfoDataSource,
        getMoneyDataSource,
        delGetMoneyDataSource,
        hasPaperRef,
        rebateInfoProps,
        setRebateInfoProps,
        getMoneyProps,
        setGetMoneyProps,
        delMoneyProps,
        setDelMoneyProps,
        createOperate,
        removeCache
    } = useOperateHooks(id)

    const operate = (type: OperateActionType, record: DataSourceRebateInfoType | null) => {
        const action = createOperate(record)
        action(type)
    }

    const onFinish = async (values: any) => {
      const ok1 = await rebateInfoFormRef.validateFields().catch(err => false)
      const ok2 = await getMoneyFormRef.validateFields().catch(err => false)
      if (ok1 && ok2) {
        const params = handleSubmitParams({refundOrderDataSource, rebateInfoDataSource, getMoneyDataSource, delGetMoneyDataSource})
        await sureApi({...params, ...values, id, type: routeName === '追加返点' ? 1 : 0}, true, () => {
          removeCache()
          history.push('/rebate/list')
        })
      }
    }

    return (
        <ProForm layout="horizontal" params={{id}} request={requestWithCache as any} onValuesChange={onValuesChange} onFinish={onFinish} style={{boxSizing: 'border-box', padding: '15px'}}>
          <div className="zen-ban">基本信息</div>
          <DescriptionsList descList={baseInfoDesList} data={baseData} />
          <div className="zen-ban">退款订单 <Tooltip title={`当前客户账号存在退款订单，相对应的消费订单的返点金额是否已追回，若已追回，请对该退款订单执行追回操作，若未追回，将在当前返点进行抵扣。`}><Icon type="icon-ziyuan" /></Tooltip></div>
          <RefundOrders dataSource={refundOrderDataSource} />
          <div className="zen-ban"><span style={{color: 'red', marginRight: 4}}>*</span>返点信息 <RuleInfo trigger={<Icon type="icon-ziyuan" />} /></div>
          <Row justify="space-between" style={{boxSizing: 'border-box', padding: '0 28px'}}>
            <Col span={8}>
              <ProFormRadio.Group name="hasPaper" label="有无票据" initialValue={1} options={[{label: '有票据', value: 1}, {label: '无票据', value: 0}]} rules={[{ required: true }]} />
            </Col>
            <Button type="primary" size="small" onClick={() => operate && operate('返点信息-新增', null)}>新增</Button>
          </Row>
          <RebateInfoTable 
            form={rebateInfoFormRef}
            columns={rebateInfoColumnsFn(operate)} 
            value={rebateInfoDataSource} 
            onValuesChange={rebateInfoTableOnValuesChange} 
            operate={operate} />
          <AddRebateInfo title="新增返点信息" modalProps={{onCancel: () => setRebateInfoProps({...rebateInfoProps, visible: false}), maskClosable: false}} {...rebateInfoProps} />
          <Row justify="space-between" style={{position: 'relative', marginBottom: 15}}>
            <div className="zen-ban"><span style={{color: 'red', marginRight: 4}}>*</span>收款信息</div>
            <Button size="small" style={{position: 'absolute', right: 26}} type="primary" onClick={() => operate && operate('收款信息-新增', null)}>新增</Button>
          </Row>
          <GetMoneyTable 
            form={getMoneyFormRef} 
            columns={getMoneyColumnsFn(operate)} 
            value={getMoneyDataSource} 
            onChange={getMoneyTableOnValuesChange} 
          />
          <AddGetMoney title="新增收款信息" modalProps={{onCancel: () => setGetMoneyProps({...getMoneyProps, visible: false}), maskClosable: false}} {...getMoneyProps} />
          <DelGetMoney title="删除收款信息" modalProps={{onCancel: () => setDelMoneyProps({...delMoneyProps, visible: false}), maskClosable: false}} {...delMoneyProps} />
          <div className="zen-ban" style={{marginBottom: 15}}>备注</div>
          <ProFormTextArea name="remark" label="" fieldProps={{maxLength: 300, showCount: true}} rules={[{max: 300, type:'string', message: '300字以内'}]} />
        </ProForm>
    )
}