import React from "react";
import { TreeSelect, Button, Input, Row, Col, Cascader } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { queryDeptTreeData } from "@api/index";
import { useHasAuthCode, dimensionList } from "@hooks/index";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { transferTreeData } from "@utils/index";
import { Double, Ellipsis } from "@components/Common";
import { docStateOptions } from "../DocumentList/data";
import { calcHref } from "../Home/data";
import { Link } from "react-router-dom";
export type TableListItem = {
    key: number;
    id: string;
    mainName: string;
    sonName: string;
};
export type OperateActionType = '下架' | '上架' | '删除' 

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
  const [, hasAuthCode] = useHasAuthCode()

  return [
    {
        title: '标题',
        dataIndex: 'title',
        fixed: 'left',
        order: 100,
        render: (text: any, record: any) => <Link to={calcHref({...record, type: 5})}>{record.title}</Link>
    },
    {
        title: '相关标签',
        dataIndex: 'labelIds',
        order: 4,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: labelTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        render: (text, record: any) => <Ellipsis text={record.labelNameStr||'-'} />
    },
    {
        title: '关联客户',
        dataIndex: 'customerName',
        order: 3,
        render: (text: any, record: any) => record.customerNameStr
    },
    {
        title: '发布人',
        dataIndex: 'username',
        order: 2,
        width: 100,
        fieldProps: {
            maxTagCount: 4,
            treeCheckable: true,
            showSearch: true,
            treeNodeFilterProp: 'title',
            treeData: deptWithUserTreeData,
            placeholder: '请选择'
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
            transform: (ids) => {
                const userId = (ids||[]).map((v: string) => v.slice(1))
                return { userId }
            },
        },
        render: (text: any, record: any) => record.username
    },
    {
        title: '发布时间',
        dataIndex: 'createTime',
        valueType: 'dateRange',
        order: 1,
        width: 120,
        search: {
            transform: (times) => {
                return { 
                    from: times[0],
                    to: times[1]
                }
            },
        },
        render: (text: any, record: any) => record.createTime
    },
    {
        title: '状态',
        dataIndex: 'state',
        order: 5,
        valueType: 'select',
        width: 100,
        fieldProps: {
            options: docStateOptions
        }
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text, record: any) => {
          return [
            (hasAuthCode(2338) && record.state === 1) ? <a key="down" onClick={() => operate && operate('下架', record)}>下架</a> : null,
            (hasAuthCode(2337) && record.state === 2) ? <a key="up" onClick={() => operate && operate('上架', record)}>上架</a> : null,
            hasAuthCode(2339) && <a key="del"  onClick={() => operate && operate('删除', record)}>删除</a>,
          ]
        },
    },
  ]
}
