import { ProColumns } from "@ant-design/pro-table"
import { Double, Ellipsis } from "@components/Common"
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index"
import { StateType } from "@store/index"
import { amountFormat } from "@utils/index"
import { Dropdown, Menu, Progress, Space, TreeSelect } from "antd"
import { stringify } from "qs"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { OperateActionType } from "../../data"
import { claimOptions } from "@components/Finance/RebackMoney/data"
import { StatusDot } from "@components/Finance/Contract/data"

export const columnsFn: (operate: (type: OperateActionType, record?: any) => void, activeKey: any) => ProColumns<any>[] = (operate, activeKey) => {
    const { deptWithUserTreeData, cityTreeData, labelTreeData } = useSelector((state: StateType) => state)
    const [, , request] = useDictEnumHooks({}, false)
    const [, hasAuthCode] = useHasAuthCode()
  
    return [
      {
        title: (_, type) => type === 'form' ? '打款客户' : '打款客户名称',
        dataIndex: 'payCustomerName',
        order: 15,
        align: 'center',
      },
      {
        title: (_, type) => type === 'form' ? '打款金额' : '打款金额(元)',
        dataIndex: 'payAmount',
        sorter: true,
        fieldProps: { placeholder: '请输入打款金额'},
        renderFormItem: (props) => <Double props1={{addonBefore: '¥'}} props2={{addonBefore: '¥'}} />,
        order: 13,
        search: {
          transform: (payAmounts) => {
            return { payAmounts }
          },
        },
        align: 'center',
      },
      
      {
        title: '打款日期',
        dataIndex: 'payDate',
        order: 9,
        align: 'center',
        sorter: true,
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {payDates: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.payDate||''}`
      },
      {
        title: '收款账号',
        dataIndex: 'businessType',
        order: 14,
        valueType: 'select',
        align: 'center',
        params: { dictId: 270 },
        fieldProps: { mode: 'multiple' },
        request,
        search: {
          transform: (payAccounts) => {
            return { payAccounts }
          },
        },
        render: (text: any, record: any) => record.payAccountStr
      },
      {
        title: '打款备注',
        dataIndex: 'remark',
        // order: 8,
        hideInSearch: true,
        align: 'center',
      },
      {
        title: '状态',
        dataIndex: 'status',
        order: 16,
        align: 'center',
        fieldProps: { options: claimOptions, mode: 'multiple' },
        valueType: 'select',
        // render: (text: any, record: any) => record.statusStr
        render: (text: any, record: any) => <><StatusDot status={record.status} />{`${(record.statusStr||[]).toString()}`}</>
      },
      {
        title: '客户名称',
        dataIndex: 'customerName',
        order: 8,
        align: 'center',
        hideInSearch: true,
      },
      {
        title: (_, type) => type === 'form' ? '合同编号' : '销售合同编号',
        dataIndex: 'xsContractNumber',
        fieldProps: { placeholder: '请输入销售合同编号'},
        order: 10,
        align: 'center',
        hideInSearch: true,
      },
     {
        title: '认领人',
        dataIndex: 'userId',
        order: 12,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const userIds = (ids||[]).map((v: string) => v.slice(1))
            return { userIds }
          },
        },
        render: (text: any, record: any) => `${(record.name||[]).toString()}`
      },
      {
        title: '认领时间',
        dataIndex: 'claimDate',
        valueType: 'dateRange',
        sorter: true,
        fieldProps: { allowEmpty: [true, true]},
        order: 8,
        align: 'center',
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {claimDates: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.claimDate||''}`
      },
      {
        title: '上传者',
        dataIndex: 'creator',
        order: 11,
        fieldProps: {
          maxTagCount: 4,
          treeCheckable: true,
          showSearch: true,
          treeNodeFilterProp: 'title',
          treeData: deptWithUserTreeData
        },
        renderFormItem: (props) => <TreeSelect />,
        search: {
          transform: (ids) => {
            const creators = (ids||[]).map((v: string) => v.slice(1))
            return { creators }
          },
        },
        render: (text: any, record: any) => `${(record.creatorStr||[]).toString()}`
      },
      {
        title: '上传时间',
        dataIndex: 'createTime',
        order: 10,
        sorter: true,
        align: 'center',
        valueType: 'dateRange',
        fieldProps: { allowEmpty: [true, true]},
        search: {
          transform: (times) => {
            const startTime = times[0] ? `${times[0]} 00:00:00` : null
            const endTime = times[1] ? `${times[1]} 23:59:59` : null
            return {createTimes: [startTime, endTime]}
          },
        },
        render: (text: any, record: any) => `${record.createTime||''}`
      },
      {
        title: '操作',
        key: 'option',
        dataIndex: 'option',
        valueType: 'option',
        width: 120,
        fixed: 'right',
        render: (text: any, record: any) => [
            (hasAuthCode(2483) && [1].includes(record?.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="认领" onClick={() => operate && operate('待认领回款-认领', record)}>认领</a> : null,
            (hasAuthCode(2483) && [2].includes(record?.status)) ? <a style={{'whiteSpace': 'nowrap'}} key="撤销" onClick={() => operate && operate('待认领回款-撤销', record)}>撤销</a> : null,
        ],
      },
    ]
}
