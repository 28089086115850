import { Table, Tabs, Input, Modal } from "antd"
import React, { useEffect, useState } from "react"
import { MyCollectionColumns, MyPublicColumns, MyPublicTabPanes, MyAnswerColumns, MyAnswerTabPanes, MySurveyColumns, mockArticleItem, OperateActionType } from './data'
import { MyTabs } from '@components/EnterpriseDetail'
import { useHasAuthCode, useTableHooks } from "@hooks/index"
import { parseSearch, productApi } from "@utils/index"
import { knowledDocDel, knowledDocUpDown, knowledgeMyPublic, knowledgeMyAnswer, knowledgeMyCollect, knowledgeMyQuestion, knowledgeMySurvery, knowledCollect } from "@api/knowledge"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"
import { calcCreatHref } from "../Home/data"
import { DocAudit } from "../DocumentAudit"

const {TabPane} = Tabs
const { Search } = Input
const myCollectApi = productApi(knowledgeMyCollect) // 我的收藏
const myQuestionApi = productApi(knowledgeMyQuestion) // 我的提问
const myAnswerApi = productApi(knowledgeMyAnswer) // 我的提问
const mySurveryApi = productApi(knowledgeMySurvery) // 我的问卷

const myPublicApi = productApi(knowledgeMyPublic) // 我发布的
const docDelApi = productApi(knowledDocDel) 
const docDelCollectApi = productApi(knowledCollect) // 取消收藏
const docUpDownApi = productApi(knowledDocUpDown)

const MyCollection = () => {
    const { userInfo } = useSelector((state: StateType) => state)
    const { user } = userInfo || {}
    const { id: userId } = user || {}
    const [keyword, setKeyWord] = useState<String>('')
    const {tableProps, fetchApi } = useTableHooks(myCollectApi, {userId}, false)

    const operate = async (record: any, type: OperateActionType) => {
        const {id} = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelCollectApi({type: 1, id, actionFlag: false}, true, () => fetchApi({keyword}))
                },
            });
        }
    }
    useEffect(() => {
        fetchApi({keyword, userId})
    }, [])
    return (<>
    <Search 
       placeholder="输入标题" 
       style={{ width: 400, margin:'0 16px' }} 
       enterButton 
       onSearch={(keyword) => {
           setKeyWord(keyword)
           fetchApi({keyword, userId})
       }} />
    <Table showHeader={false} columns={MyCollectionColumns(operate)} rowKey="id" {...tableProps} style={{boxSizing: 'border-box', paddingRight: 20}} />
    </>)
}

const MyPublic = () => {
    const history = useHistory()
    const [type, setType] = useState<String>('{}')
    const {tableProps, fetchApi } = useTableHooks(myPublicApi, {}, false)
    const [docAuditProps, setDocAuditProps] = useState<any>({visible: false, title: '审核详情', params: {}})
    const operate = async (record: any, type: OperateActionType) => {
        const { id, type: atype, format, status, mark } = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelApi({ids: [id]}, true, () => fetchApi({type}))
                },
            });
        } else if(type === '上架') {
            Modal.confirm({
                title: '确认要上架吗?',
                content: '文档上架后，在系统中可以被用户浏览',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 1}, true, () => fetchApi({type}))
                },
            });
        } else if(type === '下架') {
            Modal.confirm({
                title: '确认要下架吗?',
                content: '文档下架后，将不被系统用户搜索到',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 2}, true, () => fetchApi({type}))
                },
            });
        } else if (type === '编辑'){
            const link = calcCreatHref({...record})
            history.push(link)
        } else if (type === '详情') {
            setDocAuditProps({
                ...docAuditProps,
                visible: true,
                title: type,
                params: { id },
                disabled: true,
                values: { status: 2, mark },
                onSuccess: () => {
                    setDocAuditProps({...docAuditProps, visible: false})
                }
            })
        }
    }

    useEffect(() => {
        const params = JSON.parse(type as string)
        fetchApi(params)
    }, [type])
    return(<>
        <MyTabs tabPanes={MyPublicTabPanes} activeKey={type} onChange={setType} className="zen-vertical-tabpane-normal" />
        <Table showHeader={false} columns={MyPublicColumns(operate)} rowKey="id" {...tableProps} style={{boxSizing: 'border-box', paddingRight: 20}} />
        <DocAudit {...docAuditProps} modalProps={{onCancel: () => setDocAuditProps({...docAuditProps, visible: false}), maskClosable: false}} />
    </>)
}

const MyAnswer = () => {
    const history = useHistory()
    const [type, setType] = useState<String>('0')
    const { userInfo } = useSelector((state: StateType) => state)
    const { user } = userInfo || {}
    const { id: userId } = user || {}

    const {tableProps, fetchApi } = useTableHooks(type === '0' ? myQuestionApi : myAnswerApi, {}, false)

    const operate = async (record: any, type: OperateActionType) => {
        const { id, type: atype, format } = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelApi({ids: [id]}, true, () => fetchApi({type}))
                },
            });
        } else if(type === '上架') {
            Modal.confirm({
                title: '确认要上架吗?',
                content: '文档上架后，在系统中可以被用户浏览',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 1}, true, () => fetchApi({type}))
                },
            });
        } else if(type === '下架') {
            Modal.confirm({
                title: '确认要下架吗?',
                content: '文档下架后，将不被系统用户搜索到',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docUpDownApi({id, state: 2}, true, () => fetchApi({type}))
                },
            });
        } else if (type === '编辑'){
            const link = calcCreatHref(record)
            history.push(link)
        }
    }

    useEffect(() => {
       fetchApi({userId})
    }, [type])
    return(<>
        <MyTabs tabPanes={MyAnswerTabPanes} activeKey={type} onChange={setType} className="zen-vertical-tabpane-normal" />
        <Table showHeader={false} columns={MyAnswerColumns(operate, type)} rowKey="id" {...tableProps} style={{boxSizing: 'border-box', paddingRight: 20}} />
    </>)
}

const MySurvey = () => {
    const history = useHistory()
    const [keyword, setKeyWord] = useState<String>('')
    const {tableProps, fetchApi } = useTableHooks(mySurveryApi, {}, true)
    const operate = async (record: any, type: OperateActionType) => {
        const { id , docId} = record || {}
        if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    docDelApi({id}, true, () => fetchApi({type}))
                },
            });
        } else if (type === '填写') {
            const link = calcCreatHref({...record, docId, type: '3.1'})
            history.push(link)
        }
    }

    useEffect(() => {
        fetchApi({keyword})
    }, [keyword])
    return (<>
    <Search 
       placeholder="输入问卷标题, 关联客户" 
       style={{ width: 400, margin:'0 16px' }} 
       enterButton 
       onSearch={(keyword) => {
        setKeyWord(keyword)
        fetchApi({keyword})
       }}
    />
    <Table showHeader={false} columns={MySurveyColumns(operate)} rowKey="id" {...tableProps} style={{boxSizing: 'border-box', paddingRight: 20}} />
    </>)
}

export default () => {
    const history = useHistory() 
    const [, hasAuthCode] = useHasAuthCode()
    const { search } = history.location
    const { activeKey } = parseSearch(search)
    return (
        <div style={{background: '#fff', boxSizing: 'border-box', padding: 10, paddingLeft: 0}}>
            <Tabs 
               tabPosition={'left'} 
               className="zen-vertical-tabpane" 
               style={{ height: '100%' }} 
               tabBarStyle={{width: 160}}
               activeKey={activeKey}
               onChange={(activeKey: any) => history.push(`/knowledge/mine?activeKey=${activeKey}`)} 
            >
                { hasAuthCode(2356) ? <TabPane tab="我的收藏" key="1"><MyCollection /></TabPane> : null }
                { hasAuthCode(2350) ? <TabPane tab="我发布的" key="2"><MyPublic /></TabPane> : null }
                { hasAuthCode(2358) ? <TabPane tab="我的问答" key="3"><MyAnswer /></TabPane> : null }
                { hasAuthCode(2365) ? <TabPane tab="我的问卷" key="4"><MySurvey /></TabPane> : null }
            </Tabs>
        </div>
    )
}