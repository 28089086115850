import React from "react"
import { ProColumns } from "@ant-design/pro-table"

export type QuestionType = {
    id: string | number;
    question: string
}
export const questionItem = (n: number = 1) => new Array(n).fill({
    question: ''
}).map((v, idx) => ({...v, id: `zqs-${idx}-${Date.now()}`}))

export const surveryItem = (n: number = 1) => new Array(n).fill({
    question: 'hello',
}).map((v, idx) => ({...v, answer: idx, id: `zqs-${idx}-${Date.now()}`}))


export const surveryColumns: (form: any) => ProColumns<QuestionType>[] = (form) => {
    return [
        {
            title: '序号',
            dataIndex: 'id',
            width: 50,
            editable: false,
            render: (text: any, record: any, idx: number) => idx + 1
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>录入问题</span>,
            dataIndex: 'question',
            formItemProps: {
              rules: [
                {
                  required: true,
                  whitespace: true,
                  message: '此项是必填项',
                }
              ],
            },
        },
        {
            title: '操作',
            valueType: 'option',
            fixed:'right',
            width: 100,
            render: () => [
              <a key="link">删除</a>,
            ],
        },
    ]
}