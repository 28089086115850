import React from "react"
import { Link, Route, Switch } from "react-router-dom"
import { KeepAliveProvider, WithKeepAlive, useCacheDispatch } from "@components/Common/Cache"
import { Button, Col, Input, Row } from "antd"
import { parseSearch } from "@utils/index"

// 测试 React-keep-alive 的demo
const list = new Array(100).fill(0)
// 列表页组件
export const ListPage = () => {
    const dispatch = useCacheDispatch()
    const onClearCache = (cacheId: any) => {
        if (cacheId) {
            dispatch({type: 'RESET', payload: cacheId})
        } else {
            dispatch({type: 'CLEAR'})
        }
    }
    return (<div style={{height: 460, overflow: 'scroll'}}>
        <Input placeholder="请输入..."></Input>
        <Row justify="space-between" align="middle">
            <Col><Button onClick={() => onClearCache(null)}>清除所有缓存</Button></Col>
            <Col><Link to="/test">返回</Link></Col>
        </Row>
      {list.map((v, i) => <Row key={i} justify="space-between"><Col><Link to={`/test/detail?id=${i}`}>{`点我进入列表${i}的详情页`}</Link></Col><Col onClick={() => onClearCache(`/test/detail/${i}`)}>清除缓存</Col></Row>)}
    </div>)
}
// 详情页组件
const DetailPage = () => {
    return (<div>
        hello, i am detail
        <Input placeholder="搜索条件1" style={{width: 240}}></Input>
        <Input placeholder="搜索条件2" style={{width: 240, margin: '0 10px'}}></Input>
        <Input placeholder="搜索条件3" style={{width: 240}}></Input>
    </div>)
}

const KeepAliveCacheDemo = WithKeepAlive(ListPage, {cacheId: 'ListPage'})
const KeepAliveDetailDemo = () => {
    const { search } = window.location
    const { id } = parseSearch(search)
    const cacheId = `/test/detail/${id}`
    return WithKeepAlive(DetailPage, {cacheId})
}

export const KeepAliveDemo = () => {
    return (<KeepAliveProvider>
        <Switch>
            <Route path={'/test/cache'} component={KeepAliveCacheDemo}></Route>
            <Route path={'/test/detail'} component={KeepAliveDetailDemo()}></Route>
        </Switch>
    </KeepAliveProvider>)
}
