import React, { useEffect, useRef, useState } from 'react'
import MultiIcon from '@components/KnowledgeCommunity/cpns/cpnts/MultiIcon'
import { Button, Input, message } from 'antd';
import ProForm, {  ProFormTextArea } from '@ant-design/pro-form';

import './index.less'
import { productApi } from '@utils/util';
import { knowledReplyComment, knowledListSubComment} from '@api/knowledge';


const { TextArea } = Input;

// praiseCnt : 点赞数量
// nowPraise ： 当前用户是否点赞了
// commentNumber ： 评论数量
const addChildrenComment = productApi(knowledReplyComment)

const getChildrenComment = productApi(knowledListSubComment)

const CommentItem = ({config,type, commentListUpdate, docId, parentId, updataData}: any, )=> {
    const [replyState, setReplyState] = useState(false)
    const [commentState, setCommentState] = useState(false)
    const [configData, setConfigData] = useState<any>([])
    // const [html, setHtml] = useState('')
    // const { userName, children, content, replyUname, id, praiseCnt,createTime, praiseStatus, commentCnt } = config || {}
    
    useEffect(()=> {
        setConfigData(config)
    }, [config])
    // commentRef.current && (commentRef.current.innerHTML = content)
    // content && setHtml(content || '')
    const multiIconConfig = [
        {
            type: 'good',
            title: `赞 ${configData.praiseCnt}`,
            activation: configData.praiseStatus, 
            commentId: configData.id,
            success: ()=> { 
                commentListUpdate(configData.id,'点赞')
            }
        }, 
        {
            type: type === 1 ? 'comment' : '',
            title: commentState? `收起${configData.commentCnt}条评论` : (configData.commentCnt < 1 ? '暂无评论' : `展开${configData.commentCnt}条评论`),
            activation: commentState, 
            onClick: async () => {
                if (commentState) {
                    commentListUpdate(configData.id, '收起展开评论',  [])
                } else {
                    const {data} =  await getChildrenComment({docId, parentId: configData.id}, false )
                    commentListUpdate(configData.id, '收起展开评论', commentState ? [] : data)
                }
                setCommentState(!commentState)
            }
        },
        {
            type: 'reply',
            title: replyState ? '取消回复' : '回复',
            activation: replyState, 
            onClick: ()=> { 
                setReplyState(!replyState)  }
        }
    ]
    const InputTextArea = ()=> {
        const submitChildrenComment = async (values:any) => {
            const {success} = await addChildrenComment({...values, parentId: configData.id, docId}, true)
            if (success){
                setReplyState(false)
                // 回复评论后更新总评论数量
                await updataData( undefined , async (allData:any)=> {
                    const {data} =  await getChildrenComment({docId, parentId: configData.id}, false )
                    commentListUpdate(parentId || configData.id, '收起展开评论',data, allData.commentList)
                })
                setCommentState(true)
            }
        
        }
        return <div className='InputTextArea'>
             <ProForm
                    layout="horizontal"
                    onFinish={submitChildrenComment}
                >
                    <ProFormTextArea width="xl" name="content" fieldProps={{maxLength: 300, showCount: true}} rules={[{required: true, message: '请填写评论'}, {max: 300, type:'string', message: '300字以内'}]} />
                </ProForm>
        </div>
    }
    // type:1  一级评论，   type:2   二级评论
    return <div className={type === 1 ? 'commentItem' : 'commentItem childrenCommentItem' }>
        <div className='leftDiv'>
            <div className='headImg'>
                <img src="https://p9-passport.byteacctimg.com/img/user-avatar/8db3fdff9a85ee60ad0e6706b6632064~300x300.image" alt="" /> 
            </div>
        </div>
        <div className='rightDiv'>
            <div>
                 <span className='name'>{configData.userName }{(configData.replyUname && configData.userName !== configData.replyUname) ? `-回复-${configData.replyUname}` : '' }</span>
                 <span className='time'> { configData.createTime}</span>
            </div>
            <div className="editor-content-view" dangerouslySetInnerHTML={{ __html: configData.content }}></div>
            <MultiIcon style={{marginLeft: '-5px'}} multiConfig={multiIconConfig} type={2}/>
            {replyState ? <InputTextArea /> : null}
        </div>
    </div>
}
export default ({commentDataList, docId, updataData}:any) => {
    const [commentListData, setCommentListData] = useState<any>([])
    const [commentNumber, setCommentNumber] = useState<any>([])
    
    useEffect(() => {
        const {commentCnt, commentList: commentListData} = commentDataList
        setCommentListData(commentListData)
        setCommentNumber(commentCnt)
    }, [commentDataList])
    const commentListUpdate = (itemId:number,type:string, item:object, allData?:any )=> {
       if ( type === '点赞' ) {
            setCommentListData(commentListData.map((element: any) => {
                const { id, children = [], praiseCnt, praiseStatus } = element
                if (id !== itemId && children.length !== 0) {
                    return Object.assign({},{ ...element, children: children.map((element2: any)=> {
                        const { id:id2, praiseCnt: praiseNumber2, praiseStatus: praiseStatus2 } = element2
                        if (id2 === itemId) {
                            return Object.assign({},{ 
                                ...element2,
                                praiseCnt: praiseNumber2 + (praiseStatus2 ? -1 : 1),
                                 praiseStatus: !praiseStatus2
                                }) 
                        } else {
                            return element2
                        }
                    })}) 
                } else if (id === itemId) {
                    return Object.assign({},{ 
                        ...element,
                        praiseCnt: praiseCnt + (praiseStatus ? -1 : 1),
                         praiseStatus: !praiseStatus
                        }) 
                } else {
                    return element
                }
            }))
       } else if (type === '收起展开评论' ) {
            setCommentListData((allData || commentListData).map((element: { id: number}) => {
                const { id } = element
                if (id===itemId) {
                    return Object.assign(element,{  children: item}) 
                } else {
                    return Object.assign({},{ ...element}) 
                }
            }))
       }

    }
    return <div className='commentMain'>
      <div className='header'>
          <div>
            <span className='title'>全部评论</span>
            <span className='numberSpan'>共{commentNumber}条</span>
          </div>
         {/* <MultiIcon multiConfig={[{type: 'sort', title: '时间排序',activation: sort, onClick: ()=> { setSort(!sort)}}]}  type={2}/> */}
      </div>
      <div className='commentDiv'>
            {commentListData ? commentListData.map((element: any, index: number) => {
                
              const { children = [], id } = element || []
              const hasChildren = (children || []).length
              return <div key={index}>
                  {/* {console.log(1111,element)} */}
                  <CommentItem config={element} type={1} updataData={updataData} docId={docId} commentListUpdate={commentListUpdate}/>
                  {hasChildren ? children.map((element2: object, index2: number) => {
                    return <CommentItem type={2}  parentId={id} key={index2} docId={docId} commentListUpdate={commentListUpdate} config={element2} />
                }): null }
              </div> 
          }): null}
      </div>
    </div>
 }
