import { ProColumns } from "@ant-design/pro-table";
import { DescListItem } from "@components/EnterpriseDetail/type";
import { downLoadWithATag } from "@utils/index";
import React from "react";

export const attachmentColumnsFn = (operate: any) => [
    {
        title: '文件名称',
        dataIndex: 'name',
    },
    {
        title: '操作',
        key: 'option',
        render: (text: string, record: any) => [
          ['jpg', 'png', 'gif', 'jpeg'].includes(record.format) ? <a key="link" style={{marginRight: 10}} onClick={() => operate && operate('预览', record)}>预览</a> : null,
          <a key="warn" onClick={() => downLoadWithATag(record.url)}>下载</a>
        ],
    },
]

export const descList: DescListItem[] = [
    {
        label: '系统编号',
        param: 'sysNumber',
        span: 3
    },
    {
        label: '提交人',
        param: 'creatorStr',
        span: 1
    },
    {
        label: '提交时间',
        param: 'createTime',
        span: 2
    },
    {
        label: '客户名称',
        param: 'customerName',
        span: 1
    },
    {
        label: '销售合同编号',
        param: 'xsContractNumber',
        span: 2
    },
    {
        label: '采购合同编号',
        param: 'cgContractNumber',
        span: 1
    },
    {
        label: '采购合同名称',
        param: 'cgContractName',
        span: 2
    },
    {
        label: '供应商',
        param: 'supplier',
        span: 1
    },
    {
        label: '业务类型',
        param: 'businessTypeStr',
        span: 2
    },
    {
        label: '合同金额（元）',
        param: 'contractAmount',
        span: 1,
        value: ({contractAmount}: any) => <span>{contractAmount}</span>
    },
    {
        label: '签约日期',
        param: 'signDate',
        span: 2
    },
    {
        label: '有无文本合同',
        param: 'haveTextStr',
        span: 1,
        value: ({haveText}: any) => <span>{haveText ? '有文本' : '无文本'}</span>
    },
    {
        label: '合同是否盖章',
        param: 'whetherStampStr',
        span: 2,
        value: ({whetherStamp}: any) => <span>{whetherStamp ? '盖章' : '不盖章'}</span>
    },
    {
        label: '备注',
        param: 'remark',
        span: 3
    }
]
