import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Icon } from '@components/index';
import { message, Tag, Tooltip } from 'antd';
import { stringify } from 'qs';
import { Link, useHistory } from 'react-router-dom';
import { calcTitleDetail } from '../cpns/MyDoc/Detail';
import { calcTitle } from '../cpns/MyDoc/Create';

export const mockArticleItem = (n: number = 1) => new Array(n).fill({
    title: '文档标题',
    author: '章三',
    updateTime: '2022-03-22 10:23:45',
    status: (Math.random() * 10)%4,
    browse: 2000,
    collection: 2334,
}).map((v, idx) => ({...v, rank: idx + 1, id: v.id ? v.id : `zqs-${idx}`}))

export const calcHref = ({type, id, customerId, docId, format}: any, detail: boolean = true) => {
    // if (format === 2) {
    //     message.warning('上传的文档不支持该操作!')
    //     return ''
    // }
    return `/knowledge/DocDetail?${stringify({type, id, customerId, docId, routeName: calcTitleDetail(type, id)})}`
}

export const calcCreatHref = ({type, id, format, docId, customerId,customerName, fromPath, categoryId, ownership = 1}: any) => {
    if (format === 2) {
        message.warning('上传的文档不支持该操作!')
        return '#'
    }
    return `/knowledge/create?${stringify({type, id, format, docId, customerId,customerName, categoryId, ownership, fromPath, routeName: calcTitle(type, id)})}`
}

export const StatusTag = ({docType, status}: any) => {
    if (docType === 5) {
        switch (status) {
            case 5:
                return <Tag style={{marginLeft: 10}} color="red">未解决</Tag>
            case 4:
                return <Tag style={{marginLeft: 10}} color="blue">已解决</Tag>
            default:
                break;
        }
    }
    return null
}

export const ArticleTitleRender = ({record, StatusTag}: any) => {
    const { publishName, username, updateTime, createTime } = record || {}
    const name = publishName || username
    const time = updateTime || createTime
    return(<>
        <h3><Link to={calcHref(record)} className="jns-artical-title-link">{record.title}</Link> {StatusTag}</h3>
        <p style={{color: 'rgba(0, 0, 0, 0.45)'}}>{name ? `${name}.` : ''}{time}. 浏览 {record.browse||0} . 收藏 {record.collect||0}</p>
    </>)
}

export const IssueTitleRender = ({record, StatusTag}: any) => {
    return(<>
        <h3><Link to={calcHref(record)} className="jns-artical-title-link">{record.title}</Link> {StatusTag}</h3>
        <p style={{color: 'rgba(0, 0, 0, 0.45)'}}>{record.publishName ? `${record.publishName}.` : ''}{record.updateTime}. 浏览 {record.browse||0} . 收藏 {record.collect||0} . 回复 {record.reply||0}</p>
    </>)
}

export const SurveyTitleRender = ({record, StatusTag}: any) => {
    return(<>
        <h3><Link to={calcHref(record)} className="jns-artical-title-link">{record.title}</Link> {StatusTag}</h3>
        <p style={{color: 'rgba(0, 0, 0, 0.45)'}}>{record.updateTime}. 关联客户: {record.customerName}</p>
    </>)
}

export const solutionColumns = [
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (rext: any, record: any) => <ArticleTitleRender record={record} />
    },
]

export const customerCaseColumns = [
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (rext: any, record: any) => <ArticleTitleRender record={record} />
    },
]

export const qsTemplateColumns = [
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (rext: any, record: any) => <ArticleTitleRender record={record} />
    },
    {
        title: '操作',
        key: 'option',
        valueType: 'option',
        width: 80,
        fixed: 'right',
        render: (text: any, record: any) => [
            <Tooltip key="fill" title="填写">
                <Link to={calcCreatHref({...record, fromPath: '/knowledge/docList', type: 3.1, docId:record.id, id: null})}><Icon type="icon-fabubiaoshu" style={{fontSize: 16, marginRight: 10}} /></Link>
            </Tooltip>,
        ],
    },
]

export const helpDocColumns = [
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (rext: any, record: any) => <ArticleTitleRender record={record} />
    },
]

export const issueColumns = [
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        render: (rext: any, record: any) => <IssueTitleRender record={record} StatusTag={<StatusTag docType={5} status={record.status} />} />
    },
]

export const helpTabPanes = [
    {
        Tab: '最新',
        Content: null
    },
    {
        Tab: '热门',
        Content: null
    },
    {
        Tab: '未解决',
        Content: null
    },
    {
        Tab: '已解决',
        Content: null
    },
    {
        Tab: '未回答',
        Content: null
    }
]
