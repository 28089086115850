import { ProColumns } from "@ant-design/pro-table";
import { customerCheckAccount } from "@api/index";
import { useDictEnumHooks } from "@hooks/index";
import { emailReg, fixedPhone, phoneReg, validatorMobilePhone } from "@utils/index";
import { Input, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

export type DataSourceCustomerType = {
  id: string;
  type?: string,
  customerAccount?: string,
  cooperationMode?: string,
  realName?: string;
  phone?: string;
};

export type DataSourceContactType = {
  id: string;
  contactsName?: string;
  gender?: string;
  jobPosition?: string;
  mobilePhoneNumber?: string;
  fixedTelephoneNumber?: string;
  email?: string;
  wechatAccount?: string;
  qqNumber?: string;
  dingNumber?: string;
  remarks?: string;
  primaryContact: string;
}

export type FormDataType = {
  customerName: string;
  status: string;
  customerSource: string;
  customerProperty: string;
  customerLevel: string;
  customerLabel: string;
  address: string;
  detailAddress?: string;
}

export const customerAccountItem = (n: number = 1) => new Array(n).fill({
  type: 54,
  customerAccount: undefined,
  cooperationMode: undefined,
}).map((v, idx) => ({...v, id: `zqs-${idx}-${Date.now()}`}))

export const contactItem = (n: number = 1) => new Array(n).fill({
  contactsName: undefined,
  gender: undefined,
  jobPosition: undefined,
  mobilePhoneNumber: undefined,
  fixedTelephoneNumber: undefined,
  email: undefined,
  wechatAccount: undefined,
  qqNumber: undefined,
  dingNumber: undefined,
  remarks: ''
}).map((v, idx) => ({...v, id: `zqs-${idx}-${Date.now()}`, primaryContact:'0'}))

export const customerAccountColumns: (form: any) => ProColumns<DataSourceCustomerType>[] = (form) => {
  const [, , request] = useDictEnumHooks({}, false)
  return [
      {
        title: <span><i style={{color: 'red'}}>*</i>账号类型</span>,
        dataIndex: 'type',
        width: '24%',
        valueType: 'select',
        params: {dictId: 24 },
        request,
        fieldProps: { defaultValue: 54, allowClear: false },
        formItemProps: {
          rules: [
            {
              required: true,
              message: '此项是必填项',
            }
          ],
        },
        render: () => <Select />
      },
      {
        title: <span><i style={{color: 'red'}}>*</i>客户账号</span>,
        key: 'customerAccount',
        dataIndex: 'customerAccount',
        formItemProps: {
          rules: [
            {
              required: true,
              whitespace: true,
              // message: '此项是必填项',
              validator: async (rules: any, val: string, callback: Function) => {
                if(!(val||'').trim()) {
                  return Promise.reject('此项是必填项')
                }
                const {field} = rules
                const fieldName = field.split('.')[0]
                const {type, customerAccount: name, ...rest} = form.getFieldValue(fieldName)
                const {data, msg} = await customerCheckAccount({type, name, id: fieldName.startsWith('zqs-') ? undefined : fieldName}) as any
                if (data) {
                  return Promise.resolve()
                }
                return Promise.reject(msg)
              },
            }
          ],
        },
      },
      {
        title: <span><i style={{color: 'red'}}>*</i>账号来源</span>,
        dataIndex: 'accountSource',
        valueType: 'select',
        params: {dictId: 274 },
        request,
        fieldProps: {
          showSearch: true
        },
        formItemProps: {
          rules: [
            {
              required: true,
              message: '此项是必填项',
            }
          ],
        },
        render: () => <Select />
      },
      {
        title: <span><i style={{color: 'red'}}>*</i>合作模式</span>,
        dataIndex: 'cooperationMode',
        valueType: 'select',
        params: {dictId: 25 },
        request,
        formItemProps: {
          rules: [
            {
              required: true,
              message: '此项是必填项',
            }
          ],
        },
        render: () => <Select />
      },
      {
        title: <span><i style={{color: 'red'}}>*</i>实名认证名称</span>,
        dataIndex: 'realNameAuthentication',
        formItemProps: {
          rules: [
            {
              required: true,
              message: '此项是必填项',
            }
          ],
        },
        // editable: false,
        render: () => <Input />
      },
      // {
      //   title: '手机号码',
      //   dataIndex: 'phone',
      //   editable: false,
      //   render: (text, record) => <Input disabled value={(record||{}).phone} />
      // },
      {
        title: '操作',
        valueType: 'option',
        width: 50,
        fixed:'right',
        render: () => [
          <a key="link">删除</a>,
        ],
      },
  ];
  
}

export const contactColumns: () => ProColumns<DataSourceContactType>[] = () => {
  const [, , request] = useDictEnumHooks({}, false)
  return [
    {
      title: <span><i style={{color: 'red'}}>*</i>姓名</span>,
      dataIndex: 'contactsName',
      fixed: 'left',
      width: 120,
      formItemProps: {
        rules: [
          {
            required: true,
            whitespace: true,
            message: '此项是必填项',
          }
        ],
      },
    },
    {
      title: '性别',
      width: 80,
      key: 'gender',
      fixed: 'left',
      fieldProps: {style: {width: '60px'}},
      dataIndex: 'gender',
      valueType: 'select',
      valueEnum: {
        0: { text: '男', status: 'Default' },
        1: { text: '女', status: 'Error' },
      },
    },
    {
      title: <span><i style={{color: 'red'}}>*</i>职位</span>,
      width: 120,
      fixed: 'left',
      dataIndex: 'jobPosition',
      // valueType: 'select',
      // params: {dictId: 22},
      // request,
      formItemProps: {
        rules: [
          {
            required: true,
            message: '此项是必填项',
          }
        ],
      },
      // render: () => <Select />
    },
    {
        title: <span><i style={{color: 'red'}}>*</i>手机号</span>,
        width: 172,
        fixed: 'left',
        dataIndex: 'mobilePhoneNumber',
        formItemProps: {
          rules: [
            {
              validator: validatorMobilePhone
            }
          ]
        },
    },
    {
        title: '固定电话',
        dataIndex: 'fixedTelephoneNumber',
        formItemProps: {
          rules: [
            {
              required: false,
              whitespace: true,
              message: '此项是必填项',
            },
            { 
              pattern: fixedPhone, 
              message: '请填写正确的固定电话'
            }
          ],
        },
    },
    {
        title: '电子邮箱',
        dataIndex: 'email',
        formItemProps: {
          rules: [
            {
              required: false,
              whitespace: true,
              message: '此项是必填项',
            },
            { 
              pattern: emailReg, 
              message: '请填写正确的邮箱'
            }
          ],
        },
    },
    {
        title: '微信号',
        dataIndex: 'wechatAccount',
        formItemProps: {
          rules: [
            {max: 20, type:'string', message: '20字以内'}
          ],
        },
    },
    // {
    //     title: 'QQ号',
    //     dataIndex: 'qqNumber',
    //     formItemProps: {
    //       rules: [
    //         {max: 20, type:'string', message: '20字以内'}
    //       ],
    //     },
    // },
    {
        title: '钉钉号',
        dataIndex: 'dingNumber',
        formItemProps: {
          rules: [
            {max: 20, type:'string', message: '20字以内'}
          ],
        },
    },
    {
        title: '备注',
        dataIndex: 'remarks',
        formItemProps: {
          rules: [
            {max: 100, type:'string', message: '100字以内'}
          ],
        },
    },
    {
        title: '操作',
        valueType: 'option',
        width: 50,
        fixed:'right',
        render: () => [
          <a key="link">删除</a>,
        ],
    },
  ];
}

// sourceType
export const SourceMaps: any = {
  '0': {
    title: '新建客户',
  },
  '1': {
    title: '新建线索'
  }
}