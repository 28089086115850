import { Button, Dropdown, Modal, Space } from "antd"
import ProForm, { ModalForm, ProFormText, ProFormSelect, ProFormTextArea, ProFormDatePicker, ProFormDateTimePicker } from '@ant-design/pro-form';
import { columns } from "./data";
import { useMemo } from "react";
import { useRequest } from "ahooks";
import React from "react";
import { productApi, request } from "@utils/index";
import { useModalHook, usePlusTableHook } from "@hooks/plusTableHook";
import ProTable from "@ant-design/pro-table";
import { BASEURL } from "@api/index";
import { getBuilderEnums, clearCache } from "@api/customer";
import { stringify } from "qs";
import { useHistory } from "react-router-dom";

// 接口请求的另一种写法，方便 dvm 代码生成 
const getApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/web/sys/sysDiydoFunction/getPage`,
        // method: 'GET',
        data: params
    }) as any
    const { values, total } = data
    return {
        data: values,  // 列表数据
        total: total,   // 列表总条数
        success: true   // 请求成功，关闭表格 loading 等待
    }
}
const delApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoFunction/deleteBatch`,
    // method: 'GET',
    data,
})) 
const addApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoFunction/save`,
    // method: 'GET',
    data,
}))
const updateApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoFunction/save`,
    // method: 'GET',
    data,
}))
const getInfoApi = async (params: any) => {
    const { data } = await request({
        url: `${BASEURL}/web/sys/sysDiydoFunction/getById`,
        method: 'GET',
        params,
    }) as any
    return data
}
const buildByIniApi = productApi((data?: any) => request({
    url: `${BASEURL}/web/sys/sysDiydoFunction/buildByIni`,
    // method: 'GET',
    data,
}))

// 获取页面枚举数据
const getInitDataApi = async () => {
    const { data } = await getBuilderEnums() as any
    return data
} 

// 组件区域
const SysDiydoFunction = () => {
    const history = useHistory()
    const operate = async (action: any, record: any) => {
        const { id : id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (action) {
            //【新增，编辑，删除】操作可能存在一下几种情况:
            // case 1: 理想情况下，不需要额外配置 弹窗，以及接口体交参数
            // case 2: 非理想情况下，需要额外配置 弹窗，以及接口体交参数
            case '新增':
                addAction()        // case 1: 极简模式
                // addAction({     // case 2: 自定义模式
                //     visible: true,             // 配置: 弹窗是否打开
                //     title: '新增弹窗的标题',     // 配置: 弹窗的标题
                //     params: {},                // 配置: 弹窗提交时额外的 提交参数
                //     onFinish: async (values: any) => {  // 复写弹窗提交方法（会覆盖掉 addApi 方法）
                //         console.log('新增弹窗的表单数据', values)
                //         addAction({visible: false})
                //     },
                //     request: async (params: any) => {   // 复写 编辑弹窗时 回填表单数据的方法 (会覆盖掉 getInfoApi 方法)
                //         return {}
                //     },
                // }) 
                break;
            case '编辑':			// editAction 方法 同 addAction 方法一致
                editAction({params: {id}})
                break;
            case '删除':
                delAction({ids: ids})    // case 1: 极简模式
                // Modal.confirm({      			// case 2: 自定义模式
                //     title: '确认要删除该数据吗?',
                //     content: '删除后当前内容将永久删除，不可恢复。',
                //     okText: '确认',
                //     cancelText: '取消',
                //     onOk: async() => {
                //         await delApi?.(params, true, onRefresh)
                //     },
                // });
                break;
            case '字段配置':
                const params = {dfId: id}
                history.push(`/system/sysDiydoField?${stringify(params)}`)
                break;
            case 'json生成配置':
                buildByIniAction({})
                break;
            case '清空服务端缓存':
                await clearCache() as any
                break;
        }
    }

    const { data } = useRequest(getInitDataApi)
    const { 
        selectedRowKeys,
        tableProps, 
        modalProps, 
        addAction, 
        editAction, 
        delAction,
        setModalProps,
        onRefresh
    } = usePlusTableHook({
        delApi,
        updateApi,
        addApi,
        getInfoApi,
        columns: useMemo(() => columns(operate, data), [data])  // 可以将 data 枚举值 透传到 columns 中
    })
    const { 
        modalProps: aModalProps,
        setModalProps: setAModalProps,
        addAction: buildByIniAction,
     } = useModalHook({
        addApi: buildByIniApi
    })
    const calcDisabled = () => !selectedRowKeys.length

    return (<>
        <ProTable
            request={getApi}
            scroll={{x: 1800}}
            toolbar={{
                actions: [
                    <Button type="primary" key="新增" onClick={() => operate?.('新增', null)}>新增</Button>,
                    <Button type="primary" key="json生成配置" onClick={() => operate?.('json生成配置', null)}>json生成配置</Button>,
                    <Button key="清空服务端缓存" onClick={() => operate?.('清空服务端缓存', null)}>清空服务端缓存</Button>,
                    // <Button type="primary" key="导入" onClick={() => operate?.('导入', null)}>导入</Button>,
                    // <Button type="primary" key="导出" onClick={() => operate?.('导出', null)}>导出</Button>,
                    <Dropdown key="批量" menu={{items: [
                        {
                            key: '批量删除',
                            disabled: calcDisabled(),
                            label: <a key="批量删除" onClick={() => operate?.('删除', null)}>批量删除</a>,
                        }
                        
                    ]}}>
                        <Button>批量操作</Button>
                    </Dropdown>
                ]
            }}
            rowKey="id"
            {...tableProps}
        />
        <ModalForm<any>
            layout="horizontal"
            labelCol={{span: 6}}
            width={800}
            {...modalProps}
            grid={true}  // 开启栅格布局，一行允许有多列
            initialValues={{ // 可用于新增时的默认值
                uiType: 101,
                isFinish: 0
            }}
        >
            <ProFormText 
                label="功能别名" 
                name="functionAlias" 
                colProps={{span: 12}}
            />
            <ProFormText 
                label="功能名称" 
                name="functionName"
                colProps={{span: 12}} 
            />
            <ProFormText 
                label="关联别名" 
                name="relationAlias" 
                colProps={{span: 12}}
            />
            <ProFormText 
                label="数据表" 
                name="tableName" 
                colProps={{span: 12}}
            />
            <ProFormSelect 
                label="界面类型" 
                name="uiType" 
                options={data?.uiTypeList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 12}}
            />
            <ProFormSelect 
                label="是否完成" 
                name="isFinish" 
                options={data?.yesNoList?.map(({caption: label, value}: any) => ({label, value}))} 
                rules={[{required: true, message: '请选择'}]} 
                colProps={{span: 12}}
            />
            <ProFormTextArea
                label="备注" 
                name="memo" 
                labelCol={{span: 3}}
            />
        </ModalForm>

        <ModalForm<any>
            layout="horizontal"
            width={800}
            {...aModalProps}
            title="请输入配置内容"
            grid={true}  // 开启栅格布局，一行允许有多列
        >
            <ProFormTextArea
                name="json" 
                fieldProps={{rows: 10}}
            />
        </ModalForm>
    </>)
}

export default SysDiydoFunction