import { StateType } from "@store/index";
import { parseSearch, productApi, treeDataDisabledRules } from "@utils/util"
import { Button, Form } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ProForm, ProFormText, ProFormDateTimePicker, ProFormRadio, ProFormUploadDragger, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form';
import { BackBan, ProFormTreeSelect, ProFormWithCache } from "@components/Common"
import MyEditor from "@components/Common/MyEditor"
import type { ProFormProps } from '@ant-design/pro-form' 
import { knowledgeDocIssueCreate, oaGetSystemNoticeDetails, oaAddEditSystemNotice } from "@api/index"
import '../../index.less'
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

const getQuestionApi = productApi(oaGetSystemNoticeDetails) // 公告回显数据
const addUpdateApi = productApi(oaAddEditSystemNotice) // 新增编辑公告
oaAddEditSystemNotice
interface CreateDocumentProps extends ProFormProps{
    onSuccess?: () => void;
    msgTitle?: any;
    publishTime?: any;
    receiver?: any;
    content?: any;
}
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
};
const checkDocContent = async (rules: any, val: string, callback: Function, params?: Record<string, any>) => {
    if (!val || val === '<p><br></p>') {
        return Promise.reject('请填写正文')
    } else {
        return Promise.resolve()
    }
}
export default () => {
    const [options, setCustomerOptions] = useState([])
    const history = useHistory()
    const { deptWithUserTreeData: treeData } = useSelector((state: StateType) => state)
    const { search } = useLocation()
    const { id = undefined, msgType } = parseSearch(search)

    const onFinish = async (value: any) => {
        const { receiver: ids } = value || {}
        const receiver = (ids||[]).filter((v: any) => v[0] === 's').map((v: string) => v.slice(1))
        const { success } = await addUpdateApi({...value, id, publishTime: moment(value.publishTime).format('YYYY-MM-DD HH:mm:ss'), receiver}, true, ()=> {  history.goBack() })
        return success
    }

    const request = async (params: any) => {
        const { data } = await getQuestionApi(params)
        const { msgTitle, publishTime	, receiver, content} = data || {}
        return {msgTitle, publishTime	, receiver: receiver ? receiver.map((val: any) => 's' + val) : [], content }
    }
    const disabledDate = (current: any) => current && current < moment().add(-1, 'days');
    return (<div className="addMessageMain">
        <BackBan title={id ? '编辑公告' : '新建公告'} style={{marginBottom: 20}} />
        <ProFormWithCache<CreateDocumentProps> 
                layout="horizontal" 
                key={location.href}
                {...layout} 
                params={{id, msgType: Number(msgType)}} 
                request={id && request}
                submitter={{render: (props: any, doms: any) => [
                    <Button key="submit" type="primary" style={{ marginLeft: 154 }} onClick={() => props.form?.submit?.()}>提交</Button>,
                ]}}
                onFinish={onFinish} 
                >
                <ProFormText name="msgTitle" label="消息标题" fieldProps={{showCount: true,  maxLength: 50, placeholder: '请输入标题'}} rules={[{ required: true,message: '标题不能为空'}]} />
                <ProFormTreeSelect name="receiver" label="接收人" fieldProps={{treeData,  maxTagCount: 4, treeCheckable: true, showSearch: true, treeNodeFilterProp: 'title',}}  rules={[{required: true, message: '请选择接收人'}]} />
                <ProFormDateTimePicker width="md" name="publishTime" label="发布时间" fieldProps={{disabledDate, format:"YYYY-MM-DD HH:mm"}} rules={[{ required: true, message: '请选择发布时间',}]} placeholder="请选择" />
                <Form.Item label="消息内容" name="content" wrapperCol={{span: 18}} rules={[{required: true, message: '请填写消息内容', validator: checkDocContent}]}>
                    <MyEditor />
                </Form.Item>
        </ProFormWithCache>
    </div>
  )
}
