import { request } from '@utils/index'
import { stringify } from 'qs';
import { BASEURL } from './baseUrl'
/*-----------------------------------------用户相关----------------------------------*/
//修改密码
export const userUpdateUserPwd = (data?: any) => request({
    url: `${BASEURL}/user/updateUserPwd`,
    data,
})

// 根据部门获取人员列表
export const userListByDeptId = (data?: any) => request({
    url: `${BASEURL}/backUser/getUserInfoList`,
    method: 'GET',
    data,
})

// 新增用户
export const userAdd = (data?: any) => request({
    url: `${BASEURL}/backUser/addUserInfo`,
    data,
})

// 编辑用户
export const userUpdate = (data?: any) => request({
    url: `${BASEURL}/backUser/editUserInfo`,
    data,
})

// 编辑用户  根据id查询回显数据
export const getUserUpdate = (data?: any) => request({
    url: `${BASEURL}/backUser/getUserInfo`,
    method: 'GET',
    data,
})

// 删除用户
export const userDel = (data?: any) => request({
    url: `${BASEURL}/backUser/delUserInfo`,
    data,
})

// 修改用户状态
export const userStatusUpdate = (data?: any) => request({
    url: `${BASEURL}/backUser/editUserStatus`,
    data,
})

// 管理员重置密码  获得用户新密码
export const getNewPwd = (data?: any) => request({
    url: `${BASEURL}/getNewPwd`,
    method: 'GET',
    data,
})

//  设置用户新密码
export const resetUserPwd = (data?: any) => request({
    url: `${BASEURL}/backUser/resetUserPwd`,
    data,
})

// 新增组织架构树
export const deptAdd = (data?: any) => request({
    url: `${BASEURL}/dept/new`,
    data,
})

// 编辑组织架构树
export const deptUpdata = (data?: any) => request({
    url: `${BASEURL}/dept/edit`,
    data,
})

// 校验架构树下是否有员工
export const checkIsMember = (data?: any) => request({
    url: `${BASEURL}/dept/checkIsMember`,
    data,
})

// 删除架构树
export const deptDel = (data?: any) => request({
    url: `${BASEURL}/dept/del`,
    data,
})
// 获取用户权限信息
export const userAuth = (data?: any) => request({
    url: `${BASEURL}/user/getCurrentUser`,
    method: 'GET',
    data,
})
