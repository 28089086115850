import React from 'react'
import { Image } from 'antd';
import ProForm, { ModalForm, } from '@ant-design/pro-form';
import type { ModalFormProps } from '@ant-design/pro-form' 
export interface PreviewProps extends ModalFormProps{
    src?: string;
}
export default ({src, ...rest}: PreviewProps) => {
    return <ModalForm<PreviewProps>  modalProps={{maskClosable: false}} title="预览" {...rest}>
        <Image src={src}></Image>
    </ModalForm>
}