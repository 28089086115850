import React, { useEffect, useRef, useState } from 'react';
import type { ProFormInstance } from '@ant-design/pro-form';
import { Button, Col, Empty, Radio, Row, Select, Tabs } from 'antd';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { columnsFn, reportStatus, reportTypeOptions } from './data'
import type { OperateActionType, TableListItem } from './data'
import './index.less'
import { AddReport, ReportDetail, ReportStatistic, } from './cpnts/index';
import { oaWorkReportList, oaWorkReportSave } from '@api/index'
import { parseSearch, productApi } from '@utils/util';
import { useHasAuthCode } from '@hooks/index';
import { VariableSizeList } from 'react-window';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { Ellipsis } from '@components/index';

const { TabPane } = Tabs
const getApi = productApi(oaWorkReportList)
const reportSaveApi = productApi(oaWorkReportSave)

const RowItem = ({ index, style, isScrolling, data, onClick, className, ...rest}: any) => {
    const record = ((data||[])[index]||{})
    const { type, time } = record
    const timeStr = type == 2 ? moment(record.time?.split('~')[0]).format('YYYY[年第]WW[周]') : time
    return(<div style={style}>
        <Row 
          onClick={() => onClick && onClick(((data||[])[index]||{}))} 
          style={{height: '100%', borderBottom: '1px solid #f0f0f0', cursor: 'pointer'}} 
          justify="space-around" 
          align='middle' 
          className={className && className(((data||[])[index]||{}))} 
          {...rest}>
            <Col span={9} style={{padding: '12px 8px'}}>{timeStr}</Col>
            <Col span={9} style={{padding: '12px 8px'}}><Ellipsis.Nowrap>{((data||[])[index]||{}).title}</Ellipsis.Nowrap></Col>
            <Col span={6} style={{padding: '12px 8px'}}>{(reportStatus[((data||[])[index]||{}).status]||{}).text}</Col>
        </Row>
    </div>
    )
};


export default () => {
    const { search } = useLocation()
    const { reportId, type: reportType } = parseSearch(search)
    const [activeKey, setActiveKey] = useState<string>('0')
    const [type, setType] = useState<any>((reportType||1)*1)
    const [currentRecord, setCurrentRecord] = useState<TableListItem | null>(null)
    const [, hasAuthCode] = useHasAuthCode()

    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi(params, false)
        const { total } = data || {}
        if (reportId) {
            const thatRecord = (data||[]).find((v: any) => v.id == reportId)
            thatRecord && setCurrentRecord(thatRecord || null)
        }
        return {
            data,
            success: true,
            total
        }
    }

    const operate = async (type: OperateActionType, record: TableListItem | null, values: any) => {
        if (type === '行点击') {
            setCurrentRecord(record || null)
        } else if (type === '写日报') {
            await reportSaveApi({...values, status: 1}, true, () => {
                ref.current?.reload()
            })
        }
    }

    const columns = columnsFn(operate)

    return (
      <div className="oa-workReport" style={{boxSizing: 'border-box'}}>
        <Tabs activeKey={activeKey} 
           onChange={(activeKey) => setActiveKey(activeKey)} 
           style={{background: '#fff'}} 
           tabBarStyle={{paddingLeft: 20}} 
           tabBarExtraContent={{
               left: <Select options={reportTypeOptions} bordered={false} value={type} onChange={setType} style={{minWidth: 80, marginRight: 30}}></Select>,
               right: hasAuthCode(2116) ? <AddReport title={`写${['','日','周'][type]}报`} type={type} onSuccess={() => ref.current?.reload()} trigger={<Button key="写日报" type="primary" style={{marginRight: 12}}>写{['','日','周'][type]}报</Button>} /> : null
           }}
        >
            <TabPane tab="全部" key="0"></TabPane>
            <TabPane tab="提交给我的" key="3"></TabPane>
            <TabPane tab="我的报告" key="1"></TabPane>
            <TabPane tab="下属的报告" key="2"></TabPane>
            <TabPane tab="报告统计" key="100">
                <ReportStatistic type={type} />
            </TabPane>
        </Tabs>
       {
           ['0', '1', '2', '3'].includes(activeKey) &&
            <ProTable<TableListItem>
                    scroll={{y: 500}}
                    columns={columns}
                    tableClassName='customScrollBar'
                    params={{range: activeKey, type}}
                    request={!['100'].includes(activeKey) && getListApi as any}
                    options={{reload: false, density: false, setting: false}}
                    toolbar={{}}
                    tableStyle={{boxSizing: 'border-box'}}
                    tableRender={(_: any, dom, ...rest) => {
                        return(
                            <div style={{display: 'flex',width: '100%',}}>
                                <div className='' style={{width: 320, position: 'sticky', top: '150px', background: '#fff'}}>
                                    {/* {dom} */}
                                    <Row justify="space-around" style={{background: '#fafafa'}}>
                                        <Col span={9} style={{padding: '12px 8px'}}>报告时间</Col>
                                        <Col span={9} style={{padding: '12px 8px'}}>报告标题</Col>
                                        <Col span={6} style={{padding: '12px 8px'}}>状态</Col>
                                    </Row>
                                    <VariableSizeList height={500} itemCount={_?.action?.dataSource?.length||0} itemData={_?.action?.dataSource} itemSize={() => 48} >
                                       {(props: any) => <RowItem {...props} onClick={(record: any) => operate('行点击', record, null)} className={({id}: any) => id === (currentRecord||{}).id ? 'jns-vertial-row-selected' : 'jns-vertial-row'}></RowItem>}
                                    </VariableSizeList>
                                </div>
                                <div style={{flex: 1, marginLeft: '16px', background: '#fff', minHeight: '80vh'}}>
                                    { currentRecord ? <ReportDetail record={currentRecord} onSuccess={() => ref.current?.reload()} /> : <Empty description={false} style={{marginTop: '30%'}} /> }    
                                </div>         
                            </div>)
                    }}
                    rowKey="id"
                    pagination={false}
                    search={{
                        optionRender: (searchConfig, formProps, dom) => 
                        [ ...dom.reverse() ],
                      }}
                    formRef={formRef}
                    actionRef={ref as any}
                    // onRow={(record) => ({onClick: () => operate('行点击', record, null)})}
                    // rowClassName={({id}) => id === (currentRecord||{}).id ? 'ant-table-row-selected' : ''}
            />
        }
      </div>
    );
};
