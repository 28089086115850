import React, { Key, ReactNode, useRef, useState } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import { ModalForm, ProFormText, ProFormRadio } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, Modal, Table } from "antd";
import { sysLabelList, sysLabelDel, sysLabelAddUpdate } from '@api/index'
import { productApi } from "@utils/util";
import { useHasAuthCode } from "@hooks/index";
import { useDispatch } from "react-redux";
import { asyncSetLabelTreeData } from "@store/actions";

interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(sysLabelList)
const delApi = productApi(sysLabelDel)
const addUpdateApi = productApi(sysLabelAddUpdate)

interface AddCustomTagProps extends ModalFormProps{
    params?: any,
    onSuccess?: () => void | null;
}
const AddCustomTag = ({params, onSuccess, ...rest}: AddCustomTagProps) => {
    const onFinish = async (values: any) => {
        await addUpdateApi({...values, ...(params||{})}, true, onSuccess)
    }
    return (<ModalForm<AddCustomTagProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 4}} onFinish={onFinish} {...rest}>
            {/* <ProFormText width="md" name="parentName" disabled label="父级标签" rules={[{required: false, message: '请输入'}]} /> */}
            <ProFormText width="md" name="name" label="标签名" rules={[{required: true, message: '请填写标签名'}, {max: 30, message: '最多30字'}]} />
            <ProFormRadio.Group width="md" name="industryLabelStatus" initialValue="1" label="状态" options={[{label: '启用', value: '1'}, {label: '禁用', value: '0'}]} rules={[{required: true, message: '请选择状态'}]} />

        </ModalForm>)
}

export default () => {
    const dispatch = useDispatch();
    const ref = useRef<ActionType>(null);
    const formRef = useRef<ProFormInstance>()
    const [, hasAuthCode] = useHasAuthCode()
    const [addTagsProps, setAddTagsProps] = useState<AddCustomTagProps>({title: '新增角色', visible: false, onSuccess: undefined})

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi(params)
        const { values, total } = data || {}
        return {
            data: values || [],
            total,
            success: true,
        }
    }
    
    const operate = (type: OperateActionType, record?: TableListItem | null) => {
        const { id = '-', name, industryLabelStatus, labelLevel, parentId } = record || {}
        if (['新建标签', '添加子级', '编辑'].includes(type)) {
            formRef.current?.setFieldsValue({name: type === '编辑' ? name : '', industryLabelStatus})
            const params = type === '新建标签' ? {parentId: id} : type === '添加子级' ? { parentId: labelLevel } : { id, parentId }
            setAddTagsProps({
                ...addTagsProps,
                visible: true,
                title: type,
                params,
                onSuccess: () => {
                    ref.current?.reload()
                    setAddTagsProps({...addTagsProps, visible: false})
                    dispatch(asyncSetLabelTreeData())
                }
            })
        } else if(type === '删除') {
            Modal.confirm({
                title: '确认要删除该数据吗?',
                content: '删除后当前内容将永久删除，不可恢复。',
                okText: '确认',
                cancelText: '取消',
                onOk: async() => {
                    await delApi({id}, true, () => {
                        ref.current?.reload()
                        dispatch(asyncSetLabelTreeData())
                    })
                },
            });
        }
    }
    const columns = columnsFn(operate)

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2018) ? <Button key="primary" type="primary" onClick={() => operate('新建标签', null)}>新建标签</Button> : null,
                    ],
                }}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                rowKey="id"
            />
        </div>
        <AddCustomTag {...addTagsProps} formRef={formRef} modalProps={{onCancel: () => setAddTagsProps({...addTagsProps, visible: false}), maskClosable: false}} />
    </div>
}


