import React from 'react'
import { AliRebateConfirm, HuaWeiRebateConfirm, TencentRebateConfirm } from './cpnts'
import { BackBan } from '@components/index'
import { useLocation } from 'react-router-dom'
import { CLOUD_VENDOR, parseSearch } from '@utils/index'

const ConfirmMaps = {
    [CLOUD_VENDOR.ALI]: AliRebateConfirm,
    [CLOUD_VENDOR.HUAWEI]: HuaWeiRebateConfirm,
    [CLOUD_VENDOR.TENCENT]: TencentRebateConfirm
}

export default () => {
    const { search } = useLocation()
    const { id, routeName, cloudVendor } = parseSearch(search)
    // @ts-ignore
    const Confirm = ConfirmMaps[cloudVendor]

    return (<div style={{background: '#fff', paddingBottom: 20}}>
        <BackBan title={routeName || '确认信息'} style={{paddingLeft: 20, position: 'sticky', top: -24, zIndex: 9}} />
        <Confirm id={id} routeName={routeName} />
    </div>)
}
