import { useState } from "react"
import { AddRebackModalForm } from "./cpnts"
import type { AddRebackModalFormProps } from "./cpnts/AddReback"
import { Modal } from "antd"
import { OperateActionType } from "../../data"
import { productApi } from "@utils/index"
import { rebackListDel, rebackListUndo } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"

const rebackListDelApi = productApi(rebackListDel)
const rebackListUndoApi = productApi(rebackListUndo)

export const useAddRebackHooks = () => {
    const history = useHistory()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [addRebackModalFormProps, setAddRebackModalFormProps] = useState<AddRebackModalFormProps>({title: '新建回款记录', visible: false, params: {}})
    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { backId } = record || {}

        const backIds = backId ? [backId] : selectedRowKeys
        // 新建回款记录
        const addRebackAction = (props: Object = {}) => setAddRebackModalFormProps({
            ...addRebackModalFormProps,
            title: '新建回款记录',
            visible: true,
            params: { noCache: new Date() },
            onSuccess: () => {
                setAddRebackModalFormProps({visible: false})
                onSuccess?.()
            }
        })

         // 编辑回款记录
         const updateRebackAction = (props: Object = {}) => setAddRebackModalFormProps({
            ...addRebackModalFormProps,
            title: '编辑回款记录',
            visible: true,
            params: {backId, noCache: new Date()},
            onSuccess: () => {
                setAddRebackModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        // 回款记录一键申请
        const quickAddRebackAction = (props: Object = {}) => setAddRebackModalFormProps({
            ...addRebackModalFormProps,
            title: '一键申请回款记录',
            visible: true,
            params: {originId: backId, noCache: new Date()},
            onSuccess: () => {
                setAddRebackModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        // 撤销申请-回款记录
        const undoAction = (params: Object = {}) => Modal.confirm({
            title: '撤销申请',
            content: '确定撤销已提交的回款审批？',
            cancelText: '取消',
            onOk: async () => {
                await rebackListUndoApi({backIds}, true, onSuccess)
            },
        })

        // 删除-回款记录
        const delAction = (params: Object = {}) => Modal.confirm({
            title: '删除',
            content: '确定删除已选中的数据？删除成功之后，将无法恢复',
            cancelText: '取消',
            onOk: async () => {
                await rebackListDelApi({backIds}, true, onSuccess)
            },
        })

        const toRebackDetailAction = (params: Object = {}) => history.push(`/finance/rebackDetail?${stringify({backId, status, routeName: '回款记录详情', fromPath: '/finance/rebackMoney'})}`)

        return {
            addRebackAction,
            updateRebackAction,
            quickAddRebackAction,
            undoAction,
            delAction,
            toRebackDetailAction
        }
    }

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            addRebackAction,
            updateRebackAction,
            quickAddRebackAction,
            undoAction,
            delAction,
            toRebackDetailAction
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '回款记录-新建':
                    return addRebackAction()
                case '回款记录-编辑':
                    return updateRebackAction()
                case '回款记录-一键申请':
                    return quickAddRebackAction()
                case '回款记录-撤销申请':
                    return undoAction()
                case '回款记录-删除':
                    return delAction()
                case '回款记录-详情':
                    return toRebackDetailAction()
            }
        }
    }

    return {
        addRebackModalFormProps,
        setAddRebackModalFormProps,
        createOperate,
        selectedRowKeys,
        setSelectedRowKeys
    }

}