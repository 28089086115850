import React, { useState } from "react";
import { Input, Select, DatePicker, TreeSelect, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { Double, Ellipsis, Icon } from "@components/index";
import { Link, useHistory } from "react-router-dom";
import { useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { customerSeePhoneNumber } from '@api/index'

/**
 * msgType: 1.1: 是任务, 1.2: 是公告, 2: 为系统公告
*/
// ts--types
export type TableListItem = {
    id: number;
    msgId: number;
    msgTitle? :string;
    msgType: number;
    customerName?: string;
    taskCenterId?: number; //任务id有的话就跳转任务详情
    readStatus?: string;
    receiverTime? : Array<any>;
};

const MsgLinkRender = ({ text, hasAuth = true, search }: any) => {
  const history = useHistory()
  const onClick = (e: React.MouseEvent) => {
    const url = `/oa/messageDetail?${stringify(search||{})}`
    if (hasAuth) {
      e.ctrlKey ? window.open(url, '_blank') : history.push(url)
    }
  }
  return <a onClick={onClick} onMouseDown={onClick}><Ellipsis text={text as any || '-'} /></a>
}

export const columnsFn: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
  const [, hasAuthCode] = useHasAuthCode()
  return [
    {
        title: '消息标题',
        dataIndex: 'msgTitle',
        order: 6,
        render: (text, {id, msgId, msgType, msgTitle, customerName, taskCenterId}) => <MsgLinkRender text={msgTitle} search={{id, msgId, msgType, customerName}} />
    },
    {
        title: '接收时间',
        dataIndex: 'receiverTime',
        order: 2, 
        valueType: 'dateRange',
        fieldProps: { 
          format:"YYYY-MM-DD",
        },
        search: {
          transform: (value) => {
            return {
              receiverTime: [value[0] + ' 00:00:00',value[1]+ ' 23:59:59']
            }
          },
        },
        render: (text: any, record: any) => `${record.receiverTime|| '-'}`
    },
    {
        title: '消息状态',
        dataIndex: 'readStatus',
        order: 1,
        hideInSearch: true,
        valueEnum: {
            0: { text: '未读', status: 'Default' },
            1: { text: '已读', status: 'Processing' },
          },
    }
  ]
}
