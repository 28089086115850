import { Empty, Result } from 'antd'
import React from 'react'
import noDocSearchImg from '@assets/images/no-doc-search.png'
import './index.less'

export const NoPermission = () => {
   return <Result status="403" title="403" subTitle="暂无权限,请联系管理员!" />
}

export const SearchEmpty = () => {
   return(<Empty
      image={noDocSearchImg}
      style={{height: '66vh', paddingTop: '15%'}}
      imageStyle={{ height: 120 }}
      description={<div><h3 style={{color: 'rgba(0, 0, 0, 0.85)'}}>未查询到相关知识内容</h3><p style={{color: 'rgba(0, 0, 0, 0.45)'}}>建议您修改搜索关键词重新再试</p></div>}
  >
  </Empty>)
}

export default () => {
   return <div className="notfont-box"></div>
}
