import React, { useEffect, useRef, useState } from "react"
import { columnsFn } from './data'
import type { TableListItem } from './data'
import ProTable, { ActionType } from '@ant-design/pro-table';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form'
import { oaGetMessageUpdateMsgStatus, oaGetmyMsg } from '@api/index' 
import { productApi } from "@utils/util";
import { ProTableWithFormSetting } from "@components/index";
import { useHasAuthCode } from "@hooks/index";
import { asyncSetMessageNumber } from "@store/actions/common";
import { useDispatch } from "react-redux";
import { Button, Modal, Tabs } from "antd";
import { CustomerViewProps } from "@components/Customer/List";

const { TabPane } = Tabs

const getApi = productApi(oaGetmyMsg)
const oaGetMessageUpdateMsgStatusApi = productApi(oaGetMessageUpdateMsgStatus)

type OperateActionType = '已读'

// 客户视图
const CustomerView = ({view, scope, onChange}: CustomerViewProps) => {
    return <Tabs 
            onChange={(val) => onChange && onChange('scope', val)} 
            style={{background: '#fff'}}
            tabBarStyle={{paddingLeft: 20}}
        >
        <TabPane tab="全部" key=" "></TabPane>
        <TabPane tab="已读" key="1"></TabPane>
        <TabPane tab="未读" key="0"></TabPane>
    </Tabs>
}

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const dispatch = useDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);

    const [view, setView] = useState<string>('')
    const [scope, setScope] = useState<string>('')
    
    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const { id } = record || {}
        const ids = id ? [id] : selectedRowKeys
        switch (type) {
            case '已读':
                Modal.confirm({
                    title: '温馨提示',
                    content: '确定将消息设置为已读？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: async() => {
                        await oaGetMessageUpdateMsgStatusApi({ids}, true, reFush)
                    },
                })
        }
    }

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values || [],
            success: true,
            total
        }
    }

    const calcDisabled = () => !selectedRowKeys.length
    const customerViewOnChange = (type: string, val: string) => type === 'view' ? setView(val) : setScope(val)
    const params = { readStatus: scope }

    const reFush = () => {
        // @ts-ignore
        ref.current?.clearSelected()
        ref.current?.reload()
    }
     
    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <CustomerView view={view} scope={scope} onChange={customerViewOnChange} />
            <ProTable<TableListItem>
                columns={columnsFn()}
                params={params}
                request={getListApi}
                options={false}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ],
                }}
                tableStyle={{marginTop: '15px'}}
                toolbar={{
                    actions: [
                        hasAuthCode(-1) ? <Button key="btn6" type="primary" disabled={calcDisabled()} onClick={() => operate('已读')}>已读</Button> : null,
                    ],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                // rowSelection={false}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                }}
                columnsState={{ persistenceKey: 'oa/myMessage', persistenceType: 'localStorage'}}
            />
        </div>
    </div>
}
