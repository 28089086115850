import React, {useState, useMemo} from "react";
import { ProColumns } from "@ant-design/pro-table";
import { Row, Col, Modal, Image  } from "antd";
import { Icon } from '@components/index'
// ts--types
export type TableListItem = {
    key: number;
    id: number;
    customerAccount: string;
    holderCompany: object;
} | Record<string, any>;

// ali-aps原始消费订单
export const apsColumns = [
    {
        title: '账期',
        dataIndex: 'billMonth',
        key: 'billMonth'
    },
    {
        title: '账单类型',
        dataIndex: 'billType',
        key: 'billType'
    },
    {
        title: '付款类型',
        dataIndex: 'payType',
        key: 'payType'
    },
    {
        title: '消费时间',
        dataIndex: 'consumeTime',
        key: 'consumeTime'
    },
    {
        title: '客户账号',
        dataIndex: 'account',
        key: 'account'
    },
    {
        title: '客户类型',
        dataIndex: 'customerType',
        key: 'customerType'
    },
    {
        title: '产品code',
        dataIndex: 'proCode',
        key: 'proCode'
    },
    {
        title: '产品名称',
        dataIndex: 'proName',
        key: 'proName'
    },
    {
        title: '产品分类',
        dataIndex: 'proClassify',
        key: 'proClassify'
    },
    {
        title: '云产品实例ID',
        dataIndex: 'cloudProId',
        key: 'cloudProId'
    },
    {
        title: '员工账号',
        dataIndex: 'staffAccount',
        key: 'staffAccount'
    },
    {
        title: '项目编号',
        dataIndex: 'projectNo',
        key: 'projectNo'
    },
    {
        title: '实付金额',
        dataIndex: 'payAmount',
        key: 'payAmount'
    },
    {
        title: '主账单ID',
        dataIndex: 'orderId',
        key: 'orderId'
    },
    {
        title: '子订单ID',
        dataIndex: 'subOrderId',
        key: 'subOrderId'
    },
    {
        title: '订单服务时长',
        dataIndex: 'orderServDuration',
        key: 'orderServDuration'
    },
    {
        title: '订单类型',
        dataIndex: 'orderType',
        key: 'orderType'
    },
    {
        title: '原订单ID',
        dataIndex: 'originOrderId',
        key: 'originOrderId'
    }
]

// 汇总信息
export const summaryColumns = [
    {
        title: '客户名称',
        dataIndex: 'customerName',
        ellipsis: true,
        key: 'customerName'
    },
    {
        title: '客户账号',
        dataIndex: 'customerAccount',
        key: 'customerAccount'
    },
    {
        title: '客户经理',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: '合作模式',
        dataIndex: 'cooperationMode',
        key: 'cooperationMode'
    },
    {
        title: '有无票据',
        dataIndex: 'hasInvoice',
        key: 'hasInvoice',
        valueEnum: {
            0: { text: '无' },
            1: { text: '有'},
        },
    },
    {
        title: '消费金额',
        dataIndex: 'amountPaid',
        key: 'amountPaid'
    },
    {
        title: '预计返点金额',
        dataIndex: 'predictRebates',
        key: 'predictRebates'
    },
    {
        title: '实际返点金额',
        dataIndex: 'practicalRebates',
        key: 'practicalRebates'
    },
]

/// 返点信息
export const rebateColumns = [
    {
        title: '维度子类',
        dataIndex: 'sonDimension',
        key: 'sonDimension'
    },
    {
        title: '消费金额',
        dataIndex: 'consumeAmount',
        key: 'consumeAmount'
    },
    {
        title: '抵扣退款金额',
        dataIndex: 'deductAmount',
        key: 'deductAmount'
    },
    {
        title: '返点比例',
        dataIndex: 'rebatePercent',
        key: 'rebatePercent',
        render: (text: string) => text + '%'
    },
    {
        title: '预计返点金额',
        dataIndex: 'expectRebateAmount',
        key: 'expectRebateAmount'
    }
]


/// 收款信息
export const collectionColumns = [
    {
        title: '收款人类型',
        dataIndex: 'payeeType',
        key: 'payeeType'
    },
    {
        title: '收款人',
        dataIndex: 'payeeName',
        key: 'payeeName'
    },
    {
        title: '银行卡账号',
        dataIndex: 'bankAccount',
        ellipsis: true,
        key: 'bankAccount'
    },
    {
        title: '开户行',
        dataIndex: 'bank',
        key: 'bank'
    },
    {
        title: '支付宝账号',
        dataIndex: 'alipayAccount',
        key: 'alipayAccount'
    },
    {
        title: '预计返点金额',
        dataIndex: 'expectRebateAmount',
        key: 'expectRebateAmount'
    },
    {
        title: '实际返点金额',
        dataIndex: 'practicalRebates',
        key: 'practicalRebates'
    },
    {
        title: '待打款金额',
        dataIndex: 'unAmount',
        key: 'unAmount'
    },
    {
        title: '打款负责人',
        dataIndex: 'remitPrincipal',
        key: 'remitPrincipal'
    }
]

// 收款人列表操作记录
export const getMoneyLogColumns = [
    { title: '操作类型', dataIndex: 'type', key: 'type' },
    { title: '操作内容', dataIndex: 'content', key: 'content' },
    { title: '原因', dataIndex: 'reason', key: 'reason' },
    { title: '凭证', dataIndex: 'files', key: 'files', render: (text: any, record: any) => <ImgListDiv imgList={(record.files||[]).map((v: any) => v.url)||[]}/>},
    { title: '操作人', dataIndex: 'operator', key: 'operator' },
    { title: '操作时间', dataIndex: 'time', key: 'time' }
]

/// 备注信息
export const remarksColumns = [
    {
        title: '表单名称',
        dataIndex: 'form',
        key: 'form'
    },
    {
        title: '备注内容',
        dataIndex: 'remarkDetails',
        ellipsis: true,
        key: 'remarkDetails'
    },
    {
        title: '提交人',
        dataIndex: 'submitterName',
        key: 'submitterName'
    }
]
// 表格内图片查看组件   批量
const ImgListDiv = ({imgList}:any) => {
    return (<div>
        <Image.PreviewGroup>
            {(imgList||[]).map((element: string, index: number) => {
                // const { url } = element || {}
                return <Image width={50} style={{marginRight:'50px'}} key={index} src={element} />
            })}
        </Image.PreviewGroup>
    </div>)
}
/// 凭证图片
export const voucherColumns: (operate?: Function) => ProColumns<TableListItem>[] = (operate) => {
    // const typeList = (type: string) => {
    //     switch (type) {
    //         case '2':
    //            return '认领'
    //         case '3':
    //             return '追回'
    //         default:
    //             return '未定义类型'
    //     }
    // }
    return [
        {
            title: '表单名称',
            dataIndex: 'type',
            key: 'type',
            // render: (text: any) => typeList(text),
            valueEnum: {
                '2': { text: '认领'},
                '3': { text: '追回'},
                '4': { text: '收款人相关'},
            },
            hideInSearch: true
        },
        {
            title: '图片',
            dataIndex: 'urls',
            key: 'urls',
            hideInSearch: true,
            render: (text, record: any) => <ImgListDiv imgList={record.urls||[]}/>
        },
        {
            title: '提交人',
            dataIndex: 'uploader',
            hideInSearch: true,
            key: 'uploader',
        }
]}

// 华为-aps原始消费订单
export const huaWeiApsColumns = [
    {
        title: '账期',
        dataIndex: 'billMonth',
        key: 'billMonth'
    },
    {
        title: '客户名称',
        dataIndex: 'customerName',
        key: 'customerName'
    },
    {
        title: '账号',
        dataIndex: 'account',
        key: 'account'
    },
    {
        title: '关联类型',
        dataIndex: 'relateType',
        key: 'relateType'
    },
    {
        title: '客户经理名称',
        dataIndex: 'acustomerOwnerName',
        key: 'customerOwnerName'
    },
    {
        title: '产品类型',
        dataIndex: 'proClassify',
        key: 'proClassify'
    },
    {
        title: '产品名称',
        dataIndex: 'proName',
        key: 'proName'
    },
    {
        title: '计费模式',
        dataIndex: 'feeModel',
        key: 'feeModel'
    },
    {
        title: '消费时间',
        dataIndex: 'consumeTime',
        key: 'consumeTime'
    },
    {
        title: '订单号/交易号',
        dataIndex: 'orderId',
        key: 'orderId'
    },
    {
        title: '账单类型',
        dataIndex: 'billType',
        key: 'billType'
    },
    {
        title: '交易时间',
        dataIndex: 'transactionTime',
        key: 'transactionTime'
    },
    {
        title: '资源名称/ID',
        dataIndex: 'resourceName',
        key: 'resourceName'
    },
    {
        title: '规格',
        dataIndex: 'standard',
        key: 'standard'
    },
    {
        title: '区域',
        dataIndex: 'area',
        key: 'area'
    },
    {
        title: '可用区',
        dataIndex: 'areaUsed',
        key: 'areaUsed'
    },
    {
        title: '使用量类型',
        dataIndex: 'usedAmountType',
        key: 'usedAmountType'
    },
    {
        title: '单价(¥)',
        dataIndex: 'price',
        key: 'price'
    },
    {
        title: '单价单位',
        dataIndex: 'priceUnit',
        key: 'priceUnit'
    },
    {
        title: '使用量',
        dataIndex: 'usedAmount',
        key: 'usedAmount'
    },
    {
        title: '使用量单位',
        dataIndex: 'usedAmountUnit',
        key: 'usedAmountUnit'
    },
    {
        title: '套餐内使用量',
        dataIndex: 'packageUsedAmount',
        key: 'packageUsedAmount'
    },
    {
        title: '官网价(¥)',
        dataIndex: 'websitePrice',
        key: 'websitePrice'
    },
    {
        title: '优惠金额(¥)',
        dataIndex: 'favourableMoney',
        key: 'favourableMoney'
    },
    {
        title: '应付金额(¥)',
        dataIndex: 'shouldMoney',
        key: 'shouldMoney'
    },
    {
        title: '现金支付(¥)',
        dataIndex: 'nowPay',
        key: 'nowPay'
    },
    {
        title: '信用额度支付(¥)',
        dataIndex: 'creditPay',
        key: 'creditPay'
    },
    {
        title: '代金券抵扣(¥)',
        dataIndex: 'voucherDeduct',
        key: 'voucherDeduct'
    },
    {
        title: '现金券抵扣(¥)',
        dataIndex: 'moneyDeduct',
        key: 'moneyDeduct'
    },
    {
        title: '储值卡抵扣(¥)',
        dataIndex: 'bankcardDeduct',
        key: 'bankcardDeduct'
    },
    {
        title: '欠费金额(¥)',
        dataIndex: 'oweMoney',
        key: 'oweMoney'
    },
    {
        title: '子资源ID',
        dataIndex: 'childrenResourceId',
        key: 'childrenResourceId'
    },
    {
        title: '子资源名称',
        dataIndex: 'childrenResourceName',
        key: 'childrenResourceName'
    },
    {
        title: '子资源产品类型',
        dataIndex: 'childrenResourceProductType',
        key: 'childrenResourceProductType'
    },
    {
        title: '子资源产品',
        dataIndex: 'childrenResourceProduct',
        key: 'childrenResourceProduct'
    },
    {
        title: '使用量(定价量纲)',
        dataIndex: 'usedAmountCalm',
        key: 'usedAmountCalm'
    },
    {
        title: '使用量单位(定价量纲)',
        dataIndex: 'usedAmountUnitCalm',
        key: 'usedAmountUnitCalm'
    },
    {
        title: '套餐内用量(定价量纲)',
        dataIndex: 'packageUsedAmountCalm',
        key: 'packageUsedAmountCalm'
    },
    {
        title: '预留实例内用量(定价量纲)',
        dataIndex: 'reserveUsedAmount',
        key: 'reserveUsedAmount'
    },
    {
        title: '使用量转换比率',
        dataIndex: 'usedAmountCovertRate',
        key: 'usedAmountCovertRate'
    },
    {
        title: '产品',
        dataIndex: 'product',
        key: 'product'
    },
    {
        title: '组合交易单号',
        dataIndex: 'combineOrderId',
        key: 'combineOrderId'
    },
]

// 华为-aps原始消费订单
export const tencentApsColumns = [
    {
        title: '订单号',
        dataIndex: 'orderId',
        key: 'orderId'
    },
    {
        title: '支付时间',
        dataIndex: 'payTime',
        key: 'payTime'
    },
    {
        title: '客户账号ID',
        dataIndex: 'account',
        key: 'account'
    },
    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark'
    },
    {
        title: '客户类型',
        dataIndex: 'customerType',
        key: 'customerType'
    },
    {
        title: '业务员名称',
        dataIndex: 'salesPerson',
        key: 'salesPerson'
    },
    {
        title: '云产品',
        dataIndex: 'cloudProduct',
        key: 'cloudProduct'
    },
    {
        title: '产品二层信息',
        dataIndex: 'productTwoInfo',
        key: 'productTwoInfo'
    },
    {
        title: '支付方式',
        dataIndex: 'payMethod',
        key: 'payMethod'
    },
    {
        title: '付款方式',
        dataIndex: 'payMoneyMethod',
        key: 'payMoneyMethod'
    },
    {
        title: '费用(元)',
        dataIndex: 'fee',
        key: 'fee'
    }
]
