import { useRef, useState } from "react"
import { AddSaleContractModalFormProps } from './cpnts/AddSaleContract'
import { OperateActionType } from "../../data"
import ProForm, { ProFormInstance } from '@ant-design/pro-form'
import { Modal } from "antd"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { productApi } from "@utils/index"
import { contractDel, contractUndo } from "@api/index"

const contractDelApi = productApi(contractDel)
const contractUndoApi = productApi(contractUndo)

export const useSaleContractHooks = () => {
    const history = useHistory()
    const formRef = useRef<ProFormInstance>()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [commonModalFormProps, setCommonModalFormProps] = useState<AddSaleContractModalFormProps>({title: '新建销售合同', visible: false, params: {}})

    // 函数调用
    const createAction = (record: any, onSuccess?: Function) => {
        const { contractId, xsContractNumber } = record || {}
        const contractIds = contractId ? [contractId] : selectedRowKeys

        // 添加销售合同
        const addSaleContractAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '新建销售合同',
            visible: true,
            params: { noCache: new Date() },
            onSuccess: () => {
                setCommonModalFormProps({visible: false})
                onSuccess?.()
            }
        })

         // 编辑销售合同
         const updateSaleContractAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '编辑销售合同',
            visible: true,
            params: { contractId, noCache: new Date() },
            onSuccess: () => {
                setCommonModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        // 一键申请销售合同
        const quickAddSaleContractAction = (props: Object = {}) => setCommonModalFormProps({
            ...commonModalFormProps,
            title: '一键申请销售合同',
            visible: true,
            params: { originId: contractId, noCache: new Date() },
            onSuccess: () => {
                setCommonModalFormProps({visible: false})
                onSuccess?.()
            }
        })

        // 撤销申请
        const undoAction = (params: Object = {}) => Modal.confirm({
            title: '撤销申请',
            content: '确定撤销已提交的合同审批？',
            cancelText: '取消',
            onOk: async () => {
                await contractUndoApi({contractIds}, true, onSuccess)
            },
        })

        // 删除
        const delAction = (params: Object = {}) => Modal.confirm({
            title: '删除',
            content: '确定删除已选中的数据？删除成功之后，将无法恢复',
            cancelText: '取消',
            onOk: async () => {
                await contractDelApi({contractIds}, true, onSuccess)
            },
        })

        // 发票申请
        const toFinanceBillAddAction = (params: Object = {}) => history.push(`/finance/billAdd?${stringify({status, routeName: '一键开票', contractNumber: xsContractNumber, fromPath: '/finance/contract'})}`)

        return {
            addSaleContractAction,
            updateSaleContractAction,
            quickAddSaleContractAction,
            undoAction,
            delAction,
            toFinanceBillAddAction
        }
    }

    const calcDisabled = () => !selectedRowKeys.length

    const createOperate = (record: any, onSuccess?: Function) => {
        const { 
            addSaleContractAction,
            updateSaleContractAction,
            quickAddSaleContractAction,
            undoAction,
            delAction,
            toFinanceBillAddAction
        } = createAction(record, onSuccess)
        return (type: OperateActionType, params: any) => {
            switch (type) {
                case '销售合同-新建':
                    return addSaleContractAction()
                case '销售合同-编辑':
                    return updateSaleContractAction()
                case '销售合同-一键申请':
                    return quickAddSaleContractAction()
                case '销售合同-撤销申请':
                    return undoAction()
                case '销售合同-删除':
                    return delAction()
                case '销售合同-发票申请':
                    return toFinanceBillAddAction()
            }
        }
    }

    return {
        formRef,
        commonModalFormProps,
        setCommonModalFormProps,
        createOperate,
        selectedRowKeys, 
        setSelectedRowKeys,
        calcDisabled
    }
    
}
