import React, { useEffect, useState } from "react"
import { MenuRouter } from "@router/index";
import { getPathStage, isDef, parseSearch, treeDataToFlatArr } from "@utils/index";
import { Breadcrumb, Tabs, Tooltip } from "antd"
import { useHistory, useLocation } from "react-router-dom"
import { Icon } from "..";
import { useCacheDispatch, } from 'react-keepalive-router'
import { useFormDataCache } from "@hooks/index";
const { TabPane } = Tabs

const Breadcrumbs = () => {
    const { pathname, search } = useLocation();
    const { fromPath } = parseSearch(search)
    const pathStage = [...getPathStage(pathname), fromPath || pathname]
    const flatArr = treeDataToFlatArr(MenuRouter, false)
    const breadcrumbArr = pathStage.map(v => {
        return flatArr.find(r => v === r.path) || {}
    })
    return (<Breadcrumb style={{background: '#fff', boxSizing: 'border-box', padding: 16, borderTop: '1px solid #F1F3F6'}}>
        <Breadcrumb.Item key="home"><Icon type="icon-shouye1" /><a href='/home'>首页</a></Breadcrumb.Item>
        {
            (breadcrumbArr||[]).map(v => <Breadcrumb.Item key={v.path}><a href={v.path}>{v.name}</a></Breadcrumb.Item>)
        }
      </Breadcrumb>)
}


export default () => {
    const [routeList, setRouteList] = useState<any[]>([])
    const [,,,removeCacheKeys] = useFormDataCache()
    const dispatch = useCacheDispatch()
    const { pathname, search } = useLocation();
    const history = useHistory()
    const {name: sName, customerName, fromPath, routeName, routeTitName } = parseSearch(search) || {}
    const fullPath = `${pathname}${search}`
    const title = routeTitName || sName || customerName

    const flatArr = treeDataToFlatArr(MenuRouter, false)
    const onEdit = (targetKey: any, action: string) => {
       if (action === 'remove') {
           if (routeList.length > 1) {
                const { cacheId } = routeList.find(({fullPath}) => fullPath === targetKey)
                const newRouteList = routeList.filter(({fullPath}) => fullPath !== targetKey)
                fullPath === targetKey && history.push(newRouteList[newRouteList.length -1].fullPath)
                removeCacheKeys(fullPath)
                setRouteList(newRouteList)
                // 叉掉的时候消除页面缓存
                dispatch({ type:'reset',payload: cacheId })
           }
       }
    }

    const uniqueRouteList = (pathname: string, unique: boolean = true) => {
        const { name, path, cacheId: calcCacheId } = flatArr.find(v => v.path === pathname) || {}
        const cacheId = (!isDef(calcCacheId) || typeof calcCacheId === 'string') ? (calcCacheId || path) : calcCacheId()
        const tabContent = routeName || name
        if (unique) {
            const unqiueTabContent = [...new Set(routeList.map(({tabContent}) => tabContent))]
            const idx = unqiueTabContent.indexOf(tabContent) 
            const newRouteList = idx < 0 ? [...routeList, {tabContent, title, fullPath, cacheId }] : (routeList[idx] = {tabContent, title, fullPath, cacheId }, routeList)
            setRouteList([...newRouteList])
        } else {
            const unqiueFullPath = [...new Set(routeList.map(({fullPath}) => fullPath))]
            !unqiueFullPath.includes(fullPath) && setRouteList([...routeList, {tabContent, title, fullPath, cacheId}])
        }
    }
    
    useEffect(() => {
        if (!['/'].includes(pathname)) {
            uniqueRouteList(pathname)
        }
    }, [pathname])

    const Tab = (v: any) => {
        return <Tooltip placement="top" title={v.title}>{v.tabContent}</Tooltip>
    }

    return (<Tabs activeKey={fullPath} onChange={history.push} onEdit={onEdit} type="editable-card" hideAdd tabBarStyle={{margin: 0}}>
      {
        routeList.map(v => <TabPane key={v.fullPath} tab={Tab(v)} />)
      }
    </Tabs>)
}
