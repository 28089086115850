import React from "react";
import { ProColumns } from "@ant-design/pro-table";
import { LqCompanyTableRender } from "../Search/data";

export type TableListItem = {
    key: number;
    id: string;
    attention: number | string;
    isDefault: number;
    customerName: string;
    customerTags: string;
    customerLevel: string;
    customerOrigin: string;
    customerArea: string;
    customerStatus: string;
    customerRecord: string;
    customerBelong: string;
    createTime: string;
    lastUpdateTime: string;
    notFollowDays: string;
    isReceive: number;
};

export type OperateActionType = '新建线索池' | '编辑' | '删除' 

export const columnsFn: (search: Object, operate?: Function) => ProColumns<TableListItem>[] = (search = {}, operate) => {
  return [
    {
        title: '企业名称',
        dataIndex: 'name',
        hideInTable: true,
    },
    {
        title: '企业信息',
        hideInSearch: true,
        dataIndex: 'companyName',
        order: 100,
        width: 520,
        fixed: 'left',
        render: (text: any,record: any) => <LqCompanyTableRender dataObj={record} search={{name: record.companyName, lqId: record.id, type: 2, module: 2, routeName: '企业详情', fromPath: '/lq/mySearch', ...search}} />
    },
    {
        title: '客户来源',
        hideInSearch: true,
        dataIndex: 'companySource',
    },
    {
        title: '云服务供应商',
        hideInSearch: true,
        dataIndex: 'cloudServiceProvider',
    },
    {
        title: '所属行业',
        hideInSearch: true,
        dataIndex: 'companyIndustry',
    },
    {
        title: '参保人数',
        hideInSearch: true,
        dataIndex: 'companyCbzz',
        sorter: true
    },
    {
        title: '有无ICP备案',
        hideInSearch: true,
        dataIndex: 'isIcp',
        render: (text: any, record: any) => text ? '有' : '无'
    },
    {
        title: '高新技术企业',
        hideInSearch: true,
        dataIndex: 'isTechnology',
        render: (text: any, record: any) => text ? '是' : '否'
    },
    {
        title: '有无公安备案',
        hideInSearch: true,
        dataIndex: 'isRecordno',
        render: (text: any, record: any) => text ? '有' : '无'
    },
    {
        title: '最新招聘时间',
        hideInSearch: true,
        dataIndex: 'positionTime',
        sorter: true
    },
    {
        title: '领取状态',
        dataIndex: 'isTaken',
        width: 100,
        valueEnum: {
          0: { text: '未领取', status: 'Default' },
          1: { text: '已领取', status: 'Processing' },
        },
        fixed: 'right',
        render: (text: any, {bz}: any) => bz
    },
  ]
}
