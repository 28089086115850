import React, { Key, useRef } from "react"
import { columnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable from '@ant-design/pro-table';
import { productApi } from "@utils/index";
import { lqOperateLogs } from '@api/index'
import { useHasAuthCode } from "@hooks/index";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(lqOperateLogs)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: OperateActionType, record?: TableListItem | null) => {}
    const columns = columnsFn(operate)

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                scroll={{x: 1300}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{}}
                rowKey={(_, idx) => `${idx}`}
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                search={{
                    optionRender: (searchConfig, formProps, dom) => 
                    [ ...dom.reverse() ]
                }}
            />
        </div>
    </div>
}
