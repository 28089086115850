import { ProColumns } from "@ant-design/pro-table";
import { AuthActionArr } from "@components/EnterpriseDetail/type";
import { Ellipsis } from "@components/index";
import { StateType } from "@store/index";
import { getFileNameByUrl, isImageFile, treeDataDisabledRules } from "@utils/index";
import { TreeSelect, Image } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const AffixPreview = ({urls}: any) => {
    const files = Array.isArray(urls) ? urls : (urls||'').split(',');
    return (<Image.PreviewGroup>
        {files?.map((url: string, i: number) => {
            return isImageFile(url) ? <Image width={80} key={i} src={url} /> : <div key={i}><a href={url}>{getFileNameByUrl(url)}</a></div>
        })}
      </Image.PreviewGroup>)
}

export const columns: (operate: any, data: any, authCode?: AuthActionArr) => ProColumns<any>[] = (operate, data, authCode) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    return [
        {
            title: '模块',
            dataIndex: 'model',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 200,
            render: (text) => <Ellipsis text={text as any||'-'} />
        },
        {
            title: '业务系统',
            dataIndex: 'businessSysName',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 200,
            render: (text) => <Ellipsis text={text as any||'-'} />
        },
        {
            title: '资源',
            dataIndex: 'cloudResource',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 200,
            render: (text) => <Ellipsis text={text as any||'-'} />
        },
        {
            title: '资源详情',
            dataIndex: 'resourceDetail',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 200,
            render: (text) => <Ellipsis text={text as any||'-'} />
        },
        {
            title: '资源所用平台',
            dataIndex: 'resourcePlat',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 200,
            render: (text) => <Ellipsis text={text as any||'-'} />
        },
        {
            title: '附件',
            dataIndex: 'accessory',
            hideInSearch: true,
            hideInTable: false,
            hideInForm: false,
            width: 150,
            // render: (text: any, record: any) => (record.accessory||'').split(',').map((v: any) => (<div key={v}><a href={v}>{v}</a></div>))
            render: (text: any, record: any) => <AffixPreview urls={record.accessory} />
        },
        {
            title: '创建人',
            dataIndex: 'createId',
            valueType: 'select',
            width: 90,
            hideInSearch: false,
            hideInTable: false,
            hideInForm: true,
            fieldProps: {
                    maxTagCount: 4,
                    treeCheckable: true,
                    showSearch: true,
                    treeNodeFilterProp: 'title',
                    treeData: treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
                },
                renderFormItem: (props) => <TreeSelect />,
                search: {
                    transform: (ids) => {
                        const createIds = (ids||[]).map((v: string) => v.slice(1))
                        return { createIds }
                        // return {
                        //     createId: (ids||'').slice(1)
                        // }
                    },
                },
            render: (text: any, record: any) => record.createIdName
        },
        {
            title: '创建时间',
            valueType: 'dateRange',
            dataIndex: 'createTimes',
            width: 170,
            hideInSearch: false,
            hideInTable: false,
            hideInForm: true,
            sorter: true,
            render: (text: any, record: any) => record.createTime
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            width: 120,
            fixed: 'right',
            hideInTable: !authCode?.length,
            render: (_text: any, record: any) => [
                authCode?.includes('edit') ? <a key="编辑" onClick={() => operate?.('编辑', record)}>编辑</a> : null,
                authCode?.includes('del') ? <a key="删除" onClick={() => operate?.('删除', record)}>删除</a> : null
            ]
        },
    ]
}
