import ProTable, { ActionType, ColumnsState } from "@ant-design/pro-table"
import { MyTabs } from "@components/EnterpriseDetail"
import { getPersistenceKeyFromStorage, productApi } from "@utils/util"
import { Button, Modal, Select } from "antd"
import React, { useRef, useState } from "react"
import { BASEURL, invoiceCancel, oaTaskCancel, invoicePageList, oaTaskDel, oaTaskPause, oaTaskResume } from "@api/index"
import { useHistory } from "react-router-dom"
import { stringify } from "qs"
import { useHasAuthCode } from "@hooks/index"
import { ExportButton, ProTableWithFormSetting } from "@components/index"
import SaleContractAudit from "./cpnts/SaleContractAudit"
import PurchaseContractAudit from "./cpnts/PurchaseContractAudit"

const tabPanes = [
    {
        Tab: '销售合同',
        key: ' ',
        Content: SaleContractAudit
    },
    {
        Tab: '采购合同',
        key: 2,
        Content: PurchaseContractAudit
    }
]

export default () => {
    const [activeKey, setActiveKey] = useState(' ')

    return(<div className="oa-task-center">
        <MyTabs 
          tabBarStyle={{paddingLeft: 20, marginBottom: 0, background: '#fff'}} 
          style={{marginTop: 0}} 
          tabPanes={tabPanes} 
          activeKey={activeKey} 
          onChange={setActiveKey}
        />
        
    </div>)
}
