import { ProColumns } from "@ant-design/pro-table";
import { DescListItem } from "@components/EnterpriseDetail/type";
import { downLoadWithATag } from "@utils/index";
import React from "react";

export const attachmentColumnsFn = (operate: any) => [
    {
        title: '文件名称',
        dataIndex: 'name',
    },
    {
        title: '操作',
        key: 'option',
        render: (text: string, record: any) => [
          ['jpg', 'png', 'gif', 'jpeg'].includes(record.format) ? <a key="link" style={{marginRight: 10}} onClick={() => operate && operate('预览', record)}>预览</a> : null,
          <a key="warn" onClick={() => downLoadWithATag(record.url)}>下载</a>
        ],
    },
]

export const descList: DescListItem[] = [
    {
        label: '系统编号',
        param: 'sysNumber',
        span: 1
    },
    {
        label: '提交人',
        param: 'creatorStr',
        span: 2
    },
    {
        label: '提交时间',
        param: 'createTime',
        span: 1
    },
    {
        label: '客户名称',
        param: 'customerName',
        span: 2
    },
    {
        label: '销售合同名称',
        param: 'xsContractName',
        span: 1
    },
    {
        label: '销售合同编号',
        param: 'xsContractNumber',
        span: 2
    },
    {
        label: '业务类型',
        param: 'businessTypeStr',
        span: 1
    },
    {
        label: '合同金额（元）',
        param: 'contractAmount',
        span: 2
    },
    {
        label: '合同回款比例(%)',
        param: 'backRate',
        span: 1
    },

    {
        label: '优惠折扣率(%)',
        param: 'discountRate',
        span: 2
    },
    {
        label: '有无文本合同',
        param: 'haveTextStr',
        span: 1,
        value: ({haveText}: any) => <span>{haveText ? '有文本' : '无文本'}</span>
    },
    {
        label: '合同是否盖章',
        param: 'whetherStampStr',
        span: 2,
        value: ({whetherStamp}: any) => <span>{whetherStamp ? '盖章' : '不盖章'}</span>
    },
    {
        label: '签约日期',
        param: 'signDate',
        span: 1
    },
    {
        label: '已回款金额(元)',
        param: 'backedAmount',
        span: 2,
        value: ({backedAmount}: any) => <span>{backedAmount}</span>
    },
    {
        label: '未回款金额(元)',
        param: 'noBackAmount',
        span: 1,
        value: ({noBackAmount}: any) => <span>{noBackAmount}</span>
    },
    {
        label: '已开票金额(元)',
        param: 'openedAmount',
        span: 2,
        value: ({openedAmount}: any) => <span>{openedAmount}</span>
    },
    {
        label: '未开票金额(元)',
        param: 'noOpenAmount',
        span: 3,
        value: ({noOpenAmount}: any) => <span>{noOpenAmount}</span>
    },
    {
        label: '备注',
        param: 'remark',
        span: 3
    },
]

export const columnsFn: (operate: Function) => ProColumns<any>[] = (operate) => {
    return [
      {
        title: '回款金额（元）',
        dataIndex: 'backAmount',
        fixed: 'left',
        width: 120,
        align: 'center',
      },
      {
        title: '回款日期',
        dataIndex: 'backTime',
        width: 110,
        align: 'center',
      },
      {
        title: '收款账号',
        dataIndex: 'payAccountStr',
        align: 'center',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        align: 'center',
        render: (text: any, record: any) => <div style={{width: 300, }}>{record.remark}</div>
      },
      {
        title: '审批状态',
        dataIndex: 'statusStr',
        width: 100,
        align: 'center',
      },
      {
        title: '提交人',
        dataIndex: 'creatorStr',
        width: 80,
        align: 'center',
      },
      {
        title: '提交时间',
        dataIndex: 'createTime',
        align: 'center',
      }
    ]
}